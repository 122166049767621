export enum TransactionStatus {
    Pending = 0,
    Success = 1,
    Failure = 2
}

export enum TransactionType {
    Charge = 0,
    Refund = 1,
    ChargeBack = 2
}

export interface ILineItem {
    EventId: string;
    CatalogItemId: string;
    InvoiceId: string;
    StudentId: string;
    Price: number;
    Tax?: number | undefined;
    Description: string;
}

export interface ITransaction {
    Id: string;
    DeclineCode: string;
    Date: Date;
    Discount: number;
    ErrorCode: string;
    ErrorType: string;
    ErrorDescription: string;
    UserId: string;
    Status: TransactionStatus;
    Type: TransactionType;
    Method: string;
    Category: string;
    CardBrand: string;
    LastFour: string;
    ConvenienceFee: number;
    
    LineItems: ILineItem[];
}

let d: Date = new Date();
export class Transaction implements ITransaction {
    Id: string;
    DeclineCode: string;
    Date: Date;
    Discount: number;
    ErrorCode: string;
    ErrorType: string;
    ErrorDescription: string;
    UserId: string;
    Status: TransactionStatus;
    Type: TransactionType;
    Category: string;
    Method: string;
    CardBrand: string;
    LastFour: string;
    ConvenienceFee: number;
    
    LineItems: ILineItem[];

    constructor(
        params: ITransaction = {} as ITransaction) {
            
        let {
            Id = '',
            DeclineCode = '',
            Date = d,
            Discount = 0,
            ErrorCode = '',
            ErrorType = '',
            ErrorDescription = '',
            Status = TransactionStatus.Pending,
            Type = TransactionType.Charge,
            Category = "",
            CardBrand = '',
            Method = '',
            LastFour = '',
            LineItems = [],
            UserId = '',
            ConvenienceFee = 0
        } = params;
                    
        this.Id = Id;
        this.DeclineCode = DeclineCode;
        this.Date = Date;
        this.Discount = Discount;
        this.ErrorCode = ErrorCode;
        this.ErrorType = ErrorType;
        this.ErrorDescription = ErrorDescription;
        this.UserId = UserId;
        this.Status = Status;
        this.Type = Type;
        this.Method = Method;
        this.Category = Category;
        this.CardBrand = CardBrand;
        this.LastFour = LastFour;
        this.LineItems = LineItems;
        this.ConvenienceFee = ConvenienceFee;
    }

    GetTotal() {
        var total = 0;
        
        for(let l of this.LineItems) {
            total = total + l.Price;

            if(l.Tax !== undefined) {
                total = total + l.Tax;
            }
        }

        total = total - this.Discount + this.ConvenienceFee;

        return this.Type === TransactionType.Charge ? total : total * -1;
    }

    GetTotalLessFees() {
        var total = this.GetTotal();

        //TODO: revisit this so it's not hardcoded client-side
        return total > 0 ? (total - (total * 0.029) - 0.3) : 0;
    }

    GetDescription() {
        return this.LineItems.map(l=>l.Description).join(', ');
    }
}