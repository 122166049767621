export interface ICategory {    
    Id: string;
    Title: string;
}

export class Category implements ICategory {
    Id: string;
    Title: string;

    constructor(
        params: ICategory = {} as ICategory) {
            
        let {
            Id = "",
            Title  = ""
        } = params;
            
        this.Id = Id;
        this.Title = Title;
    }
}