import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useAccount, useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, EventType } from '@azure/msal-browser';
import { completeOnboarding } from '../ApiService';
import { Stack, Text } from '@fluentui/react';
import ErrorPage from '../pages/ErrorPage';

const RedeemInvitation : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Redeem Invitation" });

    const navigate = useNavigate();
    let { redemptionToken } = useParams<{redemptionToken: string }>() as {redemptionToken: string };
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
    const [onboardingState, setOnboardingState] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: `${document.location.href}|${redemptionToken}`});

    const doOnboarding = async () => {
        await completeOnboarding(instance, account!, onboardingState);
        navigate('/', {replace:true});
    }

    useEffect(()=> {
        if(account !== null) {
            doOnboarding();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onboardingState, account]);

    useEffect(()=> {
        const callbackId = instance.addEventCallback(async (message: any) => {
            switch (message.eventType)
            {
                case EventType.LOGIN_FAILURE:
                    {                     
                        setErrorMessage(message.error.errorMessage);
                        break;
                    }
                case EventType.LOGIN_SUCCESS:
                    {
                        if (!isOnboarding) {
                            setIsOnboarding(true);
                            setOnboardingState(message.payload.state.split('|')[1]);               
                        }
                        break;
                    }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account]);
       
    return (  
        errorMessage.length > 0 ?
            <ErrorPage error={{error: errorMessage}} /> :
            <div style={{backgroundColor:'white', padding:80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>
                <Stack horizontalAlign='center' tokens={{childrenGap:10}}>
                    <Text variant='xLargePlus'>We're getting your account ready.</Text>
                    <br />
                    <Loader Text='Just a moment...' />
                </Stack>
            </div>
    );
}

export default RedeemInvitation;