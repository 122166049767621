import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ActionButton, Checkbox, Label, Stack, TextField, Text, DefaultButton, PrimaryButton } from '@fluentui/react';
import { EditIcon } from '@fluentui/react-icons-mdl2';
import React, {FC, useEffect, useState} from 'react';
import { getAllLevels, getAllPrograms, getViralJoinEnv } from '../ApiService';
import { Level } from '../model/Level';
import { Membership } from '../model/Membership';
import { Program } from '../model/Program';
import { Student } from '../model/Student';
import { UserInvitation } from '../model/UserInvitation';
import { UserRoles } from '../model/UserProfile';
import StudentEditor from './StudentEditor';
import TagEditor from './TagEditor';
import { ValidateEmailField } from '../Validation';
import { GetGenderString } from '../Display';
import { DateTime } from 'luxon';

interface IData {
    disabled: boolean;
    canEditEmail?: boolean;
    membership?: Membership;
    onStateChange: (currentState: CurrentState, userInvitation: UserInvitation)  => void;
}

export enum CurrentState {
    CreateUser = 1,
    AddStudent = 2,
    CanSubmit = 3
}

const UserEditor : FC<IData> = ({onStateChange, disabled, membership, canEditEmail}) => { 
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [userInvitation, setUserInvitation] = useState<UserInvitation>(membership === undefined ? 
      new UserInvitation() : 
      new UserInvitation({Emails:[membership.Users![0].DisplayName], Roles:membership.Users![0].Roles, Students: membership.Students, MarketingConsent: false}));
    const [currentState, setCurrentState] = useState<CurrentState>(CurrentState.CreateUser);
    const [allPrograms, setAllPrograms] = useState<Program[]>([]);
    const [allLevels, setAllLevels] = useState<Level[]>([]);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [canGoBack, ] = useState<boolean>(true);
    const [pendingStudent, setPendingStudent] = useState<Student>();    
    const [nextButtonText, setNextButtonText] = useState<string>("Next");
    const [editStudent, setEditStudent] = useState<Student>();
    const [requestStudentGender, setRequestStudentGender] = useState<boolean>(false);
    const [requestStudentDob, setRequestStudentDob] = useState<boolean>(false);

    useEffect(()=> {
        onStateChange(currentState, userInvitation);

        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[currentState]);

    useEffect(()=> {
        if(!isAuthenticated) {
            return;
        }
       
        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var env = await getViralJoinEnv();
                var programs = await getAllPrograms(instance, account);
                var levels = await getAllLevels(instance, account);
                
                if(env !== undefined) {                
                  setRequestStudentDob(env?.RequestStudentDob);
                  setRequestStudentGender(env?.RequestStudentGender);
                }

                setAllPrograms(programs);
                setAllLevels(levels);
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

      const checkModelValidationState = () => {
        if(currentState === CurrentState.CreateUser) {
            //can move next only when at least one valid email is entered and at least one role is selected
            if(userInvitation.Emails.length === 0) {
                setCanGoNext(false);
                return;
            }

            if(userInvitation.Roles.length === 0) {
                setCanGoNext(false);
                return;
            }

            setCanGoNext(true);
            return;
        }
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [userInvitation]);              

      const updateRoles = (e?: React.FormEvent, checked?: boolean) => {
        var id = (e?.target as HTMLInputElement).getAttribute("id");
        var roles = userInvitation.Roles;
  
        if(checked) {
          roles.push(id!);
  
          setUserInvitation(userInvitation => ({...userInvitation, Roles: roles }));
        }
        else {
          roles.forEach( (item, index) => {
            if(item === id) {
              roles.splice(index, 1);
            }
          })
  
          setUserInvitation(userInvitation => ({...userInvitation, Roles: roles }));
        }
      }
  
      const goNextState = () => {
        switch(currentState) {
            case CurrentState.CreateUser:                
                setCurrentState(CurrentState.CanSubmit);
                setNextButtonText("Save");
                return;
            case CurrentState.AddStudent:
                if(editStudent === undefined) {
                  userInvitation.Students.push(pendingStudent!);
                  setUserInvitation(userInvitation);
                }
                else {
                  setEditStudent(undefined);
                }

                setCurrentState(CurrentState.CanSubmit);
                return;
        }
      }

      const goPreviousState = () => {
        switch(currentState) {
            case CurrentState.AddStudent:
                setCurrentState(CurrentState.CanSubmit);
                return;
            // case CurrentState.CanSubmit:
            //     setCurrentState(CurrentState.AddStudent);
            //     return;
        }
      }

      const studentValidationStatusChanged = (student: Student, isValid: boolean) => {
          
          if(!isValid) {
              setCanGoNext(false);
              return;
          }
          
          if(editStudent !== undefined) {
            var toUpdate = userInvitation.Students.find(s=>s.Id === student.Id);          

            if(toUpdate === undefined) {
              return;
            }

            toUpdate.FirstName = student.FirstName;
            toUpdate.LastName = student.LastName;
            toUpdate.LevelId = student.LevelId;
            toUpdate.ProgramIds = student.ProgramIds;
            toUpdate.DateOfBirth = student.DateOfBirth;
            toUpdate.Weight = student.Weight;
            toUpdate.Gender = student.Gender;

            setUserInvitation({...userInvitation});
          }
          
          setPendingStudent(student);
          setCanGoNext(true);
      }

      const onEditStudent = (s:Student) => {
        setEditStudent(s);
        setCurrentState(CurrentState.AddStudent);
      }
  
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                {currentState === CurrentState.CreateUser ?                     
                    <>                      
                        <Label required>Email(s)</Label>
                        <TagEditor  
                          disabled={canEditEmail === undefined ? false : !canEditEmail}
                          defaultSelectedItems={userInvitation.Emails.map((e,idx)=> { return { name:e, key:idx} })}
                          itemLimit={3} 
                          onChange={(items) => setUserInvitation(userInvitation => ({...userInvitation, Emails: items!}))} 
                          validator={ValidateEmailField} />
                        <Label required>Roles</Label>
                        {UserRoles.GetAll().map((r,idx)=> {
                        return <Stack key={idx}><Checkbox id={r} key={idx} label={r} defaultChecked={userInvitation.Roles.indexOf(r) !== -1} onChange={updateRoles} /><Text variant='small'>{UserRoles.GetDescription(r)}</Text> </Stack>
                        })}

                        <Label>Welcome Message (optional)</Label>
                        <TextField 
                          inputMode="text" 
                          disabled={canEditEmail === undefined ? false : !canEditEmail}
                          multiline
                          placeholder="An optional message to include with the invitation email that will be sent to this user."
                          rows={3}
                          onChange={(c: React.FormEvent, newValue?: string) => setUserInvitation(userInvitation => ({...userInvitation, CustomMessage: newValue!}))} 
                          defaultValue={userInvitation?.CustomMessage} />

                    </>
                    : null}
                    
                    {currentState === CurrentState.AddStudent ?
                        <>
                            <Label>Student Details</Label>
                            <StudentEditor 
                              levels={allLevels}
                              programs={allPrograms} 
                              editStudent={editStudent}
                              requestDob={requestStudentDob}
                              requestGender={requestStudentGender}
                              onModelValidationStatusChanged={studentValidationStatusChanged} />
                        </>
                        : null
                        }

                    {currentState === CurrentState.CanSubmit ? 
                        <>
                        <Label>User</Label>
                        <Text>Email(s): {userInvitation.Emails.join('; ')}</Text>
                        <Text>Roles: {userInvitation.Roles.join(', ')}</Text>
                        {userInvitation.CustomMessage !== undefined && userInvitation.CustomMessage.length > 0 ? <Text>Message: {userInvitation.CustomMessage}</Text> : null}
                        <Label>Students</Label>
                        {userInvitation.Students.length === 0 ? <Text>No students.</Text> : null}
                        {userInvitation.Students.map((s,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                              <Stack >
                                  <Text variant='medium'>{s.FirstName} {s.LastName}</Text>
                                  {s.DateOfBirth !== undefined ? <Text variant='smallPlus'>{DateTime.fromJSDate(s.DateOfBirth).toFormat('yyyy-MM-dd')}</Text> : null}
                                  {s.Gender !== undefined ? <Text variant='smallPlus'>{GetGenderString(s.Gender)}</Text> : null}
                                  <Text variant='smallPlus'>{allPrograms.find(p=>p.Id === s.ProgramIds[0])?.Name}</Text>
                                  <Text variant='smallPlus'>{allLevels.find(l=>l.Id === s.LevelId)?.Label}</Text>
                              </Stack>
                              <EditIcon style={{cursor:'pointer'}} onClick={() => onEditStudent(s)} />
                            </Stack>
                            )
                        })}
                        <ActionButton disabled={disabled} style={{fontSize:11, float:'right'}} iconProps={{iconName:'Add', style:{fontSize: 11}}} onClick={()=>setCurrentState(CurrentState.AddStudent)}>Add member</ActionButton>

                        </> : null
                    }
<br />
                    <Stack horizontal horizontalAlign='end' tokens={{childrenGap:10}}>                      
                        {currentState === CurrentState.AddStudent ? <DefaultButton disabled={!canGoBack} onClick={()=>goPreviousState()}>Cancel</DefaultButton> : null}
                        {currentState !== CurrentState.CanSubmit ? <PrimaryButton disabled={!canGoNext} onClick={()=>goNextState()}>{nextButtonText}</PrimaryButton> : null}
                    </Stack>
                  </Stack>

        </>
    )
}

export default UserEditor;