import { CheckboxVisibility, CommandBar, FontWeights, IColumn, ICommandBarItemProps, Modal, Panel, PanelType, Selection, SelectionMode, getTheme, mergeStyleSets } from '@fluentui/react';
import {useBoolean} from '@fluentui/react-hooks';
import React, { FC, useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { getAllTournaments, getAvailableTimezones, getCategories, getTournament } from '../../ApiService';
import { IAdminTournamentViewModel } from '../../model/AdminTournamentViewModel';
import { Tournament, TournamentType } from '../../model/Tournament';
import List, { IDocument } from '../../components/List';
import Loader from '../../components/Loader';
import TournamentWizard from '../../components/TournamentWizard';
import { ITimezoneViewModel } from '../../model/TimezoneViewModel';
import { Category } from '../../model/Category';

interface ITableRow extends IDocument {
    key: string;
    title: string;
    start: Date;
    end: Date;
    type: TournamentType;
    directors: string;
}

const TournamentManagement: FC = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);
  const [tournaments, setTournaments] = useState<IAdminTournamentViewModel[]>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState<ITableRow[]>();
  const [selection, setSelection] = useState<Selection>(new Selection());
  
  const [columns,] = useState<IColumn[]>([
    {
      key: 'title',
      name: 'Title',
      fieldName: 'title',
      minWidth: 90,
      maxWidth: 320,
      isResizable: true,
      isCollapsible: false,
      data: 'string',
      isPadded: true
    },
    {
      key: 'start',
      name: 'Start',
      fieldName: 'start',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: false,
      data: 'date',
      isPadded: true,
      onRender: (item: ITableRow) => <span>{item.start.toLocaleDateString()}</span>
    },
    {
      key: 'end',
      name: 'End',
      fieldName: 'end',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: false,
      data: 'date',
      isPadded: true,
      onRender: (item: ITableRow) => <span>{item.end.toLocaleDateString()}</span>
    },
    {
      key: 'type',
      name: 'Type',
      fieldName: 'type',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: false,
      data: 'string',
      isPadded: true,
      onRender: (item: ITableRow) => <span>{item.type === TournamentType.Hybrid ? "Hybrid" : item.type === TournamentType.InPerson ? "In Person" : "Online"}</span>
    },
    {
      key: 'directors',
      name: 'Directors',
      fieldName: 'directors',
      minWidth: 90,
      maxWidth: 120,
      isResizable: true,
      isCollapsible: false,
      data: 'string',
      isPadded: true
    },

  ]);

  const [allTz, setAllTz] = useState<ITimezoneViewModel[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const fetchAsync = async () => {
      var allTournaments = await getAllTournaments(instance, account!);
      setTournaments(allTournaments);
            
      var tzs = await getAvailableTimezones(instance, account!);
      setAllTz(tzs);

      var categories = await getCategories(instance, account!);
      setCategories(categories);
    }

    fetchAsync();
    
    // eslint-disable-next-line react-hooks/exhaustive-deps                 
  }, [isAuthenticated, instance, inProgress, account]);

  useEffect(() => {
    if (tournaments === undefined) {
      return;
    }

    var tableRows = buildTableRows(tournaments);
    setItems(tableRows);

    setIsLoaded(true);
  }, [tournaments]);

  const buildTableRows = (tournaments: IAdminTournamentViewModel[]) => {
    var tableRows = new Array<ITableRow>();

    for (let tournament of tournaments) {
      let title = `${tournament.Title}`;

      tableRows.push({
        key: tournament.Id,
        directors: tournament.Directors,
        end: tournament.End,
        start: tournament.Start,
        title: title,
        type: tournament.Type,
        getTitle: () => title
      });
    }
  
    return tableRows;
  }

  const [isTournamentEditorOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

  const commandBarItems: ICommandBarItemProps[] = [{
    key: 'new',
    text: 'New',
    disabled: false,//selection.getSelectedCount() !== 1,
    cacheKey: 'new',
    iconProps: { iconName: 'Add' },
    onClick: () => openPanelNew()
  },
  {
    key: 'edit',
    text: 'Edit',
    cacheKey: 'edit',
    disabled: selection.getSelectedCount() !== 1,
    iconProps: { iconName: 'Edit' },
    onClick: () => getTournamentThenOpenPanel()
  }]

  const [editTournament, setEditTournament] = useState<Tournament | null | undefined>(null);

  useEffect(() => {
    if (editTournament !== null) {
      openPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTournament]);

  const openPanelNew = () => {
    setEditTournament(undefined);
  }

  const getTournamentThenOpenPanel = () => {
    showModal();
    let tournamentId = selection.getSelection()[0].key as string;
    
    getTournament(instance, account!, tournamentId!).then(t => {
      setEditTournament(t);
      hideModal();
    });
  }

  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

  return (
    <>
      <div style={{ paddingTop: 20 }}>
        {isLoaded && items !== undefined ?
          <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <CommandBar
              items={commandBarItems}
              ariaLabel="Use left and right arrow keys to navigate between commands" />
            <List
              columns={columns}
              selectionMode={SelectionMode.single}
              enableSort
              onSelectionChanged={(s) => setSelection(s)}
              checkboxVisibility={CheckboxVisibility.hidden}
              items={items} />

            <Panel
              headerText={editTournament === null ? "Create Tournament" : "Edit Tournament"}
              type={PanelType.smallFixedFar}
              isOpen={isTournamentEditorOpen}
              isLightDismiss={false}
              onDismiss={() => { setEditTournament(null); dismissPanel(); }}
              closeButtonAriaLabel="Close">
              <TournamentWizard
                editTournament={editTournament != null ? editTournament! : undefined}
                timezones={allTz}
                categories={categories} />
            </Panel>
          </div>
          :
          <Loader Text='Just a moment...' />}
      </div>
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        containerClassName={contentStyles.container}>
        <Loader Text="Fetching, just a moment..." />
      </Modal>
    </>
  )
}

export default TournamentManagement;