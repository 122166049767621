import React, { FC } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {useParams } from 'react-router-dom';
import StudentVideoCapture from '../components/StudentVideoCapture';

const StudentSubmission : FC = () => {
    let { curriculumId } = useParams<{curriculumId: string }>() as {curriculumId: string };
    let { studentId } = useParams<{studentId: string }>() as {studentId: string };
    
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"StudentSubmission" });

    return (
      <StudentVideoCapture CurriculumId={curriculumId} StudentId={studentId} />
    )
}

export default StudentSubmission;