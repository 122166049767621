import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useParams } from 'react-router-dom';
import { getCurriculum, getReviewById, getStudentCurriculumSubmission, getUserProfileImage } from '../ApiService';
import Loader from "../components/Loader";
import { StudentCurriculumSubmission } from '../model/StudentCurriculumSubmission';
import { CurriculumItem } from '../model/CurriculumItem';
import { ActivityItem, IActivityItemStyles, Label, precisionRound, Rating, Stack, Text } from '@fluentui/react';
import { InteractionType } from '@azure/msal-browser';
import VideoPlayer from '../components/VideoPlayer';
import { VideoMarker } from '../model/VideoMarker';
import { RubricType } from '../model/Rubric';
import { IResultRow, ReviewCollection, ScoreTypes } from '../model/ReviewCollection';

const StudentCurriculumReview : FC = () => {
    let { reviewId } = useParams<{reviewId: string }>() as {reviewId: string };
    
    const isAuthenticated = useIsAuthenticated();
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"StudentCurriculumReview" });
    
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const [profileImages, setProfileImages] = useState<any[]>();
    const [resultRows, setResultRows] = useState<IResultRow[]>();
    const [reviewCollection, setReviewCollection] = useState<ReviewCollection>();
    const [curriculum, setCurriculum] = useState<CurriculumItem>();
    const [studentCurriculumSubmission, setStudentCurriculumSubmission] = useState<StudentCurriculumSubmission>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                
                try {
                    var reviewCollection = await getReviewById(instance, account, reviewId);              
                    var studentCurriculumSubmission = await getStudentCurriculumSubmission(instance, account, reviewCollection!.Reviews[0].StudentCurriculumSubmissionId);
                    var curriculum = await getCurriculum(instance, account, studentCurriculumSubmission.CurriculumId);
                    var profileImages :any[] = [];

                    for(let review of reviewCollection!.Reviews) {
                        if(review.AuthorId !== undefined && review.AuthorId !== null){
                            profileImages.push({key: review.AuthorId, value: await getUserProfileImage(instance, account, review.AuthorId)});
                        }                        
                    }

                    setProfileImages(profileImages);
                    
                    setCurriculum(curriculum);
                    setStudentCurriculumSubmission(studentCurriculumSubmission);
                    setReviewCollection(reviewCollection);                           
                    setResultRows(reviewCollection!.Result.Rows);

                    setIsLoading(false);
                }            
                catch(error) {
                    console.log(error);
                }
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const getMarkers = () : VideoMarker[] => {        
        var toReturn = new Array<VideoMarker>();

        //TODO: bucket feedback by reviewer (ie: colour code)
        if(reviewCollection?.HasFeedback) {
            for(let review of reviewCollection.Reviews) {
                if(review?.Feedback !== undefined) {
                    for(let feedback of review?.Feedback) {
                        if(feedback.RubricType === RubricType.TimeScoped && feedback.Timestamp !== -1) {
                            toReturn.push(new VideoMarker({
                                Timestamp: feedback.Timestamp,
                                Title: feedback.RubricTitle!,
                                X: feedback.X,
                                Y: feedback.Y,
                                Height: feedback.Height,
                                Width: feedback.Width
                            }));
                        }
                    }
                }        
            }
        }
        
        return toReturn;
    }

    const activityItemStyles: IActivityItemStyles  = {
        activityText: {
            fontSize:16,
            fontWeight: 'Bold'
        },
        commentText: {
            fontSize:15
        }
    }

   return (  
        isAuthenticated ? 
            isLoading ? 
                <Loader Text="Fetching review, just a moment..." /> :
                <>                            
                    <h3>{curriculum?.Name}</h3> 
                    <Text variant='smallPlus'><em>{curriculum?.Description}</em></Text><br /><br />
                    <div style={{backgroundColor:'#FFF', padding:15}}>
                        <Stack horizontal wrap>
                            <Stack.Item grow={1}>
                                {resultRows !== undefined ?                                
                                    reviewCollection?.Result.Type === ScoreTypes.Sum ?
                                         resultRows.map((r,idx) => {
                                            if(!r.IsTotal) {
                                                return <div key={idx}><Label><strong>{r.Label}</strong></Label><Text style={{marginBottom:10}} variant='mediumPlus'>{precisionRound(r.Value * 100, 0)}</Text></div>
                                            }

                                            return null;
                                        })
                                        :
                                        resultRows.map((r,idx) => {
                                            if(r.IsTotal) {
                                                return <><strong>Overall</strong> <Rating style={{marginBottom:10}} readOnly rating={r.Value * 100/10} max={10} contentEditable={false} /></>
                                            }
                                            else {
                                                return <div key={idx}>{r.Label}<Rating style={{marginBottom:10}} readOnly rating={r.Value * 100/20} contentEditable={false} /></div>
                                            }
                                        }) : null
                                }
                                <br />
                            </Stack.Item>
                            <Stack.Item align='center' verticalFill grow={1}>
                                {reviewCollection?.Reviews.map((r, idx) => {
                                    return <ActivityItem 
                                                key={idx} 
                                                activityDescription={[<>{r?.Author} commented<br /></>]}
                                                comments={r.Text}
                                                activityPersonas={[{ 
                                                                imageUrl: (profileImages!.find(i => (i as any).key === r.AuthorId) as any)?.value, 
                                                                text: r.AuthoredBy === undefined ? r.Author : r.AuthoredBy.DisplayName, 
                                                                imageInitials: r.AuthoredBy === undefined ? 
                                                                    `${r.Author!.split(' ')[0][0]}${r.Author!.split(' ')[1][0]}` :
                                                                    `${r.AuthoredBy.DisplayName.split(' ')[0][0]}${r.AuthoredBy.DisplayName.split(' ')[1][0]}` 
                                                            }
                                                        ]}
                                                timeStamp={new Date(r.Created!).toDateString()} 
                                                isCompact={false} 
                                                styles={activityItemStyles}/>
                                })}
                                
                            </Stack.Item>
                        </Stack>
                    </div>
                    <Stack>
                        <div style={{backgroundColor:'#FFF', padding:15, marginTop:15}}>
                            <h3>Review</h3>
                            Explore the moments in the video {reviewCollection?.Reviews.flatMap(m=> m.Author)} would like to bring to your attention.
                            <Stack horizontal={true} tokens={{childrenGap: 5}} wrap={true}>
                                    <VideoPlayer  
                                        Resource={{
                                            MediaType:studentCurriculumSubmission!.ContentType, 
                                            Description: "",
                                            Name: "", 
                                            Uri:studentCurriculumSubmission!.ContentUri}}                                            
                                        Markers={getMarkers()}/>
                            </Stack>
                        </div>

                        <div style={{backgroundColor:'#FFF', padding:15, marginTop:15}}>
                            <h3>Next Steps</h3>
                            <p>Visit the <a href={`/student/${studentCurriculumSubmission?.StudentId}`}>curriculum library</a> to continue your learning.</p>
                              
                        </div>
                    </Stack>
                </>
            : null
   );
}

export default StudentCurriculumReview;;