import { CatalogItem } from "./CatalogItem";
import { CurriculumItem } from "./CurriculumItem";
import { Student } from "./Student";
import { Tournament } from "./Tournament";
import { TournamentRegistration } from "./TournamentRegistration";
import { UserProfile } from "./UserProfile";

export interface ITournamentRegistrationViewModel {
    Curriculum: CurriculumItem[];
    Catalog: CatalogItem[];
    Registrations: TournamentRegistration[];
    Students: Student[];
    Tournaments: Tournament[];
    Users: UserProfile[];
}

export class TournamentRegistrationViewModel implements ITournamentRegistrationViewModel {    
    Curriculum: CurriculumItem[];
    Catalog: CatalogItem[];
    Registrations: TournamentRegistration[];
    Students: Student[];
    Tournaments: Tournament[];
    Users: UserProfile[];

    constructor(
        params: ITournamentRegistrationViewModel = {} as ITournamentRegistrationViewModel) {
            
        let {
            Curriculum = [],
            Catalog = [],
            Registrations = [],
            Students = [],
            Tournaments = [],
            Users = []
        } = params;
            
        this.Catalog = Catalog;
        this.Curriculum = Curriculum;
        this.Registrations = Registrations;
        this.Students = Students;
        this.Tournaments = Tournaments;
        this.Users = Users;        
    }
}