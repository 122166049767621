export interface IResource {    
    Name: string;
    Description: string;
    MediaType: string;
    Uri: string;
}

export class Resource implements IResource {
    Name: string;
    Description: string
    MediaType: string;
    Uri: string;

    constructor(
        params: IResource = {} as IResource) {
            
        let {
            Name = "",
            MediaType = "",
            Uri = "",
            Description = ""
        } = params;
            
        this.Name = Name;
        this.Description = Description;
        this.MediaType = MediaType;
        this.Uri = Uri;
    }
}