import { ActionButton, Dropdown, IDropdownOption, ITag, Label, Stack, TagPicker } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Level } from '../model/Level';
import { Program } from '../model/Program';

interface IData { 
    allPrograms: Program[];
    allLevels: Level[];

    onModelValidationStatusChanged: (programId: string, levelIds: string[], isValid: boolean)  => void;
}

const AudienceEditor : FC<IData> = ({allPrograms, allLevels, onModelValidationStatusChanged}) => {    
    const [programId, setProgramId] = useState<string>();
    const [levelIds, setLevelIds] = useState<string[]>();
    const [selectedItems, setSelectedItems] = useState<ITag[]>([]);

    useEffect(()=>{
        setLevelIds(selectedItems?.flatMap(i=>i.key! as string));
    },[selectedItems]);
    
    useEffect(()=> {
        var isValid = programId !== undefined && programId.length > 0 && 
                      levelIds !== undefined &&  levelIds?.length > 0;

        onModelValidationStatusChanged(programId!, levelIds!, isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[levelIds, programId])

    const programOptions : IDropdownOption[] = allPrograms.map((p)=> { return {
        key: p.Id,
        text: p.Name,
        id: p.Id
    }});    

    const resolveSuggestions = (filter: string, selectedItems?: ITag[]) : ITag[] => {        
        var foundLevels = allLevels.filter(l=>l.ProgramIds.indexOf(programId!) !== -1).filter(l=> l.Description.toLowerCase().indexOf(filter.toLowerCase()) !== -1);

        if(foundLevels.length === 0) {
            return allLevels.filter(l=>l.ProgramIds.indexOf(programId!) !== -1).filter(l=> selectedItems?.find(s=>s.key === l.Id) === undefined).map(l => ({ name: l.Description, key: l.Id}));
        }

        return foundLevels.filter(l=> selectedItems?.find(s=>s.key === l.Id) === undefined).map(l => ({ name: l.Description, key: l.Id}));
    }    

    const addAllLevels = () => {        
        var foundLevels = allLevels.filter(l=>l.ProgramIds.indexOf(programId!) !== -1);
        selectedItems.splice(0);

        for(let l of foundLevels) {
            selectedItems?.push({ key:l.Id, name: l.Description });
        }

        setSelectedItems([...selectedItems]);
    }

    const onChange = (items: ITag[] | undefined) => {        
        items === undefined ? setSelectedItems([]) : setSelectedItems([...items]);
    }

    const getPlaceholder = () : string => {
        return selectedItems.length === 0 ? 
            'Start typing the level name, or add all levels below' : 
            '';
    }
    
    return (
        <>
           <Label>Program</Label>
            <Dropdown 
                required
                placeholder='Select the program this curriculum will be available to'
                options={programOptions} 
                onChange={(e,option) => { setProgramId(option?.key! as string); }} />
            
            <Label>Level(s)</Label>
            
            <TagPicker                
                inputProps={{autoFocus:true, placeholder: getPlaceholder()}}
                selectedItems={selectedItems}             
                disabled={programId === undefined} 
                onChange={onChange}
                onResolveSuggestions={resolveSuggestions} 
            />
            <Stack>
                <Stack.Item align='start'>
                    <ActionButton 
                        styles={{root:{ fontSize:10}}} 
                        iconProps={{iconName:'ImportAllMirrored'}} 
                        onClick={()=>addAllLevels()}
                        disabled={programId === undefined}>Add all levels</ActionButton>
                </Stack.Item>
            </Stack>
        </>
    );    
}

export default AudienceEditor;