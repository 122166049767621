import { DefaultButton, PrimaryButton, Stack, Text, Persona, PersonaSize } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMembership, getUserProfile, touchMembership } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import { UserProfile } from '../../model/UserProfile';
import { Membership } from '../../model/Membership';
import { GetGenderString, GetPhoneDisplayString } from '../../Display';
import Loader from '../../components/Loader';
import { useMediaQuery } from 'react-responsive';

const ReviewProfile : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Review Profile" });
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    
    const navigate = useNavigate(); 
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [membership, setMembership] = useState<Membership>();
    
    useEffect(() => {

        const fetchAsync = async () => {
            let profile = await getUserProfile(instance, account!);
            let membershipResult = await getMembership(instance, account!);

            setUserProfile(profile);
            setMembership(membershipResult.Result);
        }
        fetchAsync();
    }, [instance, account, inProgress]);

    const looksGood = async () => {
        let result = await touchMembership(instance, account!);
        
        if (!result) {
            alert("Sorry, something went wrong. Please try again.");
            return;
        }

        navigate(-1);
    }

    return (
        <>
            <div style={{backgroundColor:'white', padding:isMobile ? 10 : 80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>                                
                <Stack tokens={{childrenGap:10}}>
                    <Text variant='xLarge'>Are these details correct?</Text>
                    <Text variant='medium'>Please edit your profile to update any details that are no longer current.</Text>
                </Stack>
                {userProfile === undefined ? <Loader Text='Just a moment...' /> :
                    <Stack tokens={{ childrenGap: 20 }}>
                        <br />
                        <Stack>
                            <Text variant='mediumPlus'>{userProfile?.DisplayName}</Text>
                            <Text variant='medium'>{userProfile?.Email}</Text>
                            <Text variant='medium'>{GetPhoneDisplayString(userProfile?.Mobile)}</Text>
                            <Text variant='medium'>{membership?.TrainingFacilityTitle}</Text>
                        </Stack>
                        <Stack tokens={{ childrenGap: 20 }} horizontalAlign='start' wrap horizontal>                            
                            {membership?.Students.map((student, idx) =>                                
                                <Stack key={idx}>
                                    <Persona
                                        imageUrl={student.ImageUri}
                                        text={`${student.FirstName} ${student.LastName}`}
                                        showSecondaryText                                            
                                        secondaryText={GetGenderString(student.Gender!)}
                                        size={isMobile ? PersonaSize.size32 : PersonaSize.size48} />
                                    
                                    <Text variant='smallPlus' style={{ paddingLeft: isMobile ? 40 : 60 }}>{student.DateOfBirth === undefined ? undefined : student.DateOfBirth.toLocaleDateString()}</Text>
                                    <Text variant='smallPlus' style={{ paddingLeft: isMobile ? 40 : 60, fontWeight: 'bold' }}>{student.Level?.Label}</Text>
                                    <Text variant='smallPlus' style={{ paddingLeft: isMobile ? 40 : 60 }}>Special needs: {student.SpecialNeeds ? "Yes" : "No"}</Text>
                                </Stack>
                            )}
                        </Stack>
                        
                        <Stack.Item align='center'>
                            <br />
                            <Stack style={{ width: isMobile ? '90vw' : 'initial' }} horizontal={isMobile ? false : true} tokens={{ childrenGap: 10 }}>
                                <PrimaryButton disabled={membership === undefined} style={{ padding: 10 }} onClick={looksGood}>Looks good</PrimaryButton>
                                <DefaultButton iconProps={{ iconName: 'Edit' }} style={{ padding: 10 }} onClick={() => navigate('/user/profile')}>Edit profile</DefaultButton>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                }
            </div>
        </>
    )
}

export default ReviewProfile;