import React, { FC, useEffect, useState } from 'react';
import { DefaultButton, Dropdown, IDropdownOption, Label, PrimaryButton, Stack, TextField, Text, ActionButton } from '@fluentui/react';
import { CurriculumItem, CurriculumResponse } from '../model/CurriculumItem';
import { Level } from '../model/Level';
import { Resource } from '../model/Resource';
import ResourceEditor from './ResourceEditor';
import { Audience, AudienceComparison } from '../model/Audience';
import { Program } from '../model/Program';
import { DeleteIcon, EditIcon } from '@fluentui/react-icons-mdl2';
import AudienceEditor from './AudienceEditor';
import { Category } from '../model/Category';

interface ICurriculumProps {
    disabled: boolean;
    item?: CurriculumItem;
    categories: Category[];
    programs: Program[];
    levels: Level[];
    onStateChange: (currentState: CurrentState, curriculum: CurriculumItem) => void;
}

export enum CurrentState {
    CreateCurriculum = 1,
    SpecifyAudience = 2,
    AddAudience = 3,
    AddResource = 4,
    CanSubmit = 5
}

const CurriculumEditor : FC<ICurriculumProps> = ({item, disabled, categories,programs,levels, onStateChange}) => {

    const [curriculumItem, setCurriculumItem] = useState<CurriculumItem>(item === undefined ? new CurriculumItem() : item);
    const [currentState, setCurrentState] = useState<CurrentState>(CurrentState.CreateCurriculum);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [canGoBack, ] = useState<boolean>(true);
    const [nextButtonText, setNextButtonText] = useState<string>("Next");
    const [pendingResource, setPendingResource] = useState<Resource>(new Resource());
    const [pendingAudience, setPendingAudience] = useState<Audience[]>([]);
    const [editResource, setEditResource] = useState<Resource>();
    
    const responseOptions : IDropdownOption[] = [
        { key: CurriculumResponse.None, text: 'None',  },
        { key: CurriculumResponse.Video, text: 'Video' }        
      ];

    const categoryOptions : IDropdownOption[] = categories.map((c)=> { return {
        key: c.Title, //TODO: switch this over to the category id 
        text: c.Title
    }});

    const programOptions : IDropdownOption[] = programs.map((p)=> { return {
        key: p.Id,
        text: p.Name,
        id: p.Id
    }});

    useEffect(()=> {
        onStateChange(currentState, curriculumItem);

        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[currentState]);

    
    useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, [curriculumItem]);
    
    
    const checkModelValidationState = ()  => {
        switch(currentState)
        {
            case CurrentState.CreateCurriculum:
                {
                    if(curriculumItem.Name.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }
        
                    if(curriculumItem.Description.length === 0) {
                    setCanGoNext(false);
                    return;    
                    }

                    if(curriculumItem.Response !== CurriculumResponse.None && curriculumItem.Category.length === 0) {
                    setCanGoNext(false);
                    return;    
                    }

                    break;
                }
            case CurrentState.SpecifyAudience:
                {
                    if(curriculumItem.IntendedAudience[0] === undefined ||
                        curriculumItem.IntendedAudience[0].ProgramId.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }

                    if(curriculumItem.IntendedAudience[0] === undefined ||
                        curriculumItem.IntendedAudience[0].LevelId.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }

                    break;
                }
        }

        setCanGoNext(true);
    }   

  const goNextState = () => {
    switch(currentState) {
        case CurrentState.CreateCurriculum:         
            if(curriculumItem.IntendedAudience === undefined) {
                curriculumItem.IntendedAudience = [];
            }       

            setCurrentState(CurrentState.SpecifyAudience);
            setNextButtonText("Next");
            setCanGoNext(curriculumItem.IntendedAudience.length > 0);
            return;
        case CurrentState.SpecifyAudience:         
            if(curriculumItem.IntendedAudience === undefined) {
                curriculumItem.IntendedAudience = [];
            }            

            setCurrentState(CurrentState.CanSubmit);
            setNextButtonText("Save");
            return;
        case CurrentState.AddAudience:
            for(let a of pendingAudience!) {
                curriculumItem.IntendedAudience!.push(a);
            }

            setCurriculumItem(curriculumItem);
            setCurrentState(CurrentState.SpecifyAudience);
            setNextButtonText("Next");
            return;        
        case CurrentState.AddResource:
            if(editResource !== undefined) {
                var foundIdx = curriculumItem.Resources!.findIndex( (v) => v === editResource!);
                curriculumItem.Resources!.splice(foundIdx, 1);
                curriculumItem.Resources!.push(pendingResource!);
            }
            else {                
                curriculumItem.Resources!.push(pendingResource!);
            }

            setCurriculumItem(curriculumItem);
            setCurrentState(CurrentState.CanSubmit);
            return;
    }
  }

  const goPreviousState = () => {
    switch(currentState) {
        case CurrentState.AddResource:
            setCurrentState(CurrentState.CanSubmit);
            return;
        case CurrentState.AddAudience:
            setCurrentState(CurrentState.SpecifyAudience);
            return;
        // case CurrentState.CanSubmit:
        //     setCurrentState(CurrentState.AddStudent);
        //     return;
    }
  }

    const audienceValidationStatusChanged = (programId: string, levelIds: string[], isValid: boolean) => {
            
        if(!isValid) {
            setCanGoNext(false);
            return;
        }

        var audience : Audience[] = [];

        for(let lId of levelIds) {
            audience.push({ ProgramId: programId, LevelId: lId, Comparison: AudienceComparison.Exact});
        }
        
        setPendingAudience(audience);
        setCanGoNext(true);
    }  

  const resourceValidationStatusChanged = (resource: Resource, isValid: boolean) => {
          
        if(!isValid) {
            setCanGoNext(false);
            return;
        }

        setPendingResource(resource);
        setCanGoNext(true);
    }

    const onEditResource = (r:Resource) => {
        setEditResource(r);
        setCurrentState(CurrentState.AddResource);
    }

    const onRemoveAudience = (aud: Audience) => {
        var idx = curriculumItem.IntendedAudience.indexOf(aud);

        if (idx === -1) {
            return;
        }

        curriculumItem.IntendedAudience.splice(idx,1);

        if (curriculumItem.IntendedAudience.length === 0) {
            setCurrentState(CurrentState.SpecifyAudience);
        }

        setCurriculumItem({...curriculumItem, IntendedAudience: curriculumItem.IntendedAudience});
    }

    const getAudienceString = (aud: Audience, idx: number, commaSeparated: boolean = true) : string => {
        var postFix = commaSeparated ? `${idx === curriculumItem.IntendedAudience.length-1 ? '' : ', '}` : '';
        return `${programOptions.find(po=>po.key === aud.ProgramId)?.text} - ${levels.find(l=>l.Id === aud.LevelId)?.Description}${postFix}`;
    }

    return (
        <>
        <Stack tokens={{childrenGap:10}}>
            {currentState === CurrentState.CreateCurriculum ?      
                <Stack>
                    <TextField label="What will you be teaching?" 
                        required 
                        placeholder="This will be the title that your students see for this unit"
                        defaultValue={curriculumItem.Name}
                        onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, Name: newValue!}))} />
                    <TextField label="Subtitle" 
                        defaultValue={curriculumItem.Subcategory} 
                        placeholder="Optional, displayed under the title for this unit."
                        onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, Subcategory: newValue!}))}/>
                    <TextField label="Description"
                        required 
                        placeholder="Let your students know what to expect in this unit. Remember, you can add more detail via various forms of media (ie: Video), which will be displayed below the description."
                        defaultValue={curriculumItem.Description} 
                        multiline rows={10} 
                        onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, Description: newValue!}))}/>
                    
                    <Dropdown label="Response Type" 
                        defaultSelectedKey={curriculumItem.Response} 
                        options={responseOptions} 
                        onChange={(e,option) => setCurriculumItem(curriculumItem => ({...curriculumItem, Response: option?.key as CurriculumResponse}))} />
                    <Dropdown label="Category" 
                        required 
                        disabled={curriculumItem.Response === CurriculumResponse.None}
                        defaultSelectedKey={curriculumItem.Category}
                        options={categoryOptions!} 
                        placeholder="Select the category of rubric to align this curriculum to."
                        onChange={(e,option) => setCurriculumItem(curriculumItem => ({...curriculumItem, Category: option?.key! as string}))}/>
                    <TextField 
                        label="Response Description"
                        placeholder="Enter text to prompt your students to submit a response. If you omit this, the system will provide default messaging."
                        defaultValue={curriculumItem.ResponseDescription} 
                        onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, ResponseDescription: newValue!}))}
                        multiline 
                        disabled={curriculumItem.Response === CurriculumResponse.None}
                        rows={5} />
                        
                </Stack>
                : null}

            {currentState === CurrentState.SpecifyAudience ?
                <>
                    <Text>Your audience is the combination of program and level that will be able to see this curriculum.</Text>
                    <Label>Audience</Label>
                    {curriculumItem.IntendedAudience.length > 0 ? null : <Text variant='small'>No audience specified</Text>}
                    {curriculumItem.IntendedAudience?.map((aud,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack>
                                    <Text>{getAudienceString(aud, idx, false)}</Text>                           
                                </Stack>
                                <DeleteIcon style={{cursor:'pointer'}} onClick={() => onRemoveAudience(aud)} />
                            </Stack>
                            )
                    })} 
                    
                    <Stack>
                        <Stack.Item align='end'>
                            <ActionButton disabled={disabled} style={{fontSize:11}} iconProps={{iconName:'Add', style:{fontSize: 11}}} onClick={()=>setCurrentState(CurrentState.AddAudience)}>Add audience</ActionButton>
                        </Stack.Item>
                    </Stack>
                    <br />
                            {/* <Dropdown label="Program" 
                                defaultSelectedKey={curriculumItem.IntendedAudience[0]?.ProgramId} 
                                required 
                                placeholder="Select the program that this curriculum targets." 
                                options={programOptions} 
                                onChange={onProgramChanged} />                
                            <Dropdown label="Level" 
                                defaultSelectedKey={curriculumItem.IntendedAudience[0]?.LevelId} 
                                disabled={curriculumItem.IntendedAudience[0]?.ProgramId.length === 0} 
                                required 
                                placeholder="Select the level that this curriculum targets." 
                                options={levelOptions} 
                                onChange={onLevelChanged}/> */}
                </>
                : null
            }

            {currentState === CurrentState.AddAudience ?
                <>
                    <Label>Audience Details</Label>
                    <AudienceEditor allLevels={levels} allPrograms={programs} onModelValidationStatusChanged={audienceValidationStatusChanged} /> 
                </>
                : null
            }

            {currentState === CurrentState.AddResource ?
                <>
                    <Label>Resource Details</Label>
                    <ResourceEditor editResource={editResource} onModelValidationStatusChanged={resourceValidationStatusChanged} /> 
                </>
                : null
            }

            {currentState === CurrentState.CanSubmit ? 
                    <>
                        <Label>Curriculum</Label>
                        <Text>Name: {curriculumItem.Name}</Text>
                        <Text>Category: {curriculumItem.Category}</Text>
                        <Text>Subcategory: {curriculumItem.Subcategory}</Text>
                        <Label>Audience</Label>
                        {curriculumItem.IntendedAudience?.map((aud,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack>
                                    <Text>{getAudienceString(aud, idx, false)}</Text>                           
                                </Stack>
                                <DeleteIcon style={{cursor:'pointer'}} onClick={() => onRemoveAudience(aud)} />
                            </Stack>
                            )
                        })}                        
                        <Text>Description: {curriculumItem.Description}</Text>
                        <Label>Resources</Label>
                        {curriculumItem.Resources?.length === 0 ? <Text>No resources.</Text> : null}
                        {curriculumItem.Resources?.map((r,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack>
                                    <Text variant='medium'>{r.Name}</Text>
                                    <Text variant='smallPlus'>{r.Description}</Text>
                                    <Text variant='smallPlus'><a href={r.Uri} target='_blank' rel='noreferrer'>Link</a></Text>                                
                                </Stack>
                                <EditIcon style={{cursor:'pointer'}} onClick={() => onEditResource(r)} />
                            </Stack>
                            )
                        })}
                        <ActionButton disabled={disabled} style={{fontSize:11, float:'right'}} iconProps={{iconName:'Add', style:{fontSize: 11}}} onClick={()=>setCurrentState(CurrentState.AddResource)}>Add resource</ActionButton>
                    
                    </> : null
                }

                <Stack horizontal horizontalAlign='end' tokens={{childrenGap:10}}>                      
                    {currentState === CurrentState.AddResource ? <DefaultButton disabled={!canGoBack} onClick={()=>goPreviousState()}>Cancel</DefaultButton> : null}
                    {currentState !== CurrentState.CanSubmit ? <PrimaryButton disabled={!canGoNext} onClick={()=>goNextState()}>{nextButtonText}</PrimaryButton> : null}
                </Stack>
            </Stack>
        </>
    );    
}

export default CurriculumEditor;