export interface IReactionValue {
    Timestamp: number;
    Value: string;
}

export interface IReaction {
    Author: string;
    AuthorId: string;
    StudentCurriculumSubmissionId: string;
    Values: IReactionValue[];
}

export class Reaction implements IReaction {    
    Author: string;
    AuthorId: string;
    StudentCurriculumSubmissionId: string;
    Values: IReactionValue[];

    constructor(
        params: IReaction = {} as IReaction) {
            
        let {        
            Author = '',
            AuthorId = '',  
            StudentCurriculumSubmissionId = '',
            Values = []
        } = params;
            
        this.Author = Author;
        this.AuthorId = AuthorId;
        this.StudentCurriculumSubmissionId = StudentCurriculumSubmissionId;
        this.Values = Values;
    }
}