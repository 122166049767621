import React, { FC } from 'react';
import {  Stack, Text } from '@fluentui/react';
import { AddFriendIcon } from '@fluentui/react-icons-mdl2';

interface IStudentCardInfo {
    onClick: () => void;
}

const AddStudentCard : FC<IStudentCardInfo> = ({onClick}) => {

    return (
        <div style={{cursor:'pointer'}} className='student-card' onClick={(ev?: React.MouseEvent<HTMLElement, MouseEvent>) => onClick?.()}>
            <Stack>
                <div style={{padding:5}}></div>
                <Stack horizontal tokens={{childrenGap:5}}>
                    <div style={{padding:5}}></div>
                    <Stack.Item>
                        <AddFriendIcon style={{width:48, height:48}} />
                    </Stack.Item>
                    <Stack.Item align='center'>
                        <Text>Add member</Text>
                    </Stack.Item>
                </Stack>
                <div style={{padding:5}}></div>
            </Stack>
        </div>
    )
}

export default AddStudentCard;