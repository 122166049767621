import { ImageFit, Image, PrimaryButton, Stack, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import React, {FC,  useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { CurriculumItem, CurriculumResponse } from '../model/CurriculumItem';
import { Resource } from '../model/Resource';
import { Tournament, TournamentType } from '../model/Tournament';
import { StoragePurpose } from '../Storage';
import FileUploadButton, { IUploadedFile } from './FileUploadButton';
import VideoPlayer from './VideoPlayer';

interface IData {
    previewMode?: boolean;
    curriculum: CurriculumItem;
    studentId?: string;
    curriculumId?: string;
    tournament?: Tournament;
    canSubmit?: boolean;
    onFileUploaded?: (uploadedFile: IUploadedFile) => void;
}

const CurriculumViewer : FC<IData> = ({curriculum, onFileUploaded, studentId, curriculumId, previewMode, tournament, canSubmit = false}) => {
    let navigate = useNavigate();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isBeforeTournamentStartDate, ] = useState<boolean>(tournament === undefined ? false : DateTime.fromJSDate(tournament.StartDate) > DateTime.utc());
    const [isAfterTournamentEndDate, ] = useState<boolean>(tournament === undefined ? false : DateTime.fromJSDate(tournament.EndDate) < DateTime.utc());

    return (
        <>
        <div style={{backgroundColor:'white', padding:20}}>
            {curriculum.ImageUri?.length === 0 ? null : <><Image style={{maxWidth:400, marginLeft:previewMode ? 'auto' : 'initial', marginRight:previewMode ? 'auto' : 'initial'}} src={curriculum.ImageUri} imageFit={ImageFit.cover}/><br /></>}
            <div dangerouslySetInnerHTML={{__html:curriculum!.Description}} />
        </div>
        {curriculum.Resources !== undefined && curriculum.Resources.length > 0 ?
        <div style={{backgroundColor:'white', padding:20}}>
            <Text variant='xLarge'>Resources</Text>
            <p>Use these resources to practice{curriculum.Response === CurriculumResponse.Video ?', then when you are ready submit a video of yourself for feedback.' : '.'}</p>
            {curriculum.Resources!.map((r: Resource, idx: number)=> {
                if(r.MediaType === 'link') {
                    return <Stack key={idx}>
                        <em>{r.Name}</em>
                        {r.Description !== undefined && r.Description !== null && r.Description.length > 0 ? <><br /><span style={{fontSize:13}}>{r.Description}</span></> : null}
                        <br />
                        <a href={r.Uri} target="_blank" rel="noreferrer">View {r.Name}</a>
                    </Stack>
                }
                return <span key={idx}><VideoPlayer Resource={r} /><br /></span>
            })}    
        </div> : null}
            
            {curriculum.Response === CurriculumResponse.Video && tournament?.Type !== TournamentType.InPerson ?
                <div style={{backgroundColor:'white', padding:20, marginTop:20}}>
                <Text variant='xLarge'>Ready to submit video?</Text>
                {curriculum.ResponseDescription !== undefined && curriculum.ResponseDescription.length > 0 ?
                    <div dangerouslySetInnerHTML={{__html: curriculum.ResponseDescription}} /> : 
                    <>
                        <p>When you've practiced both in class <strong>and</strong> at home on your own and feel confident with your skills, get your computer, tablet or phone setup where you have enough space to film then click the record button below to get started.</p>
                        <p>You'll see a video preview and can choose to begin recording whenever you are ready.</p>            
                    </>
                }
                {previewMode !== undefined && previewMode ? <Text style={{color:'red'}} variant={'smallPlus'}><em>Respond buttons hidden during preview</em></Text> : 
                    <>                    
                        <PrimaryButton disabled={isUploading || isBeforeTournamentStartDate || isAfterTournamentEndDate || !canSubmit} onClick={()=> navigate(`/student/${studentId}/curriculum/${curriculumId}/submit`)}>Record video</PrimaryButton>
                        <span style={{fontSize:12}}>&nbsp;&nbsp; OR<FileUploadButton Purpose={StoragePurpose.StudentCurriculumSubmission} disabled={isUploading || isBeforeTournamentStartDate || isAfterTournamentEndDate || !canSubmit} StudentId={studentId!} CurriculumId={curriculumId!} IsUploading={(uploading) => setIsUploading(uploading)} FileUploaded={onFileUploaded!} style={{textDecoration:"underline" }}>upload from your device (max 100mb)</FileUploadButton></span>
                        {isBeforeTournamentStartDate ? <Text style={{color:'red'}} variant={'medium'}><br /><em>{tournament!.Title} starts on {DateTime.fromJSDate(tournament!.StartDate).toLocaleString()}. The record button will not be active until then.</em></Text> : null}
                        {isAfterTournamentEndDate ? <Text style={{color:'red'}} variant={'medium'}><br /><em>The tournament has ended.</em></Text> : null}
                        {canSubmit ? null : <Text style={{color:'red'}} variant={'medium'}><br /><em>Only one submission per event is allowed for tournaments.</em></Text>}
                    </>
                }
                </div> : 
                null
            }            
        </>
    )
}

export default CurriculumViewer;