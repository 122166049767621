import { useMsal } from '@azure/msal-react';
import { DefaultButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { getTenantLandingPageContent } from '../ApiService';

const UserNotFound : FC = () => {
    const { instance } = useMsal();
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"User Not Found" });

    const [canJoin, setCanJoin] = useState<boolean>(false);

    useEffect(()=> {
        const fetchAsync = async () => {
            var content = await getTenantLandingPageContent();

            if(typeof content === 'number') {
                return;
            }

            setCanJoin(content.CanJoin);
        }
        
        fetchAsync();
    },[]);

    const onLogout = () => {
        setIsLoggingOut(true);
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: document.location.origin
        }
        instance.logoutRedirect(logoutRequest);
    }

    const [isLoggingOut, setIsLoggingOut] = useState<boolean>();

    const createAccount = () => {
        setIsLoggingOut(true);
        //logout first, then create new account
        const logoutRequest = {
            account: instance.getActiveAccount(),
            postLogoutRedirectUri: `${document.location.origin}/join`
        }

        instance.logoutRedirect(logoutRequest);
    } 

    return (        
            <>
                {isLoggingOut ? null : 
                    <div style={{backgroundColor:'white', padding:80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>
                        {canJoin ? 
                        <>
                            <Text variant='xLargePlus'>We can't locate your account.</Text>
                                <br /><br /> 
                            <Stack tokens={{childrenGap:10}}>
                                <Text variant='medium'>If you believe you have already created an account, you may have chosen a different external login provider during registration.</Text>
                                <Text variant='medium'>Please sign out, then select a different external login provider from the list.</Text>
                                
                                <br />
                                <Stack.Item align='center'>
                                    <Stack horizontal tokens={{childrenGap:10}} wrap>
                                        <Stack.Item align='center'>
                                            <PrimaryButton style={{padding:20}} onClick={()=>createAccount()}>Create free account</PrimaryButton>
                                        </Stack.Item>
                                        <Stack.Item align='stretch'>
                                            <DefaultButton style={{padding:20}} onClick={()=>onLogout()}>Sign Out</DefaultButton>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </> : 
                        <>
                            <Text variant='xLargePlus'>Looks like you're new here</Text>
                                <br /><br /> 
                            <Stack tokens={{childrenGap:10}}>
                                <Text variant='medium'>If you believe you have already accepted an invitation to join, you may have chosen a different external login provider during registration.</Text>
                                <Text variant='medium'>Please sign out and select a different external login provider from the list.</Text>
                                
                                <br />
                                <Stack.Item align='center'>
                                        <DefaultButton style={{padding:20}} onClick={()=>onLogout()}>Sign Out</DefaultButton>
                                </Stack.Item>
                                <br /><br />
                                <Text variant='medium'><strong>Keep seeing this message?</strong></Text>
                                <Text variant='medium'>Please contact the person who invited you to join for assistance.</Text>
                            </Stack>
                        </>}
                        
                    </div>
                }
            </>    
    )
}

export default UserNotFound;
