import React, { FC } from 'react';
import { Stack, Text } from '@fluentui/react';
import { Student } from '../model/Student';
import StudentCard from './StudentCard';

interface IData {
    SubjectName: string;
    Students: Student[];
    OnStudentSelected: (student: Student) => void;
}

const StudentSelector : FC<IData> = ({SubjectName, Students, OnStudentSelected}) => {

    return (
         
            <Stack tokens={{childrenGap:10}}>
                <Text variant='medium'>We'll use this to let {SubjectName} know who reacted</Text>
                <br />
                <Stack tokens={{childrenGap:10}} horizontal wrap>
                    {Students.map((s,idx)=> 
                        <StudentCard 
                                key={idx}
                                imageUri={s.ImageUri}
                                initials={`${s.FirstName[0]}${s.LastName[0]}`}
                                level={s.Level!}
                                studentName={s.FirstName} 
                                onClick={()=> OnStudentSelected(s)} /> )}
                </Stack>
            </Stack>    
    )

}

export default StudentSelector;