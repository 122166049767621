import { Student } from "./Student";
import { UserProfile } from "./UserProfile";

export interface IMembership {
    Id: string;
    Created: Date;
    Modified: Date;
    Invited: Date | undefined;
    Status: MembershipStatus;
    Students: Student[];
    TrainingFacilityTitle: string;
    TrainingFacilityId: string;
    Users: UserProfile[];
}

export enum MembershipStatus
{
    Active = 0,
    Canceled = 1,
    Trial = 2,
    Invited = 3,
    InvitationExpired = 4
}

export class Membership implements IMembership {
    Id: string;
    Status: MembershipStatus;
    Created: Date;
    Modified: Date;
    Invited: Date | undefined;
    Students: Student[];
    TrainingFacilityTitle: string;
    TrainingFacilityId: string;
    Users: UserProfile[];

    constructor(
        params: IMembership = {} as IMembership) {
            
        let {            
            Id = "",
            Created = new Date(),
            Modified = new Date(),
            Invited = undefined,
            Users = [],
            Students = new Array<Student>(),
            TrainingFacilityTitle = "",
            TrainingFacilityId = "",
            Status = MembershipStatus.Active
        } = params;
            
        this.Id = Id;
        this.Created = Created;
        this.Modified = Modified;
        this.Invited = Invited;
        this.Students = Students;
        this.TrainingFacilityTitle = TrainingFacilityTitle;
        this.TrainingFacilityId = TrainingFacilityId;
        this.Users = Users;
        this.Status = Status;
    }
}