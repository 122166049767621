import React, { FC } from 'react';
import { ContextualMenu, IContextualMenuItem, Persona, PersonaSize, PersonaPresence, Stack, Text, Panel } from '@fluentui/react';
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import {Features, useFeatures} from '../Features';
import { useIdentity } from '../Identity';
import { ChevronDownMedIcon } from '@fluentui/react-icons-mdl2'; 
import { useBoolean } from '@fluentui/react-hooks';
import FeedbackPanel from './FeedbackPanel';
import { UserRoles } from '../model/UserProfile';
import { TournamentStakeholderRoles } from '../model/TournamentReviewDashboardViewModel';

const UserAccount : FC = () => {
    const identity = useIdentity();
    const navigate = useNavigate();
    const features = useFeatures();
    const { instance } = useMsal();

    const linkRef = React.useRef(null);
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);
    const onShowContextualMenu = React.useCallback((ev: any) => {
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    }, []);

    const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);    
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const menuItems: IContextualMenuItem[] = [{
        key: 'profile',
        text: 'Edit Profile',
        iconProps: { iconName: 'ContactInfo' },
        onClick: () => { navigate('/user/profile') },
    }];

    const loadUri = (uri: string) => {
        if (document.location.href.endsWith('/manage')) {
            document.location.href = uri;   
        } 
        else {
            navigate(uri);
        }
    }
    
    if(features?.indexOf(Features.Tournament) !== -1) {
        menuItems.push({
            key: 'registrations',
            text: 'View Registrations',
            iconProps:{ iconName: 'ViewAll'},
            onClick: () => { navigate('/user/registrations') },
        });
    }

    if (identity.tournamentRoles !== null && identity.tournamentRoles !== undefined) {
        let submenuItemsForStaff = [];
        let submenuItemsForCoaches = [];

        for (let tournamentRole of identity.tournamentRoles) {
            let roleTitle = tournamentRole.Title;
            let id = tournamentRole.TournamentId;
            let url = `/tournament/${tournamentRole.TournamentId}/manage`;
            
            if (tournamentRole.Value === TournamentStakeholderRoles.Director ||
                tournamentRole.Value === TournamentStakeholderRoles.Admin ||
                tournamentRole.Value === TournamentStakeholderRoles.Staging) {
                    submenuItemsForStaff.push({
                    key: id,
                    iconProps: { iconName: 'AccountManagement' },
                    text: roleTitle,
                    onClick: () => loadUri(url),
                });
            }
            else if (tournamentRole.Value === TournamentStakeholderRoles.Coach) {
                submenuItemsForCoaches.push({
                    key: id,
                    iconProps: { iconName: 'ContactList' },
                    text: roleTitle,
                    onClick: () => loadUri(url),
                });
            }
        }

        if (submenuItemsForStaff.length > 0) {
            menuItems.push({
                key: "managetournament",
                iconProps: { iconName: 'CalendarSettings' },
                text: "Manage Events",
                subMenuProps: {
                    items: submenuItemsForStaff
                }
            })
        }

        if (submenuItemsForCoaches.length > 0) {
            menuItems.push({
                key: "manageathletes",
                iconProps: { iconName: 'ContactList' },
                text: "Coaches View",
                subMenuProps: {
                    items: submenuItemsForCoaches
                }
            })
        }
    }

    if (identity.userProfile?.Roles !== null && identity.userProfile?.Roles !== undefined) {
        for(var role of identity.userProfile?.Roles) {
            if(role === UserRoles.Admin) {
                menuItems.push({
                    key: 'admin',
                    text: 'Admin',
                    iconProps: { iconName: 'Admin' },
                    onClick: () => { navigate('/admin') },
                });
            }
        }
    }

    menuItems.push({
        key: 'help',
        text: 'Help',
        iconProps: { iconName: 'Help' },
        onClick: () => {openPanel()},
    });
    
    menuItems.push({
        key: 'signout',
        text: 'Sign Out',     
        iconProps: { iconName: 'SignOut' },
        onClick: () => {instance.logout()},
    });

    return (
        <AuthenticatedTemplate>
            {identity !== undefined ? 
                <>
                    <div ref={linkRef} style={{cursor:"pointer", marginRight:20}} onClick={onShowContextualMenu}>
                        <Stack horizontal tokens={{childrenGap:5}}>
                            <Stack.Item align='center'>
                                <Persona                                                            
                                    text={identity.userProfile?.DisplayName}
                                    imageUrl={identity.userProfile?.ProfileImageUri}
                                    size={PersonaSize.size32}
                                    hidePersonaDetails={true}            
                                    presence={PersonaPresence.none}
                                    imageAlt="" />
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <Text variant='smallPlus' style={{color:'white'}}>{identity.userProfile?.DisplayName.substring(0,identity.userProfile.DisplayName.indexOf(' ') === -1 ? identity.userProfile.DisplayName.length : identity.userProfile.DisplayName.indexOf(' '))}</Text>
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <ChevronDownMedIcon style={{height:10,width:10, fontWeight:'bolder'}} />
                            </Stack.Item>
                                

                        </Stack>
                    </div>
                    <ContextualMenu
                        items={menuItems}
                        hidden={!showContextualMenu}
                        target={linkRef}
                        onItemClick={onHideContextualMenu}
                        onDismiss={onHideContextualMenu} />
                </>
                     : 
                <div></div>
            }
            <Panel
                isLightDismiss
                isOpen={isOpen}
                onDismiss={dismissPanel}
                closeButtonAriaLabel="Close"
                headerText="Support">
                    <FeedbackPanel dismissPanel={dismissPanel} />
            </Panel>
        </AuthenticatedTemplate>
    );
}

export default UserAccount;