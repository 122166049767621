import { FC, useRef } from 'react';
import { IBasePicker, ITag, TagPicker } from '@fluentui/react/lib/Pickers';

interface IData {
    onChange: (items: string[] | undefined) => void;
    validator?: (item: string) => boolean;
    itemLimit?: number | undefined;
    disabled?: boolean;
    defaultSelectedItems?: ITag[] | undefined;
    onResolveSuggestions?: (filter: string, selectedItems?: ITag[]) => ITag[];
}

const TagEditor : FC<IData> = ({onChange, validator, itemLimit, defaultSelectedItems, disabled, onResolveSuggestions}) => { 
    const ref = useRef<IBasePicker<ITag>>(null);

    const resolveSuggestions = (filter: string, selectedItems?: ITag[]) : ITag[] => {
        //auto-completion via touch keyboards often adds a trailing space, trim the end to do what the user was more likely intending to do which is
        //to add the value without a trailing space (we're not writing sentences here)    
        filter = filter.trimEnd();
        if (validator !== undefined)
        {
            if(!validator(filter)) {
                return [];
            }
        }

        return [{ name: filter, key: filter}];        
    }

    const forceAddValue = (value: string) => {        
        value = value.trimEnd();
        
        if(value.length > 0) {
            ref.current?.completeSuggestion(true);
        }
    }
    
    return (
        <>
            <TagPicker
                componentRef={ref}     
                disabled={disabled} 
                defaultSelectedItems={defaultSelectedItems}      
                itemLimit={itemLimit}
                onBlur={b => forceAddValue(b.target.value)}
                onChange={(items) => onChange(items?.flatMap(i=>i.name))}
                onResolveSuggestions={onResolveSuggestions === undefined ? resolveSuggestions : onResolveSuggestions} 
            />
        </>
    )
}

export default TagEditor;