import { ActionButton, PrimaryButton, Text, TextField } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { DivisionRuleGroup, IDivisionRule } from '../../../model/DivisionRuleGroup';
import DivisionRuleCreator from './DivisionRuleCreator';

interface IData {
    onDivisionCreated: (ruleGroup: DivisionRuleGroup) => void;
}
const DivisionCreator : FC<IData> = ({onDivisionCreated}) => {
    const [divisionRuleGroup, setDivisionRuleGroup] = useState<DivisionRuleGroup>(new DivisionRuleGroup());
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isRuleEditorOpen, setIsRuleEditorOpen] = useState<boolean>(false);

    useEffect(()=> {
        //validate the division rule group
        //must have at least 1 rule
        if(divisionRuleGroup.Name.length === 0) {
            setIsValid(false);
            return;
        }

        if(divisionRuleGroup.Prefix.length === 0) {
            setIsValid(false);
            return;
        }

        if(divisionRuleGroup.Rules.length === 0) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
        
    },[divisionRuleGroup]);

    const onRuleCreated = (rule: IDivisionRule) => {
        setDivisionRuleGroup(divisionRuleGroup => ({...divisionRuleGroup, Rules: [...divisionRuleGroup.Rules, rule]}));
        setIsRuleEditorOpen(false);
    }
    
    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>            
            <div style={{display:'flex', flexDirection:'row', gap:10}}>            
                <TextField label="Name" 
                    required 
                    placeholder="The name of this rule group"
                    defaultValue={divisionRuleGroup.Name}
                    onChange={(e,newValue) => setDivisionRuleGroup(divisionRuleGroup => ({...divisionRuleGroup, Name: newValue!}))} />
                <TextField label="Prefix" 
                    defaultValue={divisionRuleGroup.Prefix} 
                    placeholder="The prefix used when generating credentials. Each division generated will begin with this prefix and a sequential number, ie: PRE-1, PRE-2"
                    onChange={(e,newValue) => setDivisionRuleGroup(divisionRuleGroup => ({...divisionRuleGroup, Subcategory: newValue!}))}/>
                <TextField label="Tags" 
                    defaultValue={divisionRuleGroup.Tags} 
                    placeholder="Optional, the tags are not presently shown but may be used in future"
                    onChange={(e,newValue) => setDivisionRuleGroup(divisionRuleGroup => ({...divisionRuleGroup, Tags: newValue!}))}/>                           
            </div>
            <hr />
            <Text>Rules</Text>
            {divisionRuleGroup.Rules.map((r,idx) => <Text key={idx}><strong>{r.Field}</strong> {r.Min} {r.Max}</Text>)}
            {isRuleEditorOpen ? 
                <DivisionRuleCreator 
                    onRuleCreated={onRuleCreated} /> :
                <ActionButton iconProps={{iconName:'Add'}} onClick={() => setIsRuleEditorOpen(true)}>Add</ActionButton>
                }

            <PrimaryButton disabled={!isValid}  onClick={() => onDivisionCreated(divisionRuleGroup)} >Create</PrimaryButton>
        </div>
    )
}

export default DivisionCreator;