import React, { FC, useState } from 'react';
import { Text, PrimaryButton, Stack, DefaultButton } from '@fluentui/react';
import { Tournament } from '../../model/Tournament';
import { CurriculumItem } from '../../model/CurriculumItem';
import { createTournament, updateTournament } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import { GetPriceDisplayString, GetPricingLabel } from '../../Display';
import Loader from '../Loader';
import { CatalogItem } from '../../model/CatalogItem';

interface IData {
    tournament: Tournament;
    isEdit: boolean;
    curriculumAdds?: CurriculumItem[];
    curriculumEdits?: CurriculumItem[];
    catalogAdds?: CatalogItem[];
    catalogEdits?: CatalogItem[];
    onCreated: (tournament: Tournament) => void;
    onGoBack: () => void;
}

const TournamentConfirmation : FC<IData> = ({tournament, isEdit, curriculumAdds, curriculumEdits, catalogAdds, catalogEdits, onCreated, onGoBack}) => {    
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
  
    const onCreateTournament = async () => {
        setCanSubmit(false);
        setIsSubmitting(true);

        var result = await createTournament(instance, account!, tournament, curriculumAdds!, catalogAdds!);
        setIsSubmitting(false);

        if (!result?.ok) {
            
            alert("An error occurred while creating the tournament.");            
            return;
        }
        else {
            var data = await result.json();
            
            //just worry about the id which may have been updated server side
            tournament.Id = data.id;
        }

        onCreated(tournament);        
    }

    const onUpdateTournament= async () => {
        setCanSubmit(false);
        setIsSubmitting(true);

        var result = await updateTournament(instance, account!, tournament, curriculumAdds, curriculumEdits, catalogAdds, catalogEdits);
        setIsSubmitting(false);

        if (!result?.ok) {
            
            alert("An error occurred while creating the tournament.");            
            return;
        }
        else {
            var data = await result.json();
            
            //just worry about the id which may have been updated server side
            tournament.Id = data.id;
        }

        onCreated(tournament);
    }

    return (
        isSubmitting ?
            <Loader Text="Publishing tournament, just a moment..." /> :
            <div style={{display:'flex', flexDirection:'column', gap:10}}>            
                <Text variant="large">{tournament.Title}</Text>
                <strong>Description</strong>
                <div dangerouslySetInnerHTML={{__html:tournament.Description}} />
                
                <strong>Registration dates</strong>
                <Text variant="medium">{tournament.DisplayAfter?.toLocaleDateString()} {tournament.DisplayAfter?.toLocaleTimeString()} - {tournament.RegistrationEnds?.toLocaleDateString()} {tournament.RegistrationEnds?.toLocaleTimeString()}</Text>
                <strong>Event dates</strong>
                <Text variant="medium">{tournament.StartDate.toLocaleDateString()} {tournament.StartDate.toLocaleTimeString()} - {tournament.EndDate.toLocaleDateString()} {tournament.EndDate.toLocaleTimeString()}</Text>
                <strong>Timezone</strong>
                <Text variant="medium">{tournament.Timezone}</Text>
                <strong>Terms</strong>
                <Text variant="small">{tournament.Terms}</Text>
                <strong>Events</strong>
                <Text variant="medium">{tournament.Events.map((e,idx) => <Stack key={idx} tokens={{childrenGap:10}}>
                    <div>
                        <Text variant='medium'>{e.Title}</Text><br />
                        {/* curriculum.find(c => c.Id === e.EventId)?.Name */}
                        <Text variant='smallPlus'>{GetPricingLabel(tournament, e)}</Text>
                        <br /><br />
                    </div>
                </Stack>)}</Text>
                <strong>Catalog</strong>
                {tournament.Catalog.length === 0 ?
                    <Text variant='medium'>No items in catalog.</Text> :
                    <Text variant="medium">{tournament.Catalog.map((c, idx) => <Stack key={idx} tokens={{ childrenGap: 10 }}>
                        <div>
                            <Text variant='medium'>{c.Name}</Text><br />
                            <Text variant='smallPlus'>{c.AvailableQuantity > 0 ? "Available" : "Not Available"}</Text><br />
                            <Text variant='smallPlus'>{GetPriceDisplayString(c.Price)}</Text>
                            <br /><br />
                        </div>
                    </Stack>)}</Text>
                }

                <br /><br />
                {isEdit ? 
                    <PrimaryButton disabled={!canSubmit} onClick={onUpdateTournament}>Update Tournament</PrimaryButton> :
                    <PrimaryButton disabled={!canSubmit} onClick={onCreateTournament}>Create Tournament</PrimaryButton>}
                <DefaultButton onClick={onGoBack}>Go back</DefaultButton>
            </div >    
    )
}

export default TournamentConfirmation;