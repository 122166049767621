import {Stack, Text } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Tournament, TournamentType } from '../../../model/Tournament';
import CountdownTimer from '../../../components/CountdownTimer';
import { DateTime } from 'luxon';
import { useAccount, useMsal } from '@azure/msal-react';
import Leaderboard from './Leaderboard';
import { getAllTournamentEntries, getRegisteredStudentsForTournament, getTournamentRegistrations } from '../../../ApiService';
import { Student } from '../../../model/Student';
import { TournamentEntryViewModel } from '../../../model/TournamentEntryViewModel';
import Carousel from '../../../components/Carousel';
import Loader from '../../../components/Loader';
import { RegistrationStatus, TournamentRegistrationStatus } from '../../../model/TournamentRegistration';
import ErrorPage from '../../ErrorPage';

interface IData {
    tournament: Tournament;
}

const TournamentViewer : FC<IData> = ({tournament}) => {
    const [hasTournamentStarted, setHasTournamentStarted] = useState<boolean>(false);
    const [hasTournamentEnded, setHasTournamentEnded] = useState<boolean>(false);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [students, setStudents] = useState<Student[]>();
    const [entries, setEntries] = useState<TournamentEntryViewModel[]>();
    const [canWatch, setCanWatch] = useState<boolean>();

    useEffect(()=> {

        const fetchAsync = async () => {
        
            if(account == null) {
                return;
            }
    
            //only allow users who have a registration for this tournament to watch            
            var registrations = await getTournamentRegistrations(instance, account, tournament.Id); 

            var hasRegistration = registrations === undefined ? 
                false : 
                registrations.some(r=> 
                    (r.Events.filter(e => e.Status === RegistrationStatus.Complete).length > 0 && 
                    r.Status === TournamentRegistrationStatus.Complete));

            setCanWatch(hasRegistration);

            if(!hasRegistration) {
                setIsLoading(false);
                return;
            }
            
            var entries = await getAllTournamentEntries(instance, account, tournament.Id, true);
            setEntries(entries);

            var students = await getRegisteredStudentsForTournament(instance, account, tournament.Id);
            setStudents(students);

            setHasTournamentStarted(DateTime.fromJSDate(tournament.StartDate) <= DateTime.utc());
            setIsLoading(false);
        }

        fetchAsync();
    }, [instance, account, tournament]);   

    return (
        isLoading ? <Loader Text='Just a moment...' /> :
            canWatch ?
                <div className='tournament-header'>
                                
                        <Stack tokens={{childrenGap:10}}>
                            {entries !== undefined && entries.length === 0 ? hasTournamentStarted ? 
                                hasTournamentEnded ? 
                                <Stack.Item align='center'>
                                    <Text variant="xLarge" style={{fontVariant:'small-caps'}}>{tournament.Title} has ended</Text>
                                </Stack.Item> 
                                : 
                                        (<>
                                        <Stack.Item align='center'>
                                            <Text variant="xLarge" style={{fontVariant:'small-caps'}}>{tournament.Title} Ends</Text>
                                        </Stack.Item>

                                        <Stack.Item align='center'>
                                            <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.EndDate!} timezone={tournament?.Timezone} completed={()=>setHasTournamentEnded(true)}/>
                                        </Stack.Item>                        
                                        </>)
                                : 
                                <>
                                <Stack.Item align='center'>
                                    <Text variant="xLarge" style={{fontVariant:'small-caps'}}>{tournament.Title} Starts</Text>
                                </Stack.Item>
                                <Stack.Item align='center'>
                                    <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.StartDate!} timezone={tournament?.Timezone} completed={()=>setHasTournamentStarted(true)}/>
                                </Stack.Item>
                                </> : null}

                                {tournament.Type === TournamentType.InPerson && !hasTournamentEnded ? <><br /><br /><Text variant='large' style={{marginLeft:'auto', marginRight:'auto'}}>This event is not being streamed, please proceed to <strong>{tournament.Location}</strong> to watch in person.</Text></> : null}
                                
                                <Carousel entries={entries} students={students} tournamentId={tournament.Id} />
                            <br />
                            <Leaderboard title={tournament.Title} tournament={tournament} hasTournamentStarted={hasTournamentStarted} />
                        </Stack>                    
                    
                </div> 
                :
                <ErrorPage 
                    error={{error: 'You do not have permission to view this event.'}} />
    )
}

export default TournamentViewer;