import { FC } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import UserManagement from '../components/UserManagement';
import { Pivot, PivotItem } from '@fluentui/react';
import CurriculumManagement from '../components/CurriculumManagement';
import RubricManagement from '../components/RubricManagement';
import ProgramManagement from '../components/ProgramManagement';
import TournamentManagement from '../pages/Admin/TournamentManagement';

const Admin : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Admin" });

    const isAuthenticated = useIsAuthenticated();

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    return isAuthenticated ? <div className="page-content" style={{marginRight:"20px"}}>   
            <Pivot overflowBehavior='menu'>
                <PivotItem key={0} itemKey="users" headerText="Users">
                    <UserManagement />
                </PivotItem>
                <PivotItem key={1} itemKey="tournament" headerText="Tournaments">
                    <TournamentManagement />
                </PivotItem>
                <PivotItem key={2} itemKey="curriculum" headerText="Curriculum">
                    <CurriculumManagement />
                </PivotItem>
                <PivotItem key={3} itemKey="rubric" headerText="Rubric">
                    <RubricManagement />
                </PivotItem>
                <PivotItem key={4} itemKey="programs" headerText="Programs">
                    <ProgramManagement />
                </PivotItem>                
                {/* <PivotItem key={4} itemKey="schedule" headerText="Schedule">
                    <ScheduleManagement />
                </PivotItem>                */}
            </Pivot>            
        </div>        
     : null;
}

export default Admin;