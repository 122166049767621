import { ActionButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import {FC, useEffect, useState} from 'react';
import { getTenantLandingPageContent } from '../../ApiService';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { TenantLandingPageViewModel } from '../../model/TenantLandingPageViewModel';
import EventCard from '../../components/EventCard';

const LandingPage : FC = () => {
    const navigate = useNavigate();
    const [landingPageViewModel, setLandingPageViewModel] = useState<TenantLandingPageViewModel>();
    const { instance } = useMsal();

    const [title, setTitle] = useState<string>();

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=>{
        const fetchData = async () => {
            var vm = await getTenantLandingPageContent();            

            if(typeof vm === 'number') {
                setErrorCode(vm);
                return;
            }

            setTitle(vm.EnvTitle);
            document.title = vm?.EnvTitle;

            if(vm?.CanJoin){                
                setLandingPageViewModel(vm);
            }
            else {
                doLogin();
            }
        }

        fetchData();     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    const doLogin = () => {
        instance.loginRedirect({ scopes:['openid profile','offline_access'], state: document.location.href});
    }

    return (
        <div style={{padding:20}}>
            {landingPageViewModel === undefined ? null : 
                <>
                    <Stack>
                        <Stack className='landing-header' tokens={{childrenGap:20}} horizontal verticalAlign='center' horizontalAlign='end'>
                            <ActionButton onClick={()=>navigate('/login')} styles={{textContainer:{fontSize:20}}}>Log In</ActionButton>
                            <PrimaryButton onClick={()=>navigate('/join')}  style={{height:40}} styles={{textContainer:{fontSize:16, padding:5}}}>Create free account</PrimaryButton>
                        </Stack>
                        <Stack horizontalAlign='center' tokens={{childrenGap:20}}>
                            <br />
                            <Text variant='xLarge'>{title}</Text>
                            <br />
                            <p style={{textAlign:'center', maxWidth:700}} dangerouslySetInnerHTML={{__html:landingPageViewModel.EnvDescription}}></p>
                            <Stack horizontal tokens={{childrenGap:40}} wrap>
                                {landingPageViewModel.Tournaments.map((p,idx) => 
                                    <EventCard key={idx} tournament={p} navTo={`/events/${p.Id}`} />
                                )}
                            </Stack>
                            <br />
                            <Stack tokens={{childrenGap:10}} verticalAlign='center'>
                                <PrimaryButton onClick={()=>navigate('/join')}  style={{height:40}} styles={{textContainer:{fontSize:20, padding:5}}}>Create free account</PrimaryButton>
                                <ActionButton onClick={()=>navigate('/login')}>Already have a NinjaPanel account? Log In here</ActionButton>
                            </Stack>
                        </Stack>
                    </Stack>
                </>
        }
        </div>
    );
}

export default LandingPage;