import React, { FC } from 'react';
import {  Stack, IPersonaSharedProps, Persona, PersonaSize, PersonaPresence } from '@fluentui/react';
import { Level } from '../model/Level';

interface IStudentCardInfo {
    imageUri: string;
    studentName: string;
    initials: string;
    additionalText?: string;
    level: Level;
    canClick?: boolean;
    onClick?: () => void;
}

const StudentCard : FC<IStudentCardInfo> = ({imageUri, studentName, initials, level, onClick, additionalText, canClick = true}) => {
    
    const studentPersona: IPersonaSharedProps = {
        imageUrl: imageUri,
        imageInitials: initials,
        text: studentName,
        secondaryText: level.Label,        
        tertiaryText: additionalText,
        size: additionalText !== undefined && additionalText.length > 0 ? PersonaSize.size72 : PersonaSize.size48
      };

    return (
        <div style={{cursor: canClick ? 'pointer' : 'default'}} className='student-card' onClick={(ev?: React.MouseEvent<HTMLElement, MouseEvent>) => canClick ? onClick?.() : {}}>
            <Stack>
                <div style={{padding:5}}></div>
                <Stack horizontal>
                    <div style={{padding:5}}></div>
                    <Persona
                        {...studentPersona}                        
                        presence={PersonaPresence.none}                        
                        hidePersonaDetails={false}
                        imageAlt={studentName}                   
                    />
                </Stack>
                <div style={{padding:5}}></div>
            </Stack>
        </div>
    )
}

export default StudentCard;