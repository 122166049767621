import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, Dropdown, IChoiceGroupOption, IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { postRequestToStageAthletes } from '../../../ApiService';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { IAthleteCallRequest } from '../../../model/AthleteCallRequest';
import { ITournamentBracket } from '../../../model/TournamentBracket';

interface IData {
    brackets: ITournamentBracket[];
    numAthletes: number;
    minutes: number[];
    stagingLocations: string[];

    onDismiss: (isSuccess: boolean, location?: string)=>void;
}

const CallToStagingDialog : FC<IData> = ({minutes, stagingLocations, numAthletes, brackets, onDismiss}) => {    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [selectedMinutes, setSelectedMinutes] = useState<number>();
    const [selectedLocation, setSelectedLocation] = useState<string|undefined>(stagingLocations.length === 1 ? stagingLocations[0] : undefined);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const minuteOptions: IChoiceGroupOption[] = minutes.map(m => {return {key:m.toString(), text:`${m} minutes` }});
    const stagingLocationOptions: IDropdownOption[] = stagingLocations.map((l,idx) => {return {key:idx.toString(), text: l}});
      
    useEffect(()=> {
        setCanSubmit(selectedLocation !== undefined && selectedMinutes !== undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[selectedMinutes, selectedLocation]);
        
    const [hideDialog, { toggle: toggleDialog }] = useBoolean(false);

    const onCallBrackets = async () => {
        setIsSubmitting(true);

        let acr: IAthleteCallRequest = {
            Minutes: selectedMinutes!,
            Location: selectedLocation!,
            BracketIds: brackets.map(d => d.Id)
        }

        var result = await postRequestToStageAthletes(instance, account!, brackets[0].TournamentId, acr);

        if(result){
            toggleDialog();
        }
        else{
            alert('Sorry, something went wrong');
        }
        onDismiss(result, result ? selectedLocation : undefined);
        setIsSubmitting(false);        
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 550 } };
    
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
      );

    return (
        <div>
            <Dialog 
                  modalProps={modalProps}
                  dialogContentProps={{
                    closeButtonAriaLabel: 'Close',
                    title:"Call to staging",
                    subText: `${numAthletes} athlete(s) will be notified`
                  }}
                  hidden={hideDialog}
                  onDismiss={()=> onDismiss(false)}>
                {isSubmitting ? 
                    <Loader Text='Just a moment...' /> : 
                    <Stack tokens={{childrenGap:10}}>
                        <Dropdown 
                            label='Staging Location'
                            defaultSelectedKey={stagingLocationOptions.length === 1 ? stagingLocationOptions[0].key : undefined}
                            onChange={(e,o)=> setSelectedLocation(o!.text)} 
                            options={stagingLocationOptions}
                        />
                        <ChoiceGroup 
                            label='Athletes due within:' 
                            defaultSelectedKey={minuteOptions.length === 1 ? minuteOptions[0].key : undefined}
                            onChange={(e,o)=> setSelectedMinutes(parseInt(o!.key))} 
                            options={minuteOptions} />
                    </Stack>
                }
                <DialogFooter>
                    <PrimaryButton disabled={!canSubmit || isSubmitting} onClick={onCallBrackets} text="Call" />
                    <DefaultButton disabled={isSubmitting} onClick={()=>onDismiss(false)} text="Don't call" />
                </DialogFooter>  
            </Dialog>
        </div>
    );
}

export default CallToStagingDialog; 