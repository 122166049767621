import { StudentCurriculumSubmission } from "./StudentCurriculumSubmission";
import { Student } from "./Student";

export class TournamentEntryViewModel {    
    Views: number;
    WhoReacted: string[];
    StudentCurriculumSubmission: StudentCurriculumSubmission;
    Student: Student; 

    constructor(
        views: number,
        whoReacted: string[],
        submission: StudentCurriculumSubmission,
        student: Student) {
            
        this.Views = views;
        this.WhoReacted = whoReacted;
        this.StudentCurriculumSubmission = submission;
        this.Student = student;
    }        
}