import { Level } from "./Level";
import { Review } from "./Review";

export enum SubmissionStatus {
    Draft = 0,
    PendingReview = 1,
    Reviewed = 2
}

export interface IStudentCurriculumSubmission {
    Id: string;
    CurriculumId: string;
    Created: Date;
    StudentId: string;
    StudentLevelWhenSubmitted: Level;
    StudentLevelIdWhenSubmitted: string;
    MembershipId: string;
    ContentUri: string;
    PosterImageUri: string;
    ContentType: string; 
    Status: SubmissionStatus;  
    Reviews?: Review[]; 
}

export class StudentCurriculumSubmission {
    Id: string;
    CurriculumId: string;
    Created: Date;
    StudentId: string;
    StudentLevelWhenSubmitted: Level;
    StudentLevelIdWhenSubmitted: string;
    MembershipId: string;
    ContentUri: string;
    PosterImageUri: string;
    ContentType: string; 
    Status: SubmissionStatus;
    Reviews?: Review[]; 
    
    constructor(
        params: IStudentCurriculumSubmission = {} as IStudentCurriculumSubmission) {
            
        let {
            Id = "",
            CurriculumId = "",
            Created = new Date(),
            StudentId = "",
            StudentLevelWhenSubmitted = new Level(),
            StudentLevelIdWhenSubmitted = "",
            MembershipId = "",
            ContentUri = "",
            PosterImageUri = "",
            ContentType = "",
            Status = SubmissionStatus.Draft,
            Reviews = undefined      
        } = params;
            
        this.Id = Id;
        this.CurriculumId = CurriculumId;
        this.Created = Created;
        this.StudentId = StudentId;
        this.StudentLevelWhenSubmitted = StudentLevelWhenSubmitted;
        this.StudentLevelIdWhenSubmitted = StudentLevelIdWhenSubmitted;
        this.MembershipId = MembershipId;
        this.ContentUri = ContentUri;
        this.PosterImageUri = PosterImageUri;
        this.ContentType = ContentType;
        this.Status = Status;
        this.Reviews = Reviews;
    }
}