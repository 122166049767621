import React, { FC, useEffect, useState } from 'react';
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { CurriculumItem } from '../model/CurriculumItem';
import Webcam from "react-webcam";
import { postStudentCurriculumSubmission } from '../ApiService';
import { getCurriculum } from '../ApiService';
import { IIconProps, PrimaryButton, Dropdown, DropdownMenuItemType, IDropdownOption,DefaultButton, Stack, Dialog, DialogType, DialogFooter, Link, Text, Modal, FontWeights, mergeStyleSets, getTheme, ActionButton, Panel, PanelType } from '@fluentui/react';
import Loader from '../components/Loader';
import {StoragePurpose, useSubmissionStorage} from '../Storage';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import CountdownTimer from './CountdownTimer';
import { DateTime } from 'luxon';


class DeviceOption implements IDropdownOption {
  isSelected?: boolean | undefined;
  key: string | number;
  id?: string | undefined;
  text: string;
  title?: string | undefined;
  itemType?: DropdownMenuItemType | undefined;
  index?: number | undefined;
  ariaLabel?: string | undefined;
  selected?: boolean | undefined;
  disabled?: boolean | undefined;
  hidden?: boolean | undefined;
  data?: any;
  deviceId: string;
  
  constructor(params: IDropdownOption = {} as IDropdownOption) {
    let {
        key= "",
        text="",     
    } = params;    
  
    this.key = key;
    this.text = text;
    this.deviceId = "";
  }
}

interface IData {
    CurriculumId: string;
    StudentId: string;
}

const StudentVideoCapture : FC<IData> = ({CurriculumId, StudentId}) => {
    const recordIcon: IIconProps = { iconName: 'VideoSolid' };
    const stopIcon: IIconProps = { iconName: 'CircleStopSolid' };

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [curriculum, setCurriculum] = useState<CurriculumItem>();
    const webcamRef = React.useRef<Webcam>(null);
    const mediaRecorderRef = React.useRef<MediaRecorder>();
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [loading, setLoading] = useState(true);
    const [videoDeviceOptions, setVideoDeviceOptions] = useState(new Array<IDropdownOption>());
    const [audioDeviceOptions, setAudioDeviceOptions] = useState(new Array<IDropdownOption>());
    const [captureBufferEmpty, setCaptureBufferEmpty] = useState(false);
    const [hasCapture, setHasCapture] = useState(false);
    const [hasCaptureError, setHasCaptureError] = useState(false);
    const [replayVideoUrl, setReplayVideoUrl] = useState("");
    const [mediaType, setMediaType]= useState("");
    const [mediaCaptureSupported, setMediaCaptureSupported] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submissionStorage = useSubmissionStorage(StoragePurpose.StudentCurriculumSubmission, StudentId, CurriculumId);    

    const [videoDeviceId, setVideoDeviceId] = React.useState<string>();
    const [audioDeviceId, setAudioDeviceId] = React.useState<string>();

    const [videoDevices, setVideoDevices] = React.useState(Array<any>());
    const [audioDevices, setAudioDevices] = React.useState(Array<any>());

  const handleDevices = React.useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
    {
      setVideoDevices(mediaDevices.filter(({ kind } :any) => kind === "videoinput"));
      setAudioDevices(mediaDevices.filter(({ kind } :any) => kind === "audioinput"));      
    },
    [setVideoDevices, setAudioDevices]
  );

    useEffect(
      () => {
        var hasMediaRecorder = window.MediaRecorder !== null && window.MediaRecorder !== undefined;
        var mediaType = hasMediaRecorder ? 
          MediaRecorder.isTypeSupported("video/webm") ? 
            "video/webm" : 
            "video/mp4" 
          : "notsupported";
    
        setMediaType(mediaType);
        setMediaCaptureSupported(hasMediaRecorder);
        // console.log('media type: ' + mediaType);

        if(hasMediaRecorder) {
          navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(()=>navigator.mediaDevices.enumerateDevices().then(handleDevices))
            .catch(c=>
              {
                console.log(c);
                setHasCaptureError(true);
                setLoading(false);
              });            
        }
      },
      [handleDevices]
    );
    
    useEffect(()=>{
      if(!captureBufferEmpty) {
        return;
      }

      const blob = new Blob(recordedChunks, {
        type: mediaType
      });
      
      var url = URL.createObjectURL(blob);
      setReplayVideoUrl(url);
      setHasCapture(true);

    },[recordedChunks, captureBufferEmpty, mediaType]);

  const handleStartCaptureClick = React.useCallback(() => {
    setCaptureBufferEmpty(false);
    setHasCapture(false);
    setReplayVideoUrl("");
    setRecordedChunks([]);
    setCapturing(true);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current?.stream!, {
      mimeType: mediaType     
    });
    
    
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );

    mediaRecorderRef.current.start();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }          
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {   
    mediaRecorderRef.current?.stop();  
    setCapturing(false);
    setCaptureBufferEmpty(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaRecorderRef, webcamRef, setCapturing]);


  const handleSubmit = React.useCallback(async () => {
    if (recordedChunks.length) {
      setIsSubmitting(true);

      const blob = new Blob(recordedChunks, {
        type: mediaType
      });

        var fileName = submissionStorage?.submissionEnv.Filename!;
        var success = await submissionStorage?.uploadFile(blob);

        if(success) {
          await postStudentCurriculumSubmission(instance, account!, CurriculumId, StudentId, fileName, mediaType);
          navigate(`/student/${StudentId}/curriculum/${CurriculumId}/submitted`);
        }          
      
      setRecordedChunks([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordedChunks]);

  const handlePlaying = (stream: MediaStream) => {
    
    try
    {
      setAudioDeviceId(stream.getAudioTracks()[0].getSettings().deviceId);
    }
    catch{}
    
    try
    {
      setVideoDeviceId(stream.getVideoTracks()[0].getSettings().deviceId);
    }
    catch{}

    setLoading(false);
  }  

  useEffect(()=>{
    videoDeviceOptions.splice(0,videoDeviceOptions.length);

    for(let d of videoDevices) {
      var deviceOption = new DeviceOption({key: d.deviceId, text: d.label});
      deviceOption.deviceId = d.deviceId;
      videoDeviceOptions?.push(deviceOption);
    }
    
    setVideoDeviceOptions(videoDeviceOptions);    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[videoDevices]);

  useEffect(()=>{
    audioDeviceOptions.splice(0,audioDeviceOptions.length);

    for(let d of audioDevices) {
      var deviceOption = new DeviceOption({key: d.deviceId, text: d.label});
      deviceOption.deviceId = d.deviceId;
      audioDeviceOptions?.push(deviceOption);
    }

    setAudioDeviceOptions(audioDeviceOptions);    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[audioDevices]);

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                
                try {
                    var curriculum = await getCurriculum(instance, account, CurriculumId); 
                    setCurriculum(curriculum);
                }            
                catch(error) {
                    console.log(error);
                }
            }
        }
       
        fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [isAuthenticated, inProgress, account]);

    const handleUserMediaError = (errorString: string | DOMException) => {
      console.log(errorString);
    }

    const handleWebcamError = (event:any) => {
      console.log(event);
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };
    
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
      );

    const [hideFilmAgainDialog, { toggle: toggleFilmAgainDialog }] = useBoolean(true);
      
    const filmAgainDialogContentProps = {
        type: DialogType.normal,
        title: 'Are you sure you want to film again?',
        closeButtonAriaLabel: 'Close',
        subText: `This recording will be deleted and cannot be recovered.`,
      };

    const onRestartSubmission = () => {
      //clear the recording chunks
      //get to the state for recording
      setIsSubmitting(false);
      setHasCapture(false);
      setCapturing(false);
      toggleFilmAgainDialog();
    }
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [isDeviceSettingsOpen, { setTrue: showDeviceSettings, setFalse: hideDeviceSettings }] = useBoolean(false);
    
    const beginRecording = () => {
      hideModal();
      handleStartCaptureClick();
    }

   return (  
        <>
            <h3>{curriculum?.Name}</h3>
            {curriculum?.ResponseDescription !== undefined && curriculum?.ResponseDescription.length > 0 ?
            <div style={{backgroundColor:'#FFF', padding:15}}>
              <div dangerouslySetInnerHTML={{__html: curriculum.ResponseDescription}} />
            </div> : null }
            <br />
            {!mediaCaptureSupported ? 
              <p style={{color:"red"}}>Your browser does not currently support capturing video. Please try using a different browser such as Edge, Firefox or Chrome.</p> :
              loading ? 
              <Loader Text="Connecting to your camera, just a moment..." />: 
              null}
            {hasCapture ? 
            <video src={replayVideoUrl} controls={true} muted={false} />
            :
            mediaCaptureSupported && videoDevices.length >= 1 ?
              <>     
              {capturing ? 
                <div 
                  style={{
                    animationName:'recording',
                    animationIterationCount:'infinite',   
                    animationDuration:'2s',                 
                    position:'absolute', 
                    width:40, 
                    height:40, 
                    opacity:0.7,
                    zIndex:10,
                    marginTop:10,
                    marginRight:10,
                    right:20,
                    borderRadius:'50%', 
                    backgroundColor:'red'
                    }} /> : null }
                <Webcam 
                  mirrored={true} 
                  audioConstraints={{deviceId: audioDeviceId, echoCancellation: true}} 
                  videoConstraints={{deviceId: videoDeviceId}} 
                  audio
                  muted
                  ref={webcamRef} 
                  onUserMedia={handlePlaying}                   
                  onUserMediaError={handleUserMediaError} 
                  onError={handleWebcamError} />                                                      

                  <div style={{display:'flex', flexDirection:'column'}}>                      
                    {capturing ? 
                      <DefaultButton 
                        disabled={!capturing}
                        iconProps={stopIcon} 
                        onClick={handleStopCaptureClick}>Stop</DefaultButton>
                     : <PrimaryButton                     
                        disabled={capturing}
                        iconProps={recordIcon} 
                        onClick={showModal}>Record</PrimaryButton>}
                  </div>
                  <ActionButton 
                    style={{marginLeft:'auto', marginRight:0, display:'block'}}
                    iconProps={{iconName:'settings'}}
                    onClick={showDeviceSettings}>Device settings</ActionButton>
              </>
              : null}
            
            
            <br /><br />
            {loading ? null :
              capturing ? null
              : hasCapture ? null :
              (
                hasCaptureError ? <Stack>
                  <Text variant='mediumPlus' style={{color:"red"}}>Unable to connect to camera.</Text>
                  <Text>Please check that you have enabled camera access in your browser and that no other tabs or applications are using the camera, then try again.</Text>
                  <Text>Refreshing this page may also help.</Text>
                  </Stack>
                : 
                null
              )}              

              {mediaCaptureSupported && hasCapture ? <Stack tokens={{childrenGap:10, maxWidth:300}}><PrimaryButton onClick={handleSubmit} disabled={recordedChunks.length === 0 || isSubmitting}>Submit</PrimaryButton><Link onClick={toggleFilmAgainDialog} disabled={recordedChunks.length === 0 || isSubmitting}>Not happy with your video? Record again.</Link></Stack> : null}
              {isSubmitting ? <Loader Text="Uploading, just a moment..." /> : null}


              <Dialog
                    hidden={hideFilmAgainDialog}
                    onDismiss={toggleFilmAgainDialog}
                    dialogContentProps={filmAgainDialogContentProps}
                    modalProps={modalProps}>
                    <DialogFooter>
                        <PrimaryButton onClick={onRestartSubmission} text="Delete" />
                        <DefaultButton onClick={toggleFilmAgainDialog} text="Don't delete" />
                    </DialogFooter>
                </Dialog>

              <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}            
                containerClassName={contentStyles.container}>
                  <div className={contentStyles.header}>
                    <span>Get Ready</span>
                  </div>
                  <div className={contentStyles.body}>
                    <div style={{padding:40}}>
                      <CountdownTimer                   
                        completed={beginRecording}
                        showSeconds 
                        targetDate={DateTime.now().plus({seconds:5}).toJSDate()} />
                    </div>
                  </div>
              </Modal>

              <Panel
                headerText="Device Settings"
                type={PanelType.smallFixedFar}
                isOpen={isDeviceSettingsOpen}
                isLightDismiss={false}
                onDismiss={hideDeviceSettings}
                closeButtonAriaLabel="Close">
                <div 
                    style={{
                      paddingTop:40,
                      display:'flex', 
                      flexDirection:'column',
                      justifyItems:'end',
                      gap:10,
                      justifyContent:'space-between'                      
                      }}>

                      {videoDevices.length > 1 ?                  
                        <Dropdown
                          label='Camera'
                          placeholder="Switch camera"
                          disabled={capturing}
                          selectedKey={videoDeviceId}
                          options={videoDeviceOptions!}
                          onChange={(ev: any, deviceOption:any) => {setLoading(true); setVideoDeviceId(deviceOption.deviceId);}}
                        />
                      : null }
                      {audioDevices.length > 1 ?                  
                        <Dropdown
                          label='Microphone'
                          placeholder="Switch microphone"
                          disabled={capturing}
                          selectedKey={audioDeviceId}                
                          options={audioDeviceOptions!}
                          onChange={(ev: any, deviceOption:any) => { setAudioDeviceId(deviceOption.deviceId);}}
                        /> 
                        : null }
                  </div>
              </Panel>
        </>
   );
}

export default StudentVideoCapture;