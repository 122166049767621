import React, { FC, useCallback, useState } from 'react';
import { SearchBox, Stack, Text } from '@fluentui/react';
import Loader from './Loader';

interface IData {
    placeholder?: string | undefined;
    getSuggestions: (query: string) => Promise<IAutoCompleteSuggestion[]>;
    itemSelected?: (item: IAutoCompleteSuggestion) => void;
    onRenderSuggestion?: (suggestion: IAutoCompleteSuggestion) => JSX.Element;
}

export interface IAutoCompleteSuggestion {
    id: string;
    title: string;
    subtitle: string;
    extraData: string;
}

const AutoCompleteSearchBox: FC<IData> = ({ placeholder, itemSelected, getSuggestions, onRenderSuggestion }) => {
    const [isSearching, setIsSearching] = useState<boolean>();
    const [suggestions, setSuggestions] = useState<IAutoCompleteSuggestion[]>([]);

    const onChange = async (event: any, newValue: string | undefined) => {
        setIsResultsView(false);

        if (!newValue || newValue.trim() === "") {
            setSuggestions([]);
        }
        else {
            setSuggestions([]);
            debouncedGetSuggestions(newValue);
        }
    };

    const onSuggestionClicked = (suggestion: IAutoCompleteSuggestion) => {
        itemSelected?.(suggestion);
    };

    const debounce = (func: (...args: any[]) => void, wait: number) => {
        let timeout: NodeJS.Timeout;
        return (...args: any[]) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, wait);
        };
    };
    
    const getSuggestionsInternal = async (query: string) => { 
        setIsSearching(true);        

        var suggestions = await getSuggestions(query);

        setIsSearching(false);
        setIsResultsView(true);
        setSuggestions(suggestions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedGetSuggestions = useCallback(debounce(getSuggestionsInternal, 500), []);

    const [isResultsView, setIsResultsView] = useState<boolean>(false);

    return (
        <div style={{ position: 'relative', width: 300, backgroundColor:'white' }}>
            <SearchBox
                placeholder={placeholder}
                onClear={() => { setSuggestions([]); setIsSearching(false); setIsResultsView(false); }}
                onChange={onChange} />
            {isSearching &&
                <div style={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    listStyleType: 'none',
                    padding: '0',
                    margin: '0',
                    zIndex: 1000}}>
                    <Loader Text='Just a moment...' />
                </div>
            }
            {suggestions.length === 0 && isResultsView && (<div style={{padding:10}}>No users found.</div>)}
            {suggestions.length > 0 && (
                <ul style={{
                    position: 'relative',
                    width: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                    listStyleType: 'none',
                    padding: '0',
                    margin: '0',
                    zIndex: 1000
                }}>
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingBottom:5,
                                cursor: 'pointer'
                            }}
                            onClick={() => onSuggestionClicked(suggestion)}>
                            {onRenderSuggestion ?
                                onRenderSuggestion(suggestion) :
                                (<Stack tokens={{ childrenGap: 5 }}>
                                    <Text variant='medium'>{suggestion.title}</Text>
                                    <Text variant='smallPlus'>{suggestion.subtitle}</Text>
                                    {suggestion.extraData && <Text variant='small'>{suggestion.extraData}</Text>}
                                </Stack>)
                            }
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutoCompleteSearchBox;