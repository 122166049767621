import { TournamentRole } from "./Tournament";

//yes this is confusing. 
//the registration status is used by the individual events, the tournament registration status by the parent registration and the numbers are inverted
export enum RegistrationStatus {
    Complete = 0,
    Pending = 1,
    Canceled = 2
}

export enum TournamentRegistrationStatus {
    Pending = 0,        
    Complete = 1
}

export interface IStudentRegistration {
    StudentId: string;
    CanRegister: boolean;
}

export interface ITournamentEventRegistration {
    Id: string;
    CurriculumId: string;
    SubmissionId: string;
    StudentId: string;
    Role: TournamentRole;
    Created: Date;
    Status: RegistrationStatus;
}

export interface ITournamentCatalogItemConfig {
    Key: string;
    Value: string;
}

export interface ITournamentCatalogItemPurchase {
    CatalogItemId: string;
    Qty: number;
    Config?: ITournamentCatalogItemConfig[];
    Status: RegistrationStatus;
    Created: Date;
}

export interface ITournamentRegistration {
    Id: string;
    TournamentId: string;
    Created: Date;
    Modified: Date;
    Catalog: ITournamentCatalogItemPurchase[];
    Events: ITournamentEventRegistration[];
    UserId: string;
    Status: TournamentRegistrationStatus;
    TransactionIds: string[];
}

export class TournamentRegistration implements ITournamentRegistration {    
    Id: string;
    TournamentId: string;
    Created: Date;
    Modified: Date;
    Catalog: ITournamentCatalogItemPurchase[];
    Events: ITournamentEventRegistration[];
    UserId: string;
    Status: TournamentRegistrationStatus;
    TransactionIds: string[];

    constructor(
        params: ITournamentRegistration = {} as ITournamentRegistration) {
            
        let {
            Id = "",
            TournamentId = "",
            Created = new Date(),
            Modified = new Date(),
            Catalog = [],
            Events = [],
            UserId = "",
            Status = TournamentRegistrationStatus.Pending,
            TransactionIds = []
        } = params;
            
        this.Id = Id;
        this.TournamentId = TournamentId;
        this.Created = Created;
        this.Modified = Modified;
        this.Catalog = Catalog;
        this.Events = Events;
        this.UserId = UserId;
        this.Status = Status;
        this.TransactionIds = TransactionIds;
    }
}