import React, { FC, useEffect, useState } from 'react';
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Loader from '../../../components/Loader';
import { StripeError, StripePaymentElementChangeEvent } from '@stripe/stripe-js';
import { Dialog, DialogFooter, DialogType, PrimaryButton, Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';

interface IData {
    returnUri: string;
    // canSubmit: (canSubmit: boolean) => void;
    // shouldSubmitPayment: boolean;
}

const CheckoutForm : FC<IData> = ({returnUri}) => {
    const stripe = useStripe();
    const elements = useElements();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<StripeError>();

    useEffect(()=> {
        if(error) {
            toggleHideDialog();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[error]);
    
    const completePayment = async () => {

        if(!stripe || !elements) {
            return;
        }

        setIsSubmitting(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: returnUri,
            },
        });
        
        setIsSubmitting(false);
        
        if(error) {
            setError(error);            
        }
    }

    const paymentStatusChanged = (e:StripePaymentElementChangeEvent) => {
        setCanSubmit(e.complete);
    }

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Transaction Failed',
        subText: error?.message,
      };

    return ( 
        <>
            <br />
            {isLoading ? <Loader Text="Just a moment..." /> : null}
            <PaymentElement id="payment-element" options={{readOnly: isSubmitting}} onReady={()=>setIsLoading(false)} onChange={paymentStatusChanged} />      
            <br />
            <Stack.Item align='end'>
                <PrimaryButton disabled={!canSubmit || isSubmitting} onClick={()=> completePayment()}>Submit</PrimaryButton>
            </Stack.Item>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true, styles:modalPropsStyles}}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </>
    );
}

export default CheckoutForm;