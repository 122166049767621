import { useAccount, useMsal } from '@azure/msal-react';
import { DefaultButton, Dialog, DialogFooter, Dropdown, IDropdownOption, PrimaryButton, Stack } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { postStagedBracketsToLocation } from '../../../ApiService';
import Loader from '../../../components/Loader';
import { IAthleteCallRequest } from '../../../model/AthleteCallRequest';
import { ITournamentBracket } from '../../../model/TournamentBracket';


//note that here brackets are selected, not divisions. we will be able to select multiple brackets to send them to the same location
//or independently, we can select single brackets to go to different locations

interface IData {
    tournamentId: string;
    brackets: ITournamentBracket[];
    bracketLocations: string[];

    onDismiss: (isSuccess: boolean, location?: string)=>void;
}

const SendToCompeteDialog : FC<IData> = ({tournamentId, brackets, bracketLocations, onDismiss}) => {    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [location, setLocation] = useState<string>();

    const bracketLocationOptions: IDropdownOption[] = bracketLocations.map((l, idx) => { return { key: idx.toString(), text: l } });

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);    
    
    useEffect(()=> {
        setCanSubmit(location !== undefined);

        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[location]);
        
    const [hideDialog, { toggle: toggleDialog }] = useBoolean(false);    

    const onSendToCompete = async () => {
        if(location === undefined) {
            return;
        }

        setIsSubmitting(true);
        
        let req: IAthleteCallRequest = {
            BracketIds: brackets.map(b => b.Id),
            Location: location!,
            Minutes: 0
        };
        
        var result = await postStagedBracketsToLocation(
            instance, 
            account!, 
            tournamentId,
            req);

        if(result){
            toggleDialog();
        }
        else{
            alert('Sorry, something went wrong');
        }
        onDismiss(result, result ? location : undefined);
        setIsSubmitting(false);        
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 550 } };
    
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
    );    

    return (
        <div>
        <Dialog 
              modalProps={modalProps}
              dialogContentProps={{
                closeButtonAriaLabel: 'Close',
                title:"Assign to location",
                subText: `${brackets.length} brackets will be assigned to this location.`
              }}
              hidden={hideDialog}
              onDismiss={()=> onDismiss(false)}>
            {isSubmitting ? 
                <Loader Text='Just a moment...' /> : 
                <Stack>
                    <Dropdown 
                        label='Location'
                        onChange={(e,v) => setLocation(v!.text)}
                        options={bracketLocationOptions} />
                    <br />
                </Stack>
            }
            <DialogFooter>
                <PrimaryButton disabled={!canSubmit || isSubmitting} onClick={onSendToCompete} text="Assign" />
                <DefaultButton disabled={isSubmitting} onClick={()=> onDismiss(false)} text="Don't assign" />
            </DialogFooter>  
        </Dialog>
    </div>
    );
}

export default SendToCompeteDialog; 