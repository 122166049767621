import React, { FC, useEffect, useState } from 'react';
import { ActionButton, FontWeights, getTheme, Label, mergeStyleSets, Modal, PrimaryButton, Text } from '@fluentui/react';
import { Tournament } from '../../model/Tournament';
import {useBoolean} from '@fluentui/react-hooks';
import CatalogEditor, { CatalogState } from '../../pages/Admin/components/CatalogEditor';
import { CatalogItem, ICatalogItemWithQuantity } from '../../model/CatalogItem';
import { CleanId } from '../../Validation';
import { EditIcon } from '@fluentui/react-icons-mdl2';
import { CurriculumItem } from '../../model/CurriculumItem';

interface IData {
    tournament: Tournament;
    onValidated: (
        isValid: boolean,
        tournament: Tournament,
        curriculumAdds?: CurriculumItem[],
        curriculumEdits?: CurriculumItem[],
        catalogAdds?: CatalogItem[],
        catalogEdits?:CatalogItem[]) => void;
}

const CatalogWizard : FC<IData> = ({tournament, onValidated}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    const [pendingCatalogItem, setPendingCatalogItem] = useState<ICatalogItemWithQuantity>();
    const [pendingCatalogAdds, setPendingCatalogAdds] = useState<CatalogItem[]>([]);
    const [, setPendingCatalogEdits] = useState<CatalogItem[]>([]);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const onStateChange = (currentState: CatalogState, catalogItem: ICatalogItemWithQuantity)  => {
        if (currentState === CatalogState.CanSubmit) {
            setPendingCatalogItem(catalogItem);
        }

        setCanSubmit(currentState === CatalogState.CanSubmit);
    }

    useEffect(()=> {        
        if(internalTournament.Catalog.length === 0) {
            onValidated(false, internalTournament, undefined, undefined, pendingCatalogAdds);
            return;
        }
        
        onValidated(true, internalTournament, undefined, undefined, pendingCatalogAdds);
    // eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament, pendingCatalogItem]);

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        minWidth:400,
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const onSubmitCatalogItem = () => {  

        if (pendingCatalogItem === undefined) {
            return;
        }

        //if it's an edit, remove the old one and add the pending catalog item in it's place
        if (editCatalogItem !== undefined) {
            setPendingCatalogEdits(pendingCatalogEdits => [...pendingCatalogEdits.filter(c => c.Id !== editCatalogItem.Id), pendingCatalogItem]);

            let catalog = internalTournament.Catalog.filter(c => c.Id !== editCatalogItem.Id);
            catalog.push({
                AvailableQuantity: pendingCatalogItem.AvailableQuantity,
                Description: pendingCatalogItem.Description,
                Id: pendingCatalogItem.Id,
                CatalogItemId: pendingCatalogItem.Id, //weird duplicate in model here, this property needs to be set for the backend to ingest
                ImageUri: pendingCatalogItem.ImageUri,
                Inputs: pendingCatalogItem.Inputs,
                IsSuggested: false,
                IsTaxed: false,
                Name: pendingCatalogItem.Name,
                Price: pendingCatalogItem.Price,
                AdditionalTerms: pendingCatalogItem.AdditionalTerms
            });

            setInternalTournament(internalTournament => ({...internalTournament, Catalog: catalog}));
        }
        else {
            pendingCatalogItem.Id = `${internalTournament.Id}-${CleanId(pendingCatalogItem!.Name)}`;            
    
            setPendingCatalogAdds(pendingCatalogAdds => [...pendingCatalogAdds, pendingCatalogItem]);

            let catalog = internalTournament.Catalog;
            catalog.push({
                AvailableQuantity: pendingCatalogItem.AvailableQuantity,
                Description: pendingCatalogItem.Description,
                Id: pendingCatalogItem.Id,
                CatalogItemId: pendingCatalogItem.Id, //weird duplicate in model here, this property needs to be set for the backend to ingest
                ImageUri: pendingCatalogItem.ImageUri,
                Inputs: pendingCatalogItem.Inputs,
                IsSuggested: false,
                IsTaxed: false,
                Name: pendingCatalogItem.Name,
                Price: pendingCatalogItem.Price,
                AdditionalTerms: pendingCatalogItem.AdditionalTerms
            });
    
            setInternalTournament(internalTournament => ({...internalTournament, Catalog: catalog}));
        }

        setEditCatalogItem(undefined);
            
        hideModal();
    }

    const [editCatalogItem, setEditCatalogItem] = useState<ICatalogItemWithQuantity>();

    useEffect(() => {
        if (editCatalogItem === undefined) {
            return;
        }
        
        showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[editCatalogItem])
    
    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>
            <Label>Catalog</Label>
            {internalTournament.Catalog.length === 0 ?
                <Text>No items in catalog.</Text> :
                internalTournament.Catalog.map((e, idx) =>
                    <div key={idx} style={{display:'flex', flexDirection:'row', gap:10}}>
                        <EditIcon style={{cursor:'pointer'}} onClick={() => setEditCatalogItem(e)} />
                        <Text>{e.Name}<br />
                            {e.AvailableQuantity > 0 ? "Available" : "Not available"}<br />
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(e.Price)}</Text>
                    </div>
            )}
            <ActionButton iconProps={{iconName:'Add'}} onClick={showModal}>Add catalog item</ActionButton>

            <Modal                
                isOpen={isModalOpen}
                isBlocking                   
                containerClassName={contentStyles.container}   
                onDismiss={hideModal}>
                    <div className={contentStyles.header}>
                    <span>Add catalog item</span>
                </div>
                <div className={contentStyles.body}>                
                    <CatalogEditor 
                        editCatalogItem={editCatalogItem}
                        onStateChange={onStateChange} />
                    <br />
                    {canSubmit && <PrimaryButton onClick={onSubmitCatalogItem}>Submit</PrimaryButton>}
                </div>  
                
            </Modal>            
            
        </div>
    )
}

export default CatalogWizard;