export enum ActivityFeedItemCategory {
    Specific = 0,
    Global = 1
}

export interface IActivityFeedItem {
    Id: string;
    Text: string;
    Created: Date;
    Category: ActivityFeedItemCategory;
    Link: string;
    Author: string;
    StudentId: string;
    MembershipId: string;
    ImageUri: string;
}

export class ActivityFeedItem implements IActivityFeedItem {
    Id: string;
    Text: string;
    Created: Date;
    Category: ActivityFeedItemCategory;
    Link: string;
    Author: string;
    StudentId: string;
    MembershipId: string;
    ImageUri: string;

    constructor(
        params: IActivityFeedItem = {} as IActivityFeedItem) {
            
        let {
            Id = "",
            Text = "",
            Created = new Date(),
            Category = ActivityFeedItemCategory.Specific,
            Link = "",
            Author = "",
            StudentId = "",
            MembershipId = "",
            ImageUri = ""
        } = params;
            
        this.Id = Id;
        this.Text = Text;
        this.Created = Created;
        this.Category = Category;
        this.Link = Link;
        this.Author = Author;
        this.StudentId = StudentId;
        this.MembershipId = MembershipId;
        this.ImageUri = ImageUri;
    }
}