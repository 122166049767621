import {FC, useEffect, useState} from 'react';
import { getTenantEnvironment } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../../components/Loader';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import InvoicePaymentResult from './InvoicePaymentResult';

const InvoicePaymentCompleted : FC = () => {
    const [stripePromise, setStripePromise] = useState<any>();
    
    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(()=> {
        const fetchAsync = async () => {
            var env = await getTenantEnvironment(instance, account!);            
            setStripePromise(loadStripe(env.PaymentGatewayPublicToken));
        }

        if(account) {
            fetchAsync();
        }
    },[account, instance]);

    return (
        <>
            {stripePromise === undefined ? 
                
                <Loader Text="Just a moment..." /> : 

                <Elements stripe={stripePromise}>
                    <InvoicePaymentResult />
                </Elements>
            }
        </>
    )
}

export default InvoicePaymentCompleted;