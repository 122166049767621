import { ImageFit, Image, Stack, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ILandingPageTournament } from '../model/TenantLandingPageViewModel';
import { TournamentType } from '../model/Tournament';
import { GetTournamentStartAndEndDates } from '../Display';

export interface IData {
    tournament: ILandingPageTournament;    
    navTo?: string;
}

const EventCard : FC<IData> = ({tournament, navTo}) => {
    const navigate = useNavigate();

    return(
        <div style={{backgroundColor:'white', padding:20, width:300, cursor: navTo !== undefined ? 'pointer' : 'initial'}} onClick={()=> navTo !== undefined ? navigate(navTo) : {}}>
            <Stack tokens={{childrenGap:10}} horizontalAlign='center'>
                <Image style={{width:165, height:165}} src={tournament.ImageUri} imageFit={ImageFit.contain}/>
                <Text variant='mediumPlus'>{tournament.Title}</Text>                                                
                <Text variant='smallPlus'>{GetTournamentStartAndEndDates(tournament.StartDate!, tournament.EndDate!, tournament.Timezone)}</Text>
                {tournament.Type === TournamentType.Online && DateTime.fromJSDate(tournament.EndDate!) < DateTime.utc() ? <Link to={`/tournament/${tournament.Id}/watch`}><Text variant='small'>View Results</Text></Link> : null}
            </Stack>
        </div>
    );
}

export default EventCard;