import { useAccount, useMsal } from '@azure/msal-react';
import { Stack, TextField, Text, IChoiceGroupOption, ChoiceGroup, PrimaryButton } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { postFeedback } from '../ApiService';
import { Feedback, FeedbackCategory } from '../model/Feedback';

interface IData {
  dismissPanel: () => void
}

const FeedbackPanel : FC<IData> = ({dismissPanel}) => { 
    const { instance, accounts } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [feedbackItem, setFeedbackItem] = useState<Feedback>(new Feedback());
    const [isValid, setIsValid] = useState<boolean>(false);
    

    const options: IChoiceGroupOption[] = [
      { key: FeedbackCategory.Support.toString(), iconProps:{ style:{zoom:0.75}, iconName:'Telemarketer'}, text: 'Support' },
      { key: FeedbackCategory.Bug.toString(), iconProps:{style:{zoom:0.75}, iconName:'Bug'}, text: 'Bug' },
      { key: FeedbackCategory.FeatureRequest.toString(), iconProps:{style:{zoom:0.75}, iconName:'Giftbox'}, text: 'Feature' },
    ];
    
    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {          
        if(feedbackItem.Summary.length === 0) {
            setIsValid(false);
            return;
        }

        if(feedbackItem.Category === undefined) {
          setIsValid(false);
          return;
        }
        
        setIsValid(true);
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [feedbackItem]);


      const getCategory = (category: string) : FeedbackCategory => {
        switch(category) {
          case FeedbackCategory.Bug.toString():
            return FeedbackCategory.Bug;
          case FeedbackCategory.FeatureRequest.toString():
            return FeedbackCategory.FeatureRequest;
          case FeedbackCategory.Support.toString():
            return FeedbackCategory.Support;
        }

        return FeedbackCategory.Support;
      }

      const submitFeedback = async () => {        
        var result = await postFeedback(instance, account!, feedbackItem);

        if(result?.ok) {
          dismissPanel();
        }
        else {
          alert('Sorry, something went wrong. Please try again.');
        }
      }
     
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Text>When you submit feedback, the information entered below will be emailed to the NinjaPanel support team.</Text>
                <TextField 
                    label="Summary"
                    required
                    inputMode="text"                                               
                    defaultValue={feedbackItem.Summary}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setFeedbackItem({...feedbackItem, Summary: newValue!})} 
                    onGetErrorMessage={validateFieldNotEmpty}/>
                  <TextField 
                    label="Explain in more detail (optional)"
                    inputMode="text"                                               
                    defaultValue={feedbackItem.Detail}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    multiline
                    rows={5}
                    onChange={(c: React.FormEvent, newValue?: string) => setFeedbackItem({...feedbackItem, Detail: newValue!})} />        

                  <ChoiceGroup options={options} onChange={(e,o)=> setFeedbackItem({...feedbackItem, Category:getCategory(o!.key)})} label="Category of feedback" required />
                    <PrimaryButton disabled={!isValid} onClick={()=>submitFeedback()}>Submit</PrimaryButton>
            </Stack>

        </>
    )
}

export default FeedbackPanel;