import { CurriculumItem } from './CurriculumItem';
import { Review } from './Review';
import { Student } from './Student';
import { StudentCurriculumSubmission } from './StudentCurriculumSubmission';
import { EventModel } from './EventModel';

let s = new Student();

export interface IStudentDashboardModel {
    Student: Student;
    Reviews: Review[];
    CurriculumSubmissions: StudentCurriculumSubmission[];
    CurriculumItems: CurriculumItem[];
    HasInvitationToTest: boolean;
    HasRegisteredForTest: boolean;
    MostRecentPromotionTest: EventModel;
}

export class StudentDashboardModel implements IStudentDashboardModel {
    Student: Student;
    Reviews: Review[];
    CurriculumSubmissions: StudentCurriculumSubmission[];
    CurriculumItems: CurriculumItem[];
    HasInvitationToTest: boolean;
    HasRegisteredForTest: boolean;
    MostRecentPromotionTest: EventModel;

    constructor(
        params: IStudentDashboardModel = {} as IStudentDashboardModel) {
            
        let {
            Student = s,
            Reviews = new Array<Review>(),
            CurriculumSubmissions = new Array<StudentCurriculumSubmission>(),
            CurriculumItems = new Array<CurriculumItem>(),
            HasInvitationToTest = false,
            HasRegisteredForTest = false,
            MostRecentPromotionTest = new EventModel()

        } = params;
            
        this.Student = Student;
        this.Reviews = Reviews;
        this.CurriculumSubmissions = CurriculumSubmissions;
        this.CurriculumItems = CurriculumItems;
        this.HasInvitationToTest = HasInvitationToTest;
        this.HasRegisteredForTest = HasRegisteredForTest;
        this.MostRecentPromotionTest = MostRecentPromotionTest;
    }
}