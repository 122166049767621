import React, { FC } from 'react';
import { getFocusStyle, getTheme, Icon, getRTL, ITheme, mergeStyleSets, Stack } from '@fluentui/react';
import { CurriculumItem } from '../model/CurriculumItem';
import {useNavigate} from 'react-router-dom';
import { SubmissionStatus } from '../model/StudentCurriculumSubmission';

interface ICurriculumRow {
    icon: string;
    studentId:string;
    item: CurriculumItem;
    state?: SubmissionStatus;
    isPass?: boolean;
    onClick?: (item:CurriculumItem) => void;
}

const CurriculumRow : FC<ICurriculumRow> = ({icon, state, isPass, studentId, item, onClick}) => {
    const navigate = useNavigate();
    const theme: ITheme = getTheme();
    const { palette, semanticColors, fonts } = theme;    

    const classNames = mergeStyleSets({
        itemCell: [
          getFocusStyle(theme, { inset: -1 }),
          {
            minHeight: 54,
            padding: 10,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: 'flex',
            cursor: 'pointer',
            selectors: {
              '&:hover': { background: palette.neutralLight },
            },
          },
        ],
        itemImage: {
          flexShrink: 0,
        },
        itemContent: {
          marginLeft: 10,
          overflow: 'hidden',
          flexGrow: 1,
        },
        itemName: [
          fonts.xLarge,
          {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        ],
        itemIndex: {
          fontSize: fonts.small.fontSize,
          color: palette.neutralTertiary,
          marginBottom: 10,
        },
        chevron: {
          alignSelf: 'center',
          marginLeft: 10,
          color: palette.neutralTertiary,
          fontSize: fonts.large.fontSize,
          flexShrink: 0,
        },
      });
      
      const handleClick = (e:any) => {
        if(onClick === undefined) {
          var escapedUri = encodeURIComponent(item?.Id);          
          navigate(`/student/${studentId}/curriculum/${escapedUri}`);
        }
        else {
          onClick(item);
        }
      }

      const getColourForState = () : string => {
        if(state === undefined) {
          return "grey"; 
        }

        switch(state){
          case SubmissionStatus.Draft:
            return "grey";          
          case SubmissionStatus.PendingReview:
            return "orange";
          case SubmissionStatus.Reviewed:
            return isPass ? "green" : "red";
        }
      }

    return (
      <Stack horizontal={true} horizontalAlign='stretch'>
        <div style={{marginTop:20}}>
          {state === undefined ? 
            <div className="no-dot"></div> : 
            <div style={{backgroundColor:getColourForState()}} className="dot"></div>
          }
          </div>
          <Stack horizontalAlign='stretch' grow>
              <div className={classNames.itemCell} data-is-focusable={true} onClick={handleClick}>
                <div className={classNames.itemContent}>
                  <div className={classNames.itemName}><div style={{height: "3px", width:"15px", /*backgroundColor:item?.Rank?.Colour*/}}></div>{item!.Name}</div>
                  <div className={classNames.itemIndex}>{item!.Subcategory !== null ? item.Subcategory : item.Category}</div>              
                </div>
                {item?.Category === "" ? null : <Icon className={classNames.chevron} iconName={getRTL() ? 'ChevronLeft' : 'ChevronRight'} />}
              </div>            
          </Stack>
      </Stack>
    )

}

export default CurriculumRow;