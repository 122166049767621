import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {useParams, useNavigate} from 'react-router-dom';
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { CurriculumItem } from '../model/CurriculumItem';
import { getCurriculum, getReviewBySubmissionId, getStudent, getStudentCurriculumSubmissions, getTournament, postStudentCurriculumSubmission } from '../ApiService';
import { Breadcrumb, IBreadcrumbItem, IColumn, SelectionMode } from '@fluentui/react';
import List, { IDocument } from '../components/List';
import CurriculumViewer from '../components/CurriculumViewer';
import Loader from '../components/Loader';
import { Tournament, TournamentType } from '../model/Tournament';
import { ScoreTypes } from '../model/ReviewCollection';
import { IUploadedFile } from '../components/FileUploadButton';

interface ICurriculumDocument extends IDocument {    
    studentName: string;
    studentId: string;
    studentLevelWhenReviewed: string;
    score: number;
    scoreType: ScoreTypes;
    reviewStatus: string; 
    dateSubmitted: Date;   
    dateReviewed?: Date; 
    hasFeedback: boolean;
    reviewId: string;
}

const CurriculumDetail : FC = () => {
    let { curriculumId } = useParams<{curriculumId: string }>() as {curriculumId: string };
    let { studentId } = useParams<{studentId: string }>() as {studentId: string };
    let { tournamentId } = useParams<{tournamentId?: string }>() as {tournamentId?: string };

    let navigate = useNavigate();

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"CurriculumDetail" });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [curriculum, setCurriculum] = useState<CurriculumItem>();
    const [tournament, setTournament] = useState<Tournament>();
    const [items, setItems] = useState<ICurriculumDocument[]>();
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    const [columns, ] = useState<IColumn[]>([        
        {
            key: 'column2',
            name: 'Level When Reviewed',
            fieldName: 'studentLevelWhenReviewed',
            minWidth: 100,
            maxWidth: 250,      
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column3',
            name: 'Score',
            fieldName: 'score',
            minWidth: 60,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'number',
            onRender: (item: ICurriculumDocument) => {
                return item.score !== -1 ? item.scoreType === ScoreTypes.Percentage ? `${item.score.toFixed(2)}%` : item.score : null;
            },
            isPadded: true,
        },
        {
            key: 'column4',
            name: 'Status',
            fieldName: 'reviewStatus',
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column5',
            name: 'Date Submitted',
            fieldName: 'dateSubmitted',
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            data: 'date',
            isPadded: true,
            onRender: (item: ICurriculumDocument) => {
              return <span>{item.dateSubmitted.toDateString()}</span>;
            }
        },
        {
          key: 'column6',
          name: 'Actions',
          fieldName: 'actions',
          minWidth: 100,
          maxWidth: 120,
          isResizable: true,
          data: 'date',
          isPadded: true,
          onRender: (item: ICurriculumDocument) => {
            return item.hasFeedback ? <a href={`/student/${item.studentId}/review/${item.reviewId}`}>View Feedback</a> : null;
          }
        }
      ]);

      const onFileUploaded = async (uploadedFile: IUploadedFile) => {        
        var result = await postStudentCurriculumSubmission(instance, account!, curriculumId, studentId, uploadedFile.relativeFilePath, uploadedFile.type);

        if(result?.ok) {
            navigate(`/student/${studentId}/curriculum/${curriculumId}/submitted`)
        }
        else {
            alert('Something went wrong with the upload. Please refresh the page and try again.');
        }
        
      }

      const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>();

    useEffect(() => {        
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                
                var curriculum = await getCurriculum(instance, account, curriculumId); 
                var existingSubmissions = await getStudentCurriculumSubmissions(instance, account, curriculumId, studentId);
                var student = await getStudent(instance, account, studentId);

                let crumbs : IBreadcrumbItem[] = [];

                if(tournamentId !== undefined) {
                    var tournament = await getTournament(instance, account, tournamentId);
                    setTournament(tournament);

                    var hasSubmission = false;

                    for(let e of tournament.Events) {
                        let event = e;
                        if(existingSubmissions.find(s=>s.CurriculumId === event.EventId) !== undefined) {
                            hasSubmission = true;
                        }
                    }
                    
                    if(!hasSubmission) {
                        setCanSubmit(tournament.Type !== TournamentType.InPerson);
                    }

                    crumbs = [
                        { text: `${tournament.Title}`, key: '0', href: `/tournament/${tournament.Id}` },
                        { text: `${student.FirstName}`, key: '1' },
                        { text: `${curriculum.Name}`, key: '2', isCurrentItem:true, as: 'h2' }
                    ]
                }
                else {
                    setCanSubmit(true);

                    crumbs = [
                        { text: `${student.FirstName}`, key: '0', href: `/student/${studentId}` },
                        { text: `${curriculum.Name}`, key: '1', isCurrentItem:true, as: 'h2' }
                    ]
                }
                
                setCurriculum(curriculum);
                setIsLoading(false);
                setBreadcrumbs([...crumbs]);
                
                var key = 0;
                var items = new Array<ICurriculumDocument>();

                for(let submission of existingSubmissions) {
                    try
                    {
                        var reviewCollection = await getReviewBySubmissionId(instance, account, submission.Id);
                        
                        if(reviewCollection !== undefined){
                            items.push({
                                key: key.toString(),
                                dateSubmitted: new Date(submission.Created),
                                dateReviewed: new Date(reviewCollection.Reviews[reviewCollection.Reviews.length - 1].Created),
                                reviewStatus: reviewCollection.ResultText,
                                hasFeedback: reviewCollection.HasFeedback,
                                reviewId: reviewCollection.Reviews[0].StudentCurriculumSubmissionId,
                                score: reviewCollection.Result.Total * 100,
                                scoreType: reviewCollection.Result.Type,
                                studentName: student!.FirstName,
                                studentId: student!.Id,
                                studentLevelWhenReviewed: submission.StudentLevelWhenSubmitted.Label,
                                getTitle: ()=> student!.FirstName
                            });

                            key++;
                        }
                        else {
                            items.push({
                                key: key.toString(),
                                dateSubmitted: new Date(submission.Created),
                                reviewStatus: "Pending review",
                                hasFeedback: false,
                                reviewId: "",
                                score: -1,
                                scoreType: ScoreTypes.Percentage,
                                studentName: student!.FirstName,
                                studentId: student!.Id,
                                studentLevelWhenReviewed: student!.Level!.Label,
                                getTitle: ()=> student!.FirstName});

                            key++;    
                        }                        
                    }
                    catch{
                        items.push({
                            key: key.toString(),
                            dateSubmitted: new Date(submission.Created),
                            reviewStatus: "",
                            hasFeedback: false,
                            reviewId: submission.Id,
                            score: -1,
                            scoreType: ScoreTypes.Percentage,
                            studentName: student!.FirstName,
                            studentId: student!.Id,
                            studentLevelWhenReviewed: student!.Level!.Label,                            
                            getTitle: ()=> student!.FirstName});

                        key++;
                    }
                }
                
                setItems(items);                                       
            }
        }
       
        fetchData();    
        // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);    

   return (  
        isLoading ? <Loader Text="Just a moment.." /> : 
            curriculum !== undefined ? 
            <>
            {breadcrumbs !== undefined ? <Breadcrumb items={breadcrumbs!} /> : null }
           
            <CurriculumViewer canSubmit={canSubmit} tournament={tournament} curriculum={curriculum} studentId={studentId} curriculumId={curriculumId} onFileUploaded={onFileUploaded} />

            {items !== undefined && items.length > 0 ?
                <div style={{maxWidth:'90vw'}}>
                <br /><br />
                <hr />
                    <h3>History</h3>
                    <List items={items!} columns={columns} enableSort={true} selectionMode={SelectionMode.none} />                    
                    <br />

                </div>
                :
                null
            }  
            </>
            :
            <p>Not found :(</p>
   );
}

export default CurriculumDetail;