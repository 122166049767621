export interface ITournamentDivision {
    Id: string;
    Prefix: string;
    Name: string;
    Description: string;
    EventId: string;
    TournamentId: string;
    
    StatusModified:Date;
    Status: DivisionStatus;
    LastStatus: DivisionStatus;
}

export enum DivisionStatus {
    Pending = 0,        
    Staging = 1,
    AssignedToLocation = 2  
}