import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

const Auth : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Complete Auth" });

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [redirectTarget, setRedirectTarget] = useState<string>();
    const [redemptionToken, setRedemptionToken] = useState<string>();

    useEffect(()=> {
        var targetHref = decodeURI(document.location.hash).split('|')[1].split('&')[0];
        var targetSubdomain = decodeURIComponent(targetHref).split('.')[0].replace('https://','');

        var currentSubdomain = document.location.host.split('.')[0].replace('https://','');
        
        if(currentSubdomain !== targetSubdomain) {
            //send them over to complete auth on the originating subdomain
            document.location.replace(`https://${targetSubdomain}.ninjapanel.com/auth${document.location.hash}`);
        }

    },[]);

    const redirect = async () => {
        if (redirectTarget === undefined) {
            return;
        }
        
        var url = new URL(redirectTarget);
        if(url.hostname.endsWith('.ninjapanel.com')) {
           //redirect back to where they were trying to visit previously
           document.location.replace(`${redirectTarget}${redemptionToken === undefined ? '' : `/${redemptionToken}`}`);
        }
    }

    useEffect(()=> {
        if(account !== null) {
            redirect();
            return;
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, redirectTarget]);

    useEffect(()=> {

        // This will be run on component mount
        const callbackId = instance.addEventCallback(async (message:any) => {            
            // This will be run every time an event is emitted after registering this callback
            if (message.eventType === EventType.LOGIN_SUCCESS) {
                    const state = message.payload.state;

                    if(state.indexOf('|')) {
                        //new user onboarding auth came in here
                        var split = state.split('|');
                        setRedemptionToken(split[1]);
                        setRedirectTarget(split[0]);
                        return;
                    }
                    setRedirectTarget(state);                    
            }
        });

        return () => {
            // This will be run on component unmount
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[account]);
       
    return (  
        <></>
    );
}

export default Auth;
