import { DefaultButton, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IData {
    emails: string[];
}

const UserAlreadyExists : FC<IData> = ({emails}) => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"User Already Exists" });
    
    const navigate = useNavigate();    

    return (
        <>
            <div style={{backgroundColor:'white', padding:80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>                
                <Text variant='xLargePlus'>Have we seen you here before?</Text>
                
                <Stack tokens={{childrenGap:10}} horizontalAlign='start'>
                    <br />                    
                    {emails.length === 1 ?
                        <Text variant='medium'>We were unable to create a new user with email <strong>{emails[0]}</strong>, as a user with that email address already exists.</Text> : 
                        <Text variant='medium'>We were unable to create new users with the supplied email addresses, as one or more users with those email addresses already exist.</Text>
                    }
                    
                    <Text variant='medium'>Please login, or if you <em>really</em> want to create another account, try again with a different email address.</Text>
                    <br /><br />
                    <Stack.Item align='center'>
                        <Stack horizontal tokens={{childrenGap:10}}>
                            <PrimaryButton style={{padding:20}} onClick={() => navigate('/login', {replace:true})}>Login</PrimaryButton>
                            <DefaultButton style={{padding:20}} onClick={()=>document.location.reload()}>Create free account</DefaultButton>
                        </Stack>
                    </Stack.Item>
                    <br />
                    <Text variant='medium'><em>Unsure how to login?</em> <br />Check your inbox for an email from <strong>app@ninjapanel.com</strong> with more details.</Text>
                </Stack>
            </div>
        </>
    )
}

export default UserAlreadyExists;