import React, { FC, useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import DateTimePicker from 'react-datetime-picker';
import { CalendarIcon } from '@fluentui/react-icons-mdl2';
import { useMediaQuery } from 'react-responsive';
import { DateTime } from 'luxon';

interface IData {
    date?: Date;
    minDate?:Date;
    maxDate?:Date;
    showTime?: boolean;
    onDateChange: (date: Date) => void;
}
const DateEditor : FC<IData> = ({date, minDate, maxDate, onDateChange, showTime = false}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const [intDate, setIntDate] = useState<Date>(date === undefined ? new Date() : date);

    useEffect(()=> {
        onDateChange(intDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[intDate]);

    return (
        isMobile ? 
            <input 
                type='date' 
                style={{fontSize:16}}
                required
                defaultValue={DateTime.fromJSDate(intDate).toFormat('yyyy-MM-dd')}
                min={minDate !== undefined ? DateTime.fromJSDate(minDate).toFormat('yyyy-MM-dd') : DateTime.now().toFormat('yyyy-MM-dd')} 
                max={maxDate !== undefined ? DateTime.fromJSDate(maxDate).toFormat('yyyy-MM-dd') : DateTime.now().plus({days:300}).toFormat('yyyy-MM-dd')}
                onChange={(e) => e.currentTarget.value !== undefined ? setIntDate(
                    new Date(
                        parseInt(e.currentTarget.value.split('-')[0]), 
                        parseInt(e.currentTarget.value.split('-')[1])-1, 
                        parseInt(e.currentTarget.value.split('-')[2])))
                        : {}} /> : 
            showTime ? 
            <DateTimePicker            
                value={intDate}
                closeWidgets
                calendarIcon={<CalendarIcon />}                
                clearIcon={null}       
                         
                format='y-MM-dd h:mm a'
                dayPlaceholder='dd'
                monthPlaceholder='MM'
                yearPlaceholder='yyyy'
                openWidgetsOnFocus={false}
                required
                minDate={minDate} 
                maxDate={maxDate}
                onChange={(date: Date)=> setIntDate(date)}
                />    
                :
            
            <DatePicker            
                value={intDate}                        
                closeCalendar
                calendarIcon={<CalendarIcon />}
                clearIcon={null}                
                format='y-MM-dd'
                dayPlaceholder='dd'
                monthPlaceholder='mm'
                yearPlaceholder='yyyy'
                openCalendarOnFocus={false}
                defaultActiveStartDate={date !== undefined ? date : DateTime.now().toJSDate()}
                minDate={minDate} 
                maxDate={maxDate}
                onChange={(date: Date)=> setIntDate(date)}
                />                    
    )
}

export default DateEditor;