import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { Tournament } from '../model/Tournament';
import BasicWizard from './tournamentwizard/BasicWizard';
import TermsWizard from './tournamentwizard/TermsWizard';
import DateWizard from './tournamentwizard/DateWizard';
import EventsWizard from './tournamentwizard/EventsWizard';
import { ITimezoneViewModel } from '../model/TimezoneViewModel';

import { Category } from '../model/Category';
import TournamentConfirmation from './tournamentwizard/TournamentConfirmation';
import { CurriculumItem } from '../model/CurriculumItem';
import OperationsWizard from './tournamentwizard/OperationsWizard';
import CatalogWizard from './tournamentwizard/CatalogWizard';
import { CatalogItem } from '../model/CatalogItem';
import DisplayWizard from './tournamentwizard/DisplayWizard';

interface IData {
    timezones: ITimezoneViewModel[];
    categories: Category[];
    editTournament: Tournament | undefined;
}

enum WizardState {
    Step1 = 0,
    Step2 = 1,
    Step3 = 2,
    Step4 = 3,
    Step5 = 4,
    Step6 = 5,
    Step7 = 6,
    FinalConfirmation = 7
}

const TournamentWizard : FC<IData> = ({timezones, categories, editTournament}) => {
    const [tournament, setTournament] = useState<Tournament>(editTournament === undefined ? new Tournament() : editTournament);
    
    const [curriculumAdds, setCurriculumAdds] = useState<CurriculumItem[]>();
    const [curriculumEdits, setCurriculumEdits] = useState<CurriculumItem[]>();
    const [catalogAdds, setCatalogAdds] = useState<CatalogItem[]>([]);
    const [catalogEdits, setCatalogEdits] = useState<CatalogItem[]>([]);


    const [isValid, setIsValid] = useState<boolean>(false);
    const [wizardState, setWizardState] = useState<WizardState>(WizardState.Step1);
    
    const onValidated = (
        isValid: boolean,
        tournament: Tournament,
        curriculumAdds?: CurriculumItem[],
        curriculumEdits?: CurriculumItem[],
        catalogAdds?: CatalogItem[],
        catalogEdits?:CatalogItem[]) => {
        
        setIsValid(isValid);
        
        if(isValid) {
            setTournament(tournament);

            if (curriculumAdds !== undefined) {
                setCurriculumAdds(curriculumAdds!);
            }

            if (curriculumEdits !== undefined) {
                setCurriculumEdits(curriculumEdits!);
            }

            if (catalogAdds !== undefined) {
                setCatalogAdds(catalogAdds!);
            }

            if (catalogEdits !== undefined) {
                setCatalogEdits(catalogEdits!);
            }
        }
    }

    const nextStep = () => {
        if (editTournament === undefined && wizardState === WizardState.Step4) {
            //simplify the process for new tournaments
            setWizardState(WizardState.FinalConfirmation);
            return;
        }

        setWizardState(wizardState + 1);                
    }

    const previousStep = () => {
        setWizardState(wizardState-1);
    }

    return (
        <div style={{display:'flex', flexDirection:'column', gap:20}}>
            {wizardState === WizardState.Step1 ? <BasicWizard fixedId={editTournament !== undefined} tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.Step2 && <DisplayWizard tournament={tournament} onValidated={onValidated} />}
            {wizardState === WizardState.Step3 ? <DateWizard timezones={timezones} tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.Step4 ? <TermsWizard tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.Step5 ? <EventsWizard categories={categories} tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.Step6 ? <CatalogWizard tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.Step7 ? <OperationsWizard tournament={tournament} onValidated={onValidated} /> : null}
            {wizardState === WizardState.FinalConfirmation ?
                <TournamentConfirmation
                    isEdit={editTournament !== undefined}
                    tournament={tournament}
                    curriculumAdds={curriculumAdds}
                    curriculumEdits={curriculumEdits}
                    catalogAdds={catalogAdds}
                    catalogEdits={catalogEdits}
                    onGoBack={() => setWizardState(WizardState.Step7)}
                    onCreated={(tournament: Tournament) => document.location.href = `/event/${tournament.Id}/manage/settings`} /> : null}

            {wizardState !== WizardState.FinalConfirmation &&
                <div style={{display:'flex', gap:'5px', flexDirection:'column'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, paddingTop: 20 }}>
                        <DefaultButton onClick={() => previousStep()}>Previous</DefaultButton>
                        <PrimaryButton disabled={!isValid} onClick={() => nextStep()}>Next</PrimaryButton>
                    </div>
                    {editTournament !== undefined ? <ActionButton onClick={() => setWizardState(WizardState.FinalConfirmation)}>Skip to end</ActionButton> : null}
                    {wizardState === WizardState.Step6 ? <ActionButton onClick={() => setWizardState(WizardState.Step7)}>Skip catalog</ActionButton> : null}
                </div>
            }
        </div>
    )
}

export default TournamentWizard;