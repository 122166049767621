import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { IAppInsightsErrorBoundaryState } from "@microsoft/applicationinsights-react-js/types/AppInsightsErrorBoundary";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import React from "react";

export interface IAppInsightsErrorBoundaryStateWithError extends IAppInsightsErrorBoundaryState {
    error: any;
    properties: any;
}

export interface IAppInsightsErrorBoundaryPropsEx {
    appInsights: ReactPlugin;
    onError: (error: any, properties: any) => JSX.Element;
    children: React.ReactElement;
}

export default class AppInsightsErrorBoundaryWithError extends React.Component<IAppInsightsErrorBoundaryPropsEx, IAppInsightsErrorBoundaryStateWithError> {
    state = { hasError: false, error: undefined, properties: undefined };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.setState({ hasError: true, error: error, properties: errorInfo });
        this.props.appInsights.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: errorInfo
        });
    }

    render() {
        if (this.state.hasError) {
            const { onError } = this.props;
            return React.createElement(onError,{error: this.state.error, properties:this.state.properties});
        }

        return this.props.children;
    }
}