import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getClassSchedule, getTenantEnvironment } from '../ApiService';
import { Features } from '../Features';
import { ScheduleViewModel } from '../model/ScheduleViewModel';
import { ActionButton, FontWeights, getTheme, IButtonStyles, IconButton, IIconProps, mergeStyleSets, Modal, Persona, PersonaSize, Stack, Text } from '@fluentui/react';
import Loader from '../components/Loader';

const Schedule : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Schedule" });
    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [schedule, setSchedule] = useState<ScheduleViewModel>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
   
    useEffect(()=> {
        if(account) {
            fetchAsync();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[account, instance]);

    const fetchAsync = async () => {

        if (!account) {
            return;
        }

        //setIsLoading(true);
        var tenantEnvironment = await getTenantEnvironment(instance, account);

        if(tenantEnvironment.Features.indexOf(Features.Schedule) !== -1) {
            var schedule = await getClassSchedule(instance, account);

            if(schedule !== undefined) {
                setSchedule(schedule);             
            }
        }

        setIsLoading(false);
    }
 
    const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);

    const [joinInstructions, setJoinInstructions] = useState<string>();
    const [joinLink, setJoinLink] = useState<string>();

    useEffect(()=> {
        if(joinInstructions !== undefined && 
            joinInstructions?.length > 0) {
            
            setIsCalloutVisible(true);
        }
        else {
            setIsCalloutVisible(false);
        }
    },[joinInstructions]);
    
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.large,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  footer :{
    flex:'auto'
  }
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

    return(
        <>
            {isLoading ? <Loader Text="Fetching schedule, just a moment..." /> : null }

            {schedule?.Lessons.map((l, idx) => 
                <div style={{backgroundColor:'white', marginBottom:10, padding:20}} key={idx}>
                    <Stack tokens={{childrenGap:10}}>
                        <Text variant='medium'>{l.Title}: {l.Description}</Text>
                        <Text variant='smallPlus'>{l.StartTime} - {l.EndTime}</Text>
                        <Text variant='smallPlus'>{l.GetDayOfWeek()}</Text>
                        <Persona size={PersonaSize.size24} imageUrl={l.Instructor.ProfileImageUri} text={l.Instructor.DisplayName}  />
                        
                        {l.JoinLink?.length > 0 ? 
                        <Stack>
                            <Stack.Item align='end'>
                                <ActionButton iconProps={{iconName:'Video'}} href={l.JoinLink} target="_blank" rel='noreferrer'>Join online</ActionButton>
                            </Stack.Item>
                            <Stack.Item align='end'>
                                {l.JoinInstructions.length > 0 ? 
                                    <ActionButton 
                                        styles={{textContainer:{fontSize:10}, root:{height:'unset'}}} 
                                        onClick={()=> { setJoinLink(l.JoinLink); setJoinInstructions(l.JoinInstructions); }}>Instructions</ActionButton>
                                        : null}
                            </Stack.Item>
                        </Stack>
                        : null}                        
                        {/* <Stack.Item align='stretch'>
                            <ActionButton iconProps={{iconName:'ChevronDown', style:{marginLeft:'auto', marginRight:'auto'}}}></ActionButton>
                        </Stack.Item> */}
                    </Stack>                    
                </div>
            )}

            {joinInstructions === undefined ? 
                null : 
                <Modal     
                    styles={{main:{maxWidth:500}}}               
                    isOpen={isCalloutVisible}
                    onDismiss={() => setJoinInstructions(undefined)}
                    isBlocking={false}>
                    <div className={contentStyles.header}>
                    <span>Join Instructions</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close instruction modal"
                        onClick={() => setJoinInstructions(undefined)}
                    />
                    </div>
                    <div className={contentStyles.body}>
                        <span dangerouslySetInnerHTML={{__html:joinInstructions}} />
                    </div>
                    <div className={contentStyles.footer}>
                        <Stack>
                            <Stack.Item align='center'>
                                <ActionButton iconProps={{iconName:'Video'}} href={joinLink} target="_blank" rel='noreferrer'>Join online</ActionButton>
                            </Stack.Item>
                        </Stack>
                    </div>
                </Modal>
            }
        </>
    );
    
}

export default Schedule;