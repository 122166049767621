import { DateTime } from 'luxon';
import { Audience } from './Audience';

export class Instructor {
    DisplayName: string;
    ProfileImageUri: string;

    constructor(displayName: string, profileImageUri: string) {
        this.DisplayName = displayName;
        this.ProfileImageUri = profileImageUri;        
    }
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
};

export enum LessonDelivery {
    InPerson = 0,
    Online = 1,
    InPersonAndOnline = 2
}

export enum LessonRecurranceType {
    None = 0,
    OneWeek = 1,
    TwoWeeks = 2,
    Monthly = 4
}

export interface ILesson {    
    Id: string;
    Title: string;
    StartDate?: DateTime;
    EndDate?: DateTime;
    StartTime: string;
    EndTime: string;
    IntendedAudience: Audience[];    
    InstructorId: string;
    Instructor: Instructor;
    Delivery: LessonDelivery;
    Description: string;
    Day: DayOfWeek;
    JoinLink: string;
    JoinInstructions: string;
}

let inst = new Instructor("","");

export class Lesson implements ILesson {
    Id: string;
    Title: string;
    StartDate?: DateTime;
    EndDate?: DateTime;
    StartTime: string;
    EndTime: string;
    IntendedAudience: Audience[];    
    InstructorId: string;
    Delivery: LessonDelivery;
    Description: string;
    Day: DayOfWeek;
    JoinLink: string;
    JoinInstructions: string;

    Instructor: Instructor;

    // IsDisplayed: Boolean;
    // IsChecked: boolean;
    // IsCanceled: Boolean;
    // CancelationReason: string;

    // CurrentTime: DateTime;

    constructor(params: ILesson = {} as ILesson) {
            
        let {
            Id = "",
            Title = "",
            StartDate = undefined,
            EndDate = undefined,
            StartTime = "",
            EndTime = "",
            IntendedAudience = [],    
            InstructorId = "",
            Instructor = inst,
            Delivery = LessonDelivery.Online,
            Description = "",
            Day = DayOfWeek.Monday,
            JoinLink = "",
            JoinInstructions = ""
        } = params;
            
        this.Id = Id;
        this.Title = Title;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.StartTime = StartTime;
        this.EndTime = EndTime;
        this.IntendedAudience = IntendedAudience;
        this.InstructorId = InstructorId;
        this.Instructor = Instructor;
        this.Delivery = Delivery;
        this.Description = Description;
        this.Day = Day;
        this.JoinLink = JoinLink;
        this.JoinInstructions = JoinInstructions;        
    };

    
    // ShouldDisplayJoinLink() {

    //     if(this.IsCanceled || this.IsInPerson()) {
    //         return false;
    //     }

    //     var minsUntilClass = this.CurrentTime!.diff(this.NextStartTime,'minutes').minutes;

    //     //show the join link 120 minutes before the start of class through until 20 mins after it has started
    //     if(minsUntilClass >=0 && minsUntilClass <= 20) {
    //         return this.JoinLink !== undefined;
    //     }

    //     if(minsUntilClass > -120 && minsUntilClass <= 20) {
    //         return this.JoinLink !== undefined;
    //     }
        
    //     return false;
    // }

    // ToDisplayValue(verbose?: Boolean) {       
    //     if(verbose) {
    //         return `${this.Name}: ${this.Description}`; 
    //     }

    //     return this.Description;
    // }

    GetDayOfWeek = () : string => {
        switch(this.Day) {
            case DayOfWeek.Sunday:
                return "Sunday";
            case DayOfWeek.Monday:
                return "Monday";
            case DayOfWeek.Tuesday:
                return "Tuesday";
            case DayOfWeek.Wednesday:
                return "Wednesday";
            case DayOfWeek.Thursday:
                return "Thursday";
            case DayOfWeek.Friday:
                return "Friday";
            case DayOfWeek.Saturday:
                return "Saturday";
        }
    }
};