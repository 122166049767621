import React, { FC} from 'react';
import {AuthenticatedTemplate } from "@azure/msal-react";
import { RegistrationStatus, TournamentRegistration, TournamentRegistrationStatus } from '../../../model/TournamentRegistration';
import { Tournament, TournamentType } from '../../../model/Tournament';
import { DefaultButton, Image, Stack, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { ReviewCollection } from '../../../model/ReviewCollection';
import { useNavigate } from 'react-router-dom';
import { CanRegister } from '../../../Validation';

export interface IData {
    registrations: TournamentRegistration[];
    tournaments: Tournament[];
    reviews: ReviewCollection[];
}

interface IRego {
    text: string;
    date: Date;
    link?: string;
}

const EventRegistrations : FC<IData> = ({registrations, tournaments, reviews}) => {    

    const navigate = useNavigate();

    const getEntriesAndCatalogItems = (filteredRegos: TournamentRegistration, tournament: Tournament) : IRego[] => {
        var rows : IRego[] = [];  

        for(let e of filteredRegos.Events) {
            if(e.Status === RegistrationStatus.Pending) {
                continue;
            }

            let curriculumId = e.CurriculumId;
            let studentId = e.StudentId;
            let submissionId = e.SubmissionId;
            let created = e.Created;

            var event = tournament.Events.find(e2 => e2.EventId === curriculumId)!;
            var student = tournament.Students.find(s=>s.Id === studentId);
            var reviewCollection = reviews.find(r=>r.Reviews[0].StudentCurriculumSubmissionId === submissionId);
            
            var linkUri = reviewCollection !== undefined ? 
                `/student/${student?.Id}/review/${reviewCollection?.Reviews[0].Id}` :
                undefined;

            rows.push({
                text: `${event.Title} - ${student?.FirstName}`,
                link: linkUri,
                date: created
            });
        }

        for(let c of filteredRegos.Catalog) {
            if(c.Status === RegistrationStatus.Pending) {
                continue;
            }            

            let catItemId = c.CatalogItemId;
            let created = c.Created;
            var catItem = tournament.Catalog.find(c2 => c2.Id === catItemId)!;

            rows.push({
                text: `${c.Qty}x ${catItem.Name}`,
                date: created
            });
        }

        return rows;
    }

    return (        
            <AuthenticatedTemplate>
                {tournaments.map((t,idx) => 
                    <div key={idx} style={{backgroundColor:'white', padding:20}}>
                        <Stack key={idx} tokens={{childrenGap:10}}>                            
                            <Stack horizontal tokens={{childrenGap:10}}>
                                <Image src={t.ImageUri} width={40} />
                                <Stack>
                                    <Text variant='large'>{t.Title}</Text>
                                    {t.Type === TournamentType.InPerson ? 
                                        <Text variant='xSmall'>{t.Location}<br/>{t.StartDate === t.EndDate ? `${DateTime.fromJSDate(t.StartDate).toLocaleString(DateTime.DATE_SHORT)}` : `${DateTime.fromJSDate(t.StartDate).toLocaleString(DateTime.DATE_SHORT)} - ${DateTime.fromJSDate(t.EndDate).toLocaleString(DateTime.DATE_SHORT)}`}</Text> : 
                                        <Text variant='xSmall'>Hosted Online<br/>{DateTime.fromJSDate(t.StartDate).toLocaleString(DateTime.DATE_SHORT)}</Text>}
                                    {t.Type === TournamentType.Online && DateTime.fromJSDate(t.EndDate) < DateTime.utc() ? <DefaultButton onClick={() => navigate(`/event/${t.Id}/watch`)}><Text variant='small'>View Results</Text></DefaultButton> : null}
                                </Stack>
                            </Stack>
                            <div style={{paddingLeft:50, paddingTop:10, paddingBottom:10, gap: 10, display:'flex', flexDirection:'row'}}>
                                {
                                    ((t.HasEnded !== undefined && !t.HasEnded) ||
                                        DateTime.fromJSDate(t.EndDate) > DateTime.utc()) &&
                                        t.StatusHubId !== null &&
                                        t.StatusHubId !== undefined &&
                                        t.StatusHubId.length > 0 &&
                                        DateTime.fromJSDate(t.StartDate) < DateTime.utc() ? 
                                            <DefaultButton 
                                                onClick={()=>navigate(`/status/${t.StatusHubId}`)}
                                                iconProps={{iconName:'InternetSharing'}}>View status hub</DefaultButton> : null    
                                }
                                {t.BracketDownloadUri?.length > 0 ? <DefaultButton 
                                        iconProps={{iconName:'Org'}}><a style={{textDecoration:'none', color:'black'}} href={t.BracketDownloadUri} target='_blank' rel='noreferrer'>Download brackets</a></DefaultButton> : 
                                        null}
                                
                            </div>

                           
                            
                            <Stack tokens={{childrenGap:10}}>
                            {registrations?.filter(r=>r.TournamentId === t.Id && r.Status !== TournamentRegistrationStatus.Pending).length === 0 ?
                                <Stack tokens={{childrenGap:10}} horizontalAlign='center'>
                                    <Text>No registrations found.</Text>
                                    {
                                        CanRegister(t) ? 
                                            <DefaultButton onClick={() => navigate(`/event/${t.Id}`)}>Register</DefaultButton> : 
                                            null
                                    }
                                </Stack> :
                                    registrations?.filter(reg=>reg.TournamentId === t.Id && reg.Status !== TournamentRegistrationStatus.Pending).map((f) => 
                                        getEntriesAndCatalogItems(f,t).map((k,idx2) => 
                                            <Stack style={{paddingLeft:50}} key={idx2}>                                                
                                                <Text>{k.text}</Text>
                                                <Text variant='xSmall'>Purchased: {k.date.toLocaleString()}</Text>
                                                {k.link !== undefined ? <Link to={k.link}><Text variant='xSmall'>View Feedback</Text></Link> : null}
                                            </Stack>
                                        )
                                )}
                            </Stack>
                        </Stack>
                    </div>
                )}
            </AuthenticatedTemplate>
    );  
}

export default EventRegistrations;