export enum InvitationStatus {
    Pending = 0,
    Accepted = 1,
    Declined = 2,
    Canceled = 3,
    Expired = 4
}

export interface IUserProfile {
    Id: string;
    DisplayName: string;
    Email: string;
    NotifyEmail: boolean;
    Mobile: string;
    NotifyMobile: boolean;
    MobileConsentDate: Date | undefined;
    Issuer: string;
    ProfileImageUri: string;
    Roles: string[];
    LastVisit?: Date;
    Modified: Date;
    InvitationStatus?: InvitationStatus;
}

export class UserProfile implements IUserProfile  {
    Id: string;
    DisplayName: string;
    Email: string;
    NotifyEmail: boolean;
    Mobile: string;
    NotifyMobile: boolean;
    MobileConsentDate: Date | undefined;
    Issuer: string;
    ProfileImageUri: string;
    Roles: string[];
    LastVisit?: Date;
    Modified: Date;
    InvitationStatus?: InvitationStatus;

    constructor(
        params: IUserProfile = {} as IUserProfile) {
            
        let {
            Id = "",
            DisplayName = "",
            Email = "",
            NotifyEmail = false,
            Mobile = "",
            NotifyMobile = false,
            MobileConsentDate = undefined,
            Issuer = "",
            ProfileImageUri = "",
            Roles = [],
            LastVisit = undefined,
            Modified = new Date(),
            InvitationStatus = undefined   
        } = params;
            
        this.Id = Id;
        this.DisplayName = DisplayName;
        this.Email = Email;
        this.NotifyEmail = NotifyEmail;
        this.Mobile = Mobile;
        this.NotifyMobile = NotifyMobile;
        this.MobileConsentDate = MobileConsentDate;
        this.Issuer = Issuer;
        this.Roles = Roles;
        this.ProfileImageUri = ProfileImageUri;
        this.LastVisit = LastVisit;
        this.Modified = Modified;
        this.InvitationStatus = InvitationStatus;
    }
}

export class UserRoles {
    static Viewer: string = "Viewer";
    static Reviewer: string = "Reviewer";
    static Support: string = "Support";
    static Admin: string = "Admin";
    
    static GetDescription = (userRole: string) : string => {
        switch (userRole) {
            case UserRoles.Viewer:
                return "Can view curriculum and submit video responses.";
            case UserRoles.Reviewer:
                return "Can view submitted videos from all students and review them."
            case UserRoles.Support:
                return "Receives emailed support requests from students when issues arise using the portal."
            case UserRoles.Admin:
                return "Can invite new users and manage settings for all users."
        }

        return '';
    }

    static GetAll = () : string[] => {
        return [
            UserRoles.Viewer,
            UserRoles.Reviewer,
            UserRoles.Support,
            UserRoles.Admin
        ];
    }
}