import { InteractionType } from "@azure/msal-browser";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { Stack, Text, Image, IBreadcrumbItem, Breadcrumb } from "@fluentui/react";
import { DateTime } from "luxon";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStudent, getTournament, getTournamentEntries, getTournamentRegistrations } from "../ApiService";
import Loader from "../components/Loader";
import StudentCard from "../components/StudentCard";
import { Student } from "../model/Student";
import { Tournament } from "../model/Tournament";
import { TournamentEvent } from "../model/TournamentEvent";
import { useNavigate } from "react-router-dom";
import { TournamentEntryViewModel } from "../model/TournamentEntryViewModel";
import { ITournamentEventRegistration } from "../model/TournamentRegistration";

const TournamentEventDashboard : FC = () => {
    let { tournamentId } = useParams<{tournamentId: string }>() as {tournamentId: string };
    let { tournamentEventId } = useParams<{tournamentEventId: string }>() as {tournamentEventId: string };

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();

    const [tournamentEvent, setTournamentEvent] = useState<TournamentEvent>();
    const [tournament, setTournament] = useState<Tournament>();
    const [hasTournamentStarted, setHasTournamentStarted] = useState<boolean>(false);
    const [hasTournamentEnded, setHasTournamentEnded] = useState<boolean>(false);

    const [students, setStudents] = useState<Student[]>();
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>();

    const [otherStudentEntries, setOtherStudentEntries] = useState<TournamentEntryViewModel[]>();

    useEffect(()=> {
        const fetchAsync = async () => {
            var tournament = await getTournament(instance, account!, tournamentId);

            if(tournament === undefined) {
                return;
            }

            setTournament(tournament);
            setHasTournamentStarted(DateTime.utc() >= DateTime.fromJSDate(tournament.StartDate));
            setHasTournamentEnded(DateTime.utc() >= DateTime.fromJSDate(tournament.EndDate));

            var event = tournament.Events.find(e=>e.EventId === tournamentEventId);
            
            var registrations = await getTournamentRegistrations(instance, account!, tournamentId);

            var matchingEvents : ITournamentEventRegistration[] = [];

            if(registrations !== undefined) {            
                for(let r of registrations) {
                    var filtered = r.Events.filter(e=> e.CurriculumId === event?.EventId);                    
                    matchingEvents.push(...filtered);
                }
            }
            
            if(matchingEvents === undefined) {
                return;
            }

            let crumbs : IBreadcrumbItem[] =[
                { text: `${tournament.Title}`, key: '0', href: `/tournament/${tournament.Id}` },
                { text: `${event?.Title}`, key: '1', isCurrentItem:true, as: 'h2' }
            ]

            setBreadcrumbs([...crumbs]);
            
            var students : Student[] = [];
            
            for(let e of matchingEvents) {                
                students.push(await getStudent(instance, account!, e.StudentId));
            }
            
            setStudents(students);
            setTournamentEvent(event);

            //also look for submitted entries
            var otherStudentEntries = await getTournamentEntries(instance, account!, event!.EventId);

            setOtherStudentEntries(otherStudentEntries);
        }

        if(account) {
            fetchAsync();
        }

    },[account, instance, tournamentEventId, tournamentId]);

    return (
        <>
            {tournamentEvent === undefined ? 
                <Loader Text='Just a moment...' /> : 
                
                <div style={{padding:20}}>
                    {breadcrumbs !== undefined ? <Breadcrumb items={breadcrumbs!} /> : null }
                    <Stack style={{backgroundColor:'white', padding:20}} tokens={{childrenGap:10}}>
                        <Image style={{width:300}} src={tournamentEvent.ImageUri} />
                        <Text variant='xLarge'>{tournamentEvent.Title}</Text>
                        <Text variant='medium'><div dangerouslySetInnerHTML={{__html:tournamentEvent.Description}} /></Text>
                        <br />
                        {hasTournamentEnded ? null :
                            hasTournamentStarted ? 
                            students !== undefined && students?.length > 0 ? 
                            <>
                                <Text variant='xLarge'>Enter event</Text>
                                <Text variant='medium'>The following students are registered for this event.</Text>
                                
                                <br />
                                
                            </>  : null
                             :
                             students !== undefined && students?.length > 0 ? 
                             <>
                                <Text variant='xLarge'>Practice</Text>
                                <Text variant='medium'>The tournament runs from {DateTime.fromJSDate(new Date(tournament!.StartDate)).toLocaleString()} until {DateTime.fromJSDate(new Date(tournament!.EndDate)).toLocaleString()}. In the meantime, registered students should practice using the buttons below.</Text>
                                
                                <br />
                            </> : <Text variant="medium" style={{color:'red'}}>No students are registered for this event</Text>
                        } 
                        <Stack tokens={{childrenGap:10}} horizontal>
                            {students?.map((s,idx)=> {
                                return <StudentCard 
                                            key={idx} 
                                            imageUri={s.ImageUri} 
                                            studentName={s.FirstName} 
                                            initials={`${s.FirstName[0]}${s.LastName[0]}`} 
                                            level={s.Level!} 
                                            onClick={()=>navigate(`/student/${s.Id}/curriculum/${tournamentEvent.EventId}/tournament/${tournament?.Id}`)} />  
                            })}
                        </Stack>

                        {otherStudentEntries !== undefined ?                        
                            <>
                            <br />
                                <Text variant='xLarge'>Other entries</Text> 
                                
                                {otherStudentEntries?.map((s,idx) => 
                                    <div key={idx} style={{backgroundColor:'white', padding:20}}>
                                        <Stack tokens={{childrenGap:10}}>
                                            <Image src={s.StudentCurriculumSubmission.PosterImageUri} />
                                            <Text>{s.Student.FirstName} {s.Student.LastName[0]}</Text>
                                        </Stack>  
                                    </div>
                                )}
                            </>
                            : null
                        }                        
                    </Stack>
                </div>
            }
        </>
    );
}

export default TournamentEventDashboard;