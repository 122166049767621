import { Checkbox, Label, SpinButton, Stack, TextField } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { Level } from '../model/Level';
import { Program } from '../model/Program';

interface IData {
    editLevel?: Level;
    programs: Program[];
    onModelValidationStatusChanged: (level: Level, isValid: boolean)  => void;
}

const LevelEditor : FC<IData> = ({programs, onModelValidationStatusChanged, editLevel}) => { 
    const [level, setLevel] = useState<Level>(editLevel === undefined ? new Level() : editLevel);

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {        
        if(level.Label === null || level.Label.length === 0) {
            onModelValidationStatusChanged(level, false);    
            return;
        }

        if(level.Description === null || level.Description.length === 0) {
            onModelValidationStatusChanged(level, false);    
            return;
        }

        if(level.Order === -1) {
            onModelValidationStatusChanged(level, false);    
            return;
        }

        if(level.ProgramIds === null || level.ProgramIds.length === 0) {
            onModelValidationStatusChanged(level, false);    
            return;
        }
        
        onModelValidationStatusChanged(level, true);
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [level]);
     
      const updatePrograms = (e?: React.FormEvent, checked?: boolean) => {
        var id = (e?.target as HTMLInputElement).getAttribute("id");
        var programIds = level.ProgramIds;
  
        if(checked) {
            programIds.push(id!);
            setLevel(level => ({...level, ProgramIds: programIds }));
        }
        else {
            programIds.forEach( (item, index) => {
                if(item === id) {
                    programIds.splice(index, 1);
                }
            })
  
            setLevel(level => ({...level, ProgramIds: programIds }));
        }
      }

    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>Label</Label>            
                <TextField 
                    inputMode="text"                                               
                    defaultValue={level.Label}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setLevel({...level, Label: newValue!})}
                    onGetErrorMessage={validateFieldNotEmpty}/>
                <Label>Description</Label>            
                <TextField 
                    inputMode="text"                                               
                    defaultValue={level.Description}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setLevel({...level, Description: newValue!})}
                    onGetErrorMessage={validateFieldNotEmpty}/>
                <Label>Order</Label>            
                <SpinButton 
                    styles={{root:{width:50}}} 
                    max={100}
                    min={0}
                    value={level.Order.toString()}
                    onChange={(e,newValue) => setLevel({...level,Order: parseInt(newValue!)})} />
                <br />   
                <Label>Programs</Label>
                {programs.map((p,idx)=> {
                    return <Checkbox 
                                key={idx}
                                id={p.Id}
                                label={p.Name} 
                                defaultChecked={level.ProgramIds.find(l => l === p.Id) !== undefined}
                                onChange={updatePrograms}
                                 />
                })}
                <br />
            </Stack>

        </>
    )
}

export default LevelEditor;