import Header from './components/Header';
import Footer from './components/Footer';

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { Outlet } from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import AppInsightsErrorBoundaryWithError from './components/AppInsightsErrorBoundaryWithError';

initializeIcons();

const GlobalErrorBoundary: React.FC = (props) => {
  return (
      <AppInsightsErrorBoundaryWithError      
          onError={(error) => <ErrorPage error={error} />}
          appInsights={reactPlugin}>
           <>
          {props.children}
          </>
      </AppInsightsErrorBoundaryWithError>
  );
};

function App() {
  return (    
    <AppInsightsContext.Provider value={reactPlugin}>
      <GlobalErrorBoundary>
        <AuthenticatedTemplate>
          <Header /> 
        </AuthenticatedTemplate>        
        <div className="container">           
            <div className="main">
              <Outlet />
            </div>
        </div>
      </GlobalErrorBoundary> 
      <Footer />
    </AppInsightsContext.Provider>
  );
}

export default App;