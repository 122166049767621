import { DefaultButton, Dialog, DialogFooter, Text, PrimaryButton, Stack } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { cancelRegistration, getTransactionsById } from '../../../ApiService';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { ITournamentRegistration } from '../../../model/TournamentRegistration';
import { Transaction } from '../../../model/Transaction';

interface IData {
    transactionIds: string[] | undefined;
    tournamentRegistration: ITournamentRegistration
    tournamentId: string;
    onDismiss: () => void;
    onCancelWithoutRefundCompleted: (isSuccess: boolean) => void;
}

const CancelWithoutRefundDialog: FC<IData> = ({ transactionIds, tournamentRegistration, tournamentId, onDismiss, onCancelWithoutRefundCompleted }) => {

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<Transaction[]>();

    useEffect(() => {

        const fetchAsync = async () => {
            if (transactionIds === undefined ||
                tournamentId === undefined ||
                account === undefined ||
                instance === undefined) {
                return;
            }

            let foundTransactions = await getTransactionsById(instance, account!, tournamentId, transactionIds);

            if (typeof foundTransactions === 'number') {
                return;
            }

            setTransactions(foundTransactions);
        }

        fetchAsync();

    }, [transactionIds, account, instance, tournamentId]);
    
    const [hideDialog, { toggle: toggleDialog }] = useBoolean(false);

    const onCancelRegistration = async () => {
        if (tournamentRegistration === undefined) {
            return;
        }
        
        setIsSubmitting(true);
        
        var result = await cancelRegistration(instance,
            account!,
            tournamentId!,
            tournamentRegistration.Id);

        if (result) {
            toggleDialog();
        }
        else {
            alert('Sorry, something went wrong');
        }
        onCancelWithoutRefundCompleted(result!);
        setIsSubmitting(false);
    }

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 550 } };
    
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
    );    
    
    return (        
        <div>
            {tournamentRegistration !== undefined &&
                <Dialog
                    modalProps={modalProps}
                    dialogContentProps={{
                        closeButtonAriaLabel: 'Close',
                        title: "Registration Change"
                    }}
                    hidden={hideDialog}
                    onDismiss={() => onDismiss()}>
                    {isSubmitting || transactions === undefined ?
                        <Loader Text='Just a moment...' /> :
                            transactions !== undefined && transactions.length > 0  && <Stack tokens={{ childrenGap: 5 }}>
                                <Text variant='mediumPlus'>The following items will be canceled:</Text>
                                <ul>
                                {transactions.flatMap(f=>f.LineItems).map((li, idx) =>
                                    <li key={idx}>{li.Description}</li>
                                )} 
                                </ul>
                                <Text variant='smallPlus' style={{color:'red'}}>This action cannot be reversed and no money will be refunded to the registrant. To selectively cancel or refund money, please use the Refund button on the Transactions tab instead.</Text>
                        </Stack>
                    }
                    <DialogFooter>
                        <PrimaryButton disabled={isSubmitting || transactions === undefined} onClick={onCancelRegistration} text="Cancel registration" />
                        <DefaultButton disabled={isSubmitting || transactions === undefined} onClick={() => onDismiss()} text="Don't cancel" />
                    </DialogFooter>
                </Dialog>
            }
        </div>            
    );
}

export default CancelWithoutRefundDialog; 