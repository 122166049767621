import { Audience } from './Audience';
import {Resource} from './Resource';
import { SubmissionStatus } from './StudentCurriculumSubmission';

export enum CurriculumResponse
{
    None = 0,
    Video = 1
}

export enum CurriculumType
{
    Default = 0,
    TournamentEvent = 1
}

export interface ICurriculumItem {
    Id: string;
    Description: string;
    ResponseDescription: string;
    Name: string;
    ImageUri: string;
    Category: string;
    Response: CurriculumResponse;
    Subcategory: string;
    Steps: number;
    IntendedAudience: Audience[];
    Techniques: [];
    Resources?: Resource[];
    Type: CurriculumType;
    Order: number;
}

export class CurriculumItem implements ICurriculumItem {
    Id: string;
    Description: string;
    ResponseDescription: string;
    Name: string;
    ImageUri: string;
    Category: string;
    Response: CurriculumResponse;
    Subcategory: string;
    Steps: number;
    Techniques: [];
    IntendedAudience: Audience[];
    Resources?: Resource[];
    Type: CurriculumType;
    Order: number;

    Status?: SubmissionStatus;
    HasPass?: boolean;

    constructor(
        params: ICurriculumItem = {} as ICurriculumItem) {
            
        let {
            Id= "",
            Description ="",
            ResponseDescription = "",
            Name= "",
            ImageUri = "",
            Category= "",
            Response = CurriculumResponse.Video,
            Subcategory = "",
            Steps= 0,
            Techniques= [],
            Resources = [],
            IntendedAudience = [],
            Type = CurriculumType.Default,
            Order = -1

        } = params;
            
        this.Id = Id;
        this.Description = Description;
        this.ResponseDescription = ResponseDescription;
        this.Name = Name;
        this.ImageUri = ImageUri;
        this.Category = Category;
        this.Response = Response;
        this.Subcategory = Subcategory;
        this.Steps = Steps;
        this.IntendedAudience = IntendedAudience;
        this.Techniques = Techniques
        this.Resources = Resources;
        this.Type = Type;
        this.Order = Order;
    }
}