import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { CommandBar, IColumn, ICommandBarItemProps, Pivot, PivotItem, SelectionMode, Modal, getTheme, mergeStyleSets, FontWeights, Dialog, PrimaryButton, DefaultButton, DialogFooter, DialogType, IObjectWithKey } from '@fluentui/react';
import { cancelAssignedBrackets, cancelRequestToStageAthletes, downloadTournamentBracketsForDivision, getDivisionStatus } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useMediaQuery } from 'react-responsive';
import { IDivisionStatus, IEventStatus } from '../../../model/EventStatus';
import { DivisionStatus } from '../../../model/TournamentDivision';
import List, { IDocument } from '../../../components/List';
import CallToStagingDialog from './CallToStagingDialog';
import FileDownloader from '../../../components/FileDownloader';
import { useBoolean } from '@fluentui/react-hooks';
import SendToCompeteDialog from './SendToCompeteDialog';
import { DateTime } from 'luxon';
import { IAthleteCallRequest } from '../../../model/AthleteCallRequest';
import { ITournamentBracket } from '../../../model/TournamentBracket';

interface IData {
    status: IEventStatus;
    tournamentId: string;
}

interface ITableRow extends IDocument {
  key: string;
  name: string;
  description: string;
  numAthletes: number;
  location: string;
  statusModified: string;
  status: DivisionStatus;
  lastStatus: DivisionStatus;
  divisionId: string;
}

const AthleteCallDivisions : FC<IData> = ({status, tournamentId}) => {  
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  
  const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

  const [errorCode, setErrorCode] = useState<number>();
  const [divisionStatus, setDivisionStatus] = useState<IDivisionStatus>();
  const [bracketItems, setBracketItems] = useState<ITableRow[]>();

  const [selectedItem, setSelectedItem] = useState<ITableRow>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
  
  const [hasPendingSelection, setHasPendingSelection] = useState<boolean>(false);
  const [hasStagingSelection, setHasStagingSelection] = useState<boolean>(false);
  const [hasCompetingSelection, setHasCompetingSelection] = useState<boolean>(false);
  const [currentSelection, setCurrentSelection] = useState<IObjectWithKey[]>([]);

  useEffect(()=> {
    if (divisionStatus === undefined) {
      return;
    }

    var bracketTableRows = buildBracketTableRows(divisionStatus);

    setBracketItems([...bracketTableRows]);

      // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, [divisionStatus]);
  
  const buildBracketTableRows = (divisionStatus: IDivisionStatus) => {
    var tableRows = new Array<ITableRow>();

    for(let d of divisionStatus.Divisions) {
      
      let divisionId = d.Id;
      
      var brackets = divisionStatus.Brackets.filter(b=> b.DivisionId === divisionId);      
      
      if(brackets.length > 0) {
        var locations = brackets.map((br) => br.Location).join(',');

        for(let b of brackets) {
          
          var numAthletes = 0;
          
          for(let r of b.Rounds) {
            for(let m of r.Matches) {
              numAthletes += m.CompetitorIds.length;
            }
          }

          let title = `${d.Prefix} | ${b.Label} | ${d.Name}`;

          tableRows.push({
            description: d.Description,
            status: b.Status,
            lastStatus: b.LastStatus,
            statusModified: DateTime.fromJSDate(d.StatusModified).plus({days: 2}) > DateTime.now()  ? DateTime.fromJSDate(d.StatusModified).toLocaleString(DateTime.TIME_SIMPLE) : '',
            location: locations,
            name: title,
            getTitle: () => title,            
            key: b.Id,
            divisionId: b.DivisionId,
            numAthletes: numAthletes,            
          });
        }
      }          
    }

    return tableRows;
  }

    const [columns, ] = useState<IColumn[]>([
      {
          key: 'name',
          name: 'Name',
          fieldName: 'name',
          minWidth: 90,
          maxWidth: 150,
          isResizable: true,
          isCollapsible: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',   
          isPadded: true
        },
        {
          key: 'desc',
          name: 'Description',
          fieldName: 'description',
          minWidth: 90,
          maxWidth: 400,    
          isResizable: true,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true
      },
      {
        key: 'numAthletes',
        name: 'Number of Athletes',
        fieldName: 'numAthletes',
        minWidth: 90,
        maxWidth: 150,    
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'number',
        isPadded: true
    },
    {
      key: 'location',
      name: 'Location',
      fieldName: 'location',
      minWidth: 90,
      maxWidth: 150,    
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true
  },
    {
      key: 'statusModified',
      name: 'Last Modified',
      fieldName: 'statusModified',
      minWidth: 90,
      maxWidth: 150,
      isResizable: true,
      isCollapsible: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',   
      isPadded: true
    },]);

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
          var divisionStatus = await getDivisionStatus(instance, account!, tournamentId, status.EventId);

            if(typeof divisionStatus === 'number') {
                setErrorCode(divisionStatus);
                return;
            }

            setDivisionStatus(divisionStatus);                     
        }

        fetchAsync();        
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[tournamentId, account, instance]);  

    const pendingCommandBarItems : ICommandBarItemProps [] = [
      {
        key:'callToStaging',
        text: 'Call to Staging',
        iconProps: { iconName: 'Megaphone' },        
        disabled: !hasPendingSelection,
        onClick: () => setShowCallToStagingDialog(true)
      },
      {
        key:'sendToCompete',
        text: 'Assign to location',
        iconProps: {iconName:'FollowUser'},
        disabled: !hasPendingSelection,
        onClick: () => setShowSendToCompeteDialog(true)
      },
    ];

    const stagingCommandBarItems : ICommandBarItemProps [] = [
      {
        key:'sendToCompete',
        text: 'Assign to location',
        iconProps: {iconName:'FollowUser'},
        disabled: !hasStagingSelection,
        onClick: () => setShowSendToCompeteDialog(true)
      },
      {
        key:'cancelCallToStaging',
        text: 'Cancel Call to Staging',
        iconProps: {iconName:'Cancel'},
        disabled: !hasStagingSelection,
        onClick: () => toggleHideDialog()
      }
  ];
  
  const competingCommandBarItems : ICommandBarItemProps [] = [    
    {
      key:'cancelSendToCompete',
      text: 'Cancel location assignment',
      iconProps: {iconName:'Cancel'},
      disabled: !hasCompetingSelection,
      onClick: () => toggleHideDialog()
    }
  ];

    const farCommandBarItems : ICommandBarItemProps [] = [
      {
        key:'bracketDownload',
        text: 'Download brackets',
        iconProps: {iconName:'Org'},
        disabled: selectedItem === undefined,
        onClick: () => showModal()
      }
    ];

    const [showCallToStagingDialog, setShowCallToStagingDialog] = useState<boolean>(false);
    const [showSendToCompeteDialog, setShowSendToCompeteDialog] = useState<boolean>(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
  
  const moveItemToNextStage = (location: string, nextStage: DivisionStatus) => {
    //update the items locally
    currentSelection.forEach((s) => {
      var item = bracketItems?.find(i => i.key === s.key);

      if (item !== undefined) {        
        item.statusModified = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
        item.location = location;

        item.lastStatus = item.status;
        item.status = nextStage;// item.status === DivisionStatus.Pending ? DivisionStatus.Staging : DivisionStatus.AssignedToLocation;        
      }
    });   

    //force UI update
    setBracketItems([...bracketItems!]);
  }

  const onActiveItemChanged = (item?: any, index?: number) => {      
      setSelectedItem(item);
  }

  const [isGoingBack, setIsGoingBack] = useState<boolean>(false);
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Confirm Change',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to move ${(currentSelection?.length === 1 ? 'this division' : 'these divisions')}  back to ${currentSelection?.length === 1 ? "it's" : "their"} prior state?`,
  };

  const cancelAssignment = async () => {
    setIsGoingBack(true);

    //we've confirmed, so move the item back to the previous stage
    let result: boolean = false;    
    let selectedBrackets = getSelectedBrackets();

    let req: IAthleteCallRequest = {
      BracketIds: selectedBrackets.map(b => b.Id),
      Location: '',
      Minutes: 0
    }


    //we only allow one action for all selected brackets, can just take the first one and calculate based off that    
    let bracketItem = bracketItems?.find(b => b.divisionId === selectedBrackets[0].DivisionId);

    if (bracketItem?.status === DivisionStatus.AssignedToLocation) {
      result = await cancelAssignedBrackets(instance, account!, tournamentId!, req);
    }
    else {
      result = await cancelRequestToStageAthletes(instance, account!, tournamentId!, req);
    }

    if (!result) {
      alert("Sorry, something went wrong. Please try again");
    }
    else {
      //update the items locally
      currentSelection.forEach((s) => {
        var item = bracketItems?.find(i => i.key === s.key);
        
        if (item !== undefined) {
          item.statusModified = DateTime.now().toLocaleString(DateTime.TIME_SIMPLE);
          item.location = '';
            
          let lastStatus = item.lastStatus;
          item.lastStatus = item.status;
          item.status = lastStatus;//item.lastStatus === DivisionStatus.Staging ? DivisionStatus.Pending : DivisionStatus.Staging;

          var idxBra = bracketItems!.findIndex(i => i.key === item!.key);
        
          if (idxBra !== undefined) {
            setBracketItems([...bracketItems!]);
          }
        }
      });   
    }

    setIsGoingBack(false);
    toggleHideDialog();
  }

  const getSelectedBrackets = (): ITournamentBracket[] => {
    var brackets = new Array<ITournamentBracket>();

    currentSelection.forEach((s) => {
      var b = divisionStatus?.Brackets.find(b => b.Id === s.key);

      if (b !== undefined) {
        brackets.push(b);
      }
    });

    return brackets;
  }

  const getSelectedNumAthletes = (): number => {
    //find all selected ITableRow instances, sum the number
    var numAthletes = 0;

    bracketItems?.forEach((i) => {
      if (currentSelection.find(s => s.key === i.key)) {
        numAthletes += i.numAthletes;
      }
    });
      
    return numAthletes;
  }
    
    return (
          <AuthenticatedTemplate>
            <div style={{minWidth:isMobile ? 100 : 400, padding:40}}>
          {
            bracketItems === undefined ?
              <Loader Text='Just a moment...' /> :

              <Pivot onLinkClick={() => setSelectedItem(undefined)}>
                <PivotItem headerText='Pending'>
                  <br />
                  <CommandBar
                    items={pendingCommandBarItems} farItems={farCommandBarItems} />
                  <List
                    columns={columns}
                    items={bracketItems.filter(i => i.status === DivisionStatus.Pending)}
                    selectionMode={SelectionMode.multiple}
                    onActiveItemChanged={onActiveItemChanged}
                    onSelectionChanged={(s) => { setHasPendingSelection(s.getSelectedCount() > 0); setCurrentSelection(s.getSelection()) }}
                    enableSort />
                </PivotItem>
                <PivotItem headerText='Staging'>
                  <br />
                  <CommandBar
                    items={stagingCommandBarItems} farItems={farCommandBarItems} />
                  <List
                    columns={columns}
                    items={bracketItems.filter(i => i.status === DivisionStatus.Staging)}
                    selectionMode={SelectionMode.multiple}
                    onSelectionChanged={(s) => { setHasStagingSelection(s.getSelectedCount() > 0); setCurrentSelection(s.getSelection()); }}
                    onActiveItemChanged={onActiveItemChanged}
                    enableSort />
                </PivotItem>
                <PivotItem headerText='Competing at location'>
                  <br />
                  <CommandBar
                    items={competingCommandBarItems} />
                  <List
                    columns={columns}
                    items={bracketItems.filter(i => i.status === DivisionStatus.AssignedToLocation)}
                    selectionMode={SelectionMode.multiple}
                    onSelectionChanged={(s) => { setHasCompetingSelection(s.getSelectedCount() > 0); setCurrentSelection(s.getSelection()); }}
                    onActiveItemChanged={onActiveItemChanged}
                    enableSort />
                </PivotItem>
              </Pivot>
          }
          {divisionStatus === undefined || !hasPendingSelection || !showCallToStagingDialog ? null :                
            <CallToStagingDialog 
              onDismiss={(isValid, location)=>{  if(isValid) { moveItemToNextStage(location!, DivisionStatus.Staging)}; setShowCallToStagingDialog(false);}}
              numAthletes={getSelectedNumAthletes()}
              brackets={getSelectedBrackets()}
              minutes={divisionStatus.AthleteCallMinutes}
              stagingLocations={divisionStatus.StagingLocations} />                        
          }

          {divisionStatus === undefined || (!hasStagingSelection && !hasPendingSelection) || !showSendToCompeteDialog ? null :                
            <SendToCompeteDialog
              brackets={getSelectedBrackets()}
              tournamentId={tournamentId}
              bracketLocations={divisionStatus.BracketLocations}
              onDismiss={(isValid, location)=>{ if(isValid) { moveItemToNextStage(location!, DivisionStatus.AssignedToLocation)}; setShowSendToCompeteDialog(false);}} />
          }

            <Modal
              isOpen={isModalOpen}
              onDismiss={()=>{hideModal()}}            
              containerClassName={contentStyles.container}>
            <FileDownloader 
                    loadingMessage='Preparing brackets, just a moment...'
                    startDownload={()=> downloadTournamentBracketsForDivision(instance, account!, tournamentId!, status.EventId, getSelectedBrackets()[0].DivisionId)} />
          </Modal>
          
          <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
          >
            {isGoingBack ? <Loader Text='Just a moment...' /> : null}
                <DialogFooter>
                  <PrimaryButton disabled={isGoingBack} onClick={() => cancelAssignment()} text="Yes" />
                  <DefaultButton disabled={isGoingBack} onClick={toggleHideDialog} text="No" />
                </DialogFooter>
              </Dialog>
            </div>            
          </AuthenticatedTemplate>
    )
}

export default AthleteCallDivisions;