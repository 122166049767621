import React, { FC, useEffect, useState } from 'react';
import { DefaultButton, Label, PrimaryButton, Stack, TextField, Text, ActionButton, Dropdown, IDropdownOption } from '@fluentui/react';
import { CurriculumItem, CurriculumResponse, CurriculumType } from '../../../model/CurriculumItem';
import { Resource } from '../../../model/Resource';
import ResourceEditor from '../../../components/ResourceEditor';
import { EditIcon } from '@fluentui/react-icons-mdl2';
import DivisionCreator from './DivisionCreator';
import { IDivisionRuleGroup } from '../../../model/DivisionRuleGroup';
import { IPricingStrategy, Tournament, TournamentLayout, TournamentRole, TournamentType } from '../../../model/Tournament';
import { Category } from '../../../model/Category';
import { BracketingPolicy, EventRegistrationStatus, TournamentEvent } from '../../../model/TournamentEvent';
import { GetPricingLabelForStrategy, GetUserInputTypeString } from '../../../Display';
import EditableImage from './EditableImage';
import { StoragePurpose } from '../../../Storage';
import UserInputCreator from './UserInputCreator';
import { IUserInput } from '../../../model/CatalogItem';
import { IChangeState } from '../../../components/tournamentwizard/EventsWizard';
import { DateTime } from 'luxon';
import PricingWizard from '../../../components/tournamentwizard/PricingWizard';

interface ICurriculumProps {
    disabled: boolean;
    editTournamentEvent?: TournamentEvent;
    editCurriculumItem?: CurriculumItem;
    categories: Category[];
    tournament: Tournament;
    onStateChange: (
        currentState: CurrentState,
        changes: IChangeState) => void;
}

export enum CurrentState {
    CreateCurriculum = 1,
    SpecifyAudience = 2,
    AddCustomFields = 3,
    AddResource = 4,
    AddPricing = 5,
    CanSubmit = 6
}

const TournamentEventEditor : FC<ICurriculumProps> = ({tournament, editTournamentEvent, editCurriculumItem, categories, disabled, onStateChange}) => {
    const [showCustomFieldEditor, setShowCustomFieldEditor] = useState<boolean>(false);
    const [showResourceEditor,] = useState<boolean>(false);
    
    const highestOrder = Math.max(0, ...tournament.Events.map(event => event.Order));
    const newOrder = highestOrder + 1;
    const [tournamentEvent, setTournamentEvent] = useState<TournamentEvent>(editTournamentEvent === undefined ?
        new TournamentEvent(newOrder, EventRegistrationStatus.Open, "Athlete", "", "", "", "", "", [], [], [], BracketingPolicy.Ordered, [],[], '') :
        editTournamentEvent);
    const [curriculumItem, setCurriculumItem] = useState<CurriculumItem>(editCurriculumItem === undefined ?
        new CurriculumItem(
            {
                Type: CurriculumType.TournamentEvent,
                Response: tournament.Type === TournamentType.InPerson ?
                    CurriculumResponse.None : CurriculumResponse.Video,
                Category: '',
                Description: '',
                Id: '',
                ImageUri: '',
                IntendedAudience: [],
                Name: '',
                Order: -1,
                ResponseDescription: '',
                Steps: 0,
                Subcategory: '',
                Techniques: []                
            }) : editCurriculumItem);
    const [currentState, setCurrentState] = useState<CurrentState>(CurrentState.CreateCurriculum);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [canGoBack, ] = useState<boolean>(true);
    const [nextButtonText, setNextButtonText] = useState<string>("Next");
    const [pendingResource, setPendingResource] = useState<Resource>(new Resource());
    const [editResource, setEditResource] = useState<Resource>();
    const [pricingStrategyId, setPricingStrategyId] = useState<string | undefined>(tournament.Pricing.find(p => p.CurriculumId === tournamentEvent.EventId)?.PricingStrategyId);
    const [additionalFee, setAdditionalFee] = useState<number>(0);

    const categoryOptions: IDropdownOption[] = categories.map((c) => {
        return {
            key: c.Title, //TODO: switch this over to the category id 
            text: c.Title
        }
    });    

    const [pricingOptions, setPricingOptions] = useState<IDropdownOption[]>(
        tournament.PricingStrategies.reduce((options: IDropdownOption[], p) => {
            const existingOption = options.find((option) => option.key === p.Id);
            if (existingOption) {
                //existingOption.text += `, ${GetPricingLabelForStrategy(p, 0)}`;
            } else {
                options.push({
                    key: p.Id,
                    text: `${p.Title}`
                });
            }
            return options;
        }, [])); 
        
    const entrantOptions: IDropdownOption[] = [
        { key: "Athlete", text:"Athlete" },
        { key: "Coach", text: "Coach" },
        { key: "Referee", text: "Referee" },
        { key: "VIP", text: "VIP" },
        { key: "Volunteer", text: "Volunteer" }
    ];

    const bracketingPolicyOptions: IDropdownOption[] = [
        { key: BracketingPolicy.Ordered, text:"Ordered" },
        { key: BracketingPolicy.Repechage, text: "Repechage" }
    ];
    
    useEffect(() => {
        onStateChange(
            currentState,
            {
                curriculum: curriculumItem,
                tournamentEvent: tournamentEvent,
                pricing: {
                    AdditionalFee: additionalFee,
                    CurriculumId: tournamentEvent.EventId,
                    PricingStrategyId: pricingStrategyId!,
                    Price: 0,
                    Role: TournamentRole.Participant
                }
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[currentState]);

    
    useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, [curriculumItem]);
    
    
    const checkModelValidationState = ()  => {
        switch(currentState)
        {
            case CurrentState.CreateCurriculum:
                {
                    if(curriculumItem.Name.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }
        
                    if(curriculumItem.Description.length === 0) {
                    setCanGoNext(false);
                    return;    
                    }                    

                    break;
                }
                //TODO: replace this state with one that will select the division rules/allow creation of division rules
            case CurrentState.SpecifyAudience:
                {
                    if(curriculumItem.IntendedAudience[0] === undefined ||
                        curriculumItem.IntendedAudience[0].ProgramId.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }

                    if(curriculumItem.IntendedAudience[0] === undefined ||
                        curriculumItem.IntendedAudience[0].LevelId.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }

                    break;
                }
        }

        setCanGoNext(true);
    }   

  const goNextState = () => {
    switch(currentState) {
        case CurrentState.CreateCurriculum:         
            if(curriculumItem.IntendedAudience === undefined) {
                curriculumItem.IntendedAudience = [];
            }       

            setCurrentState(CurrentState.AddCustomFields);
            setNextButtonText("Next");
            setCanGoNext(true);
            // setCanGoNext(curriculumItem.IntendedAudience.length > 0);
            return;
        case CurrentState.SpecifyAudience:         
            if(curriculumItem.IntendedAudience === undefined) {
                curriculumItem.IntendedAudience = [];
            }            

            setCurrentState(CurrentState.CanSubmit);
            setNextButtonText("Save");
            return;
        case CurrentState.AddCustomFields:
            // for(let a of pendingAudience!) {
            //     curriculumItem.IntendedAudience!.push(a);
            // }

            setCurriculumItem(curriculumItem);
            setCurrentState(CurrentState.AddResource);
            setNextButtonText("Next");
            return;        
        case CurrentState.AddResource:
            if (pendingResource.Uri.length !== 0) { //hack to avoid writing this code correctly, assume it's invalid if the uri is empty
                
                if (editResource !== undefined) {
                    var foundIdx = curriculumItem.Resources!.findIndex((v) => v === editResource!);
                    curriculumItem.Resources!.splice(foundIdx, 1);
                    curriculumItem.Resources!.push(pendingResource!);
                }
                else {
                    curriculumItem.Resources!.push(pendingResource!);
                }
            
                setCurriculumItem(curriculumItem);
            }
            
            setCurrentState(CurrentState.CanSubmit);
            return;
    }
  }

  const goPreviousState = () => {
    switch(currentState) {
        case CurrentState.AddResource:
            setCurrentState(CurrentState.AddCustomFields);
            return;
        case CurrentState.AddCustomFields:
            setCurrentState(CurrentState.CreateCurriculum);
            return;
        // case CurrentState.CanSubmit:
        //     setCurrentState(CurrentState.AddStudent);
        //     return;
    }
  }

    
    const resourceValidationStatusChanged = (resource: Resource, isValid: boolean) => {
          
        if (!isValid) {
            setCanGoNext(false);
            return;
        }
      
        setPendingResource(resource);
        setCanGoNext(true);
    }

    const onEditResource = (r:Resource) => {        
        setEditResource(r);
        setCurrentState(CurrentState.AddResource);
    }
    
    const [, setRuleGroups] = useState<IDivisionRuleGroup[]>([]);

    const onDivisionCreated = (divisionRuleGroup: IDivisionRuleGroup) => {
        //by the time this is called, the rule groups are already persisted in the DB

        //these are stored separately from the tournament (the tournament references them)
        setRuleGroups(ruleGroups => ([...ruleGroups, divisionRuleGroup]));
    }

    const assignPricing = (pricingStrategyId?: string) => {        
        setPricingStrategyId(pricingStrategyId);
        return;
    }

    const getDisplayname = (bracketingPolicy: BracketingPolicy) => {
        if (bracketingPolicy === BracketingPolicy.Ordered) {
            return "Ordered";
        }
        else if (bracketingPolicy === BracketingPolicy.Repechage) {
            return "Repechage";
        }
        
        return "Unknown";
    }

    const onInputCreated = (input: IUserInput) => {
        setTournamentEvent(tournamentEvent => ({ ...tournamentEvent, Inputs: [...tournamentEvent.Inputs, input] }));
        setShowCustomFieldEditor(false);
    }

    const updateTitle = (e: any, newValue: string) => {
        setCurriculumItem(curriculumItem => ({ ...curriculumItem, Name: newValue }));
        setTournamentEvent(tournamentEvent => ({ ...tournamentEvent, Title: newValue }));
    }

    const updateDescription = (e: any, newValue: string) => {
        setCurriculumItem(curriculumItem => ({ ...curriculumItem, Description: newValue! }));
        setTournamentEvent(tournamentEvent => ({ ...tournamentEvent, Description: newValue }));
    }

    const [pendingStrategies, setPendingStrategies] = useState<IPricingStrategy[]>([]);    

    const savePricingStrategies = () => {
        tournament.PricingStrategies = pendingStrategies;

        setPricingOptions(pendingStrategies.reduce((options: IDropdownOption[], p) => {
            const existingOption = options.find((option) => option.key === p.Id);
            if (existingOption) {
                //existingOption.text += `, ${GetPricingLabelForStrategy(p, 0)}`;
            } else {
                options.push({
                    key: p.Id,
                    text: `${p.Title}`
                });
            }
            return options;
        }, []));

        setCurrentState(CurrentState.CreateCurriculum);
    }

    return (
        <div style={{width:'100%'}}>
        <Stack tokens={{childrenGap:10}}>
            {currentState === CurrentState.CreateCurriculum ?      
                    <Stack>
                        <br />{tournament.Layout === TournamentLayout.Default && <Stack>
                        <Label>Image</Label>
                        <EditableImage
                            width={75}
                            purpose={StoragePurpose.Tournament}
                            uri={curriculumItem.ImageUri}
                            onUriChange={(uri) => setCurriculumItem(curriculumItem => ({ ...curriculumItem, ImageUri: uri }))} />
                        </Stack>}
                    <TextField label="Event name" 
                        required 
                        placeholder="The title of the event"
                        defaultValue={curriculumItem.Name}
                            onChange={(e,newValue) => updateTitle(e,newValue!)} />
                        
                    <Dropdown label="Pricing" 
                        required 
                        defaultSelectedKey={tournament.Pricing.find(p=>p.CurriculumId === tournamentEvent.EventId)?.PricingStrategyId}
                        options={pricingOptions!} 
                        placeholder="Select the pricing model to apply to this event"
                        onChange={(e, option) => assignPricing(option?.key as string)} />
                        
                    <ActionButton iconProps={{iconName:'add'}} onClick={()=>setCurrentState(CurrentState.AddPricing)}>New pricing</ActionButton>
                    <TextField label="Additional Fee"                          
                        defaultValue={tournament.Pricing.find(p=>p.CurriculumId === tournamentEvent.EventId)?.AdditionalFee?.toString()}                        
                        placeholder="Additional amount to add to the price for this event."
                        onChange={(e, newValue) => setAdditionalFee(parseFloat(newValue!))} />
                        
                    {/* Supporting only a single additional terms item via the UI */}
                    <TextField label="Additional Terms"                          
                        defaultValue={tournamentEvent.AdditionalTerms[0]}
                        placeholder="Additional terms registrants must agree to when selecting this event."
                            onChange={(e, newValue) => setTournamentEvent({ ...tournamentEvent, AdditionalTerms: [newValue!]})} />
                        
                    {/* <Dropdown label="Category" 
                        required 
                        defaultSelectedKey={curriculumItem.Category}
                        options={categoryOptions!} 
                        placeholder="Select the category for this event"
                        onChange={(e, option) => setCurriculumItem(curriculumItem => ({...curriculumItem, Category: option!.text}))} /> */}
                    <Label required>Registrant Role</Label>
                    <Dropdown 
                        key='layout-options'
                        options={entrantOptions}
                        style={{width:300}} 
                        selectedKey={tournamentEvent.Role}
                            onChange={(e, o) => setTournamentEvent(() => ({ ...tournamentEvent, Role: o!.text }))} />
                        
                        {tournamentEvent.Role === "Athlete" ?
                            <>
                                <Label required>Bracketing Policy</Label>
                                <Dropdown
                                    key='layout-options'
                                    options={bracketingPolicyOptions}
                                    style={{ width: 300 }}
                                    defaultSelectedKey={tournamentEvent.BracketingPolicy}
                                    onChange={(e, o) => setTournamentEvent(() => ({ ...tournamentEvent, BracketingPolicy: o?.key as BracketingPolicy }))} />
                            </>
                            : null
}
                    
                    {/* <TextField label="Subtitle" 
                        defaultValue={curriculumItem.Subcategory} 
                        placeholder="Optional, used to group events on the registration page."
                        onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, Subcategory: newValue!}))}/> */}
                    <TextField label="Description"
                        required 
                        placeholder="Description of the event that will be displayed on the registration page."
                        defaultValue={curriculumItem.Description} 
                        multiline rows={10} 
                        onChange={(e, newValue) => updateDescription(e,newValue!)} />

                    {tournament.Type === TournamentType.InPerson ? null : 
                    <>
                        <Dropdown label="Category" 
                            required 
                            disabled={curriculumItem.Response === CurriculumResponse.None}
                            defaultSelectedKey={curriculumItem.Category}
                            options={categoryOptions!} 
                            placeholder="Select the category of rubric to align this curriculum to."
                            onChange={(e,option) => setCurriculumItem(curriculumItem => ({...curriculumItem, Category: option?.key! as string}))}/>
                        <TextField 
                            label="Response Description"
                            placeholder="Description shown when preparing to enter an online event. If you omit this, the system will provide default messaging."
                            defaultValue={curriculumItem.ResponseDescription} 
                            onChange={(e,newValue) => setCurriculumItem(curriculumItem => ({...curriculumItem, ResponseDescription: newValue!}))}
                            multiline 
                            disabled={curriculumItem.Response === CurriculumResponse.None}
                            rows={5} />
                    </> } 
                </Stack>
                : null}

            {currentState === CurrentState.SpecifyAudience ?
                <>
                    <DivisionCreator onDivisionCreated={onDivisionCreated} />                    
                </>
                : null
            }

                {currentState === CurrentState.AddCustomFields ?
                    <>
                        <Label>Custom Fields</Label>
                        <div style={{display:'flex', flexDirection:'column', gap:10}}>                            
                            {showCustomFieldEditor ?
                                <UserInputCreator onInputCreated={i => onInputCreated(i)} /> :
                                tournamentEvent?.Inputs.map((i, idx) =>
                                    <div
                                        key={idx}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            gap: 10,
                                        }}>
                                        <div>
                                            <span>{i.Label}:</span><br />
                                        </div>
                                        
                                        <span>{i.Options === undefined || i.Options.length === 0 ? GetUserInputTypeString(i.Type) : i.Options.flatMap(o=>o.Label).join(' | ')}</span>
                                    </div>
                                )}
                            <ActionButton disabled={showCustomFieldEditor} iconProps={{iconName:'Add'}} onClick={() => setShowCustomFieldEditor(true)}>Add</ActionButton>
                        </div>
                    </>
                    : null}
                
            {currentState === CurrentState.AddResource ?
                <>
                    <Label>Resource Details</Label>
                    {curriculumItem.Resources?.map((r,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack>
                                    <Text variant='medium'>{r.Name}</Text>
                                    <Text variant='smallPlus'>{r.Description}</Text>
                                    <Text variant='smallPlus'><a href={r.Uri} target='_blank' rel='noreferrer'>Link</a></Text>                                
                                </Stack>
                                <EditIcon style={{cursor:'pointer'}} onClick={() => onEditResource(r)} />
                            </Stack>
                        )
                    })}
                    
                        <ActionButton
                            disabled={disabled}
                            style={{ fontSize: 11, float: 'right' }}
                            iconProps={{ iconName: 'Add', style: { fontSize: 11 } }}
                            onClick={() => showResourceEditor}>Add resource</ActionButton>
                    {showResourceEditor &&
                        <ResourceEditor editResource={editResource} onModelValidationStatusChanged={resourceValidationStatusChanged} />} 
                </>
                : null
                }
                
                {currentState === CurrentState.AddPricing ?
                <>
                    <PricingWizard
                            tournament={tournament}
                            onDismiss={()=>setCurrentState(CurrentState.CreateCurriculum)}
                            onValidated={(isValid, tournament) => setPendingStrategies(isValid ? tournament.PricingStrategies : [])} />
                </>
                : null
            }

            {currentState === CurrentState.CanSubmit ? 
                    <>
                        <br />
                        <Text><strong>Name:</strong> {curriculumItem.Name}</Text>
                        <Text><strong>Category:</strong> {curriculumItem.Category}</Text>
                        <Text><strong>Subcategory:</strong> {curriculumItem.Subcategory}</Text>
                        
                        {tournamentEvent.Role === "Athlete" ?
                            <Text><strong>Bracketing:</strong> {getDisplayname(tournamentEvent.BracketingPolicy)}</Text> : null}
                        
                        <Text style={{ maxWidth: '90%' }}><strong>Description:</strong> {curriculumItem.Description}</Text>
                        <Label><strong>Custom Fields</strong></Label>
                        {tournamentEvent.Inputs?.length === 0 ? <Text>None.</Text> : null}
                        {tournamentEvent?.Inputs.map((i, idx) =>
                            <div key={idx}>
                                <span>{i.Label} ({GetUserInputTypeString(i.Type)})</span><br />
                                <span style={{ fontSize: 12 }}><em>{i.BindTo}</em></span>
                            </div>)}
                        <Label><strong>Pricing</strong></Label>
                        <Stack>
                            {tournament.PricingStrategies.filter(p => p.Id === pricingStrategyId).map((p, idx) =>
                                <Stack.Item key={idx}>
                                    <Stack>
                                        Until {DateTime.fromJSDate(p.Expiry).toLocaleString(DateTime.DATETIME_MED)}<br />
                                        {GetPricingLabelForStrategy(p!, additionalFee)}                                        
                                    </Stack>
                                    <br />
                                </Stack.Item>)}
                        </Stack>
                        {/* <Label>Division Rule Groups</Label>
                        No rule groups specified.                         */}
                        <Label><strong>Resources</strong></Label>
                        {curriculumItem.Resources?.length === 0 ? <Text>No resources.</Text> : null}
                        {curriculumItem.Resources?.map((r,idx) => {
                        return (
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack>
                                    <Text variant='medium'>{r.Name}</Text>
                                    <Text variant='smallPlus'>{r.Description}</Text>
                                    <Text variant='smallPlus'><a href={r.Uri} target='_blank' rel='noreferrer'>Link</a></Text>                                
                                </Stack>
                                <EditIcon style={{cursor:'pointer'}} onClick={() => onEditResource(r)} />
                            </Stack>
                            )
                        })}
                    
                    </> : null
                }

                {currentState === CurrentState.AddPricing ?
                    <>
                        <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 10 }}>
                            <PrimaryButton onClick={() => savePricingStrategies()}>Save</PrimaryButton>
                        </Stack>    
                    </>
                    :
                    <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 10 }}>
                        {currentState === CurrentState.AddResource ? <DefaultButton disabled={!canGoBack} onClick={() => goPreviousState()}>Cancel</DefaultButton> : null}
                        {currentState !== CurrentState.CanSubmit ? <PrimaryButton disabled={!canGoNext || showCustomFieldEditor} onClick={() => goNextState()}>{nextButtonText}</PrimaryButton> : null}
                    </Stack>
                }
            </Stack>
        </div>
    );    
}

export default TournamentEventEditor;