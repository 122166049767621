import { Stack, Text, Image, ImageFit, Dropdown, IDropdownOption, PrimaryButton, IDropdown, IconButton, Modal, IButtonStyles, FontWeights, IIconProps, getTheme, mergeStyleSets, Dialog, DialogType, DialogFooter, DefaultButton, SpinButton, ActionButton } from '@fluentui/react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { IUserInput, ICatalogItemInputWithValue } from '../../../model/CatalogItem';
import { ITournamentCatalogItemConfig, ITournamentCatalogItemPurchase, RegistrationStatus } from '../../../model/TournamentRegistration';
import { useBoolean } from '@fluentui/react-hooks';

interface IData {
    catalogItemId: string;
    inputs: IUserInput[];
    name: string;
    description: string;
    imageUri: string;
    price: number;
    availableQuantity: number;
    isCompact?: boolean;
    purchasedItems?: ITournamentCatalogItemPurchase[];

    onItemsCommitted: (items: ITournamentCatalogItemPurchase[]) => void;
    onItemsCleared: (catalogItemId: string) => void;
}

const CatalogItemCard : FC<IData> = ({purchasedItems, isCompact, availableQuantity, catalogItemId, onItemsCommitted, onItemsCleared, inputs, name, description, imageUri, price}) => {
    const [quantity, setQuantity] = useState<number>(0);
    
    const [committedItems, setCommittedItems] = useState<ITournamentCatalogItemPurchase[]>();
    const [assignedInputs, setAssignedInputs] = useState<ICatalogItemInputWithValue[]>([]);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);

    useEffect(()=>{
        var tempCanSubmit = true;

        for(let i of inputs) {
            let input = i;

            if(input.IsRequired && assignedInputs.find(a=>a.Label === input.Label) === undefined) {
                tempCanSubmit = false;
            }
        }
        
        setCanSubmit(tempCanSubmit);
    },[assignedInputs, inputs])

    const onOptionDropdownChanged = (input: IUserInput, value: string) => {
        var foundInput = assignedInputs?.find(i=>i.Label === input.Label);

        if(foundInput === undefined) {
            assignedInputs.push({
                BindTo: input.BindTo,
                IsRequired: input.IsRequired,
                Value: value,
                Label: input.Label,
                Description: input.Description,
                Options: input.Options,
                Type: input.Type
            });            
        }
        else {
            foundInput.Value = value;
        }

        setAssignedInputs([...assignedInputs]);
    }

    const doCommitItems = () => {        
        hideDialog();
        
        var config : ITournamentCatalogItemConfig[] = [];

        for(let a of assignedInputs) {
            config.push({
                Key: a.Label,
                Value: a.Value
            });
        }

        if(committedItems === undefined) {            
            setCommittedItems([{ Config: config, CatalogItemId: catalogItemId, Qty: quantity, Created: new Date(), Status: RegistrationStatus.Complete}]);
        } 
        else{
            setCommittedItems([...committedItems, {  Config: config, CatalogItemId: catalogItemId, Qty: quantity, Created: new Date(), Status: RegistrationStatus.Complete }]);
        }

        setQuantity(0);
        setAssignedInputs([]);
    }

    useEffect(()=> {
        committedItems === undefined ? 
            onItemsCommitted([]) : onItemsCommitted(committedItems);        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[committedItems])

    const doResetItems = () => {
        setCommittedItems(undefined);
        setQuantity(0);
        setAssignedInputs([]);
        
        onItemsCleared(catalogItemId);
        qtyRef.current?.dismissMenu();
    }

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);    

    const qtyRef = useRef<IDropdown>(null);

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 500        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    };

    const [isDialogOpen, { setTrue: showDialog, setFalse: hideDialog }] = useBoolean(false);

    const dialogContentProps = {
      type: DialogType.normal,
      title: `Configure ${name}`,
      closeButtonAriaLabel: 'Close',
      subText: inputs.length > 0 ? "Select quantity and other required values to add to cart" : "Select quantity to add to cart.",
    };

    const resetDialogThenHide = () => {
        setQuantity(0);
        setAssignedInputs([]);
        hideDialog();
    }
    
    return (
        <>        
            <Stack style={{maxWidth: isCompact ? "100%" : 400, padding:isCompact ? 10 : 20, backgroundColor:'white'}} tokens={{childrenGap:10}}>
                {isCompact ? null : <Image src={imageUri} imageFit={ImageFit.cover}/> }
                <Stack.Item>
                    <Stack tokens={{childrenGap:10}}>
                        <Stack horizontal tokens={{childrenGap:5}}>
                            <Stack.Item align='center'>
                                <Text variant='medium'>{name}</Text>
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <IconButton 
                                    styles={{root:{width:15,height:15}}} 
                                    style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:2}} 
                                    iconProps={{iconName:'StatusCircleQuestionMark'}} 
                                    onClick={showModal} />
                            </Stack.Item>
                        </Stack>
                        {isCompact ? null : <Text variant='medium'>{description}</Text>}                        
                        <Stack tokens={{childrenGap:5}}>
                            <Text variant='smallPlus'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</Text>
                        </Stack>
                        {availableQuantity > 0 ? 
                        <ActionButton iconProps={{iconName:'AddTo'}} onClick={showDialog}>Add</ActionButton> : <Text style={{color:'red'}}>Unavailable</Text>}
                    </Stack>                    
                </Stack.Item>
                <Stack.Item align='center'>
                    {committedItems === undefined || 
                        committedItems[committedItems.length - 1]?.Qty === 0 ? null : 
                        <Stack horizontal tokens={{childrenGap:5}}>
                            <Stack.Item align='center'>
                                {committedItems.map((c,idx) => <em key={idx}>{c.Config?.map(i=> `${i.Key}: ${i.Value}`)} x{c.Qty}{idx === committedItems.length -1 ? ' in cart' : ', '}</em> )}
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <IconButton 
                                    styles={{root:{width:15,height:15}}} 
                                    iconProps={{iconName:'Delete'}} 
                                    onClick={doResetItems} />
                            </Stack.Item>
                        </Stack>
                    }
                    {purchasedItems === undefined || 
                        purchasedItems[purchasedItems.length - 1]?.Qty === 0 ? null : 
                        <Stack horizontal tokens={{childrenGap:5}}>
                            <Stack.Item align='center'>
                                {purchasedItems.map((c,idx) => <em key={idx}>{c.Qty} {c.Config?.map(i=> `${i.Key} ${i.Value}`)}{idx === purchasedItems.length -1 ? ' already purchased' : ', '}</em> )}
                            </Stack.Item>                            
                        </Stack>
                    }
                </Stack.Item>
            </Stack>
            <Modal
                isOpen={isModalOpen}
                onDismiss={hideModal}
                containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>{name}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close modal"
                        onClick={hideModal} />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{childrenGap:10}}>
                        {price === 0 ? 
                            <Text variant='smallPlus'>Free</Text> :                                     
                            <Text variant='smallPlus'>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price)}</Text>                                    
                        }
                        <span dangerouslySetInnerHTML={{__html:description}} />
                    </Stack>
                </div>
            </Modal>
            <Dialog
                hidden={!isDialogOpen}
                onDismiss={hideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
              >
                
                <Stack tokens={{childrenGap:10}} horizontalAlign='end' horizontal={isCompact}>
                    {inputs.map((i,idx)=>
                        i.Options === null || i.Options.length === 0 ?  
                            <p key={idx}>{i.Label}</p> : 
                            <Stack key={idx} horizontal tokens={{childrenGap:10}}>
                                <Stack.Item align='center'>
                                    <Text variant={isCompact ? 'small': 'mediumPlus'}>{i.Label}:</Text>
                                </Stack.Item>
                                <Stack.Item align='center'>
                                    <Dropdown 
                                        styles={{dropdownOptionText:{fontSize:16}, title:{fontSize:16}}}
                                        onChange={(e,o) => onOptionDropdownChanged(i,o!.text)}
                                        dropdownWidth='auto' 
                                        options={i.Options.map((o)=> ({key: o.Id, text: o.Label} as IDropdownOption))} />
                                </Stack.Item>
                            </Stack>
                    )}                    
                    <Stack horizontal tokens={{childrenGap:10}}>
                        <Stack.Item align='center'>
                            <Text variant={isCompact ? 'small': 'mediumPlus'}>Qty:</Text>
                        </Stack.Item>
                        <Stack.Item align='center'>
                            <SpinButton 
                                min={0}
                                max={10}
                                style={{width:isCompact ? 30 : 100}} 
                                onChange={(e,n)=>setQuantity(parseInt(n!))}
                            />
                        </Stack.Item>
                    </Stack>
                </Stack>
                <DialogFooter>
                    <PrimaryButton onClick={()=>doCommitItems()} disabled={quantity <= 0 || !canSubmit}>Add to cart</PrimaryButton>
                    <DefaultButton onClick={resetDialogThenHide} text="Cancel" />
                </DialogFooter>
              </Dialog>
        </>
    )    
}

export default CatalogItemCard;