import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Panel, PanelType, Stack, Text } from '@fluentui/react';
import { getAthleteCallStatus } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useMediaQuery } from 'react-responsive';
import EventCallCard from './EventCallCard';
import { IEventStatus } from '../../../model/EventStatus';
import { useBoolean } from '@fluentui/react-hooks';
import AthleteCallDivisions from './AthleteCallDivisions';
import { DateTime } from 'luxon';

interface IData {
    tournamentId?: string;
  }

const AthleteCall : FC<IData> = ({tournamentId}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [errorCode, setErrorCode] = useState<number>();
    const [eventStatus, setEventStatus] = useState<IEventStatus[]>();
    const [bracketGenerationDate, setBracketGenerationDate] = useState<string>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        fetchAsync();           
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[tournamentId, account, instance]);

    const [isEventPanelOpen, { setTrue: openEventPanel, setFalse: dismissEventPanel }] = useBoolean(false);
    const [selectedEvent, setSelectedEvent] = useState<IEventStatus>();
    
    useEffect(()=> {
        if(selectedEvent === undefined) {
            return;
        }

        openEventPanel();
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [selectedEvent]);
    
    const fetchAsync = async () => {
        var athleteCallStatus = await getAthleteCallStatus(instance, account!, tournamentId!);
        
        if(typeof athleteCallStatus === 'number') {
            setErrorCode(athleteCallStatus);
            return;
        }

        setBracketGenerationDate(DateTime.fromJSDate(athleteCallStatus.BracketGenerationDate).toLocaleString(DateTime.DATETIME_FULL));
        setEventStatus(athleteCallStatus.Events);            
    }

    const updateLocalState = () => {
        setSelectedEvent(undefined);
        dismissEventPanel();
        fetchAsync();
    }

    return (
          <AuthenticatedTemplate>
            <div style={{minWidth:isMobile ? 100 : 400, padding:40, overflowY:'hidden'}}>
               {eventStatus === undefined ? 
                <Loader Text='Just a moment...' /> :
                    <Stack tokens={{ childrenGap: 10 }}>                        
                        {eventStatus.map((s, idx) => 
                            <EventCallCard 
                                key={idx}
                                onSelected={(data)=>setSelectedEvent(data)}
                                status={s}/>                                
                        )}
                        <br />
                        <Text style={{textAlign:'right'}} variant='small'><strong>Brackets last modified:</strong> {bracketGenerationDate}</Text>
                    </Stack>
                }            
                <Panel                            
                    headerText={selectedEvent?.EventTitle}
                    type={PanelType.smallFluid}
                    isOpen={isEventPanelOpen}
                    isLightDismiss={false}
                    onDismiss={() => { updateLocalState(); }}
                    closeButtonAriaLabel="Close">      
                    {selectedEvent === undefined || tournamentId === undefined ? null : 
                    <AthleteCallDivisions status={selectedEvent} tournamentId={tournamentId} /> }
                </Panel>  
            </div>
          </AuthenticatedTemplate>
    )
}

export default AthleteCall;