import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { getTournamentRoles, getUserProfile } from "./ApiService";
import { ITournamentStakeholderRoleWithId } from "./model/TournamentReviewDashboardViewModel";
import { UserProfile } from "./model/UserProfile";

export interface IIdentityContext {
    userProfile?: UserProfile;
    tournamentRoles?: ITournamentStakeholderRoleWithId[];
    isInTournamentRole: (tournamentId: string, roles: string[]) => boolean;
}

export const useIdentity  = () : IIdentityContext => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isFetching, setIsFetching] = useState(false);
    const [tournamentRoles, setTournamentRoles] = useState<ITournamentStakeholderRoleWithId[]>();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    
    useEffect(() => {
        if(!isAuthenticated || isFetching) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                setIsFetching(true);
                
                var userProfile = await getUserProfile(instance, account);
                setUserProfile(userProfile);
                
                var roles = await getTournamentRoles(instance, account);
                setTournamentRoles(roles);
                
                setIsFetching(false);
            }
        }
       
        fetchData();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, inProgress, account]);

    const IsInTournamentRole = (tournamentId: string, roles: string[]) : boolean => {
        if(tournamentRoles === undefined) {
            return false;
        }

        var rolesForTournament = tournamentRoles.filter(r=> r.TournamentId === tournamentId);

        for(let r of rolesForTournament) {
            let currentRole = r.Value;

            if(roles.find(f=>f === currentRole) !== undefined) {
                return true;
            }
        }

        return false;
    }

    return { tournamentRoles: tournamentRoles, userProfile: userProfile, isInTournamentRole: IsInTournamentRole};
}