import { IconButton, Label, Text, Slider, Stack } from '@fluentui/react';
import React, {CSSProperties, FC, useEffect, useRef, useState} from 'react';
import ReactPlayer from 'react-player/youtube'
import { Resource } from '../model/Resource';
import VideoTrackbar from './VideoTrackbar';
import {VideoMarker} from '../model/VideoMarker';
import { IReactionValue } from '../model/Reaction';
import VideoAnnotation from './VideoAnnotation';
import { useMediaQuery } from 'react-responsive';

export interface IVideoPlayer {
    style?:CSSProperties;
    Resource: Resource;
    Markers?: VideoMarker[];
    Reactions?: IReactionValue[];
    ShowSkipControls?: boolean;
    CanPlay?: () => void;
    GoNext?: () => void;
    GoPrevious?: () => void;
    PosterImage?: string;
    VideoEnded?: () => void;
    VideoLoaded?: (videoRef: HTMLVideoElement) => void;
    PlaybackStatusChanged?: (xCoord:number, yCoord:number, width: number, height: number, isPlaying:boolean) => void;
    IsPlayingChanged?: (isPlaying: boolean) => void;
}

const VideoPlayer : FC<IVideoPlayer> = ({
    Resource, 
    PosterImage, 
    ShowSkipControls, 
    CanPlay,
    GoNext,
    Markers, 
    Reactions, 
    VideoLoaded, 
    VideoEnded, 
    PlaybackStatusChanged, 
    IsPlayingChanged,
    style}) => { 
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const [playbackCurrentTime, setPlaybackCurrentTime] = useState<number>(0);
    const [playbackProgress, setPlaybackProgress] = useState<number>(0);
    const [playbackLength, setPlaybackLength] = useState<number>(0);
    const [activeMarkers, setActiveMarkers] = useState<VideoMarker[]>();
    const [hasLoadedMeta, setHasLoadedMeta] = useState<boolean>(false);
    const [videoPlaybackSpeed, setVideoPlaybackSpeed] = useState<number>(1);

    useEffect(()=> {
        if(playbackProgress === 100) {
            VideoEnded?.();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[playbackProgress]);

    useEffect(()=> {
        //disable existing tracks (possible from a prior video), there's no way to remove them
        if(embeddedPlayerRef.current !== null && embeddedPlayerRef.current.textTracks !== null) {
            for(let i = 0; i < embeddedPlayerRef.current.textTracks.length; i++) {
                embeddedPlayerRef.current!.textTracks[i].mode = 'disabled';
            }
        }

        if(Reactions !== undefined && Reactions.length > 0) {                               
            var reactionTrack = embeddedPlayerRef.current?.addTextTrack("captions", "Reactions", "en");
            reactionTrack!.mode = "showing";

            for(let reaction of Reactions) {   
                reactionTrack?.addCue(new VTTCue(reaction.Timestamp, reaction.Timestamp + 1, reaction.Value));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[Reactions, hasLoadedMeta])

    const onTimeUpdate = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        setPlaybackCurrentTime(event.currentTarget.currentTime);
        setPlaybackProgress((event.currentTarget.currentTime/event.currentTarget.duration) * 100);

        var activeMarkers = Markers?.filter(m => 
            event.currentTarget.currentTime >= m.Timestamp && 
            m.Timestamp + 0.9 >= event.currentTarget.currentTime &&
            m.Width !== 0 &&
            m.Height !== 0);

        setActiveMarkers(activeMarkers);        
    }

    const onSeekRequested = (newSeekPercentage: number) => {
        if(playbackLength === 0) {
            return;
        }
        var newSeek = playbackLength * newSeekPercentage;
        embeddedPlayerRef.current!.currentTime = newSeek;
    }

    const getFormattedTimeString = (e:number) :string => {
        var h = Math.floor(e / 3600).toString().padStart(2,'0'),
        m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
        s = Math.floor(e % 60).toString().padStart(2,'0');
    
        if(h !== '00') {
            return h + ':' + m + ':' + s;
        }
        
        return m + ':' + s;
    }    

    useEffect(()=> {
        if(embeddedPlayerRef.current === null) {
            return;
        }

        embeddedPlayerRef.current.playbackRate = videoPlaybackSpeed;
    },[videoPlaybackSpeed]);

    const handleVideoSpeedChange = (value: number) => {
        setVideoPlaybackSpeed(value);
    }

    const onLoadedMetadata = (event:React.SyntheticEvent<HTMLVideoElement, Event>) => {
        setPlaybackCurrentTime(0);
        setPlaybackProgress(0);

        if(!isNaN(event.currentTarget.duration) && event.currentTarget.duration !== Number.POSITIVE_INFINITY) {            
            setPlaybackLength(event.currentTarget.duration);
        }
        
        if(Markers !== undefined && Markers.length > 0) {
            var markerTrack = embeddedPlayerRef.current?.addTextTrack("captions", "Feedback", "en");
            markerTrack!.mode = "showing";

            for(let marker of Markers) {                
                markerTrack?.addCue(new VTTCue(marker.Timestamp, marker.Timestamp + 1, marker.Title));
            }
        }
        
        setHasLoadedMeta(true);
        VideoLoaded?.(embeddedPlayerRef.current!);
    }

      const handleVideoClick = (e:React.PointerEvent<HTMLVideoElement>) => {
        var rect = e.currentTarget.getBoundingClientRect()
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.    
        
        
        if(embeddedPlayerRef.current?.paused) {
            embeddedPlayerRef.current?.play();
         }
         else {
            embeddedPlayerRef.current?.pause();
         }

         PlaybackStatusChanged?.(x, y, rect.width, rect.height, !embeddedPlayerRef.current?.paused);
      }

    const embeddedPlayerRef = useRef<HTMLVideoElement>(null);

    const handlePlay = () => {
        IsPlayingChanged?.(true);
    }

    const handlePause = () => {
        IsPlayingChanged?.(false);
    }

    const handleEnded = () => {        
        IsPlayingChanged?.(false);
    }

    const handleCanPlay = () => {
        embeddedPlayerRef.current!.playbackRate = videoPlaybackSpeed;
        CanPlay?.();
    }

    const renderVideo = (r: Resource) => {
        
        switch(r.MediaType){
            case "video/mp4":
            case "video/webm":
                return <div>
                            <em>{r.Name}</em>
                            {r.Description !== undefined && r.Description !== null && r.Description.length > 0 ? <><br /><span style={{fontSize:13}}>{r.Description}</span><br /></> : null}
                            
                            <div className="video-container">
                                {activeMarkers !== undefined && activeMarkers.length > 0 ? activeMarkers?.map((value, idx) => {return <VideoAnnotation key={idx} VideoMarker={value} Width={embeddedPlayerRef.current?.clientWidth!} Height={embeddedPlayerRef.current?.clientHeight!} />}) : null}
                                <video 
                                    style={style}
                                    className="video-player" 
                                    ref={embeddedPlayerRef} 
                                    src={r.Uri} 
                                    controls={false} 
                                    poster={PosterImage}
                                    onTimeUpdate={onTimeUpdate} 
                                    onLoadedMetadata={onLoadedMetadata} 
                                    onClick={handleVideoClick} 
                                    onPlay={handlePlay}
                                    onCanPlay={handleCanPlay}
                                    onPause={handlePause}
                                    onEnded={handleEnded}/>
                                <VideoTrackbar Progress={playbackProgress} Markers={Markers} Reactions={Reactions} SeekRequested={onSeekRequested} TotalDuration={playbackLength} />                            
                                <Stack horizontal className="video-controls">
                                    {embeddedPlayerRef.current === null || embeddedPlayerRef.current?.paused ?
                                     <IconButton iconProps={{iconName:"Play"}} onClick={()=>embeddedPlayerRef.current?.play()} /> :
                                    <IconButton iconProps={{iconName:"Pause"}} onClick={()=>embeddedPlayerRef.current?.pause()} />
                                    }
                                    {ShowSkipControls !== undefined && ShowSkipControls ? 
                                        <IconButton iconProps={{iconName:"Next"}} onClick={GoNext} /> : null
                                    }
                                    <Stack.Item style={{marginTop:6, paddingLeft:10}}>
                                        <Text className='video-timestamp'>{getFormattedTimeString(playbackCurrentTime)} {playbackLength > 0 ? `/ ${getFormattedTimeString(playbackLength)}`  : null}</Text>
                                    </Stack.Item>
                                    <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                        <Label className="video-speed">Speed: </Label>
                                        <Slider                                             
                                            min={0.2}                                             
                                            max={4} 
                                            step={0.2} 
                                            styles={{slideBox:{width:100}}}
                                            defaultValue={1}    
                                            valueFormat={(value:number)=> `${value}x`}                                        
                                            snapToStep 
                                            // eslint-disable-next-line react/jsx-no-bind
                                            onChange={handleVideoSpeedChange} />                                  
                                </Stack>
                            </div>                        
                        </div>
            case "youtube":
                return <div>
                            <em>{r.Name}</em>
                            {r.Description !== undefined && r.Description !== null && r.Description.length > 0 ? <><br /><span style={{fontSize:13}}>{r.Description}</span></> : null}
                            <br /><br />
                            <ReactPlayer width={isMobile ? '80vw' : 640} url={r.Uri} controls />
                        </div>
            default:
                return <p><a href={r.Uri}>{r.Name}</a></p>
        }
    }

    return (
        <>
            {renderVideo(Resource)}
        </>
    )
}

export default VideoPlayer;