import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { CheckboxVisibility, CommandBar, Image, IColumn, ICommandBarItemProps, ImageFit, Panel, Selection, SelectionMode, Stack, Text, ActionButton, Modal, FontWeights, mergeStyleSets, getTheme, Dialog, DialogFooter, DefaultButton, PrimaryButton, DialogType } from '@fluentui/react';
import { useAccount, useMsal } from "@azure/msal-react";
import { useMediaQuery } from 'react-responsive';
import Loader from '../../../components/Loader';
import { GetPriceDisplayString, GetTournamentLocalTime, GetTournamentStartAndEndDates } from '../../../Display';
import { disableTrainingFacility, exportBrackets, exportDivisionRules, getAvailableTimezones, getCategories, getCoupons, getTournament, getViralJoinEnv, ICoupon, regenerateBrackets } from '../../../ApiService';
import List, { IDocument } from '../../../components/List';
import { Tournament, TournamentType } from '../../../model/Tournament';
import CreateCouponDialog from './CreateCouponDialog';
import { useBoolean, useId } from '@fluentui/react-hooks';
import TournamentWizard from '../../../components/TournamentWizard';
import { ITimezoneViewModel } from '../../../model/TimezoneViewModel';
import { Category } from '../../../model/Category';
import { useNavigate } from 'react-router-dom';
import FileDownloader from '../../../components/FileDownloader';
import { IFileDownload } from '../../../model/FileDownload';
import DivisionRuleImporter from '../../../components/DivisionRuleImporter';
import { useIdentity } from '../../../Identity';
import { DateTime } from 'luxon';
import CreateFacilityDialog from './CreateFacilityDialog';
import { ITrainingFacility } from '../../../model/TrainingFacility';
import StakeholderManagement from './StakeholderManagement';

interface IData {
    tournamentId: string;
}

interface ICouponRow extends IDocument {
    id: string;
    expiry: string;
    value: number;
    actualRedemptions: number;
    maxRedemptions: number | undefined;
    maxRedemptionsPerMembership: number | undefined;
}

interface IFacilityRow extends IDocument {
    title: string;
    shortTitle: string;
}

enum DownloadAction {
    None = 0,
    ExportDivisionRules = 1,
    ExportBrackets = 2
}

const TournamentSettings: FC<IData> = ({ tournamentId }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const identity = useIdentity();    

    const [tournament, setTournament] = useState<Tournament>();
    const [couponItems, setCouponItems] = useState<ICouponRow[]>();

    const [downloadAction, setDownloadAction] = useState<DownloadAction>(DownloadAction.None);

    useEffect(() => {
        const fetchAsync = async () => {
            var tournament = await getTournament(instance, account!, tournamentId);

            //when editing a tournament we need to use the raw pricing strategies that we are sent as the other pricing strategies will get filtered out
            //if they have expired or are not yet active which is bad for the edit experience
            tournament.PricingStrategies = tournament.RawPricingStrategies;
            
            setTournament(tournament);
            setBracketGenerationDate(tournament.BracketGenerationDate === undefined ? 'Never' : DateTime.fromJSDate(tournament.BracketGenerationDate).toLocaleString(DateTime.DATETIME_FULL));
        }

        fetchAsync();
    }, [tournamentId, account, instance]);

    useEffect(() => {
        const fetchAsync = async () => {
            var coupons = await getCoupons(instance, account!, tournamentId);
            
            var couponItems: ICouponRow[] = [];

            coupons.forEach((coupon: ICoupon) => {
                couponItems.push({
                    key: coupon.Id,
                    getTitle: () => { return coupon.Id; },
                    id: coupon.Id,
                    expiry: coupon.Expiry === undefined ? "No Expiry" : GetTournamentLocalTime(coupon.Expiry, tournament!.Timezone),
                    value: coupon.Value,
                    actualRedemptions: coupon.ActualRedemptions,
                    maxRedemptions: coupon.MaxRedemptions,
                    maxRedemptionsPerMembership: coupon.MaxRedemptionsPerMembership
                });
            });

            setCouponItems(couponItems);
        }

        if (tournament === undefined) {
            return;
        }

        fetchAsync();
    }, [tournament, instance, account, tournamentId]);

    const [facilityItems, setFacilityItems] = useState<IFacilityRow[]>();
    const [facilities, setFacilities] = useState<ITrainingFacility[]>();
    const [canEditFacilityList, setCanEditFacilityList] = useState<boolean>(false);

    useEffect(() => {
        const fetchAsync = async () => {
            var env = await getViralJoinEnv();
            
            if (!env?.CanAddTrainingFacility) {
                setCanEditFacilityList(true);
                setFacilities(env?.TrainingFacilities);

                var facilityItems: IFacilityRow[] = [];

                env?.TrainingFacilities.forEach((facility: ITrainingFacility) => {
                    facilityItems.push({
                        key: facility.Id,
                        getTitle: () => { return facility.Title; },
                        shortTitle: facility.ShortTitle,
                        title: facility.Title
                    });
                });
    
                setFacilityItems(facilityItems);
            }
        }

        if (tournament === undefined) {
            return;
        }

        fetchAsync();
    }, [tournament, instance, account]);

    const [facilitySelection, setFacilitySelection] = useState<Selection>(new Selection());
    const [selectedFacilityRow, setSelectedFacilityRow] = useState<IFacilityRow>();

    const onFacilityActiveItemChanged = (item?: IFacilityRow, index?: number) => {
        if (facilitySelection === undefined) { //do nothing to keep linter happy
            setSelectedFacilityRow(undefined);
        }
        else {
            setSelectedFacilityRow(item!);
        }
    }

    const [facilityColumns,] = useState<IColumn[]>([
        {
            key: 'column1',
            name: 'Title',
            fieldName: 'title',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'column2',
            name: 'Short Title',
            fieldName: 'shortTitle',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        }]);


    const [couponColumns,] = useState<IColumn[]>([
        {
            key: 'column1',
            name: 'Code',
            fieldName: 'id',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'column2',
            name: 'Expiry',
            fieldName: 'expiry',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true
        },
        {
            key: 'column3',
            name: 'Value',
            fieldName: 'value',
            minWidth: 50,
            maxWidth: 75,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{GetPriceDisplayString(item.value)}</Text>
        },
        {
            key: 'column3a',
            name: 'Actual Redemptions',
            fieldName: 'actualRedemptions',
            minWidth: 50,
            maxWidth: 75,
            isResizable: true
        },
        {
            key: 'column4',
            name: 'Max Redemptions',
            fieldName: 'maxRedemptions',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{item.maxRedemptions === undefined ? 'Unlimited' : item.maxRedemptions}</Text>
        },
        {
            key: 'column5',
            name: 'Max Redemptions Per Membership',
            fieldName: 'maxRedemptionsPerMembership',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: ICouponRow) => <Text>{item.maxRedemptionsPerMembership === undefined ? 'Unlimited' : item.maxRedemptionsPerMembership}</Text>
        },
    ]);

    const [couponSelection, setCouponSelection] = useState<Selection>(new Selection());
    const [selectedCouponRow, setSelectedCouponRow] = useState<ICouponRow>();

    const onCouponActiveItemChanged = (item?: ICouponRow, index?: number) => {
        if (couponSelection === undefined) { //do nothing to keep linter happy
            setSelectedCouponRow(undefined);
        }
        else {
            setSelectedCouponRow(item!);
        }
    }

    const copyCoupon = () => {
        if (selectedCouponRow === undefined) {
            return;
        }

        navigator.clipboard.writeText(selectedCouponRow?.id);
        alert('Coupon code copied to clipboard');
    }

    const startDownload = async (): Promise<number | IFileDownload> => {       
        
        switch (downloadAction) {
            case DownloadAction.ExportDivisionRules:
                return exportDivisionRules(instance, account!, tournamentId);
            case DownloadAction.ExportBrackets:
                return exportBrackets(instance, account!, tournamentId);
            default:
                return 0;
        }
    }

    const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);
    const [isImportModalOpen, { setTrue: showImportModal, setFalse: hideImportModal }] = useBoolean(false);
    const [isRegenerateModalOpen, { setTrue: showRegenerateModal, setFalse: hideRegenerateModal }] = useBoolean(false);

    const disableSelectedTrainingFacility = () => {
        const doAsync = async () => {
            var success = await disableTrainingFacility(instance, account!, tournamentId, selectedFacilityRow!.key);

            if (success) {
                setFacilities(facilities!.filter(f => f.Id !== selectedFacilityRow!.key));
                setFacilityItems(facilityItems!.filter(f => f.key !== selectedFacilityRow!.key));
                setSelectedFacilityRow(undefined);
            }
            else {
                alert('Failed to disable facility');
            }
            
            return;
        }

        if (selectedFacilityRow === undefined) {
            return;
        }

        doAsync();
    }

    const tfNearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'New Facility',
        iconProps: { iconName: 'add' },
        disabled: false,
        onClick: () => showNewFacilityModal()
    },
    {
        key: 'disable',
        text: 'Disable',
        iconProps: { iconName: 'Hide' },
        disabled: selectedFacilityRow === undefined,
        onClick: () => disableSelectedTrainingFacility()
    }];

    const bracketingNearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'regenerate',
        text: 'Regenerate Brackets',
        iconProps: { iconName: 'BuildDefinition' },
        disabled: tournament === undefined ||
            tournament.BracketGenerationDate === undefined ||
            tournament.HasEnded === true ||
            DateTime.utc() >= DateTime.fromJSDate(tournament.StartDate),
        onClick: () => showRegenerateModal()
    },
    {
        key: 'exportBrackets',
        text: 'Export Brackets',
        iconProps: { iconName: 'Download' },
        disabled: tournament === undefined,
        onClick: () => {
            setDownloadAction(DownloadAction.ExportBrackets); showDownloadModal();
        }
    }];

    const divisionRulesNearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'export',
        text: 'Export Division Rules',
        iconProps: { iconName: 'Download' },
        disabled: tournament === undefined,
        onClick: () => {
            setDownloadAction(DownloadAction.ExportDivisionRules); showDownloadModal();
        }
    },
    {
        key: 'import',
        text: 'Import Division Rules',
        iconProps: { iconName: 'Upload' },
        disabled: tournament === undefined,
        onClick: () => {
            // Trigger the file dialog
            const fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.accept = '.xlsx';
            fileInput.addEventListener('change', (event: unknown) => handleImport(event as ChangeEvent<HTMLInputElement>));
            fileInput.click();
        }
        }];
    
    const [importFile, setImportFile] = useState<File | undefined>();

    const handleImport = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        setImportFile(file);
        showImportModal();
    };

    const nearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'New Coupon',
        iconProps: { iconName: 'add' },
        disabled: false,
        onClick: () => showNewCouponModal()
    },
    {
        key: 'copyCoupon',
        text: 'Copy Coupon',
        iconProps: { iconName: 'Copy' },
        disabled: selectedCouponRow === undefined,
        onClick: () => copyCoupon()
        }];
    
    const onCouponCreated = (coupon: ICoupon) => {
        setCouponItems([...couponItems!, {
            key: coupon.Id,
            getTitle: () => { return coupon.Id; },
            id: coupon.Id,
            expiry: coupon.Expiry === undefined ? "No Expiry" : GetTournamentLocalTime(coupon.Expiry, tournament!.Timezone),
            value: coupon.Value,
            actualRedemptions: coupon.ActualRedemptions,
            maxRedemptions: coupon.MaxRedemptions,
            maxRedemptionsPerMembership: coupon.MaxRedemptionsPerMembership
        }]);

        hideNewCouponModal();
    }

    const onFacilityCreated = (facility: ITrainingFacility) => {
        setFacilities([...facilities!, facility]);
        hideNewFacilityModal();
    }

    const [isNewFacilityModalOpen, { setTrue: showNewFacilityModal, setFalse: hideNewFacilityModal }] = useBoolean(false);
    const [isNewCouponModalOpen, { setTrue: showNewCouponModal, setFalse: hideNewCouponModal }] = useBoolean(false);
    const [isEditTournamentModalOpen, { setTrue: showEditTournamentModal, setFalse: hideEditTournamentModal }] = useBoolean(false);

    const [allTz, setAllTz] = useState<ITimezoneViewModel[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    
    const onEditTournament = async () => {
        showEditTournamentModal();

        var tzs = await getAvailableTimezones(instance, account!);
        setAllTz(tzs);
  
        var categories = await getCategories(instance, account!);
        setCategories(categories);
    }
    
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const regenerateBracketsDialogContentProps = {
        type: DialogType.normal,
        title: 'Confirm Regeneration',
        closeButtonAriaLabel: 'Close',
        subText: `Are you sure you want to regenerate brackets? This will overwrite any existing brackets and cannot be undone.`,
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };
    
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
      );
    
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [bracketRegenerationDate, setBracketGenerationDate] = useState<string>();

    const doRegeneration = async () => {
        setIsRegenerating(true);

        let success = await regenerateBrackets(instance, account!, tournamentId);

        setIsRegenerating(false);

        if(!success) {
            alert('Failed to regenerate brackets');
            return;
        }
        
        setBracketGenerationDate(DateTime.now().toLocaleString(DateTime.DATETIME_FULL));
        hideRegenerateModal();
    }

    return (
          <AuthenticatedTemplate>
            <div style={{ marginTop:40, minWidth: isMobile ? 100 : 400 }}>                
                
                <Stack tokens={{childrenGap:10}}>
                    <Text variant='large'>Tournament</Text>
                    <br />
                    {tournament === undefined ? <Loader Text='Just a moment...' /> :
                        <Stack horizontalAlign='start' tokens={{childrenGap:20}}>                            
                            <div style={{ textAlign: 'center', backgroundColor: 'white', padding: 20 }}>                                
                                <Stack horizontalAlign='center'>
                                    <Image src={tournament.ImageUri} width={165} imageFit={ImageFit.contain} />
                                </Stack>
                                <Text variant='medium'>{tournament.Title}</Text>
                                <br />                            
                                <Text variant='small'>{GetTournamentStartAndEndDates(tournament.StartDate!, tournament.EndDate!, tournament.Timezone)}</Text>
                                {tournament.Type === TournamentType.InPerson ? <><br /><Text variant='small'>{tournament.Location}</Text></> : null}
                                <br />
                                <ActionButton iconProps={{iconName:'View'}} text='View' onClick={() => navigate(`/event/${tournament.Id}`)} />
                                <ActionButton disabled={identity.userProfile?.Roles.indexOf("Admin") === -1} iconProps={{ iconName: 'Edit' }} text='Edit' onClick={onEditTournament} />                                
                            </div>                            
                            <Text variant='mediumPlus'>Tournament Stakeholders</Text>
                            <div style={{width:"100%"}}>
                                <StakeholderManagement tournamentId={tournament.Id} />
                            </div>
                        </Stack>                        
                        
                    }
                    <br /><br />
                    <Text variant='large'>Division Rules</Text>
                    <br />
                    <CommandBar
                        items={divisionRulesNearCommandBarItems}
                        farItems={[]} />
                    <br />
                    {tournament === undefined && <Loader Text='Just a moment...' />}
                    {tournament !== undefined && <>
                        <Text variant='medium'>Rules last updated: {tournament.DivisionRulesModified === undefined ?
                            DateTime.fromJSDate(tournament.Created).toLocaleString(DateTime.DATETIME_FULL) :
                            DateTime.fromJSDate(tournament.DivisionRulesModified).toLocaleString(DateTime.DATETIME_FULL)}</Text>
                    </>}

                    <br /><br />
                    <Text variant='large'>Bracketing</Text>
                    <br />
                    <CommandBar
                        items={bracketingNearCommandBarItems}
                        farItems={[]} />
                    <br />
                    {tournament === undefined && <Loader Text='Just a moment...' />}
                    {tournament !== undefined && <>
                        <Text variant='medium'>Brackets last generated: {bracketRegenerationDate}</Text>
                    </>}
                    
                    <br /><br />
                    <Text variant='large'>Coupons</Text>
                    <br />
                    <CommandBar
                        items={nearCommandBarItems}
                        farItems={[]} />
                    {couponItems === undefined && <Loader Text='Just a moment...' />}
                    {couponItems !== undefined && couponItems.length === 0 && <Text>No coupons available</Text>}
                    {couponItems !== undefined && couponItems.length > 0 && 
                        <List
                            selectionMode={SelectionMode.single}  
                            enableSort                       
                            checkboxVisibility={CheckboxVisibility.hidden}
                            onActiveItemChanged={onCouponActiveItemChanged} 
                            onSelectionChanged={(s)=>setCouponSelection(s)}    
                            items={couponItems}
                            columns={couponColumns}/>                            
                    }

                    {canEditFacilityList && <>
                        <br /><br />
                        <Text variant='large'>Training Facilities</Text>
                        <br />
                        <CommandBar
                            items={tfNearCommandBarItems}
                            farItems={[]} />
                        {facilities === undefined && <Loader Text='Just a moment...' />}
                        {facilities !== undefined && facilities.length === 0 && <Text>No facilities found</Text>}
                        {facilityItems !== undefined && facilityItems.length > 0 && 
                            <List
                                selectionMode={SelectionMode.single}  
                                enableSort                       
                                checkboxVisibility={CheckboxVisibility.hidden}
                                onActiveItemChanged={onFacilityActiveItemChanged} 
                                onSelectionChanged={(s)=>setFacilitySelection(s)}    
                                items={facilityItems}
                                columns={facilityColumns}/>                            
                        }
                    </>}
                                        
                </Stack>
                <Panel
                    headerText='Create Facility'
                    isOpen={isNewFacilityModalOpen}
                    isLightDismiss={false}
                    onDismiss={hideNewFacilityModal}
                    closeButtonAriaLabel="Close">
                    <CreateFacilityDialog 
                        tournamentId={tournamentId}
                        onCompleted={onFacilityCreated} />
                </Panel>
                <Panel
                    headerText='Create Coupon'
                    isOpen={isNewCouponModalOpen}
                    isLightDismiss={false}
                    onDismiss={hideNewCouponModal}
                    closeButtonAriaLabel="Close">
                    <CreateCouponDialog
                        tournamentId={tournamentId}
                        onCompleted={onCouponCreated} />
                </Panel>
                <Panel
                    headerText='Edit Tournament'
                    isOpen={isEditTournamentModalOpen}
                    isLightDismiss={false}
                    onDismiss={hideEditTournamentModal}
                    closeButtonAriaLabel="Close">
                    {(tournament === undefined || categories === undefined || allTz === undefined) ? <Loader Text='Just a moment...' /> : 
                        <TournamentWizard
                            editTournament={tournament}
                            categories={categories}
                            timezones={allTz} />
                    }
                </Panel>
                <Modal
                    isOpen={isDownloadModalOpen}
                    onDismiss={() => { setDownloadAction(DownloadAction.None); hideDownloadModal(); }}
                    containerClassName={contentStyles.container}>
                    <FileDownloader 
                        loadingMessage='Preparing, just a moment...'
                        startDownload={startDownload} />
                </Modal>

                <Modal
                    isOpen={isImportModalOpen}
                    onDismiss={() => hideImportModal()}
                    containerClassName={contentStyles.container}>
                    <DivisionRuleImporter 
                        tournament={tournament!}
                        file={importFile!} />
                </Modal>
                <Dialog
                    hidden={!isRegenerateModalOpen}
                    onDismiss={hideRegenerateModal}
                    dialogContentProps={regenerateBracketsDialogContentProps}
                    modalProps={modalProps}>
                    {isRegenerating && <Loader Text='Regenerating brackets, just a moment...' />}
                    <DialogFooter>
                        <PrimaryButton disabled={isRegenerating} onClick={doRegeneration} text='Regenerate' />
                        <DefaultButton disabled={isRegenerating} onClick={hideRegenerateModal} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </div>
          </AuthenticatedTemplate>
    )
}

export default TournamentSettings;