import React, { FC, useEffect, useState } from 'react';
import { CheckboxVisibility, CommandBar, Selection, IColumn, Panel, PanelType, PrimaryButton, SelectionMode, Stack, SearchBox, DialogFooter, Dialog, DefaultButton, DialogType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { deleteCurriculum, getAllCurriculum, getAllLevels, getAllPrograms, getCategories, moveCurriculum, MoveDirection, postCurriculum, updateCurriculum} from '../ApiService';
import { CurriculumItem, CurriculumResponse } from '../model/CurriculumItem';
import CurriculumEditor, { CurrentState } from './CurriculumEditor';
import Loader from './Loader';
import List, { IDocument } from './List';
import CurriculumViewer from './CurriculumViewer';
import { Program } from '../model/Program';
import { Level } from '../model/Level';
import { Audience } from '../model/Audience';
import { Category } from '../model/Category';

interface ITableRow extends IDocument {
    key: string;
    name: string;
    intendedAudience: Audience[];
    category: string;
    subcategory: string;
    response: CurriculumResponse;   
    
    //hack
    levels: Level[];
    programs: Program[];
}
  
const CurriculumManagement : FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isCurriculumOpen, { setTrue: openCurriculumPanel, setFalse: dismissCurriculumPanel }] = useBoolean(false);
    const [isPreviewOpen, {setTrue: openPreviewPanel, setFalse: dismissPreviewPanel}] = useBoolean(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [allItems, setAllItems] = useState<ITableRow[]>();
    const [curriculum, setCurriculum] = useState<CurriculumItem[]>();
    const [selectedCurriculum, setSelectedCurriculum] = useState<CurriculumItem>();
    const [isTableLocked, ] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [levels, setLevels] = useState<Level[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [programs, setPrograms] = useState<Program[]>([]);

    const [pendingCurriculum, setPendingCurriculum] = useState<CurriculumItem>();
    const [canCreateCurriculum, setCanCreateCurriculum] = useState<boolean>(false);
    const [creatingCurriculum, setCreatingCurriculum] = useState<boolean>(false);
    
    const [columns, ] = useState<IColumn[]>([{
      key: 'column0',
      name: 'Name',
      fieldName: 'name',
      minWidth: 75,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
    },   
  // {
  //       key: 'column2',
  //       name: 'Audience',
  //       fieldName: 'intendedAudience',
  //       minWidth: 150,
  //       maxWidth: 450,      
  //       isRowHeader: true,
  //       isResizable: true,
  //       isSorted: false,
  //       isSortedDescending: false,
  //       sortAscendingAriaLabel: 'Sorted A to Z',
  //       sortDescendingAriaLabel: 'Sorted Z to A',
  //       data: 'string',
  //       isPadded: true,
  //       onRender: (item: ITableRow) => <Stack>{item.intendedAudience.map((a, idx)=> { return _GetAudienceString(a.LevelId, a.ProgramId, item.levels, item.programs) })}</Stack>
  //     },      
      {
        key: 'column3',
        name: 'Category',
        fieldName: 'category',
        minWidth: 70,
        maxWidth: 150,      
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },  
      {
        key: 'column4',
        name: 'Subcategory',
        fieldName: 'subcategory',
        minWidth: 70,
        maxWidth: 150,      
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },        
      {
          key: 'column5',
          name: 'Response Type',
          fieldName: 'response',
          minWidth: 100,
          maxWidth: 120,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isCollapsible: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'number',
          isPadded: true,
          onRender: (item: ITableRow) => <span>{_GetResponseString(item.response)}</span>
        },          
      {
        key: 'column6',
        name: 'Visits',
        fieldName: 'visits',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'date',   
        isPadded: true,
        onRender: (item: ITableRow) =>  <span>TODO</span>
      }
    ]);
    
      const _GetGroupData = (intendedAudience: Audience[], programs: Program[], levels: Level[]) : [string, string, string] => {
        
        var levelString : string[] = [];

        for(let aud of intendedAudience) {
          let lvlId = aud.LevelId;
          var lvlForAudience = levels.find(l=>l.Id === lvlId);

          if(lvlForAudience !== undefined && lvlForAudience !== undefined) {
            levelString.push(lvlForAudience.Label);
          }
        }

        //this assumes that every audience targets the same level
        var foundLevel = intendedAudience.length > 0 ? levels.find(l=>l.Id === intendedAudience[0].LevelId) : undefined;

        if(foundLevel === undefined) {
          return ['','',''];
        }

        var programString : string[] = [];

        for(let a of intendedAudience) {
          let progId = a.ProgramId;
          var foundProgram = programs.find(p=>p.Id === progId);

          if(foundProgram !== undefined && 
              foundLevel !== undefined && 
              !programString.includes(foundProgram?.Name)) {
            programString.push(foundProgram.Name);
          }
        }
        
        return [foundLevel.Id, `${levelString.join(', ')}`, `${programString.join(', ')}`];
      }

      const _GetResponseString = (response: CurriculumResponse) => {
        switch(response) {
          case CurriculumResponse.None:
            return 'None';
          case CurriculumResponse.Video:
            return 'Video';
        }
      }

      const buildTableRow = (item: CurriculumItem, programs: Program[], levels: Level[]) : ITableRow => {
        let title = item.Name;
        let groupData =  _GetGroupData(item.IntendedAudience, programs, levels);

        return {
            key: item.Id,
            category: item.Category,
            subcategory: item.Subcategory,
            groupKey: groupData[0],
            groupHeader: groupData[1],
            groupSubheader: groupData[2],
            intendedAudience: item.IntendedAudience, //_GetIntendedAudienceString(item.IntendedAudience, programs, levels),
            name: item.Name,
            response: item.Response,
            getTitle: () => title,
            levels: levels,
            programs: programs
        }; 
      }

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var curriculum = await getAllCurriculum(instance, account);
                var categories = await getCategories(instance, account);
                var programs = await getAllPrograms(instance, account);
                var levels = await getAllLevels(instance, account);
                
                setCategories(categories);
                setLevels(levels);
                setPrograms(programs);

                var tableRows = new Array<ITableRow>();
                    
                for(let item of curriculum) {
                  tableRows.push(buildTableRow(item, programs, levels));
                }

                setCurriculum(curriculum);
                setItems(tableRows);
                setAllItems(tableRows);
                setIsLoaded(true);
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const [selection, setSelection] = useState<Selection>(new Selection());

    const onActiveItemChanged = (item?: any, index?: number) => {        
        var foundItem = curriculum?.find(i=>i.Id === item.key);

        setSelectedCurriculum(foundItem);
    }

    const onCurriculumEditorStateChange = (currentState: CurrentState, curriculum: CurriculumItem) => {      
      setPendingCurriculum(curriculum);      
      setCanCreateCurriculum(currentState === CurrentState.CanSubmit);
    }

    const createCurriculum = async () => {
      setCreatingCurriculum(true);

      var result = isEditing ? 
        await updateCurriculum(instance, account!, pendingCurriculum!) : 
        await postCurriculum(instance, account!, pendingCurriculum!);

        setCreatingCurriculum(false);

        if(result?.ok) {
          dismissCurriculumPanel();
          
          items?.push(buildTableRow(pendingCurriculum!, programs, levels));
          
          setItems(items);
        }
        else {
          alert('Sorry, something went wrong. Please try again.')
        }
    }

    const doSearch = (n: string | undefined) => {
      
      if(n === undefined || n.length === 0) {
        //rebuild the default list
        var defaultList = new Array<ITableRow>();
                    
        for(let item of curriculum!) {
          defaultList.push(buildTableRow(item, programs, levels));
        }

        setItems(defaultList);
        return;
      }

      var filtered = allItems?.filter(i => i.getTitle().toLowerCase().indexOf(n.toLowerCase()) !== -1);
      setItems([...filtered!]);
    }

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogContentProps = {
      type: DialogType.normal,
      title: 'Confirm Delete',
      closeButtonAriaLabel: 'Close',
      subText: 'Are you sure you want to delete this item?',
    };
    
    const deleteItem = async () => {
      var result = await deleteCurriculum(instance, account!, selectedCurriculum!.Id);      

      if(result) {
        var allIdx = allItems!.findIndex(i=>i.key === selectedCurriculum?.Id);
        allItems!.splice(allIdx, 1);        
        setAllItems([...allItems!]);

        var displayedIdx = items!.findIndex(i=>i.key === selectedCurriculum?.Id);
        items!.splice(displayedIdx, 1);        
        setItems([...items!]);
      }

      toggleHideDialog();
    }

    return (
        <>
            {isLoaded ?
                items !== undefined ? 
                    <Stack tokens={{childrenGap:10}}>
                        <Stack.Item align='end'>
                          <SearchBox placeholder="Find curriculum" onChange={(e,n) => doSearch(n)} />
                        </Stack.Item>
                        <CommandBar   
                                items={[{
                                  key: 'new',
                                  text: 'New', 
                                  disabled: false,//selection.getSelectedCount() !== 1,
                                  cacheKey: 'new',
                                  iconProps: { iconName: 'PageAdd' },            
                                  onClick: ()=> {selection.setItems(items, true); setIsEditing(false); setSelectedCurriculum(undefined); openCurriculumPanel();}    
                                },
                                {
                                  key: 'edit',
                                  text: 'Edit', 
                                  disabled: selection.getSelectedCount() !== 1,
                                  cacheKey: 'edit',
                                  iconProps: { iconName: 'Edit' },            
                                  onClick: ()=> {setIsEditing(true); setCreatingCurriculum(false); openCurriculumPanel()}    
                                },
                                {
                                  key: 'delete',
                                  text: 'Delete', 
                                  disabled: selection.getSelectedCount() !== 1,
                                  cacheKey: 'delete',
                                  iconProps: { iconName: 'Delete' },            
                                  onClick: ()=> toggleHideDialog()    
                                },
                                {
                                  key: 'view',
                                  text: 'View', 
                                  disabled: selection.getSelectedCount() !== 1,
                                  cacheKey: 'view',
                                  iconProps: { iconName: 'View' },            
                                  onClick: ()=> openPreviewPanel()    
                                },
                                {
                                  key: 'moveup',
                                  text: 'Move up', 
                                  disabled: selection.getSelectedCount() !== 1,
                                  cacheKey: 'moveup',
                                  iconProps: { iconName: 'Up' },            
                                  onClick: () => { moveCurriculum(instance, account!, selectedCurriculum!.Id, MoveDirection.Up) },
                                  subMenuProps: {
                                    items: [
                                      {
                                        key: 'movetotop',
                                        text: 'Move to top', 
                                        disabled: selection.getSelectedCount() !== 1,
                                        cacheKey: 'movetotop',
                                        iconProps: { iconName: 'Upload' },            
                                        onClick: () => { moveCurriculum(instance, account!, selectedCurriculum!.Id, MoveDirection.Top) }
                                      }
                                    ]
                                  }                                     
                                },
                                {
                                  key: 'movedown',
                                  text: 'Move down', 
                                  disabled: selection.getSelectedCount() !== 1,
                                  cacheKey: 'movedown',
                                  iconProps: { iconName: 'Down' },            
                                  onClick: () => { moveCurriculum(instance, account!, selectedCurriculum!.Id, MoveDirection.Down) }                                
                                }]}
                                ariaLabel="Use left and right arrow keys to navigate between commands" />
                       <List 
                          items={items}
                          columns={columns}
                          enableSort
                          isGrouped
                          selectionMode={SelectionMode.single}                          
                          checkboxVisibility={CheckboxVisibility.hidden}
                          isLocked={isTableLocked}
                          onActiveItemChanged={onActiveItemChanged}
                          onItemInvoked={openPreviewPanel}
                          onSelectionChanged={(s)=>setSelection(s)}
                           />

                    </Stack>
                    : null 
                :
                <Loader Text='Just a moment...' />
            }
             <Panel
                headerText={selectedCurriculum === undefined ? "Create Curriculum" : "Edit Curriculum"}
                type={PanelType.medium}
                isOpen={isCurriculumOpen}
                isLightDismiss={false}
                onDismiss={dismissCurriculumPanel}
                closeButtonAriaLabel="Close">                    
                    <CurriculumEditor 
                      item={selectedCurriculum}
                      categories={categories}
                      levels={levels}
                      programs={programs}
                      onStateChange={onCurriculumEditorStateChange} 
                      disabled={creatingCurriculum} /> 
                    <br />
                    {canCreateCurriculum ? <PrimaryButton disabled={!canCreateCurriculum || creatingCurriculum} onClick={()=>createCurriculum()}>{isEditing ? "Update Curriculum" : "Create Curriculum"}</PrimaryButton> : null}
                    {creatingCurriculum ? <Loader Text="Just a moment..." /> : null}                   
              </Panel>  

              <Panel
                headerText={`Previewing ${selectedCurriculum?.Name}`}
                type={PanelType.large}
                isOpen={isPreviewOpen}
                isLightDismiss={true}
                onDismiss={dismissPreviewPanel}
                closeButtonAriaLabel="Close">                    
                    <CurriculumViewer previewMode curriculum={selectedCurriculum!} />
              </Panel>  

              <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
              >
                <DialogFooter>
                  <PrimaryButton onClick={() => deleteItem()} text="Delete" />
                  <DefaultButton onClick={toggleHideDialog} text="Don't delete" />
                </DialogFooter>
              </Dialog>
        </>
    )
}

export default CurriculumManagement;