import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Page Not Found" });

    const navigate = useNavigate();
    
    return (
        <>
            <div style={{backgroundColor:'white', padding:80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>                
                <Stack tokens={{childrenGap:10}} horizontalAlign='center'>
                <Text style={{fontSize:200}} variant='xxLargePlus'>404</Text>
                    <br /><br /> 
                    <Text style={{fontWeight:'normal'}} variant='xLarge'>Sorry friend, we could not find what you are looking for here.</Text>                    
                    <br /><br /> 
                    <Stack.Item align='center'>
                        <PrimaryButton style={{padding:20}} onClick={()=>navigate('/',{replace:true})}>Go Home</PrimaryButton>
                    </Stack.Item>
                    <br /><br />
                </Stack>
            </div>
        </>
    )
}

export default PageNotFound;