export interface ITournamentMatch {    
    CompetitorIds: string[];
    Scores: IScore[];
}

export class TournamentMatch {    
    CompetitorIds: string[];
    Scores: Score[];
        
    constructor(
        competitorIds: string[],
        scores: Score[]) {
            this.CompetitorIds = competitorIds;
            this.Scores = scores;
    }
}

export interface IScore {
    CompetitorId: string;
    Value: number;
}

export class Score {
    CompetitorId: string;
    Value: number;

    constructor(competitorId: string, value: number) {
        this.CompetitorId = competitorId;
        this.Value = value;
    }
}