import { Label, SpinButton, Text } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { IPricingStrategy } from '../../../model/Tournament';

interface IData {
    expiry: Date;
    editPricingStrategy?: IPricingStrategy | undefined;
    onValidated: (isValid: boolean, pricingStrategy: IPricingStrategy) => void;
}
const PricingStrategyCreator : FC<IData> = ({expiry, editPricingStrategy, onValidated}) => {
    const [pricingStrategy, setPricingStrategy] = useState<IPricingStrategy>(editPricingStrategy ?? { InitialPrice: 0, SubsequentPrice: 0, Expiry: expiry } as IPricingStrategy);

    useEffect(() => {        
        if (pricingStrategy?.Id?.length === 0) {
            onValidated(false, pricingStrategy);
            return;
        }

        onValidated(true, pricingStrategy);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[pricingStrategy]);

    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>            
            <Label required>Initial Price</Label>
            <SpinButton
                defaultValue={pricingStrategy?.InitialPrice?.toString()}
                min={0}
                max={2000}
                precision={2}
                onChange={(e,v) => setPricingStrategy(pricingStrategy => ({...pricingStrategy, InitialPrice: parseInt(v!)}))} />

            <Label required>Subsequent Price</Label>
            <Text variant='small'>The price charged for subsequent events that use this pricing strategy. Can be the same as the initial price.</Text>
            <SpinButton
                defaultValue={pricingStrategy?.SubsequentPrice?.toString()}
                value={pricingStrategy?.SubsequentPrice?.toString()}
                min={0}
                max={2000}
                precision={2}
                onChange={(e,v) => setPricingStrategy(pricingStrategy => ({...pricingStrategy, SubsequentPrice: parseInt(v!)}))} />
        </div>
    )
}

export default PricingStrategyCreator;