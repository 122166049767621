import React, { FC, useEffect, useState } from 'react';
import { Label, TextField } from '@fluentui/react';
import { Tournament } from '../../model/Tournament';

interface IData {
    tournament: Tournament;

    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

const TermsWizard : FC<IData> = ({tournament, onValidated}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {        
        if(internalTournament.Terms.length === 0) {
            onValidated(false, internalTournament);
            return;
        }
        
        onValidated(true, internalTournament);
// eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament]);

    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>
            <Label required>Terms</Label>
            <TextField 
                multiline
                defaultValue={tournament.Terms}
                rows={30}
                onChange={(e, t)=> setInternalTournament(tournament => ({...tournament, Terms:t!}))}
                placeholder='Enter any terms you would want the people registering for your event to agree to' />
            
        </div>
    )
}

export default TermsWizard;