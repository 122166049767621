import React, { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getReviewHistory } from '../ApiService';
import List, { IDocument } from '../components/List';
import { IColumn, SelectionMode } from '@fluentui/react';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router';
import { ScoreTypes } from '../model/ReviewCollection';

interface ICurriculumDocument extends IDocument {    
    curriculum: string;
    studentName: string;
    studentId: string;
    studentLevelWhenReviewed: string;
    score: number;
    scoreType: ScoreTypes;
    reviewStatus: string; 
    dateSubmitted: Date;   
    dateReviewed?: Date; 
    hasFeedback: boolean;
    reviewId: string;
}

const ReviewHistory : FC = () => {
    const navigate = useNavigate();
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"ReviewDashboard" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [items, setItems] = useState<IDocument[]>();
    
    const [columns, ] = useState<IColumn[]>([        
        {
            key: 'column1',
            name: 'Student Name',
            fieldName: 'studentName',
            minWidth: 100,
            maxWidth: 250,      
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column2',
            name: 'Curriculum',
            fieldName: 'curriculum',
            minWidth: 100,
            maxWidth: 250,      
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column3',
            name: 'Level When Reviewed',
            fieldName: 'studentLevelWhenReviewed',
            minWidth: 100,
            maxWidth: 250,      
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column4',
            name: 'Score',
            fieldName: 'score',
            minWidth: 60,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'number',
            onRender: (item: ICurriculumDocument) => {
                return item.score === -1 ? '' : item.scoreType === ScoreTypes.Percentage ? `${item.score.toFixed(2)}%` : `${item.score.toFixed(0)}`;
            },
            isPadded: true,
        },
        {
            key: 'column5',
            name: 'Status',
            fieldName: 'reviewStatus',
            minWidth: 75,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
        },
        {
            key: 'column6',
            name: 'Date Submitted',
            fieldName: 'dateSubmitted',
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            data: 'date',
            isPadded: true,
            onRender: (item: ICurriculumDocument) => {
              return <span>{item.dateSubmitted.toDateString()}</span>;
            }
        },
        {
            key: 'column7',
            name: 'Actions',
            fieldName: '',
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: ICurriculumDocument) => {
              return <a href={`/student/${item.studentId}/review/${item.reviewId}`}>View Feedback</a>;
            }
        }
      ]);

      useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {                            
            try {
                if(account) {                    
                    var history = await getReviewHistory(instance, account);
                    var documents = new Array<ICurriculumDocument>();
                    
                    for(let sub of history.CurriculumSubmissions) {
                        const studentId = sub.StudentId;
                        const submissionId = sub.Id;

                        var student = history.Students.find(s=>s.Id === studentId);

                        if(student === undefined) {
                            continue;
                        }

                        var reviewCollection = history.Reviews.find(r=>r.Reviews[0].StudentCurriculumSubmissionId === submissionId);
                        var curriculum = history.Curriculum.find(c=>c.Id === sub.CurriculumId);
                        
                        var score = reviewCollection === undefined ? -1 : reviewCollection.Result.Total * 100;
                        var scoreType = reviewCollection === undefined ? ScoreTypes.Percentage : reviewCollection.Result.Type;

                        let studentFirstName = student.FirstName;

                        documents.push({
                            key: sub.Id,
                            curriculum: curriculum!.Name,
                            score: score,
                            scoreType: scoreType,
                            reviewStatus: reviewCollection === undefined ? "Pending review" : reviewCollection.ResultText,
                            studentLevelWhenReviewed: sub.StudentLevelWhenSubmitted.Label,
                            studentName: `${student.FirstName} ${student.LastName}`,
                            hasFeedback: reviewCollection === undefined ? false : reviewCollection.HasFeedback,
                            dateSubmitted: new Date(sub.Created),
                            reviewId: reviewCollection === undefined ? "" : reviewCollection.Reviews[0].Id,
                            studentId: student.Id,
                            getTitle: ()=> studentFirstName                                                              
                        });
                        
                    }

                    setItems(documents);
                                    
                }
            }            
            catch(error) {
                console.log(error);
            }
        }
       
        if (inProgress === "none" && account) {
            fetchData();    
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);   
    
    return (
        <>
        <h2>Review History</h2>
        {items === undefined ? 
        <Loader Text="Loading review history..." /> : 
        <List items={items!} columns={columns} enableSort={true} selectionMode={SelectionMode.none} onItemInvoked={(item: ICurriculumDocument)=>navigate(`/student/${item.studentId}/review/${item.reviewId}`)} />
        }
        
        </>
    );
}

export default ReviewHistory;