import {FC} from 'react';
import { Stack, Text } from '@fluentui/react';


const JoinCommunityPending : FC = () => {

    return (
        <>
            <div style={{backgroundColor:'white', padding:80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>                
                <Text variant='xLargePlus'>Email ahoy!</Text>
                
                <Stack tokens={{childrenGap:10}} horizontalAlign='start'>
                    <br />                  
                        <Text variant='medium'>Please check your inbox for an email from <strong>app@ninjapanel.com</strong> inviting you to complete registration.</Text>                     
                    <br /><br />
                    <Text style={{fontWeight:'bold'}} variant='medium'>Can't find the email?</Text>
                    <Text variant='medium'>Please check both your inbox and your spam folders.</Text>
                </Stack>
            </div>
        </>
    )
}

export default JoinCommunityPending;