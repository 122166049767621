
export enum DivisionRuleFieldNames {
    YearOfBirth = "DateOfBirth.Year",
    LevelOrder = "Level.Order",
    Gender = "Gender",
    Weight = "Weight"
}

export interface IDivisionRule {
    Min: number;
    Max: number;
    Field: string;
}

export interface IDivisionRuleGroup {    
    Id: string;
    Prefix: string;
    Name: string;
    Tags: string;

    Rules: IDivisionRule[];
}

export class DivisionRuleGroup implements IDivisionRuleGroup {
    Id: string;
    Prefix: string;
    Name: string;
    Tags: string;

    Rules: IDivisionRule[];

    constructor(
        params: IDivisionRuleGroup = {} as IDivisionRuleGroup) {
            
        let {
            Id = "",
            Prefix = "",
            Name = "",
            Tags = "",
            Rules = []
        } = params;
            
        this.Id = Id;
        this.Prefix = Prefix;
        this.Name = Name;
        this.Tags = Tags;
        this.Rules = Rules;
    }
}