import { ImportError } from "./ImportError";
import { Student } from "./Student";
import { TrainingFacility } from "./TrainingFacility";

export enum InvitationTokenState {
    Invalid = 0,
    NotFound = 1,
    Valid = 2,
    Resent = 3
}

export interface IUserInvitation {
    Emails: string[];
    CustomMessage?: string;
    TrainingFacility?: TrainingFacility;
    MarketingConsent: boolean;
    Roles: string[];
    Students: Student[];
    Error?: ImportError;
}

export interface IStudentAndEvent {
    StudentId: string;
    EventTitle: string;
}

export interface IUserWithEventRegistration extends IUserInvitation {
    Registrations: IStudentAndEvent[];
}

export class UserInvitation implements IUserInvitation {
    Emails: string[];
    CustomMessage?: string;
    TrainingFacility?: TrainingFacility;
    MarketingConsent: boolean;
    Roles: string[];
    Students: Student[];
    Error?: ImportError;

    constructor(
        params: IUserInvitation = {} as IUserInvitation) {
            
        let {
            Emails = [],
            CustomMessage = undefined,
            TrainingFacility = undefined,
            MarketingConsent = false,
            Roles = [],
            Students = [],
            Error = undefined   
        } = params;
            
        this.Emails = Emails;
        this.CustomMessage = CustomMessage;
        this.TrainingFacility =  TrainingFacility;
        this.MarketingConsent = MarketingConsent;
        this.Roles = Roles;
        this.Students = Students;
        this.Error = Error;
    }
}

export class UserInvitationData {
    Link: string;
    Expiry: Date;

    constructor(link: string, expiry: Date) {
        this.Link = link;
        this.Expiry = expiry;
    }
}