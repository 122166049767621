import { Spinner } from '@fluentui/react';
import React, {FC} from 'react';

export interface ILoader {
    Text: string;
}

const Loader : FC<ILoader> = ({Text}) => { 

    return (
        <>
            <Spinner style={{paddingTop:10}} label={Text} />
        </>
    )
}

export default Loader;