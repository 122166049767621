import { UnauthenticatedTemplate } from '@azure/msal-react';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTournamentTerms } from '../../ApiService';

const ViewTerms : FC = () => {  
    let { tournamentId } = useParams<{tournamentId: string }>() as {tournamentId: string };

    const [errorCode, setErrorCode] = useState<number>();
    const [title, setTitle] = useState<string>();
    const [terms, setTerms] = useState<string>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        if(tournamentId === undefined) {
            return;
        }
        
        const fetchAsync = async() => {
            var terms = await getTournamentTerms(tournamentId);
            
            if(typeof terms === 'number') {
                setErrorCode(terms);
            }

            var t = terms as {title:'',terms:''};
            
            setTitle(t.title);
            setTerms(t.terms);
        }
        
        fetchAsync()
    },[tournamentId]);

    return (
          <UnauthenticatedTemplate>
            <div style={{padding:20, backgroundColor:'white'}}>
                <h1>{title}</h1>
                <p dangerouslySetInnerHTML={{__html:terms!}} />
            </div>
          </UnauthenticatedTemplate>
    )
}

export default ViewTerms;