import React, { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { InteractionType } from '@azure/msal-browser';
import { Panel, PanelType, Pivot, PivotItem, Stack } from '@fluentui/react';
import Loader from '../../components/Loader';
import {AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { getMembership, getTournament, getTournamentReviewDashboard } from '../../ApiService';
import { TournamentReviewDashboardViewModel, TournamentStakeholderRoles } from '../../model/TournamentReviewDashboardViewModel';
import { useNavigate, useParams } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { Tournament } from '../../model/Tournament';
import ConfirmJudgingSelection from './ConfirmJudgingSelection';
import TournamentEventCard, { EventCardDisplayMode, IStudentWithRegistrationPermission } from '../Tournament/components/TournamentEventCard';
import { TournamentBracket } from '../../model/TournamentBracket';
import { Student } from '../../model/Student';
import { Membership } from '../../model/Membership';

const TournamentReviewDashboard : FC = () => {
    let { tournamentId } = useParams<{tournamentId?: string }>() as {tournamentId: string };

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"TournamentReviewDashboard" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [vm, setVm] = useState<TournamentReviewDashboardViewModel>();
    const [membership, setMembership] = useState<Membership>();
    const [tournament, setTournament] = useState<Tournament>();

    useEffect(() => {        
        setIsLoading(true);

        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var tournament = await getTournament(instance, account, tournamentId);
                setTournament(tournament);

                var vm = await getTournamentReviewDashboard(instance, account, tournamentId);
                setVm(vm);

                var membership = await getMembership(instance, account);
                
                if(membership.StatusCode === 200) {
                    setMembership(membership.Result);
                }
                                
                setIsLoading(false);                                    
            }
        }
       
        fetchData();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, inProgress, account]);

    const [isConfirmPanelOpen, { setTrue: openConfirmPanel, setFalse: dismissConfirmPanel }] = useBoolean(false);
    const [selectedBracket, setSelectedBracket] = useState<TournamentBracket>();
    const [selectedStudents, setSelectedStudents] = useState<Student[]>();

    useEffect(()=> {
        if(selectedBracket !== undefined && selectedStudents !== undefined) {
            openConfirmPanel();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedBracket, selectedStudents]);

    useEffect(()=> {
        if(!isConfirmPanelOpen) {
            setSelectedBracket(undefined);
            setSelectedStudents(undefined);
        }
    },[isConfirmPanelOpen]);

    const getJudgeCandidates = (bracket: TournamentBracket) : IStudentWithRegistrationPermission[] => {
        var judges = vm?.Roles.find(r=>r.Value === TournamentStakeholderRoles.Judge || r.Value === TournamentStakeholderRoles.Director);

        if(judges === undefined) {
            return [];
        }

        var toReturn : Student[] = [];
        
        for(let judgeId of judges.StudentIds) {
            let jId = judgeId;

            var found = membership?.Students.find(s => s.Id === jId);

            if(found !== undefined && bracket.JudgeIds !== null && bracket.JudgeIds.find(j => j === jId) === undefined) {
                toReturn.push(found);
            }
        }

        return toReturn.map(s => ({Student: s, CanRegister: true}));
    }

    const getAssignedJudges = (bracket: TournamentBracket) : Student[] => {
        var judges = vm?.Roles.find(r=>r.Value === TournamentStakeholderRoles.Judge);

        if(judges === undefined) {
            return [];
        }

        var toReturn : Student[] = [];
        
        for(let judgeId of judges.StudentIds) {
            let jId = judgeId;

            var found = membership?.Students.find(s => s.Id === jId);

            if(found !== undefined && bracket.JudgeIds !== null && bracket.JudgeIds.find(j => j === jId) !== undefined) {
                toReturn.push(found);
            }
        }

        return toReturn;
    }

    const onEventSelectionChanged = (selectedBracket: TournamentBracket, selectedStudents: Student[]) => {
        setSelectedBracket(selectedBracket);
        setSelectedStudents(selectedStudents);
    }

    const onConfirmed = () => {
        if(selectedBracket !== undefined && selectedStudents !== undefined) {
            var divisionId = '';

            for(let e of vm!.Events) {
                let event = e;

                for(let d of event.Divisions) {
                    if(d.Brackets.find(b=>b.Id === selectedBracket.Id) !== undefined) {
                        divisionId = d.Id;
                    }
                } 
            }

            //taking only 1 student for now
            navigate(`/event/${tournament?.Id}/division/${divisionId}/bracket/${selectedBracket.Id}/${membership?.Id}/${selectedStudents[0].Id}/review`);
        }
    }

    return (
        isLoading ? <Loader Text="Just a moment.." /> :
        <>
            <AuthenticatedTemplate>
                <h2>Review Dashboard: {tournament?.Title}</h2>
                <Pivot overflowBehavior='menu'>
                    {vm?.Events.map((e, idx)=> 
                        <PivotItem  key={idx} itemKey={idx.toString()} headerText={e.EventName}>
                            <Stack horizontal wrap tokens={{childrenGap:10}}>
                                {e.Divisions.map((d)=>                                 
                                        d.Brackets.map((b,idx3) =>
                                            <TournamentEventCard
                                                key={idx3}
                                                displayMode={EventCardDisplayMode.Students}   
                                                eventId={d.EventId}
                                                eventImageUri={e.ImageUri}
                                                eventTitle={b.Label}
                                                onEventSelectionChanged={(eId,selectedStudents)=> onEventSelectionChanged(b, selectedStudents)}
                                                onViewEventClicked={()=>{}}
                                                eventDescription={`${d.EventRegistrations.filter(r=> b.Rounds[0].Matches[0].CompetitorIds.find(s=> s === r.StudentId) !== undefined).filter(f=>f.SubmissionId.length > 0).length} / ${b.Rounds[0].Matches[0].CompetitorIds.length} ready<br />`}
                                                notRegisteredStudents={getJudgeCandidates(b)}
                                                registeredStudents={getAssignedJudges(b)} />
                                        )
                                )}
                            </Stack>
                        </PivotItem>                        
                    )}
                </Pivot>

                <Panel
                    headerText="Confirm Selection"
                    type={PanelType.smallFixedFar}
                    isOpen={isConfirmPanelOpen}
                    isLightDismiss={true}
                    onDismiss={dismissConfirmPanel}
                    closeButtonAriaLabel="Close">
                    <ConfirmJudgingSelection commitSelection={() => onConfirmed()} terms={tournament!.JudgingTerms} />
                </Panel>

            </AuthenticatedTemplate>               
        </>
    );  
}

export default TournamentReviewDashboard;

