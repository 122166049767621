import React, { FC, useEffect, useRef, useState } from 'react';
import { CheckboxVisibility, DetailsList, DetailsListLayoutMode, IColumn, MarqueeSelection, SelectionMode, Selection, Stack, Announced, Text, IGroup, IDetailsGroupDividerProps, mergeStyleSets, getTheme, IGroupDividerProps, Link, ScrollablePane, ScrollbarVisibility, TooltipHost, StickyPositionType, IDetailsColumnRenderTooltipProps, IRenderFunction, Sticky, ActionButton } from '@fluentui/react';
import Loader from './Loader';

export interface IDocument { 
    key: string;
    groupKey?: string;
    groupHeader?: string;
    groupSubheader?: string;
    getTitle: () => string;
}

export interface ICustomButton {
  label: string;
  action: (a:IDetailsGroupDividerProps) => void;
}

export interface ListProps {
  items: IDocument[];
  columns: IColumn[];
  enableSort?: boolean;
  isLocked?: boolean;
  isGrouped?: boolean;
  groupCustomButtons?: ICustomButton[];
  isHeaderVisible?: boolean;
  checkboxVisibility?: CheckboxVisibility;
  selectionMode: SelectionMode;
  onSelectionChanged?: (selection: Selection) => void;
  onItemInvoked?: (item?: any, index?: number | undefined, ev?: Event | undefined) => void;
  onActiveItemChanged?: (item?: any, index?: number | undefined, ev?: React.FocusEvent<HTMLElement> | undefined) => void;
  stickyHeader?: boolean;
  allowPaging?: boolean;
  isPaging?: boolean;
  minHeight?: string;
  maxHeight?: string;
  height?: string;
  onPageInData?: () => void;
}
  
const List: FC<ListProps> = ({
  items,
  columns,
  enableSort,
  selectionMode,
  allowPaging = false,
  isPaging = false,
  checkboxVisibility,
  stickyHeader = true,
  isLocked,
  isGrouped,
  isHeaderVisible = true,
  onPageInData = undefined,
  groupCustomButtons,
  onItemInvoked,
  onActiveItemChanged,
  onSelectionChanged,
  minHeight = 'initial',
  maxHeight = 'initial',
  height = '70vh'}) => {
  
  const scrollClass = mergeStyleSets({
    detailsListWrapper: {
      minHeight: minHeight,
      maxheight: maxHeight,
      height: height,
      width: '100%',
      position: 'relative'
    },
  });  
  
  const [canPage,] = useState<boolean>(allowPaging);
  const [groups, setGroups] = useState<IGroup[]>();
  const [columnsInternal, setColumns] = useState<IColumn[]>(columns);
  const [itemsInternal, setItems] = useState<IDocument[]>(items);
  const refItems = useRef(itemsInternal);

  useEffect(() => {
    setItems(items);
  }, [items]);

  useEffect(() => {
    refItems.current = itemsInternal;
  }, [itemsInternal]);

  useEffect(() => {
    if (items.length === 0) {
      return;
    }
      
    //bind the sort handler for columns
        
    if (isGrouped !== undefined && isGrouped) {
      //sort the items
      items.sort((a, b) => {
        if (a.groupKey === undefined || b.groupKey === undefined) {
          return 0;
        }
              
        return a.groupKey.localeCompare(b.groupKey);
      });

      var sortedGroups: IGroup[] = [];
      let startIdx = 0;

      //add the group immediatly, then iterate the count          
      sortedGroups.push({
        key: items[0].groupKey!,
        startIndex: startIdx,
        count: 0,
        name: items[0].groupHeader!,
        data: { subtitle: items[0].groupSubheader }
      });

      for (let d of items) {
        var currentGroup = sortedGroups[sortedGroups.length - 1];
            
        if (d.groupKey === currentGroup.key) {
          currentGroup.count++;
        }
        else {
          sortedGroups.push({
            key: d.groupKey!,
            startIndex: currentGroup.startIndex + currentGroup.count,
            count: 1,
            name: d.groupHeader!,
            data: { subtitle: d.groupSubheader }
          });
        }
      }
          
      setGroups(sortedGroups);
    }
    else {
      if (enableSort) {
        for (let c of columnsInternal) {
          c.onColumnClick = onColumnClick;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
    const newColumns: IColumn[] = columnsInternal.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      }
      else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    
    if (refItems.current !== undefined) {
      const newItems = _copyAndSort(itemsInternal, currColumn.fieldName!, currColumn.isSortedDescending);
            
      setColumns(newColumns);
      setItems(newItems);
    }
  };
    
  function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }

  const intSelectionChanged = () => {
    setSelectionDetails(getSelectionDetails);
        
    if (onSelectionChanged) {
      onSelectionChanged(selection);
    }
  }

  const [selectionDetails, setSelectionDetails] = useState<string>("No items selected");
  const [selection,] = useState<Selection>(new Selection({ onSelectionChanged: intSelectionChanged }));
  const getSelectionDetails = (): string => {
          
    switch (selection.getSelectedCount()) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (selection.getSelection()[0] as IDocument).getTitle();
      default:
        return `${selection.getSelectedCount()} items selected`;
    }
  }

  const GROUP_HEADER_AND_FOOTER_SPACING: number = 8;
  const GROUP_HEADER_AND_FOOTER_BORDER_WIDTH: number = 1;

  const theme = getTheme();
  const classNames = mergeStyleSets({
    headerAndFooter: {
      borderTop: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
      borderBottom: `${GROUP_HEADER_AND_FOOTER_BORDER_WIDTH}px solid ${theme.palette.neutralQuaternary}`,
      padding: GROUP_HEADER_AND_FOOTER_SPACING,
      margin: `${GROUP_HEADER_AND_FOOTER_SPACING}px 0`,
      background: theme.palette.neutralLighterAlt,
      // Overlay the sizer bars
      position: 'relative',
      zIndex: 100,
    },
    headerTitle: [
      theme.fonts.xLarge,
      {
        padding: '4px 0',
      },
    ],
    headerLinkSet: {
      margin: '4px -8px',
    },
    headerLink: {
      margin: '0 8px',
    },
  });

  const _onToggleSelectGroup = (props: IGroupDividerProps) => {
    return () => {
      props.onToggleSelectGroup!(props.group!);
    };
  }
    
  const _onToggleCollapse = (props: IGroupDividerProps) => {
    return () => {
      props!.onToggleCollapse!(props!.group!);
    };
  }

  const onRenderGroupHeader = (props: IDetailsGroupDividerProps | undefined): JSX.Element => {
    return (
      <div className={classNames.headerAndFooter}>
        <div className={classNames.headerTitle}>{props?.group!.name}</div>
        {props?.group?.data?.subtitle !== undefined ? <div>{props?.group?.data.subtitle}</div> : null}
        <div className={classNames.headerLinkSet}>
          {props?.selectionMode !== SelectionMode.none ? (
            <Link className={classNames.headerLink} onClick={_onToggleSelectGroup(props!)}>
              {props?.selected ? 'Remove selection' : 'Select group'}
            </Link>
          ) : null}
          <Link className={classNames.headerLink} onClick={_onToggleCollapse(props!)}>
            {props?.group!.isCollapsed ? 'Expand group' : 'Collapse group'}
          </Link>
          {groupCustomButtons !== undefined ?
            groupCustomButtons?.map((c, idx) => {
              return <Link key={idx} className={classNames.headerLink} onClick={() => c.action(props!)}>
                {c.label}
              </Link>
            })
              
            : null
          }
        </div>
      </div>
    )
        
  }

  return (
    <Stack>
      <MarqueeSelection selection={selection}>
        {stickyHeader && isHeaderVisible ? <Stack className={scrollClass.detailsListWrapper}>
          <Stack.Item>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
              <DetailsList
                items={itemsInternal}
                groups={groups}
                disableSelectionZone={isLocked}
                compact={false}
                columns={columnsInternal}
                checkboxVisibility={checkboxVisibility}
                selectionMode={selectionMode}
                getKey={(item: any, index?: number) => item.key}
                setKey="single"
                layoutMode={DetailsListLayoutMode.justified}
                onActiveItemChanged={onActiveItemChanged}
                onItemInvoked={onItemInvoked}
                isHeaderVisible={isHeaderVisible}
                groupProps={{ onRenderHeader: onRenderGroupHeader }}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onRenderDetailsHeader={(props, defaultRender) => {
                  if (!props) {
                    return null;
                  }
                  const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> =
                    tooltipHostProps => (
                      <TooltipHost {...tooltipHostProps} />
                    );
                  return (
                    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                      {defaultRender!({
                        ...props,
                        onRenderColumnHeaderTooltip,
                      })}
                    </Sticky>
                  );
                }}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="Row checkbox" />
              {/* </Shimmer> */}
            </ScrollablePane>
          </Stack.Item>
        </Stack>
          :
          <DetailsList
            items={itemsInternal}
            groups={groups}
            disableSelectionZone={isLocked}
            compact={false}
            columns={columnsInternal}
            checkboxVisibility={checkboxVisibility}
            selectionMode={selectionMode}
            getKey={(item: any, index?: number) => item.key}
            setKey="single"
            layoutMode={DetailsListLayoutMode.justified}
            onActiveItemChanged={onActiveItemChanged}
            onItemInvoked={onItemInvoked}
            isHeaderVisible={isHeaderVisible}
            groupProps={{ onRenderHeader: onRenderGroupHeader }}
            selection={selection}            
            selectionPreservedOnEmptyClick={true}
            enterModalSelectionOnTouch={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="Row checkbox" />
        }
      </MarqueeSelection>
      {selectionMode !== SelectionMode.none ? selectionDetails !== undefined && selectionDetails!.length > 0 ?
        <Stack tokens={{ childrenGap: '20px' }} style={{ marginTop: '20px' }}>
          <Text variant='small' style={{ marginLeft: '20px' }}>{selectionDetails}</Text>
          <Announced message={selectionDetails} />          
        </Stack>
        : null
        : null}
      {canPage && !isPaging && <Stack.Item align='center'>
        <ActionButton styles={{ label: { fontSize: 24 } }} onClick={() => onPageInData?.()}>Load more</ActionButton></Stack.Item>}
      {isPaging && <Loader Text='Just a moment...' /> }
    </Stack>
  );
}

export default List;