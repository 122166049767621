// appInsights?.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

if(process.env.REACT_APP_NO_APPINSIGHTS === undefined) {    
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin]
        }
    });
    appInsights?.loadAppInsights();
}

export { reactPlugin, appInsights };