import { IPersonaProps, NormalPeoplePicker } from "@fluentui/react";
import { FC } from "react";
import { findStudentByName } from "../ApiService";
import { useAccount, useMsal } from "@azure/msal-react";

export interface IStakeholderPersonaProps extends IPersonaProps {
    membershipId: string;
    studentId: string;
    trainingFacility: string;
    email: string;
}

interface IData {
    tournamentId: string;
    disabled?: boolean;

    onResolveSuggestions?: (filter: string, selectedItems?: IPersonaProps[] | undefined) => IPersonaProps[] | PromiseLike<IPersonaProps[]>;
    onChange: (items: IStakeholderPersonaProps[] | undefined) => void; 
}

const StudentPicker : FC<IData> = ({disabled, tournamentId, onChange, onResolveSuggestions})  => {
    const { instance, accounts } = useMsal();    
    const account = useAccount(accounts[0] || {});

    const onResolveSuggestionsDefault = (filter: string, selectedItems?: IPersonaProps[] | undefined) : IPersonaProps[] | PromiseLike<IPersonaProps[]> => {
        return new Promise<IPersonaProps[]>(async (resolve, reject) => {
            var results = await findStudentByName(instance, account!, tournamentId!, filter);

            if(typeof results === 'number') {
                reject(results);
                return;
            }
    
            var toReturn : IStakeholderPersonaProps[]  = [];
    
            for (let r of results) {                
                toReturn.push({
                    imageInitials:`${r.DisplayName.split(' ')[0][0]}${r.DisplayName.split(' ')[1][0]}`,
                    text: r.DisplayName,
                    secondaryText: r.Email,
                    showSecondaryText: true,
                    membershipId: r.MembershipId,
                    studentId: r.StudentId,
                    email: r.Email,
                    trainingFacility: r.TrainingFacility
                })
            }
            resolve(toReturn);
        });        
    }
    return (
        <NormalPeoplePicker 
            disabled={disabled}
            searchingText='Just a moment...'                            
            onResolveSuggestions={onResolveSuggestions === undefined ? onResolveSuggestionsDefault : onResolveSuggestions}
            onChange={(items?: IPersonaProps[]) => onChange(items as IStakeholderPersonaProps[])}
            itemLimit={1}
            pickerSuggestionsProps={{noResultsFoundText:'No members found.'}}
            resolveDelay={800} />
    )
}

export default StudentPicker;