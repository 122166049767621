import { Level } from "./Level";
import { Program } from "./Program";
import { TrainingFacility } from "./TrainingFacility";

export class ViralJoinEnv {
    Title: string;
    Levels: Level[];
    Programs: Program[];
    RequestStudentDob: boolean;
    RequestStudentGender: boolean;
    TrainingFacilities: TrainingFacility[];
    CanAddTrainingFacility: boolean;
    
    constructor(
       title: string,
       levels: Level[],
       programs: Program[],
       requestStudentDob: boolean,
       requestStudentGender: boolean,
       trainingFacilities: TrainingFacility[],
       canAddTrainingFacility: boolean) {
            this.Title = title;
            this.Levels = levels;
            this.Programs = programs;
            this.RequestStudentDob = requestStudentDob;
            this.RequestStudentGender = requestStudentGender;
            this.TrainingFacilities = trainingFacilities;
            this.CanAddTrainingFacility = canAddTrainingFacility;
        }
}