import { ReviewFeedback } from "./ReviewFeedback";

export enum ReviewStatus {
    Pass = 0,
    Fail = 1
}

export interface IReviewAuthor {
    DisplayName: string;
    MembershipId: string;
    StudentId: string;
}
export interface IReview {
    Id: string;
    Author?: string;
    AuthorId?: string;
    AuthoredBy?: IReviewAuthor;
    Created: Date;
    StudentCurriculumSubmissionId: string;
    Text: string;
    Status?: ReviewStatus;    
}

export class Review implements IReview {
    Id: string;
    Author?: string;
    AuthorId?: string;
    AuthoredBy?: IReviewAuthor;
    Created: Date;
    StudentCurriculumSubmissionId: string;
    Text: string;
    Status?: ReviewStatus;
    Feedback: ReviewFeedback[];

    constructor(
        params: IReview = {} as IReview) {
            
        let {
            Id = "",
            Author = undefined,
            AuthorId = undefined,
            AuthoredBy = undefined,
            Created = new Date(),
            StudentCurriculumSubmissionId = "",
            Text = ""
        } = params;
            
        this.Id = Id;
        this.Author = Author;
        this.AuthorId = AuthorId;
        this.AuthoredBy = AuthoredBy;
        this.Created = Created;
        this.StudentCurriculumSubmissionId = StudentCurriculumSubmissionId;
        this.Text = Text;
        this.Status = params.Status;
        this.Feedback = [];
    }
}