import React, { FC, useEffect, useState } from 'react';
import { ActionButton, DefaultButton, Text, TextField } from '@fluentui/react';
import { ICouponResult, applyCoupon } from '../../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../../../components/Loader';

interface IData {
    transactionId: string;
    onCouponApplied: (result: ICouponResult, coupon: string) => void;
}

const ApplyCoupon : FC<IData> = ({transactionId, onCouponApplied}) => {
    
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showCouponEntry, setShowCouponEntry] = useState<boolean>(false);
    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});

    const [couponCode, setCouponCode] = useState<string>();
    const [discount, setDiscount] = useState<number>(0);

    useEffect(()=> {
        setCanSubmit(couponCode !== undefined && couponCode.length > 0);
    },[couponCode]);

    const tryApplyCoupon = async () => {        
        if(couponCode === undefined) {
            return;
        }

        setIsSubmitting(true);

        //check if it's a valid coupon and update the payment intent if yes
        var result = await applyCoupon(instance, account!, transactionId, couponCode);
        onCouponApplied(result, couponCode);
        setDiscount(result.Discount);        
        setIsSubmitting(false);
    }
   
    return ( 
        <>
            <ActionButton style={{cursor:showCouponEntry ? 'default' : 'pointer', color:'rgb(0, 120, 212)', marginRight:0, marginLeft:'auto'}} onClick={() => setShowCouponEntry(true)}>Apply coupon code</ActionButton>        
            {showCouponEntry ? 
            isSubmitting ? <Loader Text="Just a moment..." /> : <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>            
                <TextField disabled={discount > 0} placeholder='Enter coupon code' onChange={(e,v) => setCouponCode(v!)} onKeyUp={(e) => e.key === "Enter" ? tryApplyCoupon() : setDiscount(0)}></TextField>
                <DefaultButton disabled={isSubmitting || !canSubmit || discount > 0} onClick={tryApplyCoupon}>Apply</DefaultButton>        
            </div> : null}
            {discount > 0 ? <Text style={{color:'green', paddingTop:5, marginRight:0, marginLeft:'auto'}}>{couponCode} applied</Text> : null}
            {discount === -1 ? <Text style={{color:'red', paddingTop:5, marginRight:0, marginLeft:'auto'}}>Invalid coupon '{couponCode}'</Text> : null}
        </>
    );
}

export default ApplyCoupon;