// import {ContentDelivery} from './Lesson';

export enum EventType {
    Marketing = 0,
    Holiday = 1,
    PromotionTest = 2,
    SpecialEvent = 3
}

export interface IEventModel {
    Id: string;
    Type: EventType;
    Uri: string;
    Start: Date;
    End: Date;
    ImageUri: string;
    Title: string;
    Description: string;
    // ContentDelivery: ContentDelivery;
}

// let cd = ContentDelivery.Online;

export class EventModel implements IEventModel {
    Id: string;
    Type: EventType;
    Uri: string;
    Start: Date;
    End: Date;
    ImageUri: string;
    Title: string;
    Description: string;
    // ContentDelivery: ContentDelivery;

    constructor(
        params: IEventModel = {} as IEventModel) {
            
        let {
            Id = "",
            Type = EventType.Holiday,
            Uri = "",   
            Start = new Date(),
            End = new Date(),
            ImageUri = "",
            Title = "",
            Description = "",
            // ContentDelivery = cd
        } = params;
            
        this.Id = Id;
        this.Type = Type;
        this.Uri = Uri;
        this.Start = Start;
        this.End = End;
        this.ImageUri = ImageUri;
        this.Title = Title;
        this.Description = Description;
        // this.ContentDelivery = ContentDelivery;
    }
}