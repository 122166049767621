import { ProgressIndicator, Stack, Text } from '@fluentui/react';
import React, { FC } from 'react';
import { IEventStatus } from '../../../model/EventStatus';

interface IData {
    status: IEventStatus;
    onSelected: (data:IEventStatus) => void;
}

const EventCallCard : FC<IData> = ({status, onSelected}) => {    

    return (
        <div 
            style={{padding:10, backgroundColor:'white', cursor:'pointer', minWidth:200}}
            onClick={() => onSelected(status)}>
            <Stack tokens={{childrenGap:10}}>
                <Text style={{paddingBottom:7}} variant='large'>{status.EventTitle}</Text>
                <Text style={{textAlign:'right'}} variant='medium'>{status.PendingDivisions} pending divisions | {status.StagingDivisions} staging divisions | {status.CompetingDivisions} competing divisions</Text>
                <Text style={{textAlign:'right'}} variant='medium'>{status.NumberOfStudents} student{status.NumberOfStudents > 1 ? 's' : null}</Text>

                <ProgressIndicator percentComplete={status.CompetingDivisions / (status.PendingDivisions + status.CompetingDivisions + status.StagingDivisions)} />
            </Stack>
        </div>
    );
}

export default EventCallCard; 