export enum EventChangeType
{
    Add,
    Remove
}

export enum InvoiceStatus {
    PendingNotice = 0,
    Unpaid = 1,
    Viewed = 2,
    Paid = 3
}

export interface IEventChange {
    Type: EventChangeType;
    EventId: string;
    EventName: string;
    StudentId: string;
}

export interface IInvoice {
    Id: string;    
    ClientSecret: string;
    Created: Date;
    Modified: Date;
    Paid?: Date;
    Number: number;
    Description: string;
    TournamentId: string;    
    TournamentName: string;
    TournamentImageUri: string;
    Total: number;
    TransactionId: string;
    Status: InvoiceStatus;
    UserId: string;
    EventChanges: IEventChange[];    
}

export interface ICreateInvoiceRequest {
    MembershipId: string;
    Description: string;
    Amount: number;
}