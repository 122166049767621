import { CurriculumItem } from "./CurriculumItem";
import { Level } from "./Level";
import { ReviewCollection } from "./ReviewCollection";
import { Student } from "./Student";
import { StudentCurriculumSubmission } from "./StudentCurriculumSubmission";

export interface IReviewHistoryViewModel {
    Students: Student[];
    CurriculumSubmissions: StudentCurriculumSubmission[];
    Reviews: ReviewCollection[];
    Curriculum: CurriculumItem[];
    Levels: Level[];
}

export class ReviewHistoryViewModel implements IReviewHistoryViewModel {
    Students: Student[];
    CurriculumSubmissions: StudentCurriculumSubmission[];
    Reviews: ReviewCollection[];
    Curriculum: CurriculumItem[];
    Levels: Level[];

    constructor(
        params: IReviewHistoryViewModel = {} as IReviewHistoryViewModel) {
            
        let {
            Students = new Array<Student>(),
            CurriculumSubmissions = new Array<StudentCurriculumSubmission>(),
            Reviews = [],
            Levels = [],
            Curriculum = []
        } = params;
            
        this.Students = Students;
        this.CurriculumSubmissions = CurriculumSubmissions;
        this.Reviews = Reviews;
        this.Levels = Levels;
        this.Curriculum = Curriculum;
    }
}