import React, { FC, useEffect, useState } from 'react';
import { Stack, Text } from '@fluentui/react';
import { DateTime } from 'luxon';
import { Tournament } from '../model/Tournament';
import { StoragePurpose } from '../Storage';
import FileUploadButton, { IUploadedFile } from './FileUploadButton';
import { getCurriculum } from '../ApiService';
import { CurriculumItem } from '../model/CurriculumItem';
import { useAccount, useMsal } from "@azure/msal-react";

interface IData {
    studentId?: string;
    curriculumId?: string;
    tournament?: Tournament;
    canSubmitExt?: boolean;
    onFileUploaded?: (uploadedFile: IUploadedFile) => void;
}

const StudentVideoUploader : FC<IData> = ({curriculumId, studentId, tournament, canSubmitExt, onFileUploaded}) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isBeforeTournamentStartDate, ] = useState<boolean>(tournament === undefined ? false : DateTime.fromJSDate(tournament.StartDate) > DateTime.utc());
    const [isAfterTournamentEndDate, ] = useState<boolean>(tournament === undefined ? false : DateTime.fromJSDate(tournament.EndDate) < DateTime.utc());
    const [curriculum, setCurriculum] = useState<CurriculumItem>();
    const [canSubmit, setCanSubmit] = useState<boolean | undefined>(canSubmitExt);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});    
    
    useEffect(()=> {
        const fetchAsync = async () => {
            var curriculum = await getCurriculum(instance, account!, curriculumId!);
            setCurriculum(curriculum);
            setCanSubmit(true);
        }

        if(account) {
            fetchAsync();
        }
    },[instance, account, curriculumId]);

    return (
        <Stack tokens={{childrenGap:10}}>            
            <h3>{curriculum?.Name}</h3>
            <span style={{fontSize:12}}><FileUploadButton Label='Upload from your device' Purpose={StoragePurpose.StudentCurriculumSubmission} disabled={isUploading || isBeforeTournamentStartDate || isAfterTournamentEndDate || !canSubmit} StudentId={studentId!} CurriculumId={curriculumId!} IsUploading={(uploading) => setIsUploading(uploading)} FileUploaded={onFileUploaded!} style={{textDecoration:"underline" }} /></span>
            {isBeforeTournamentStartDate ? <Text style={{color:'red'}} variant={'medium'}><br /><em>{tournament!.Title} starts on {DateTime.fromJSDate(tournament!.StartDate).toLocaleString()}. The record button will not be active until then.</em></Text> : null}
            {isAfterTournamentEndDate ? <Text style={{color:'red'}} variant={'medium'}><br /><em>The tournament has ended.</em></Text> : null}
            {canSubmit ? null : <Text style={{color:'red'}} variant={'medium'}><br /><em>Only one submission per event is allowed for tournaments.</em></Text>}
        </Stack>
    )
}

export default StudentVideoUploader;