export enum RubricType {
    TimeScoped = 0,
    Global = 1,
    Numeric = 2
}
export interface IRubric {
    Id: string;
    Category: string;
    Title: string;
    Type: RubricType;
    Weight: number;
}
export class Rubric implements IRubric {
    Id: string;
    Category: string;
    Title: string;
    Type: RubricType;
    Weight: number;

    constructor(
        params: IRubric = {} as IRubric) {
            
        let {
            Id = "",
            Category = "",
            Title = "",   
            Type = RubricType.TimeScoped,
            Weight = 0  
        } = params;
            
        this.Id = Id;
        this.Category = Category;
        this.Title = Title;        
        this.Type = Type;
        this.Weight = Weight;
    }
}