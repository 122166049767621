import React, { FC, useEffect, useRef, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { getAllStudentCurriculumSubmissions } from '../ApiService';
import {
  SelectionMode,
  IColumn,
  CheckboxVisibility,
  Stack,
  SearchBox,
} from '@fluentui/react';
import { StudentCurriculumSubmission, SubmissionStatus } from '../model/StudentCurriculumSubmission';
// import FeedbackModal from '../components/VideoFeedbackHost';
import Loader from '../components/Loader';
import { useNavigate } from 'react-router-dom';
import List, { IDocument } from '../components/List';
import { Student } from '../model/Student';
import { CurriculumItem } from '../model/CurriculumItem';

export interface ITableRow extends IDocument {
  name: string;
  studentName: string;
  curriculum: string;
  value: string;
  category: string;
  dateModified: string;
  dateModifiedValue: number;
}

const ReviewDashboard : FC = () => {
    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    appInsights?.trackPageView({ name:"ReviewDashboard" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [curriculumSubmissions, setCurriculumSubmissions] = useState<StudentCurriculumSubmission[]>();
    const [students, setStudents] = useState<Student[]>();
    const [curriculumItems, setCurriculumItems] = useState<CurriculumItem[]>();
    const [items, setItems] = useState<IDocument[]>();
    const refItems = useRef(items);
    const [isLoading, setIsLoading] = useState(true);
        
    useEffect(()=> {
        refItems.current = items;
    }, [items]);

    const [columns, ] = useState<IColumn[]>([      
      {
        key: 'column2',
        name: 'Student Name',
        fieldName: 'studentName',
        minWidth: 70,
        maxWidth: 200,      
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Curriculum',
        fieldName: 'curriculum',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Category',
        fieldName: 'category',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onRender: (item: ITableRow) => {
          return <span>{item.category}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Date Submitted',
        fieldName: 'dateSubmittedValue',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isSorted: true,
        isSortedDescending: true,
        data: 'number',
        onRender: (item: ITableRow) => {
          return <span>{item.dateModified}</span>;
        },
        isPadded: true,
      }
    ]);



    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                
                try {
                    setIsLoading(true);

                    var dashboard = await getAllStudentCurriculumSubmissions(instance, account, SubmissionStatus.PendingReview); 

                    setCurriculumSubmissions(dashboard.Submissions);
                    setStudents(dashboard.Students);
                    setCurriculumItems(dashboard.Curriculum);
                    
                    let documentItems: ITableRow[] = buildTableRows(dashboard.Submissions, dashboard.Students, dashboard.Curriculum);

                    
                    
                    setItems(documentItems);
                    setIsLoading(false);
                }            
                catch(error) {
                    console.log('acquire token error: ' + error);
                }
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const onItemInvoked  = (item: IDocument) => {
      var found = curriculumSubmissions?.find(c=>c.Id === item.key);

      if(found !== undefined) {
        navigate(`/review/${found.StudentId!}/curriculum/${found.CurriculumId}/submission/${found.Id}`);
      }      
    }

    const buildTableRows = (submissions: StudentCurriculumSubmission[], students: Student[], curriculumItems: CurriculumItem[]) : ITableRow[] => {
      var toReturn = new Array<ITableRow>();

      for(let element of submissions) { 
        
        const studentId = element.StudentId;
        const curriculumId = element.CurriculumId;

        var student = students.find(s=>s.Id === studentId);
        var curriculum = curriculumItems.find(f=>f.Id === curriculumId);        

        if(student !== undefined && curriculum !== undefined) {   
          let title = `${student.FirstName} ${student.LastName}, ${curriculum.Name}`;

          toReturn.push({
            key: element.Id,
            name: element.CurriculumId,
            studentName: `${student.FirstName} ${student.LastName}`,
            curriculum: curriculum.Name,
            value: element.ContentUri,
            category: curriculum.Category,
            dateModified: new Date(element.Created).toLocaleDateString(),
            dateModifiedValue: new Date(element.Created).valueOf(),
            getTitle: () => title
          });  
        }
      }

      return toReturn;
    }

    const doSearch = (n: string | undefined) => {
      
      if(n === undefined || n.length === 0) {
        //rebuild the default list
        var defaultList = buildTableRows(curriculumSubmissions!, students!, curriculumItems!);
        setItems(defaultList);
        return;
      }

      var filtered = items?.filter(i => i.getTitle().toLowerCase().indexOf(n.toLowerCase()) !== -1);
      setItems([...filtered!]);
    }

    return (
        <>
            {isLoading ? <Loader Text="Finding content for review, just a moment..." /> :
              isAuthenticated && items ? 
            <>
                <h2>Review Dashboard</h2>
                <Stack tokens={{childrenGap:10}}>
                  <Stack.Item align='end'>
                    <SearchBox placeholder="Find curriculum" onChange={(e,n) => doSearch(n)} />
                  </Stack.Item>
                  <List
                    items={items}
                    columns={columns}
                    checkboxVisibility={CheckboxVisibility.hidden}
                    selectionMode={SelectionMode.single}
                    isHeaderVisible={true}
                    onActiveItemChanged={onItemInvoked} 
                  />   
                </Stack>
                            
            </>
            :
            <p></p>}
        </>
    );   
}

export default ReviewDashboard;