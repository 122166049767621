
export enum FeedbackCategory {
    Support = 0,
    FeatureRequest = 1,
    Bug = 2
}

export interface IFeedback {
    Summary: string;
    Detail?: string;
    Category?: FeedbackCategory
}

export class Feedback {
    Summary: string;
    Detail?: string;
    Category?: FeedbackCategory

    constructor(
        params: IFeedback = {} as IFeedback) {
            
        let {
            Summary = "",
            Detail = undefined,
            Category = undefined
        } = params;
            
        this.Summary = Summary;
        this.Detail = Detail;
        this.Category = Category;
    }
}