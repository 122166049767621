import {FC, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import { Stack, Text } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';

export interface IData {
    showHours?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    twentyFourHour?: boolean;
}

const TimeDisplay : FC<IData> = ({showHours, showMinutes, showSeconds, twentyFourHour = false}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [hours, setHours] = useState<number>();
    const [minutes, setMinutes] = useState<number>();
    const [seconds, setSeconds] = useState<number>();

    useEffect(()=> {
        updateDelta();

        const id = setInterval(updateDelta, 1000);
        return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    const updateDelta = () => {        
        var t = DateTime.now();

        setHours(t.hour);
        setMinutes(t.minute);
        setSeconds(t.second);
    }

    return (
        <>
            {seconds !== undefined && minutes !== undefined ?
                <Stack horizontal>
                    {showHours && hours !== undefined ?
                        <Stack.Item align='end'>
                            <Text
                                style={{ fontSize: 100, color: isMobile ? 'initial' : 'white' }}
                                variant={isMobile ? 'large' : 'superLarge'}>{twentyFourHour ? hours : hours > 12 ? hours - 12 : hours}</Text>
                        </Stack.Item>
                        : null}
                
                    {showMinutes ?
                        <>
                            <Stack.Item align='end'>
                                <Text
                                    variant={isMobile ? 'large' : 'superLarge'}
                                    style={{ fontWeight: 'lighter', color: isMobile ? 'initial' : 'white', opacity: 0.9, fontSize: 75 }}>:</Text>
                            </Stack.Item>
                            <Stack.Item align='end'>
                                <Text
                                    style={{ fontSize: 100, color: isMobile ? 'initial' : 'white' }}
                                    variant={isMobile ? 'large' : 'superLarge'}>{minutes < 10 ? `0${minutes}` : minutes}</Text>
                            </Stack.Item>
                        </> : null}
                
                    {showSeconds ?
                        <>
                            <Stack.Item align='end'>
                                <Text
                                    variant={isMobile ? 'large' : 'superLarge'}
                                    style={{ fontWeight: 'lighter', color: isMobile ? 'initial' : 'white', opacity: 0.3, fontSize: 75 }}>:</Text>
                            </Stack.Item>
                            <Stack.Item align='end'>
                                <Text
                                    style={{ fontSize: 100, color: isMobile ? 'initial' : 'white' }}
                                    variant={isMobile ? 'medium' : 'xxLarge'}>{seconds < 10 ? `0${seconds}` : seconds}</Text>
                            </Stack.Item>
                        </> : null}

                    {twentyFourHour || hours === undefined ? null :
                        <Stack.Item
                            align='end'
                            style={{ paddingLeft: 2 }}>
                            <Text
                                variant={isMobile ? 'large' : 'xLarge'}
                                style={{ fontWeight: 'lighter', color: isMobile ? 'initial' : 'white', opacity: 0.9, fontSize: 75 }}>
                                {hours >= 12 ? 'pm' : 'am'}
                            </Text>
                        </Stack.Item>
                    }
                </Stack>
                : null}
        </>
    )
}

export default TimeDisplay;