import { Checkbox, Image, ImageFit, Panel, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvoice, getMembership, getTenantEnvironment, setupInvoicePayment } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import { Membership } from '../../model/Membership';
import Loader from '../../components/Loader';
import { useMediaQuery } from 'react-responsive';
import { EventChangeType, IInvoice, InvoiceStatus } from '../../model/Invoice';
import { CancelIcon, CheckMarkIcon } from '@fluentui/react-icons-mdl2';
import { GetPriceDisplayString } from '../../Display';
import { loadStripe } from '@stripe/stripe-js';
import { useBoolean } from '@fluentui/react-hooks';
import CheckoutForm from '../Tournament/components/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';

const ViewInvoice : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name: "View Invoice" });
    
    let { invoiceId } = useParams<{invoiceId?: string }>() as {invoiceId?: string };
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});

    const navigate = useNavigate();
    
    const [membership, setMembership] = useState<Membership>();
    const [invoice, setInvoice] = useState<IInvoice>();
    const [clientSecret, setClientSecret] = useState<string>();
    const [transactionId, setTransactionId] = useState<string>();
    const [stripePromise, setStripePromise] = useState<any>();
    const [acceptTerms, setAcceptTerms] = useState<boolean>(false);
    
    useEffect(() => {
        const fetchAsync = async () => {
            var env = await getTenantEnvironment(instance, account!);
            setStripePromise(loadStripe(env.PaymentGatewayPublicToken));

            let membership = await getMembership(instance, account!);
            let invoice = await getInvoice(instance, account!, invoiceId!);

            if (membership.StatusCode !== 200) {
                alert('Sorry, something went wrong');
                return;
            }

            if (typeof invoice === 'number') {
                alert('Sorry, something went wrong');
                return; 
            }

            if (invoice.Status !== InvoiceStatus.Paid) {
                //setup payment intent in the background
                if (invoice.ClientSecret === undefined || invoice.ClientSecret === null) {
                    var invPayment = await setupInvoicePayment(instance, account!, invoiceId!);

                    if (typeof invPayment === 'string') {
                        throw new Error(invPayment);
                    }
        
                    setClientSecret(invPayment.ClientSecret);    
                    setTransactionId(invPayment.TransactionId);
                }
                else {
                    setTransactionId(invoice.TransactionId);
                    setClientSecret(invoice.ClientSecret);
                }   
            }                        
            
            setMembership(membership.Result);
            setInvoice(invoice);
        }

        if (invoiceId === undefined) {
            return;
        }

        fetchAsync();
    }, [instance, account, inProgress, invoiceId]);
    
    const [isCompletePurchaseOpen, { setTrue: openCompletePurchase, setFalse: dismissCompletePurchase }] = useBoolean(false);

    return (
        <>
            <div style={{ backgroundColor: 'white', padding: isMobile ? 10 : 80, marginLeft: 'auto', marginRight: 'auto', marginTop: 50 }}>    
                {invoice === undefined && <Loader Text='Just a moment...' />}
                {invoice !== undefined && <>
                    <Stack horizontal tokens={{ childrenGap: 10 }}>                        
                        <Stack>
                            <Text variant='xLarge'>Invoice {invoice.Number}</Text>
                            <Text variant='smallPlus'>{invoice.Created.toDateString()}</Text>                                                       
                        </Stack>
                    </Stack>
                    <Stack horizontalAlign='center'>
                        <Image src={invoice?.TournamentImageUri} alt='Tournament logo' style={{maxWidth:100}} imageFit={ImageFit.contain} />
                        <Text variant='mediumPlus' style={{marginTop:10}}>{invoice.TournamentName}</Text> 
                        <br />
                    <table>
                        <thead>
                            <tr>
                                <th style={{padding:10}} align='left'><Text variant='medium' style={{fontWeight:'bold'}}>Description</Text></th>
                                <th style={{padding:10}} align='left'><Text variant='medium' style={{fontWeight:'bold'}}>Amount</Text></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                    <td style={{padding:10}} align='left'>{invoice.Description}</td>
                                    <td style={{padding:10}} align='right'>{GetPriceDisplayString(invoice.Total)}</td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{paddingRight:10, paddingTop:5}} align='right'><Text variant='medium'>Subtotal</Text></td>
                                <td style={{padding:10}} align='right'><Text variant='medium'>{GetPriceDisplayString(invoice.Total)}</Text></td>
                            </tr>
                            <tr>
                                <td style={{padding:10}} align='right'><Text variant='mediumPlus'>Amount due</Text></td>
                                <td style={{padding:10}} align='right'><Text variant='mediumPlus'>{GetPriceDisplayString(invoice.Status === InvoiceStatus.Paid ? 0 : invoice.Total)}</Text></td>
                            </tr>
                        </tfoot>
                    </table>                        
                    </Stack>
                    <Stack tokens={{ childrenGap: 20 }} horizontalAlign='center'>                        
                        {invoice !== undefined && invoice.EventChanges.length > 0 &&
                            <>
                            <hr style={{height:1, width:'80%', color:'grey', border:'none', backgroundColor:'grey' }} />                                
                                <Stack horizontal tokens={{ childrenGap: 40 }} wrap>
                                    <Stack>
                                        <Text style={{ paddingBottom: 10 }} variant='mediumPlus'>We will be <strong><em>adding</em></strong> these events:</Text>
                                        {invoice?.EventChanges.filter(x => x.Type === EventChangeType.Add).map((addition, idx) =>
                                            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ paddingLeft: 10 }}>
                                                <Stack.Item align='start'>
                                                    <CheckMarkIcon style={{ color: 'green' }} />
                                                </Stack.Item>
                                                <Stack.Item align='start'>
                                                    <Text variant='medium'>{addition.EventName} ({membership?.Students.find(s => s.Id === addition.StudentId)!.FirstName} {membership?.Students.find(s => s.Id === addition.StudentId)!.LastName})</Text>
                                                </Stack.Item>
                                            </Stack>
                                        )}
                                </Stack>
                                {invoice.EventChanges.some(e=>e.Type === EventChangeType.Remove) &&
                                    <Stack>
                                        <Text style={{ paddingBottom: 10 }} variant='mediumPlus'>We will be <strong><em>removing</em></strong> these events:</Text>
                                        {invoice?.EventChanges.filter(x => x.Type === EventChangeType.Remove).map((removal, idx) =>
                                            <Stack horizontal tokens={{ childrenGap: 10 }} style={{ paddingLeft: 10 }}>
                                                <Stack.Item align='start'>
                                                    <CancelIcon style={{ color: 'red' }} />
                                                </Stack.Item>
                                                <Stack.Item align='start'>
                                                    <Text variant='medium'>{removal.EventName} ({membership?.Students.find(s => s.Id === removal.StudentId)!.FirstName} {membership?.Students.find(s => s.Id === removal.StudentId)!.LastName})</Text>
                                                </Stack.Item>
                                            </Stack>
                                        )}
                                    </Stack>
                                }
                                </Stack>                            
                            </>
                        }

                        {invoice.Status !== InvoiceStatus.Paid &&
                            <>
                                <br />
                                <a href={`/tournament/${invoice.TournamentId}/terms`} target='_blank' rel='noreferrer'>View terms (opens in new tab)</a>
                                <Checkbox
                                    label={invoice.EventChanges.length > 0 ? 'I have reviewed the changes and wish to proceed. I have read, understand and agree to the terms.' : 'I have read, understand and agree to the terms.'}
                                    onChange={(ev, checked) => setAcceptTerms(checked!)} />
                            </>
                            }
                        <Stack.Item align='center'>
                            <br />
                            {invoice.Status === InvoiceStatus.Paid ? <Text variant='medium' style={{color:'red'}}>This invoice has already been paid.</Text> :
                                <Stack style={{ width: isMobile ? '90vw' : 'initial' }} horizontal={isMobile ? false : true} tokens={{ childrenGap: 10 }}>
                                    {invoice.Total === 0 ?
                                        <PrimaryButton
                                            disabled={!acceptTerms}
                                            style={{ padding: 10 }}
                                            onClick={()=>navigate(`/invoice/complete/${invoiceId}/?payment_intent=${transactionId}`)}>Proceed</PrimaryButton> :
                                        <PrimaryButton
                                            disabled={clientSecret === undefined || !acceptTerms}
                                            style={{ padding: 10 }}
                                            onClick={openCompletePurchase}>Pay</PrimaryButton>
                                    }
                                </Stack>
                            }
                        </Stack.Item>
                    </Stack>                
                </>
                }
            </div>
            {invoice !== undefined && 
                <Panel
                    headerText={`Pay ${GetPriceDisplayString(invoice!.Total)}`}
                    isOpen={isCompletePurchaseOpen}
                    onDismiss={dismissCompletePurchase}
                    closeButtonAriaLabel="Close">
                    <Elements 
                            options={{
                                clientSecret:clientSecret, 
                                appearance:{theme:'stripe'}}
                            } 
                            stripe={stripePromise}>
                                <CheckoutForm 
                                    returnUri={`${document.location.origin}/invoice/complete/${invoiceId!}`} />
                        </Elements>
                </Panel>
            }
        </>
    )
}

export default ViewInvoice;