import {FC, useEffect, useState} from 'react';
import UserAccount from '../components/UserAccount';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { getTenantEnvironment } from '../ApiService';

const Header : FC = () => {
    
    const [title, setTitle] = useState<string>();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    
    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (account) {
                try {
                    var tenantEnvironment = await getTenantEnvironment(instance, account);
                    setTitle(tenantEnvironment.Title);         
                }
                catch{

                }
            }
        }
       
        fetchData();            
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [account, isAuthenticated]);

    return (
        <header className="header">
            <div className="header-content">
                <h1><a href="/">{title}</a></h1>
            </div>
            <div className="header-right-icons">                                   
                {isAuthenticated ? <UserAccount /> : null}
            </div>
        </header>

    )
}

export default Header;