import { PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import { useIsAuthenticated } from '@azure/msal-react';
import { ValidateEmailField, ValidateEmailFieldWithError } from '../../Validation';
import { postBeginLogin } from '../../ApiService';
import Loader from '../../components/Loader';
import { useMediaQuery } from 'react-responsive';

enum LoginState {
    EnterEmail = 0,
    NotFound = 2,
    Found = 3
}

const Login : FC = () => {
    const [searchParams, ] = useSearchParams()

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name: "Login" });
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    const [state, setState] = useState<LoginState>(LoginState.EnterEmail);
    const [email, setEmail] = useState<string>();
    const [emailValid, setEmailValid] = useState<boolean>(false); 
    const [isBusy, setIsBusy] = useState<boolean>(false);   

    useEffect(()=> {
        if(email === undefined) {
            setEmailValid(false);
            return;
        }

        setEmailValid(ValidateEmailField(email));
    },[email]);

    const validateAccount = async () => {
        if(email === undefined) {
            return;
        }

        setIsBusy(true);

        var result = await postBeginLogin(email);
        
        if(typeof result === 'number') {
            setState(LoginState.NotFound);
            setIsBusy(false);
        }
        else {
            doLogin(result.issuer, result.email);
        }
    }

    const doLogin = (issuer:string, email: string) => {
        var to = searchParams.get('to');

        if(to !== null) {
            instance.loginRedirect({ 
                scopes:['openid profile','offline_access'], 
                state: document.location.href, 
                redirectStartPage: document.location.origin + to,
                domainHint: issuer,
                loginHint: email});
        }
        else {
            instance.loginRedirect({ 
                scopes:['openid profile','offline_access'], 
                state: document.location.href,
                domainHint:issuer,
                loginHint: email});
        }
    }

    useEffect(()=> {
        if(isAuthenticated) {
            navigate('/', {replace:true})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[isAuthenticated]);

    useEffect(()=> {
        if(state === LoginState.EnterEmail) {
            setEmail(undefined);
        }
    }, [state])

    return (
            <UnauthenticatedTemplate>
            <div style={{
                backgroundColor: 'white',
                maxWidth: isMobile ? '100%' : 450,
                padding: isMobile ? 20 : 80,
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: isMobile ? 20 : 50
            }}>
                    <Text variant='xLargePlus'>Login to NinjaPanel</Text>
                    
                    {state === LoginState.EnterEmail ?
                        <>                
                            <Stack tokens={{childrenGap:10}} horizontalAlign='start'>
                                <br />                    
                                <Text variant='mediumPlus'>Enter the email address that is associated with your account.</Text>                                                                    
                                <Text variant='mediumPlus'>You'll be redirected to your identity provider to complete authentication.</Text>
                                <Stack.Item align='stretch'>
                                    <TextField 
                                        autoComplete='username'
                                        style={{minWidth:300}} 
                                        disabled={isBusy}
                                        readOnly={isBusy}
                                        placeholder='Email address'
                                        onChange={(e,v) => setEmail(v?.toLowerCase().trim())} 
                                        validateOnLoad={false} 
                                        validateOnFocusOut 
                                        onKeyUp={(e) => e.key === "Enter" && emailValid ? validateAccount() : ()=>{}}
                                        onGetErrorMessage={ValidateEmailFieldWithError}/>
                                </Stack.Item>                            
                                <Stack.Item align='end'>
                                    <Stack tokens={{childrenGap:5}} horizontal>                                
                                        <PrimaryButton 
                                            disabled={email === undefined || email.length === 0 || !emailValid || state !== LoginState.EnterEmail || isBusy} 
                                            onClick={()=>validateAccount()}>Next</PrimaryButton>                                
                                    </Stack>
                                </Stack.Item>
                                
                                {isBusy ?
                                    <Stack.Item align='center'>
                                        <Loader Text="Just a moment..." />
                                    </Stack.Item> 
                                : null}
                            </Stack>
                        </>
                    : null}               

                    {state === LoginState.NotFound ?
                        <>
                            <Stack tokens={{childrenGap:10}} horizontalAlign='start'>
                                <br />                    
                                <Text variant='mediumPlus'>No account found.</Text>
                                <Text variant='medium'>There's no NinjaPanel account with the email address you provided.</Text>
                                <br /><br />
                                <Stack.Item align='center'>
                                    <Stack tokens={{childrenGap:5}}>
                                        <PrimaryButton onClick={()=>setState(LoginState.EnterEmail)}>Try again</PrimaryButton>                          
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </>
                        : null}                
                </div>
            </UnauthenticatedTemplate>
    )
}

export default Login;