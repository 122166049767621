import { Stack, Text, Image, PrimaryButton, ImageFit, DefaultButton } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Tournament, TournamentType } from '../model/Tournament';
import CountdownTimer from './CountdownTimer';
import { useNavigate } from 'react-router-dom';
import { RegistrationStatus, TournamentRegistrationStatus } from '../model/TournamentRegistration';
import { getTournamentRegistrations } from '../ApiService';
import { DateTime } from 'luxon';
import { useAccount, useMsal } from '@azure/msal-react';
import { Membership } from '../model/Membership';
import { GetTournamentLocalDate } from '../Display';

interface IData {
    tournament: Tournament;
    membership?: Membership;
}

const TournamentPromo : FC<IData> = ({tournament, membership}) => {
    const navigate = useNavigate();
    const [hasTournamentStarted, setHasTournamentStarted] = useState<boolean>();
    const [hasTournamentEnded, setHasTournamentEnded] = useState<boolean>();  
    const [registrationEnds, setRegistrationEnds] = useState<Date>();  

    const [canRegister, setCanRegister] = useState<boolean>(false);
    const [canEnter, setCanEnter] = useState<boolean>(false);
    const [canJudge, setCanJudge] = useState<boolean>(false);
    const [canWatch, setCanWatch] = useState<boolean>(false);

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    useEffect(()=> {

        const fetchAsync = async () => {
        
            if(account == null) {
                return;
            }
    
            var hasRegEnded = false;

            if(tournament.RegistrationEnds !== undefined) {
                setRegistrationEnds(tournament.RegistrationEnds);
                hasRegEnded = DateTime.utc() > DateTime.fromJSDate(tournament.RegistrationEnds);                
            }
            else{
                setRegistrationEnds(tournament.StartDate);
            }
            
            var hasStarted = DateTime.utc() >= DateTime.fromJSDate(tournament.StartDate);            
            var hasEnded = DateTime.utc() >= DateTime.fromJSDate(tournament.EndDate);

            setHasTournamentStarted(hasStarted);
            setHasTournamentEnded(hasEnded);
    
            //has this member registered?
            var registrations = await getTournamentRegistrations(instance, account, tournament.Id); 

            var hasRegistration = registrations === undefined ? 
                false : 
                registrations.some(r=> 
                    (r.Events.filter(e => e.Status === RegistrationStatus.Complete).length > 0 && 
                    r.Status === TournamentRegistrationStatus.Complete));

            setCanEnter(registrations === undefined ? 
                false : 
                hasStarted && hasRegistration && !hasEnded);
            setCanRegister(!hasRegEnded && !hasEnded);

            setCanWatch(hasRegistration);
            
            //can this member judge the tournament? must be online and ended and they must be listed as a stakeholder
            if(tournament.Stakeholders.find(s=>s.MembershipId === membership?.Id) !== undefined && 
                hasEnded && 
                tournament.Type === TournamentType.Online) {
                    setCanJudge(true);
            }
            
            setIsLoading(false);
        }

        fetchAsync();
    }, [instance, account, tournament, membership]);

    return (
        isLoading ? null :
        <div className='tournament-header'>
                        
                <Stack tokens={{childrenGap:10}}>
                    {hasTournamentStarted ? 
                        hasTournamentEnded ? 
                            null : 
                                (<>
                                <Stack.Item align='center'>
                                    <Text variant="xLarge" style={{fontVariant:'small-caps'}}>Tournament Ends</Text>
                                </Stack.Item>

                                <Stack.Item align='center'>
                                    <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.EndDate!} completed={()=>setHasTournamentEnded(true)}/>
                                </Stack.Item>                                
                                </>)
                        : 
                        <>
                        <Stack.Item align='center'>
                            <Text variant="xLarge" style={{fontVariant:'small-caps'}}>Tournament Starts</Text>
                        </Stack.Item>
                        <Stack.Item align='center'>
                            <CountdownTimer showDays showHours showMinutes showSeconds targetDate={tournament?.StartDate!} completed={()=>setHasTournamentStarted(true)}/>
                        </Stack.Item>                                
                        </>}

                    <Stack horizontal tokens={{childrenGap:15}} verticalAlign='start' wrap>
                        <Image src={tournament.ImageUri} width={165} imageFit={ImageFit.contain}/>
                        <Stack>                            
                            <h3>{tournament?.Title}</h3>
                            {tournament.Type === TournamentType.InPerson ? <span style={{fontStyle:'Italic', marginTop:-15}}>{tournament.Location}<br/>{DateTime.fromJSDate(GetTournamentLocalDate(tournament.StartDate, tournament.Timezone)).toLocaleString(DateTime.DATETIME_MED)}<br /><br /></span> : null}
                            <div dangerouslySetInnerHTML={{__html: tournament?.Description}} />
                            <br />
                        </Stack>
                    </Stack>
                    
                        <Stack tokens={{childrenGap:10}}>
                            <Stack horizontalAlign='center' horizontal wrap tokens={{childrenGap:10}}>                                
                                {canRegister ? <Stack.Item><PrimaryButton style={{minWidth:200}} onClick={() => navigate(`/event/${tournament.Id}`)}>Register</PrimaryButton></Stack.Item> : null}
                                {canEnter ? <Stack.Item><PrimaryButton style={{minWidth:200}} onClick={() => navigate(`/event/${tournament.Id}/enter`)}>{hasTournamentStarted ? "Enter Now" : "View Details" }</PrimaryButton></Stack.Item> : null}
                                {canWatch ? <Stack.Item><DefaultButton style={{minWidth:200}} onClick={() => navigate(`/event/${tournament.Id}/watch`)}>Watch Now</DefaultButton></Stack.Item> : null}
                                {canJudge ? <Stack.Item><PrimaryButton style={{minWidth:200}} onClick={() => navigate(`/event/${tournament.Id}/review`)}>Judge</PrimaryButton></Stack.Item> : null}
                            </Stack>
                            <Stack.Item align='center'>
                                {canRegister ? <Text variant='medium' style={{color:'red'}}>Registration ends {DateTime.fromJSDate(registrationEnds!).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}</Text> : null}
                            </Stack.Item>
                        </Stack>
                </Stack>                    
            
        </div> 
    )
}

export default TournamentPromo;