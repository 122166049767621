import { Stack, Text, Image, ImageFit, DefaultButton, Persona, PersonaPresence, PersonaSize, Link, Modal, IconButton, IIconProps, getTheme, mergeStyleSets, FontWeights, IButtonStyles, TooltipHost } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Student } from '../../../model/Student';
import { useBoolean } from '@fluentui/react-hooks';
import CurriculumViewer from '../../../components/CurriculumViewer';
import Loader from '../../../components/Loader';
import { CurriculumItem } from '../../../model/CurriculumItem';
import { getCurriculum } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import {ReceiptCheckIcon, CheckboxIcon, CheckboxCompositeIcon} from '@fluentui/react-icons-mdl2';
import { EventRegistrationStatus } from '../../../model/TournamentEvent';
import { GetPricingLabelForStrategy } from '../../../Display';
import { IPricingStrategy } from '../../../model/Tournament';

export interface IStudentWithRegistrationPermission {
    Student: Student;
    CanRegister: boolean;
}

interface IData {
    status?: EventRegistrationStatus;
    eventId: string;
    eventTitle: string;
    eventDescription: string;
    eventImageUri: string;
    
    pricingStrategy?: IPricingStrategy;
    additionalFee?: number;

    registeredStudents?: Student[] | undefined;
    notRegisteredStudents?: IStudentWithRegistrationPermission[] | undefined;
    isCompact?: boolean;
    displayMode: EventCardDisplayMode;
    onEventSelectionChanged: (eventId: string, selectedStudents: Student[]) => void;
    onViewEventClicked: (eventId: string) => void;
}

export enum StudentAction {
    Add = 0,

    Remove = 1
}

export enum EventCardDisplayMode {
    Students = 0,
    Entry = 1,
    Preview = 2,    
    EntryReceived = 3
}

const TournamentEventCard : FC<IData> = ({isCompact, onEventSelectionChanged, onViewEventClicked, status, eventId, eventDescription, eventTitle, eventImageUri, pricingStrategy, additionalFee = 0, registeredStudents, notRegisteredStudents, displayMode}) => {

    const [selectedStudents, setSelectedStudents] = useState<Student[]>([]);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    
    const [selectedCurriculum, setSelectedCurriculum] = useState<CurriculumItem>();
    const [allStudents, setAllStudents] = useState<Student[]>();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    useEffect(()=> {
        var students : Student[] = [];

        if(registeredStudents !== undefined) {
            students.push(...registeredStudents);
        }

        if(notRegisteredStudents !== undefined) {
            students.push(...notRegisteredStudents.map(s => s.Student));
        }

        setAllStudents(students);
    },[notRegisteredStudents, registeredStudents]);

    const updateSelectedStudents = (student:Student) => {
        
        var foundIdx = selectedStudents.findIndex(s => s.Id === student.Id);

        if(foundIdx === -1) {
            selectedStudents.push(student);           
        }
        else {
            selectedStudents.splice(foundIdx, 1);
        }
        
        setSelectedStudents([...selectedStudents]);
        onEventSelectionChanged(eventId, selectedStudents);
    }

    const fetchCurriculum = async () => {
        setSelectedCurriculum(undefined);
        showModal();
        var c = await getCurriculum(instance, account!, eventId);
        setSelectedCurriculum(c);
    }
    
    
    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 600        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 4px 14px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    };

    const getAllStudentsSorted = () : Student[] | undefined => {
        if (allStudents === undefined) {
            return undefined;
        }                
        
        return allStudents.sort((a, b) => {
            //if a cannot register, display a last
            //if b cannot register, display b last
            //if both a and b cannot register, display them in the order they appear in the list
            let aWithPermission = notRegisteredStudents?.find(nrs => nrs.Student.Id === a.Id);
            let bWithPermission = notRegisteredStudents?.find(nrs => nrs.Student.Id === b.Id);

            if(aWithPermission?.CanRegister === false && bWithPermission?.CanRegister === false) {
                return 0;
            }

            if(aWithPermission?.CanRegister === false) {
                return 1;
            }

            if(bWithPermission?.CanRegister === false) {
                return -1;
            }

            return 0;
        });
    }

    
    return (
        <>
        <Stack style={{maxWidth: isCompact ? "100%" : 400, padding:isCompact ? 10 : 20, backgroundColor:'white'}} tokens={{childrenGap:10}}>
            {isCompact ? null : <Image src={eventImageUri} imageFit={ImageFit.cover}/>  }
            <Stack.Item>
                <Stack tokens={{childrenGap:5}}>
                    {isCompact ? 
                        <Stack horizontal tokens={{childrenGap:5}}>
                            <Stack.Item align='center'>
                                <Text variant='medium'>{eventTitle}</Text>
                            </Stack.Item>
                            <Stack.Item align='center'>
                                <IconButton styles={{root:{width:15,height:15}}} style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:2}} iconProps={{iconName:'StatusCircleQuestionMark'}} onClick={fetchCurriculum} />
                            </Stack.Item>
                        </Stack> : 
                        <Text variant='xLarge'>{eventTitle}</Text>
                    }
                    {pricingStrategy === undefined ? 
                        null : 
                        <Text variant='smallPlus'>{GetPricingLabelForStrategy(pricingStrategy!, additionalFee)}</Text>
                    }
                </Stack>
            </Stack.Item>
            {displayMode === EventCardDisplayMode.Preview || isCompact ? null : <Text className={'text-trimming'} block nowrap={isCompact}><span dangerouslySetInnerHTML={{__html:eventDescription.replaceAll("<br />","")}} /></Text>}
            {displayMode === EventCardDisplayMode.Students ?
            <>
                 
                {isCompact ? null : 
                    <Stack.Item align='start'>
                        <Link onClick={()=>fetchCurriculum()}>View details</Link>
                    </Stack.Item>}
                
                
                <Stack tokens={{childrenGap:10}} horizontal wrap>
                    {getAllStudentsSorted()?.map((student, idx) => {
                        return ( 
                            (status !== undefined && status === EventRegistrationStatus.Closed && registeredStudents?.find(s=>s.Id === student.Id) !== undefined) || status === undefined || (status !== undefined && status === EventRegistrationStatus.Open) ?
                            <div 
                                style={{
                                        cursor:
                                            notRegisteredStudents?.find(nrs => nrs.Student.Id === student.Id) !== undefined &&
                                                !notRegisteredStudents?.find(nrs => nrs.Student.Id === student.Id)?.CanRegister ?
                                                'not-allowed' :
                                                (registeredStudents?.find(s => s.Id === student.Id) === undefined ? 'pointer' : 'default'), 
                                    borderColor: "#ddd",
                                    borderStyle: isCompact ? 'initial' : 'solid', 
                                    borderWidth: 'thin',                                                                     
                                    paddingTop: isCompact ? 2 : 10,
                                    paddingLeft: isCompact ? 2 : 10,
                                    paddingRight: isCompact ? 2 : 10
                                }} 
                                key={idx}
                                onClick={
                                    notRegisteredStudents?.find(nrs => nrs.Student.Id === student.Id) !== undefined &&
                                    !notRegisteredStudents?.find(nrs => nrs.Student.Id === student.Id)?.CanRegister ? 
                                    () => null 
                                    : (registeredStudents?.find(s => s.Id === student.Id) === undefined 
                                        ? () => updateSelectedStudents(student) 
                                        : () => null)
                                }
                            >
                                
                                {isCompact ? 
                                    <Stack horizontal tokens={{childrenGap:isCompact ? 5 : 10}}>
                                        <Stack.Item align='center'>
                                            {registeredStudents?.find(s=>s.Id === student.Id) !== undefined || selectedStudents.find(s=>s.Id === student.Id) !== undefined ? 
                                                <CheckboxCompositeIcon /> : 
                                                <CheckboxIcon /> 
                                            }
                                        </Stack.Item>
                                        <Stack.Item align='center'>
                                            <Text variant='smallPlus'>{student.FirstName}</Text>
                                        </Stack.Item>
                                    </Stack> : 
                                    <Stack>
                                        <Persona                                    
                                            imageUrl={student.ImageUri}
                                            imageInitials={`${student.FirstName[0]}${student.LastName[0]}`}
                                            size={PersonaSize.size24}
                                            text={student.FirstName}                                        
                                            hidePersonaDetails={false}                                                                                         
                                            presence={PersonaPresence.none}
                                            imageAlt={`${student.FirstName} ${student.LastName}, ${student.LevelId}`} />
                                        {registeredStudents?.find(s=>s.Id === student.Id) !== undefined ? 
                                            <div style={{height:6, marginTop:5,backgroundColor:'rgb(0, 120, 212)'}}></div>
                                            :
                                            <div style={{height:6, marginTop:5,backgroundColor:selectedStudents.find(s=>s.Id === student.Id) ? 'rgb(0, 120, 212)' : ''}}></div>
                                        }
                                    </Stack>
                                }
                            </div> : null
                        )
                    })}
                </Stack>
                {status !== undefined && status === EventRegistrationStatus.Closed ? 
                    <Text style={{color:'red'}}>Full</Text> : null}
            </>
            : 
            displayMode === EventCardDisplayMode.Entry ?
            <>
                <br />
                <DefaultButton onClick={()=> onViewEventClicked(eventId)}>View event</DefaultButton>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap:5}} wrap>
                    {registeredStudents?.map((student, idx) => {
                        return (                                                
                                <Stack.Item key={idx}>
                                     <TooltipHost
                                        tooltipProps={{onRenderContent:()=> (<>{student.FirstName} {student.LastName[0]}<br /> {student.Level?.Label}</>)}}>
                                        <Persona                                                                       
                                            imageUrl={student.ImageUri}
                                            imageInitials={`${student.FirstName[0]}${student.LastName[0]}`}
                                            size={PersonaSize.size24}
                                            hidePersonaDetails
                                            presence={PersonaPresence.none}
                                            imageAlt={`${student.FirstName} ${student.LastName[0]}, ${student.LevelId}`}
                                        />
                                    </TooltipHost>
                                </Stack.Item>
                        )
                    })}
                </Stack>
            </> 
            : 
            displayMode === EventCardDisplayMode.EntryReceived ?
            <>
                <br />
                <Stack horizontal tokens={{childrenGap: 10}} horizontalAlign='center' verticalAlign='center'>
                    <ReceiptCheckIcon color='green' />
                    <Text>Entry Received.</Text>
                </Stack>
                <Stack horizontal horizontalAlign='end' tokens={{childrenGap:5}} wrap>
                    {registeredStudents?.map((student, idx) => {
                        return (                                                
                                <Stack.Item key={idx}>
                                     <TooltipHost
                                        tooltipProps={{onRenderContent:()=> (<>{student.FirstName} {student.LastName[0]}<br /> {student.Level?.Label}</>)}}>
                                        <Persona                                                                       
                                            imageUrl={student.ImageUri}
                                            imageInitials={`${student.FirstName[0]}${student.LastName[0]}`}
                                            size={PersonaSize.size24}
                                            hidePersonaDetails
                                            presence={PersonaPresence.none}
                                            imageAlt={`${student.FirstName} ${student.LastName[0]}, ${student.LevelId}`}
                                        />
                                    </TooltipHost>
                                </Stack.Item>
                        )
                    })}
                </Stack>
            </> 
            : null
            
}
        </Stack>
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            containerClassName={contentStyles.container}            
        >
            <div className={contentStyles.header}>
                <span>{eventTitle}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close modal"
                    onClick={hideModal} />
            </div>
            <div className={contentStyles.body}>                
                {selectedCurriculum === undefined ? 
                    <Loader Text='Just a moment...' /> : 
                    <Stack>
                        {pricingStrategy === undefined ? 
                                null :
                                <Text variant='smallPlus'>Price: {GetPricingLabelForStrategy(pricingStrategy, additionalFee)}</Text>
                        }
                        <CurriculumViewer curriculum={selectedCurriculum} previewMode />
                    </Stack>
                }
            </div>
        </Modal>
        </>
    )    
}

export default TournamentEventCard;