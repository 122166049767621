import { DivisionStatus } from "./TournamentDivision";
import { ITournamentRound } from "./TournamentRound";

export interface ITournamentBracket {
    Id: string;
    DivisionId: string;
    TournamentId: string;
    Label: string;
    Location: string;
    ScheduledStart: Date;
    Status: DivisionStatus;
    LastStatus: DivisionStatus;
    Rounds: ITournamentRound[]
}

export enum BracketedAthleteStatus {
    Pending = 0,
    Scheduled = 1,
    InProgress = 2,
    Entered = 3,
    Graded = 4
}
export interface IBracketedAthlete {
    Sequence: number;
    StudentId: string;
    Status: BracketedAthleteStatus;
}
export class TournamentBracket {    
    BracketedAthletes: IBracketedAthlete[];
    Rounds: ITournamentRound[];
    Label: string;
    Id: string;
    JudgeIds: string[];

    constructor(
        id: string,
        label: string,
        bracketedAthletes: IBracketedAthlete[],
        judgeIds: string[],
        rounds: ITournamentRound[]) {
            this.Id = id;
            this.Label = label;
            this.BracketedAthletes = bracketedAthletes;
            this.Rounds = rounds;
            this.JudgeIds = judgeIds;
    }        
}