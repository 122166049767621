import { RubricType as RubricType2 } from "./Rubric";

export interface IReviewFeedback {
    Id?: string;
    X: number;
    Y: number;
    Height: number;
    Width: number;
    RubricId: string;
    RubricType: RubricType2;
    RubricTitle?: string;
    Weight: number;
    ReviewId?: string;
    Timestamp: number;
}

export class ReviewFeedback implements IReviewFeedback {
    Id?: string;
    X: number;
    Y: number;
    Height: number;
    Width: number;
    RubricId: string;
    RubricType: RubricType2;
    RubricTitle?: string;
    Weight: number;
    ReviewId?: string;
    Timestamp: number;

    constructor(
        params: IReviewFeedback = {} as IReviewFeedback) {
            
        let {
            Id = undefined,
            X = -1,
            Y = -1,
            Height = -1,
            Width = -1,
            RubricId = "",
            RubricType = RubricType2.TimeScoped,
            ReviewId = undefined,
            RubricTitle = undefined,
            Weight = 0,
            Timestamp = -1    
        } = params;
            
        this.Id = Id;
        this.X = X;
        this.Y = Y;
        this.Height = Height;
        this.Width = Width;
        this.RubricId = RubricId;
        this.RubricType = RubricType;
        this.ReviewId = ReviewId;
        this.RubricTitle = RubricTitle;     
        this.Weight = Weight;   
        this.Timestamp = Timestamp;
    }
}