import { ILevel } from "./Level";

export interface IBracketStatus {
    Name: string;
    IsProvisional: boolean;
}

export interface ILeaderboardRow {
    Place: number;
    EventId: string;
    EventName: string;
    DivisionName: string;
    BracketName: string;
    StudentCurriculumSubmissionId: string;
    StudentFirstName: string;
    StudentLastName: string;
    StudentLevel: ILevel;
    StudentImage: string;
    Score: number;
    IsHighlighted: boolean;
}

export interface ITournamentLeaderboard {
    TournamentImageUri: string;
    TournamentTitle: string;
    Rows: ILeaderboardRow[];
    Status: IBracketStatus[];
}

export class TournamentLeaderboard implements ITournamentLeaderboard {
    TournamentImageUri: string;
    TournamentTitle: string;    
    Rows: ILeaderboardRow[];
    Status: IBracketStatus[];

    constructor(
        params: ITournamentLeaderboard = {} as ITournamentLeaderboard) {
            
        let {
            TournamentImageUri = "",
            TournamentTitle = "", 
            Rows = [],
            Status = []
        } = params;
            
        this.TournamentImageUri = TournamentImageUri;
        this.TournamentTitle = TournamentTitle;
        this.Rows = Rows;
        this.Status = Status;
    }
}