import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import StudentCard from '../../components/StudentCard';
import { IStackTokens, Stack } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import { getActivityFeed, getMembership, getPreviousTournament, getTenantLandingPageContent } from '../../ApiService';
import Loader from "../../components/Loader";
import { ActivityFeedItem } from '../../model/ActivityFeedItem';
import ActivityFeed from '../../components/ActivityFeed';
import { Membership, MembershipStatus } from '../../model/Membership';
import { Features, useFeatures } from '../../Features';
import { ILandingPageTournament } from '../../model/TenantLandingPageViewModel';
import EventCard from '../../components/EventCard';
import { DateTime } from 'luxon';
import TournamentPromo from '../../components/TournamentPromo';
import { Tournament } from '../../model/Tournament';

const Dashboard : FC = () => {
    const navigate = useNavigate();
    const [ isLoaded, setIsLoaded ] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { login } = useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    const [hasLoginError, setHasLoginError] = useState<any>();
    const { instance, accounts, inProgress } = useMsal();
    const features = useFeatures();

    const account = useAccount(accounts[0] || {});
    const [membership, setMembership] = useState<Membership | undefined>(undefined);        
    const [membershipStatus, setMembershipStatus] = useState(MembershipStatus.Active);

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Dashboard" });

    const stackTokens: IStackTokens = {
        childrenGap:20
    };

    const [activities, setActivities] = useState<ActivityFeedItem[]>();
    const [tournaments, setTournaments] = useState<ILandingPageTournament[]>([]);
    const [lastTournament, setLastTournament] = useState<Tournament>();

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(() => {
        if(isLoaded || hasLoginError) {
            return;
        }
        setIsLoading(true);

        const fetchData = async () => {
            if (inProgress === "none" && account) {

                setIsLoaded(true);

                try {
                    var membershipResult = await getMembership(instance, account); 

                    if(membershipResult.StatusCode === 200) {
                        setMembershipStatus(MembershipStatus.Active);
                        setMembership(membershipResult.Result!);                                            

                        if(features?.indexOf(Features.EventDashboard) !== -1) {
                            var vm = await getTenantLandingPageContent();
                            
                            if(typeof vm === 'number') {
                                setErrorCode(vm);
                                return;
                            }
                
                            var t : ILandingPageTournament[] = [];

                            if(vm.Tournaments !== undefined && vm.Tournaments.length > 0) {
                                for(let p of vm.Tournaments) {                                    
                                    if(DateTime.fromJSDate(p.EndDate!) > DateTime.utc()) {
                                        t.push(p);
                                    }    
                                }    
                            }
                            else {
                                //no tournaments, look for most recently completed one
                                var lastTournament = await getPreviousTournament(instance, account);
                                setLastTournament(lastTournament);
                            }
                            
                            setTournaments(t);
                            setIsLoading(false);                                
                        }
                        
                        var activityItems = await getActivityFeed(instance, account);    
                                                
                        setActivities(activityItems);                            
                    }
                    else if (membershipResult.StatusCode === 404) {
                        setMembership(undefined);
                    }
                    else {
                        if(membershipResult.StatusCode === 403) {                            
                            navigate('/user/missing')
                        }
                        else {
                            setMembershipStatus(MembershipStatus.Active);
                        }
                    }

                    setIsLoading(false);
                }            
                catch(error: any) {
                    console.log(error);
                    if(error.errorCode === 'login_required') {
                        setHasLoginError(true);
                    }
                }
            }
        }
       
        fetchData();     
        // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, [inProgress, account, instance, features]);

    useEffect(()=> {
        if(!hasLoginError) {
            return;
        }

        login(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasLoginError]);

    
   return (  
        <>            
            <h2>{features === undefined ? null : features?.indexOf(Features.EventDashboard) === -1 ? "Dashboard" : tournaments.length > 0 ? "Events" : null}</h2>
            {isLoading ?
                <Loader Text="Just a moment..." /> : 
            
                features?.indexOf(Features.EventDashboard) !== -1 ? 
                <Stack tokens={{childrenGap:20}}>
                    <Stack horizontal wrap tokens={{childrenGap:10}}>    
                        {lastTournament !== undefined ?
                            <TournamentPromo 
                                tournament={lastTournament} 
                                membership={membership} /> : 
                            tournaments.map((t,idx) => <EventCard key={idx} tournament={t} navTo={`/event/${t.Id}`}/> )
                        }
                    </Stack>
                    {activities !== undefined && activities !== null && activities.length > 0 ? <div>
                        <h3>Activity Feed</h3>
                        <ActivityFeed values={activities!} />
                    </div> : null}
                </Stack> : 

                membershipStatus === MembershipStatus.Canceled ? <p>It looks like your membership is not currently active.</p> : 
                    membership === undefined ? 
                    <p>No students found</p> :
                <Stack tokens={stackTokens}>                                
                    <Stack horizontal={true} wrap={true} tokens={stackTokens}>
                        {membership?.Students.map((s,key)=>
                        {
                            return <StudentCard onClick={() => navigate(`/student/${s.Id}`)} level={s.Level!} imageUri={s.ImageUri} initials={`${s.FirstName.charAt(0).toUpperCase()}${s.LastName.charAt(0).toUpperCase()}`} studentName={`${s.FirstName} ${s.LastName}`} key={key}  />
                        })}
                    </Stack>
                    {activities !== undefined && activities !== null && activities.length > 0 ?
                    <div>
                        <h3>Activity Feed</h3>
                        <ActivityFeed values={activities!} />
                    </div> : 
                    null}
                </Stack>
            }
        </>
   );
}

export default Dashboard;