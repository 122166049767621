import { Image } from '@fluentui/react';
import React, {FC} from 'react';
import { IReactionValue } from '../model/Reaction';
import { VideoMarker } from '../model/VideoMarker';
import { parse } from 'twemoji-parser';

export interface IVideoTrackbar {
    Markers?: VideoMarker[];
    Reactions?: IReactionValue[];
    Progress: number;
    TotalDuration: number;
    SeekRequested: (newSeek: number) => void;
}

const VideoTrackbar : FC<IVideoTrackbar> = ({Markers, Reactions, Progress, SeekRequested, TotalDuration}) => { 

    const handleSeek = (e:React.PointerEvent<HTMLDivElement>) => {
        var rect = e.currentTarget.getBoundingClientRect()
        var x = e.clientX - rect.left; //x position within the element.

        var percentage = x / rect.width;
        //this is the percentage progress to seek to, not the current time
        SeekRequested(percentage);
    }

    const getMarkerPosition = (timestamp: number) => {
        var pos = timestamp / TotalDuration;
        return pos * 100;
    }

    return (
        <>
            <div className="video-trackbar" style={{cursor:'pointer'}} onPointerDown={handleSeek}>
                
                <div className="video-progress" style={{width:`${Progress}%`}}></div>   
                {Markers !== undefined ? 
                    <div className="video-markers">                        
                            {Markers.map((m, idx)=> {
                                return <div key={idx} style={{position:"absolute", left:`${getMarkerPosition(m.Timestamp)}%`, height: "8px", width:"2px", backgroundColor:"red"}} />
                            }) }
                    </div>
                    : 
                    null
                }
                {Reactions !== undefined ? 
                    <div className="video-reactions">                        
                            {Reactions.map((r, idx)=> {
                                return <Image key={idx} src={parse(r.Value)[0].url} style={{position:"absolute", left:`${getMarkerPosition(r.Timestamp)}%`}} />
                            })}
                    </div>     
                    : 
                    null
                }        
            </div>
        </>
    )
}

export default VideoTrackbar;