import {FC, useState} from 'react';
import { useEffect } from 'react';
import { VideoMarker } from '../model/VideoMarker';

export interface IVideoAnnotation {
    VideoMarker: VideoMarker;
    Width: number;
    Height: number;
}

const VideoAnnotation : FC<IVideoAnnotation> = ({VideoMarker, Width, Height}) => { 

    const [computedX, setComputedX] = useState<number>();
    const [computedY, setComputedY] = useState<number>();
    const [isValid, setIsValid] = useState<boolean>(false);
    
    useEffect(()=>{
        if (VideoMarker.X === -1 || VideoMarker.Y === -1) {
            setIsValid(false);
            return;
        }

        //calculate coordinate as percentage of viewport when captured, then position relative to the current viewport
        var ratioX = VideoMarker.X / VideoMarker.Width;
        var ratioY = VideoMarker.Y / VideoMarker.Height;

        setComputedX(ratioX * Width);
        setComputedY(ratioY * Height);
        setIsValid(true);
    },[Width, Height, VideoMarker]);

    return (
        <>
            {isValid ? <div className="video-annotation" style={{left:computedX, top:computedY}}></div> : null}
        </>
    )
}

export default VideoAnnotation;