import React, { FC, useEffect, useState } from 'react';
import {  FontWeights, getTheme, mergeStyleSets, Stack, Text } from '@fluentui/react';
import Loader from './Loader';
import { IFileDownload } from '../model/FileDownload';

interface IData {
    loadingMessage: string;
    startDownload: () => Promise<IFileDownload | number>;
}

const FileDownloader : FC<IData> = ({startDownload, loadingMessage}) => {

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const [fileDownload, setFileDownload] = useState<IFileDownload>();
    const [error, setError] = useState<string>();

    useEffect(()=> {
        const fetchAsync = async () => {
            var result = await startDownload();
            
            if(typeof result === 'number') {
                setError('There was an error downloading the file: StatusCode: ' + result);
                return;
            }

            setFileDownload(result);
        }
        fetchAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[]);
    
    return (
        <>
            <div className={contentStyles.header}>
                <span>{fileDownload === undefined ? "Just a moment" : "Ready to download"}</span>                
            </div>
            <div className={contentStyles.body}>   
                {fileDownload === undefined ? 
                    <Loader Text={loadingMessage} /> :
                    <Stack tokens={{childrenGap:15}}>
                        <Text variant='medium'>Use the link below to complete your download.</Text>
                        <a href={fileDownload.Uri} target='_blank' rel='noreferrer'>{fileDownload.Filename}</a>
                    </Stack>
                }
                {error === undefined ? null : <p style={{color:'red'}}>{error}</p>}
            </div>
        </>
    )
}

export default FileDownloader;