import { Label, Stack, TextField } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';

interface IData {
    onModelValidationStatusChanged: (category: string, isValid: boolean)  => void;
}

const CategoryEditor : FC<IData> = ({onModelValidationStatusChanged}) => { 
    const [category, setCategory] = useState<string>('');

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {
          
        if(category?.length === 0) {
            onModelValidationStatusChanged(category, false);    
            return;
        }
        
        onModelValidationStatusChanged(category!, true);
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [category]);
     
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>Name</Label>            
                <TextField 
                    inputMode="text"                                               
                    defaultValue={category}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setCategory(newValue!)} 
                    onGetErrorMessage={validateFieldNotEmpty}/>      
                    <br />                             
            </Stack>

        </>
    )
}

export default CategoryEditor;