import React, { FC } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useIsAuthenticated } from "@azure/msal-react";


const StudentSubmissionReceived : FC = () => {
    // let { curriculumId } = useParams<{curriculumId: string }>();
    // let { studentId } = useParams<{studentId: string }>();

    const isAuthenticated = useIsAuthenticated();
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"StudentSubmissionReceived" });
    
   return (  
        // <AuthenticatedTemplate>  
        isAuthenticated ? 
        <>        
            <h2>Content Received</h2>

            <p>Thank you, your video has been uploaded!</p>                        
        </>
        : null
        // </AuthenticatedTemplate>
   );
}

export default StudentSubmissionReceived;;