import React, { FC, useEffect, useState } from 'react';
import { Label, TextField, Text } from '@fluentui/react';
import { Tournament } from '../../model/Tournament';

interface IData {
    tournament: Tournament;
    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

const OperationsWizard : FC<IData> = ({tournament, onValidated}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {
        if(internalTournament.AthleteCallMinutes.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.BracketLocations.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        if(internalTournament.StagingLocations.length === 0) {
            onValidated(false, internalTournament);
            return;
        }
        
        onValidated(true, internalTournament);
    // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [internalTournament]);    
    
    const getAthleteCallMinutes = (t?: string): number[] => {
        if (t === undefined) {
            return [];
        }

        const values = t.split(',');
        const result: number[] = [];

        for (let v of values) {            
            result.push(parseInt(v));   
        }

        return result;
    }

    return (
      <div style={{display:'flex', flexDirection:'column', gap:5}}>
        <Text variant='large'>Operational details</Text>
        <br />
            <Label required>Athlete Call Minutes</Label>
            <Text variant='small'>Comma-separated values representing the number of minutes athletes will have to get to the staging location, ie: 5, 10, 20</Text>
        <TextField 
            defaultValue={tournament.AthleteCallMinutes.join(',')}
            placeholder='Comma-separated minutes' 
            onChange={(e, t) => setInternalTournament(internalTournament => ({...internalTournament, AthleteCallMinutes: getAthleteCallMinutes(t)}))} />

<Label required>Staging Locations</Label>
            <Text variant='small'>One or more locations that you will send your athletes when you want them to come to staging, ie: Staging 1, Staging 2</Text>
        <TextField 
            defaultValue={tournament.StagingLocations.join(',')}
            placeholder='Comma-separated locations' 
                onChange={(e, t) => setInternalTournament(internalTournament => ({ ...internalTournament, StagingLocations: t === undefined ? [] : t.split(',') }))} />
            
            <Label required>Competition Locations</Label>
            <Text variant='small'>One or more locations that you will send your athletes to compete, ie: Ring 1, Ring 2</Text>
        <TextField 
            defaultValue={tournament.BracketLocations.join(',')}
            placeholder='Comma-separated locations' 
            onChange={(e, t) => setInternalTournament(internalTournament => ({...internalTournament, BracketLocations: t === undefined ? [] : t.split(',')}))} />        
        
      </div>
    );
}

export default OperationsWizard;