export enum PreferredWidth {
    Narrow = 0,
    Wide = 1
}

export enum ValueKind {    
    String = 0,
    DateTime = 1
}

export interface IRowValue {
    Value: string;
}

export interface IStatusRow {
    Values: IRowValue[];
}
export interface IColumnHeader {
    Value: string;
    ValueKind: ValueKind;
    PreferredWidth: PreferredWidth
}

export interface ITournamentStatus {
    Title: string;
    Link: string;
    ColumnHeaders: IColumnHeader[];
    Rows: IStatusRow[];
}

export class TournamentStatus implements ITournamentStatus{
    Title: string;
    Link: string;
    ColumnHeaders: IColumnHeader[];
    Rows: IStatusRow[];

    constructor(
        params: ITournamentStatus = {} as ITournamentStatus) {
            
        let {
            Title = '',
            Link = '',
            ColumnHeaders = [],
            Rows = []
        } = params;
            
        this.Title = Title;
        this.Link = Link;
        this.ColumnHeaders = ColumnHeaders;
        this.Rows = Rows;
    }
}