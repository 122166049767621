import { Transaction } from "./Transaction";
import { UserProfile } from "./UserProfile";

export interface ITransactionViewModel {
    Transactions: Transaction[];
    Users: UserProfile[];
}

export class TransactionViewModel implements ITransactionViewModel {    
    Transactions: Transaction[];
    Users: UserProfile[];

    constructor(
        params: ITransactionViewModel = {} as ITransactionViewModel) {
            
        let {
            Transactions = [],            
            Users = []
        } = params;
            
        this.Transactions = Transactions;
        this.Users = Users;        
    }
}