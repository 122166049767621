import React, { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useIsAuthenticated } from "@azure/msal-react";
import VideoReactionHost from '../components/VideoReactionHost';
import { useParams } from 'react-router-dom';
import { Student } from '../model/Student';
import { getStudent, getStudentCurriculumSubmission } from '../ApiService';
import { useAccount, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { StudentCurriculumSubmission } from '../model/StudentCurriculumSubmission';

const TournamentEntry : FC = () => {    
    let { tournamentEventId } = useParams<{tournamentEventId: string }>() as {tournamentEventId: string };
    let { studentCurriculumSubmissionId } = useParams<{studentCurriculumSubmissionId: string }>() as {studentCurriculumSubmissionId: string };
    let { tournamentId } = useParams<{tournamentId: string }>() as {tournamentId: string };

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const isAuthenticated = useIsAuthenticated();
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"TournamentEntry" });

    const [student, setStudent] = useState<Student>();
    const [studentCurriculumSubmission, setStudentCurriculumSubmission] = useState<StudentCurriculumSubmission>();
    
    useEffect(()=> {
        const fetchAsync = async () => {
            var submission = await getStudentCurriculumSubmission(instance, account!, studentCurriculumSubmissionId);
            setStudentCurriculumSubmission(submission);

            var student = await getStudent(instance, account!, submission.StudentId);
            setStudent(student);
        }

        if(account) {
            fetchAsync();
        }
    },[instance, account, studentCurriculumSubmissionId]);

   return (  
        isAuthenticated && 
            student !== undefined && 
            studentCurriculumSubmission !== undefined ? 
        <>    
            <VideoReactionHost 
                CurriculumId={tournamentEventId} 
                TournamentId={tournamentId}
                CurriculumSubmission={studentCurriculumSubmission}
                Student={student} />
        </>
        : null
   );
}

export default TournamentEntry;;