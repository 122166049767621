import { CurriculumItem } from './CurriculumItem';
import { Student } from './Student';
import { StudentCurriculumSubmission } from './StudentCurriculumSubmission';

export interface IReviewDashboardViewModel {
    Submissions: StudentCurriculumSubmission[];
    Students: Student[];
    Curriculum: CurriculumItem[];
}

export class ReviewDashboardViewModel implements IReviewDashboardViewModel {
    Submissions: StudentCurriculumSubmission[];
    Students: Student[];
    Curriculum: CurriculumItem[];

    constructor(
        params: IReviewDashboardViewModel = {} as IReviewDashboardViewModel) {
            
        let {
            Submissions = new Array<StudentCurriculumSubmission>(),
            Students = new Array<Student>(),
            Curriculum = new Array<CurriculumItem>(),
        } = params;

        this.Submissions = Submissions;
        this.Students = Students;
        this.Curriculum = Curriculum;
    }
}