import { Stack } from '@fluentui/react';
import {FC} from 'react';

const Footer : FC = () => {

    return (
        <div className="footer">
            <Stack horizontal tokens={{childrenGap:20}}>
                <Stack.Item>
                    &copy; NinjaPanel
                </Stack.Item>
                <Stack.Item>
                    <a href="https://www.ninjapanel.com/privacy.html" target='_blank' rel='noreferrer'>Privacy</a>
                </Stack.Item>
                <Stack.Item>
                    <a href="https://www.ninjapanel.com/terms.html" target='_blank' rel='noreferrer'>Terms</a>
                </Stack.Item>                
            </Stack>
        </div>
    )
}

export default Footer;