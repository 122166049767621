import React, { FC, useEffect, useState } from 'react';
import { CommandBar, IColumn, Panel, PanelType, SelectionMode, Stack, CheckboxVisibility, PrimaryButton } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { createCategory, createRubric, getCategories, getRubric } from '../ApiService';
import { Rubric, RubricType } from '../model/Rubric';
import List, { IDocument } from './List';
import RubricEditor from './RubricEditor';
import CategoryEditor from './CategoryEditor';
import Loader from './Loader';
import { Category } from '../model/Category';

interface ITableRow extends IDocument{
    key: string;
    category: string;
    title: string;
    weight: number;
    type: RubricType;    
}

enum ActiveSection  {
  None = 0,
  Categories = 1,
  Deductions = 2
}
  
const RubricManagement : FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [categoryItems, setCategoryItems] = useState<IDocument[]>([]);
    const [categories, setCategories] = useState<Category[]>();

    const [canSaveCategory, setCanSaveCategory] = useState<boolean>(false);
    const [canSaveDeduction, setCanSaveDeduction] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [pendingCategory, setPendingCategory] = useState<string>();
    const [pendingDeduction, setPendingDeduction] = useState<Rubric>();

    const [activeSection, setActiveSection] = useState<ActiveSection>(ActiveSection.None);

    const [categoryColumns, ] = useState<IColumn[]>([
      {
        key: 'column1',
        name: 'Id',
        fieldName: 'key',
        minWidth: 75,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
    ]);

    const [columns, ] = useState<IColumn[]>([
      {
        key: 'column1',
        name: 'Title',
        fieldName: 'title',
        minWidth: 75,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
    {
      key: 'column2',
      name: 'Category',
      fieldName: 'category',
      minWidth: 75,
      maxWidth: 200,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
    },
    {
          key: 'column0',
          name: 'Weight',
          fieldName: 'weight',
          minWidth: 70,
          maxWidth: 150,      
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'number',
          isPadded: true,
          onRender: (item: ITableRow) => {
            return item.weight === -1 ? '' : `${(item.weight * 100).toFixed(0)}%`;
        },
        },        
        {
            key: 'column3',
            name: 'Type',
            fieldName: 'type',
            minWidth: 35,
            maxWidth: 75,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isCollapsible: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',         
            isPadded: true,
            onRender: (item: ITableRow) => {
              return <span>{_GetRubricTypeString(item.type)}</span>;
            }
          }        
      ]);
     

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var rubric = await getRubric(instance, account);
                var categories = await getCategories(instance, account);

                setCategories(categories);      

                var rubricRows = new Array<ITableRow>();
                
                for(let r of rubric) {
                    let title = _GetRubricTypeString(r.Type);
                    
                    rubricRows.push({
                        key: r.Id,
                        category: r.Category,
                        title: r.Title,
                        weight: r.Weight,
                        type: r.Type,
                        getTitle: () => title
                    });                    
                }

                setItems(rubricRows);

                var categoryRows = new Array<IDocument>();
                
                for(let c of categories) {
                    let title = c.Title;
                    
                    categoryRows.push({
                        key: title,
                        getTitle: () => title
                    });                    
                }

                setCategoryItems(categoryRows);

                setIsLoaded(true);
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const _GetRubricTypeString = (rubricType: RubricType) : string => {
      switch(rubricType) {
        case RubricType.Global:
          return "Overall";
        case RubricType.TimeScoped:
          return "Time based deduction";
        case RubricType.Numeric:
          return "Numeric";
      }
    }

    const _GetHeaderForSection = () => {

      switch(activeSection) {
        case ActiveSection.Categories: {
          return "New Category";
        }
        case ActiveSection.Deductions: {
          return "New Deduction";
        }
      }

      return "";
    }

    const onRubricValidationChanged = (rubric: Rubric, isValid: boolean) => {
      setPendingDeduction(rubric);
      setCanSaveDeduction(isValid);
    }

    const onCategoryValidationChanged = (category: string, isValid: boolean) => {
      setPendingCategory(category);
      setCanSaveCategory(isValid);
    }

    const saveCategory = async () => {
      setIsSaving(true);

      var response  = await createCategory(instance, account!, new Category({ Id : '', Title : pendingCategory!}));
      setIsSaving(false);

      if(response?.ok) {
        dismissEditPanel();      
        
        categoryItems.push(buildTableRow(pendingCategory!));
        setCategoryItems(categoryItems);
      }
      else{
        setCanSaveCategory(true);
        alert('Sorry, something went wrong. Please try again.')
      }
    }

    const buildTableRow = (item: string) : IDocument => {
      let title = item;

      return {
          key: item,
          getTitle: () => title
      }; 
    }

    const buildTableRowDeduction = (pendingDeduction: Rubric) : ITableRow => {
      let title = pendingDeduction.Title;

      return {
        key: pendingDeduction.Id,
        category: pendingDeduction.Category,
        title: pendingDeduction.Title,
        weight: pendingDeduction.Weight,
        type: pendingDeduction.Type,
        getTitle: () => title
      };
    }

    const saveDeduction = async () => {
      if(pendingDeduction === undefined) {
        return;
      }

      setIsSaving(true);

      var result = await createRubric(instance, account!, pendingDeduction);
      setIsSaving(false);

      if(result?.ok) {
        dismissEditPanel();
        
        items?.push(buildTableRowDeduction(pendingDeduction));
        
        setItems(items);
      }
      else{
        setCanSaveCategory(true);
        alert('Sorry, something went wrong. Please try again.')
      }
    }

    return (
        <>
            {isLoaded ?
                items !== undefined ? 
                    <Stack>
                        <h3>Categories</h3>
                        Categories are used to link grading rubric to curriculum.
                        {categories !== undefined ? 
                        <>
                        <br /><br />
                          <div style={{background:'white'}}>
                            <CommandBar   
                                items={[{
                                  key: 'new',
                                  text: 'New', 
                                  disabled: false,//selection.getSelectedCount() !== 1,
                                  cacheKey: 'new',
                                  iconProps: { iconName: 'AddTo' },            
                                  onClick: ()=> { setActiveSection(ActiveSection.Categories); openEditPanel();}    
                                },
                                {
                                    key: 'edit',
                                    text: 'Edit', 
                                    disabled: true,//selection.getSelectedCount() !== 1,
                                    cacheKey: 'edit',
                                    iconProps: { iconName: 'Edit' },            
                                    onClick: ()=> openEditPanel()  
                                }]}
                                ariaLabel="Use left and right arrow keys to navigate between commands" />
                              <List 
                                columns={categoryColumns} 
                                selectionMode={SelectionMode.single}
                                checkboxVisibility={CheckboxVisibility.onHover}
                                enableSort 
                                items={categoryItems} />
                          </div><br /></> : null                        
                        }
                       
                        <h3>Grading Rubric</h3>
                        Time based deductions are enabled during media review and reduce the students total score by the cumulative weight. Global deducctions are additive and applied after all of the time based deductions have accrued.
                        <br /><br />
                        <CommandBar   
                                items={[{
                                  key: 'new',
                                  text: 'New', 
                                  disabled: false,//selection.getSelectedCount() !== 1,
                                  cacheKey: 'new',
                                  iconProps: { iconName: 'AddTo' },            
                                  onClick: ()=> { setActiveSection(ActiveSection.Deductions); openEditPanel();}    
                                },
                                {
                                    key: 'edit',
                                    text: 'Edit', 
                                    disabled: true,//selection.getSelectedCount() !== 1,
                                    cacheKey: 'edit',
                                    iconProps: { iconName: 'Edit' },            
                                    onClick: ()=> openEditPanel()  
                                }]}
                                ariaLabel="Use left and right arrow keys to navigate between commands" />
                      <List 
                          columns={columns} 
                          selectionMode={SelectionMode.single}
                          checkboxVisibility={CheckboxVisibility.onHover}
                          enableSort 
                          items={items} />
                       
                    </Stack>
                    : null 
                :
                <Loader Text='Just a moment...' />
            }
             <Panel
                headerText={_GetHeaderForSection()}
                type={PanelType.smallFixedFar}
                isOpen={isEditOpen}
                isLightDismiss={true}
                onDismiss={dismissEditPanel}
                closeButtonAriaLabel="Close">
                    <br />
                    {activeSection === ActiveSection.Categories ? 
                    <CategoryEditor onModelValidationStatusChanged={onCategoryValidationChanged} /> :
                    <RubricEditor onModelValidationStatusChanged={onRubricValidationChanged} />  
                  }
                  {activeSection === ActiveSection.Categories ? 
                    <PrimaryButton disabled={!canSaveCategory || isSaving} onClick={()=> saveCategory()}>Save Category</PrimaryButton>
                    : 
                    <PrimaryButton disabled={!canSaveDeduction || isSaving} onClick={()=> saveDeduction()}>Save Deduction</PrimaryButton>
                  }
                  {isSaving ? <Loader Text="Just a moment..." /> : null}
              </Panel>
        </>
    )
}

export default RubricManagement;