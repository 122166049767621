import {FC, useEffect, useState} from 'react';
import { IColumn, Persona, PersonaPresence, PersonaSize, Pivot, PivotItem, SelectionMode, Stack, Text} from '@fluentui/react';
import { Tournament } from '../../../model/Tournament';
import { getTournamentLeaderboard } from '../../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import { ILeaderboardRow } from '../../../model/TournamentLeaderboard';
import List, { IDocument } from '../../../components/List';
import Loader from '../../../components/Loader';
import {useNavigate} from 'react-router-dom';
import { DateTime } from 'luxon';

interface IData {
    title: string;
    tournament: Tournament;
    hasTournamentStarted: boolean;
}

interface ITableRow extends ILeaderboardRow, IDocument {

}


const Leaderboard : FC<IData> = ({title, tournament, hasTournamentStarted}) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [entries, setEntries] = useState<IDocument[]>();
    const [eventGroups, setEventGrouping] = useState<string[]>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    let navigate = useNavigate();

    const [columns, ] = useState<IColumn[]>([
        {
          key: 'column1',
          name: '',
          fieldName: 'Place',
          minWidth: 50,
          maxWidth: 75,
          isRowHeader: true,
          isResizable: true,
          isSorted: false,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          data: 'string',
          isPadded: true,
          onRender: (item: ITableRow) => {                          
            let n:number = item.Place; 
            //eslint-disable-next-line no-mixed-operators
            return item.Score === -2 ? <em>Withdrawn</em> : item.Score === -1 ? <em>Pending</em> : `${n}${['','st','nd','rd'][n%100>>3^1&&n%10]||'th'}`;
          }
        },
      {
        key: 'column2',
        name: 'Name',
        fieldName: 'StudentName',
        minWidth: 100,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true, 
        onRender: (item:ITableRow)=> {
            return <Persona
                        imageUrl={item.StudentImage}
                        imageInitials={`${item.StudentFirstName[0]}${item.StudentLastName[0]}`}
                        size={PersonaSize.size32}
                        text={`${item.StudentFirstName} ${item.StudentLastName[0]}.`}
                        secondaryText={item.StudentLevel.Label}
                        hidePersonaDetails={false}
                        showSecondaryText
                        onClick={()=>navigate(`/event/${tournament.Id}/item/${item.EventId}/entry/${item.StudentCurriculumSubmissionId}`)}
                        presence={PersonaPresence.none}
                        imageAlt={`${item.StudentFirstName} ${item.StudentLastName[0]}., ${item.StudentLevel.Description}`}
                        />
          }       
      }
    ]);

    const fetchAsync = async () => {
        setIsLoading(true);
        var leaderboard = await getTournamentLeaderboard(instance, account!, tournament.Id);

        if(leaderboard.Rows.length === 0) {
            setEntries(undefined);
            setIsLoading(false);
            return;
        }

        var entries = new Array<ITableRow>();
        var eventGroups : string[] = [];

        let count = 0;

        for(let row of leaderboard.Rows) {
            let title: string = '';
            let groupKey: string = `${row.EventName}_${row.DivisionName}_${row.BracketName}`;
            
            entries.push({
                key: count.toString(),
                EventId: row.EventId,
                EventName: row.EventName,
                DivisionName: row.DivisionName,
                BracketName: row.BracketName,
                Place: row.Place,
                Score: leaderboard.Status.find(s=>s.Name === groupKey)!.IsProvisional ? -1 : row.Score,
                StudentCurriculumSubmissionId: row.StudentCurriculumSubmissionId,
                StudentImage: row.StudentImage,
                StudentLevel: row.StudentLevel,
                StudentFirstName: row.StudentFirstName,
                StudentLastName: row.StudentLastName,
                IsHighlighted: row.IsHighlighted,
                groupKey: groupKey,
                groupHeader: row.EventName,
                groupSubheader: row.BracketName,
                getTitle: () => title
            });
            
            let eventName = row.EventName;

            if(eventGroups.find(e=>e === eventName) === undefined) {                
                eventGroups.push(eventName);
            }

            count++;
        }
        
        setEventGrouping(eventGroups);
        setEntries(entries);
        setIsLoading(false);
    }

    useEffect(()=> {
        if(account) {
            fetchAsync();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps   
    },[tournament, account, instance]);

    return (
        
        <Stack tokens={{childrenGap:5}}>      
            {!isLoading ? <Text variant='xLargePlus'>{title}</Text> : null}
            {isLoading ? <Loader Text="Just a moment..."/> : null}      
            {entries !== undefined && eventGroups !== undefined && !isLoading ? 
            
                <Pivot overflowBehavior='menu'>
                    {eventGroups.map((eventGroup, idx)=>
                        <PivotItem key={idx} itemKey={eventGroup} headerText={eventGroup}>
                            <List            
                                selectionMode={SelectionMode.none}
                                enableSort={false}
                                isGrouped
                                isHeaderVisible={false}      
                                columns={columns}
                                items={entries.filter(e=>e.groupHeader === eventGroup)} />
                        </PivotItem>                
                    )}
                    
                </Pivot> 
            
             : isLoading ? null : hasTournamentStarted ? <Text variant='medium'>No entries just yet - check back soon!</Text> : <Text variant='medium'>{tournament.Title} begins {DateTime.fromJSDate(tournament.StartDate).toLocaleString(DateTime.DATETIME_SHORT)}</Text>}
        </Stack>
    );
}

export default Leaderboard;