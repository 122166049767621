import React, { FC, useEffect, useReducer, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { InteractionType } from '@azure/msal-browser';
import Loader from '../../components/Loader';
import {AuthenticatedTemplate, useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useParams } from 'react-router-dom';
import { getStudent, getSubmissionsForBracket } from '../../ApiService';
import { Stack, PrimaryButton } from '@fluentui/react';
import { StudentCurriculumSubmission } from '../../model/StudentCurriculumSubmission';
import { BracketReviewViewModel } from '../../model/BracketReviewViewModel';
import VideoFeedbackHost from '../../components/VideoFeedbackHost';
import { useBoolean } from '@fluentui/react-hooks';
import { Student } from '../../model/Student';
import StudentCard from '../../components/StudentCard';

const TournamentBracketReview : FC = () => {
    let { tournamentId } = useParams<{tournamentId?: string }>() as {tournamentId: string };
    let { divisionId } = useParams<{divisionId?: string }>() as {divisionId: string };
    let { bracketId } = useParams<{bracketId?: string }>() as {bracketId: string };
    let { membershipId } = useParams<{membershipId?: string }>() as {membershipId: string };
    let { studentId } = useParams<{studentId?: string }>() as {studentId: string };

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"TournamentBracketReview" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [vm, setVm] = useState<BracketReviewViewModel>();
    const [judge, setJudge] = useState<Student>();    

    useEffect(() => {        
        setIsLoading(true);

        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {                
                var vm = await getSubmissionsForBracket(instance, account, tournamentId, divisionId, bracketId);
                setVm(vm);

                var judge = await getStudent(instance, account, studentId);
                setJudge(judge);
                setIsLoading(false);                                    
            }
        }
       
        fetchData();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, inProgress, account]);

    const [selectedCurriculumSubmission, setSelectedCurriculumSubmission] = useState<StudentCurriculumSubmission>();
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const forceUpdate = useReducer(x => x + 1, 0)[1]

    const onEntrySubmitted = (successful: boolean) => {        
        if(successful) {
            //store client side dummy state so it's clear to the user that they don't have to review this item again
            selectedCurriculumSubmission!.Reviews!.push({Id: '', Created: new Date(),Feedback:[],StudentCurriculumSubmissionId:'',Text:'', AuthoredBy:{DisplayName:"",MembershipId:membershipId,StudentId:studentId}});
            forceUpdate();
            hideModal();
        }
        
    }

    useEffect(()=> {
        if(!isModalOpen) {
            setSelectedCurriculumSubmission(undefined);
        }
    },[isModalOpen]);

    useEffect(()=> {
        if(selectedCurriculumSubmission !== undefined) {
            showModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCurriculumSubmission]);

    return (
        isLoading ? <Loader Text="Just a moment.." /> :
        <>         
            <AuthenticatedTemplate>
                <br />                        
                {selectedCurriculumSubmission === undefined ?
                        <>
                        {judge === undefined ? null :
                            <StudentCard 
                                canClick={false}
                                additionalText={`Judging ${vm?.Curriculum.Name} - ${vm?.DivisionName}`}
                                level={judge.Level!} 
                                imageUri={judge.ImageUri}
                                initials={`${judge.FirstName.charAt(0).toUpperCase()}${judge.LastName.charAt(0).toUpperCase()}`} 
                                studentName={`${judge.FirstName} ${judge.LastName}`}
                                />
                        }
                            
                    <br />
                    <Stack horizontal wrap tokens={{childrenGap:20}}>
                            {vm?.Submissions?.sort(s=> s.Reviews?.find(r=>r.AuthoredBy?.StudentId === studentId) === undefined ? -1 : 1).map((s,idx)=>
                                <Stack key={idx}>
                                    <div
                                        onClick={()=>setSelectedCurriculumSubmission(s)}
                                        style={{ 
                                            cursor: s.Reviews?.find(r=>r.AuthoredBy?.StudentId === studentId) === undefined ? 'pointer' : 'default',
                                            backgroundBlendMode: s.Reviews?.find(r=>r.AuthoredBy?.StudentId === studentId) === undefined ? 'initial' : 'multiply',
                                            backgroundColor: s.Reviews?.find(r=>r.AuthoredBy?.StudentId === studentId) === undefined ? 'initial' : '#a8a8a8' , 
                                            padding:20, 
                                            height:130, 
                                            width:175,
                                            backgroundRepeat:'no-repeat', 
                                            backgroundSize:'cover', 
                                            backgroundImage:`url('${s.PosterImageUri}')`}}>
                                        </div>                            
                                    {s.Reviews?.find(r=>r.AuthoredBy?.StudentId === studentId) === undefined ?
                                    <PrimaryButton 
                                        onClick={()=>setSelectedCurriculumSubmission(s)}>Review</PrimaryButton>                               
                                        : null}
                                </Stack>
                            )}
                    </Stack>
                    </>
                    : 
                            <div style={{width:'90vw', height:'90vh'}}>
                                <VideoFeedbackHost 
                                    HideNeedsWork
                                    CurriculumId={selectedCurriculumSubmission!.CurriculumId} 
                                    CurriculumSubmissionId={selectedCurriculumSubmission!.Id}
                                    Student={vm!.Students.find(s=>s.Id === selectedCurriculumSubmission!.StudentId)!}
                                    TournamentId={tournamentId}
                                    AuthoredBy={{DisplayName: '', MembershipId:membershipId, StudentId: studentId}}
                                    EntrySubmitted={onEntrySubmitted}            
                                />
                            </div>
                    }                
            </AuthenticatedTemplate>               
        </>
    );  
}

export default TournamentBracketReview;

