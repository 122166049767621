import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';

import './assets/App.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

import StudentDashboard from './views/StudentDashboard';
import StudentSubmission from './views/StudentSubmission';
import StudentSubmissionReceived from './views/StudentSubmissionReceived';
import CurriculumDetail from './views/CurriculumDetail';
import ReviewDashboard from './views/ReviewDashboard';
import ReviewHistory from './views/ReviewHistory';
import ReviewEditor from './views/ReviewEditor';
import Schedule from './views/Schedule';
import StudentCurriculumReview from './views/StudentCurriculumReview';
import Admin from "./views/Admin";
import RedeemInvitation from "./views/RedeemInvitation";
import TournamentDashboard from "./pages/Tournament/TournamentDashboard";
import TournamentRegistrationCompleted from "./pages/Tournament/TournamentRegistrationCompleted";
import ValidateInvitation from "./views/ValidateInvitation";
import UserNotFound from "./views/UserNotFound";
import Auth from "./views/Auth";
import TournamentEventDashboard from "./views/TournamentEventDashboard";
import TournamentEntry from "./views/TournamentEntry";
import JoinCommunity from './pages/Onboard/JoinCommunity';
import JoinCommunityPending from "./pages/Onboard/JoinCommunityPending";
import Dashboard from './pages/Dashboard/Dashboard';
import TenantLandingPage from "./pages/Onboard/TenantLandingPage";
import TournamentLandingPage from "./pages/Onboard/TournamentLandingPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import PageNotFound from './pages/PageNotFound';
import TournamentReviewDashboard from './pages/TournamentReviewDashboard/TournamentReviewDashboard';
import TournamentBracketReview from './pages/TournamentBracketReview/TournamentBracketReview';
import EditProfile from './pages/User/EditProfile';
import Registrations from './pages/User/Registrations';
import TournamentAdmin from './pages/TournamentManagement/TournamentAdmin';
import ViewTerms from './pages/Tournament/ViewTerms';
import Hub from './pages/Status/Hub';
import Login from './pages/Onboard/Login';
import ProtectedRoute from './components/ProtectedRoute';
import ReviewProfile from './pages/Onboard/ReviewProfile';
import ViewInvoice from './pages/Purchase/ViewInvoice';
import InvoicePaymentCompleted from './pages/Purchase/InvoicePaymentCompleted';

const configuration: Configuration = {
  auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENTID as string,    
      redirectUri: process.env.REACT_APP_REDIRECT_URI as string,
      authority: 'https://ninjapanel.b2clogin.com/ninjapanel.onmicrosoft.com/B2C_1_NPNL_DEFAULT',
      knownAuthorities: ['https://ninjapanel.b2clogin.com']           
  }
};

const pca = new PublicClientApplication(configuration);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />} errorElement={<PageNotFound />}>
      <Route index element={<><AuthenticatedTemplate>
                                  <Dashboard />
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                  <TenantLandingPage />
                                </UnauthenticatedTemplate>
                              </>}/>
      
      
      <Route path="/student/:studentId" element={<ProtectedRoute><StudentDashboard /></ProtectedRoute>} />
      <Route path="/student/:studentId/review/:reviewId" element={<ProtectedRoute><StudentCurriculumReview /></ProtectedRoute>} />
      <Route path="/student/:studentId/curriculum/:curriculumId" element={<ProtectedRoute><CurriculumDetail /></ProtectedRoute>} />
      <Route path="/student/:studentId/curriculum/:curriculumId/tournament/:tournamentId" element={<ProtectedRoute><CurriculumDetail /></ProtectedRoute>} />
      <Route path='/student/:studentId/curriculum/:curriculumId/submit' element={<ProtectedRoute><StudentSubmission /></ProtectedRoute>} />
      <Route path='/student/:studentId/curriculum/:curriculumId/submitted' element={<ProtectedRoute><StudentSubmissionReceived /></ProtectedRoute>} />
      <Route path='/history' element={<ProtectedRoute><ReviewHistory /></ProtectedRoute>} />      
      <Route path='/review' element={<ProtectedRoute><ReviewDashboard /></ProtectedRoute>} />
      <Route path='/review/:studentId/curriculum/:curriculumId/submission/:submissionId' element={<ProtectedRoute><ReviewEditor /></ProtectedRoute>} />
      <Route path='/admin' element={<ProtectedRoute><Admin /></ProtectedRoute>} />
      <Route path='/schedule' element={<ProtectedRoute><Schedule /></ProtectedRoute>} />
      <Route path='/redeem/:redemptionToken' element={<ValidateInvitation />} />
      <Route path='/redeem/step2/:redemptionToken' element={<RedeemInvitation />} />
      
      <Route path='/tournament/:tournamentId/terms' element={<ViewTerms />} />
      <Route path='/tournament/:tournamentId' element={<ProtectedRoute><TournamentDashboard /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/:mode' element={<ProtectedRoute><TournamentDashboard /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/review'element={<ProtectedRoute><TournamentReviewDashboard /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/manage/'element={<ProtectedRoute><TournamentAdmin /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/manage/:hash' element={<ProtectedRoute><TournamentAdmin /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/division/:divisionId/bracket/:bracketId/:membershipId/:studentId/review'element={<ProtectedRoute><TournamentBracketReview /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/event/:tournamentEventId' element={<ProtectedRoute><TournamentEventDashboard /></ProtectedRoute>} />                
      <Route path='/tournament/registration/complete/:tournamentId/' element={<ProtectedRoute><TournamentRegistrationCompleted /></ProtectedRoute>} />
      <Route path='/tournament/:tournamentId/event/:tournamentEventId/entry/:studentCurriculumSubmissionId' element={<ProtectedRoute><TournamentEntry /></ProtectedRoute>} />                  
      
      <Route path='/event/:tournamentId/terms' element={<ViewTerms />} />
      <Route path='/event/:tournamentId' element={<ProtectedRoute><TournamentDashboard /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/:mode' element={<ProtectedRoute><TournamentDashboard /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/review'element={<ProtectedRoute><TournamentReviewDashboard /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/manage/'element={<ProtectedRoute><TournamentAdmin /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/manage/:hash' element={<ProtectedRoute><TournamentAdmin /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/division/:divisionId/bracket/:bracketId/:membershipId/:studentId/review'element={<ProtectedRoute><TournamentBracketReview /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/item/:tournamentEventId' element={<ProtectedRoute><TournamentEventDashboard /></ProtectedRoute>} />                
      <Route path='/event/registration/complete/:tournamentId/' element={<ProtectedRoute><TournamentRegistrationCompleted /></ProtectedRoute>} />
      <Route path='/event/:tournamentId/item/:tournamentEventId/entry/:studentCurriculumSubmissionId' element={<ProtectedRoute><TournamentEntry /></ProtectedRoute>} />      

      <Route path='/status/:appToken' element={<Hub />} />

      <Route path='/user/missing/' element={<UserNotFound />} />                
      <Route path='/auth' element={<Auth />} />
      <Route path='/login' element={<Login />} />
      <Route path='/join' element={<JoinCommunity />} />
      <Route path='/pending' element={<JoinCommunityPending />} />
      <Route path='/events/:tournamentId' element={<TournamentLandingPage />} />
      <Route path='/user/review' element={<ProtectedRoute><ReviewProfile /></ProtectedRoute>} />
      <Route path='/user/profile' element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
      <Route path='/user/registrations' element={<ProtectedRoute><Registrations /></ProtectedRoute>} />

      <Route path='/invoice/view/:invoiceId' element={<ProtectedRoute><ViewInvoice /></ProtectedRoute>} />
      <Route path='/invoice/complete/:invoiceId/' element={<ProtectedRoute><InvoicePaymentCompleted /></ProtectedRoute>} />
    </Route>
  )
);


ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <RouterProvider router={router} />
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
