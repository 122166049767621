import { RubricPoints, RubricScoreImpact } from "./model/RubricPoints";
import { Rubric } from "./model/Rubric";
import { ReviewFeedback } from "./model/ReviewFeedback";

export class TournamentScoring {
   Rubric: Rubric[];
   RubricPoints: RubricPoints[];

    constructor() {
        this.Rubric = [];
        this.RubricPoints = [];
    }

    calculateScore = async (feedback: ReviewFeedback[], rubricPoints: RubricPoints[]) : Promise<number> =>  {
        if(feedback.length === 0 || feedback === undefined) {
            return 100;
        }
        
        var groupedResults = new Map<string, number>();

        //if there was no feedback for a given rubric (and the rubric has a decrement score impact), we need to add to the total
        var noFeedback = rubricPoints.filter(r=> feedback.find(f=>r.RubricIds.indexOf(f.RubricId) === -1 && r.ScoreImpact === RubricScoreImpact.Decrement));

        for(let nf of noFeedback) {
            groupedResults.set(nf.Id, nf.Value);
        }

        for(let f of feedback)
        {
            let rubricId = f.RubricId;

            //find the existing rubric in the dictionary, add if it's not there
            var foundPoints = rubricPoints.filter(r=> r.RubricIds.indexOf(rubricId) !== -1)[0];

            if (foundPoints == null)
            {
                foundPoints = new RubricPoints( 
                { 
                    Id: "default", 
                    RubricIds: [],
                    Value: 0, 
                    Min: 0, 
                    Max: Number.MAX_VALUE, 
                    ScoreImpact: RubricScoreImpact.Increment 
                });
            }

            var scoreSubtotal = 0;

            if (!groupedResults.has(foundPoints.Id))
            {
                //store the id and default points
                groupedResults.set(foundPoints.Id, foundPoints.Value);                
            }
            scoreSubtotal = groupedResults.get(foundPoints.Id)!;
            
            switch (foundPoints.ScoreImpact)
            {
                case RubricScoreImpact.Increment:
                    {
                        scoreSubtotal += f.Weight;
                        break;
                    }
                case RubricScoreImpact.Decrement:
                    {
                        scoreSubtotal -= f.Weight;
                        break;
                    }
            }

            groupedResults.set(foundPoints.Id, scoreSubtotal < foundPoints.Min ?
                foundPoints.Min :
                    scoreSubtotal > foundPoints.Max ?
                        foundPoints.Max :
                            scoreSubtotal);            
        }

        var total = 0;
        groupedResults.forEach((value) => total = total + value);
        return Math.round(total * 100);
    };
}