import React, { FC, useEffect, useState } from 'react';
import { CommandBar, DefaultButton, Selection, Dialog, DialogFooter, IColumn, Panel, PanelType, PrimaryButton, SelectionMode, Stack, DialogType, CheckboxVisibility, Text, ICommandBarItemProps, TextField, Modal, getTheme, mergeStyleSets, FontWeights, ActionButton } from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { postDeleteUser, getMembers, getAllPrograms, getUserInvitationLink, postUpdatedInvitation, postUpdateUser, postUserInvitation, resendUserInvitation, changeMembershipStatus, downloadTenantUsers, findMember } from '../ApiService';
import { Membership, MembershipStatus } from '../model/Membership';
import { UserProfile, UserRoles, InvitationStatus } from '../model/UserProfile';
import { UserInvitation, UserInvitationData } from '../model/UserInvitation';
import UserEditor, { CurrentState } from './UserEditor';
import Loader from './Loader';
import List, { IDocument } from './List';
import { Program } from '../model/Program';
import { DateTime } from 'luxon';
import { GetIssuerString } from '../Display';
import FileDownloader from './FileDownloader';
import AutoCompleteSearchBox, { IAutoCompleteSuggestion } from './AutoCompleteSearchBox';

interface ITableRow extends IDocument {
    key: string;
    students: IStudentProgramsCombo[];
    contacts: UserProfile[];
    status: MembershipStatus;
    joined: Date; 
    invited?: Date;
    lastVisit?: Date;
}

interface IStudentProgramsCombo {
  StudentName: string;
  Programs: string[];
}
  
const UserManagement : FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false);
    const [isInviteOpen, { setTrue: openInvitePanel, setFalse: dismissInvitePanel }] = useBoolean(false);
    // const [isBulkInviteOpen, { setTrue: openBulkInvitePanel, setFalse: dismissBulkInvitePanel }] = useBoolean(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [, setAllItems] = useState<ITableRow[]>();
  const [programs, setPrograms] = useState<Program[]>();
    
  const [memberships, setMemberships] = useState<Membership[]>();
  const [totalMembers, setTotalMembers] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [count, ] = useState<number>(50);
    const [selectedMembership, setSelectedMembership] = useState<Membership>();
    const [isTableLocked, setIsTableLocked] = useState<boolean>(false);
    const [selection, setSelection] = useState<Selection>(new Selection());
    const [pendingInvitation, setPendingInvitation] = useState<UserInvitation>();
    const [canSendInvitation, setCanSendInvitation] = useState<boolean>(false);
    const [sendingInvitation, setSendingInvitation] = useState<boolean>(false);

    const [isDownloadModalOpen, { setTrue: showDownloadModal, setFalse: hideDownloadModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
    
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };
    
    const modalProps = React.useMemo(
        () => ({
          titleAriaId: labelId,
          subtitleAriaId: subTextId,
          isBlocking: false,
          styles: dialogStyles
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps  
        [labelId, subTextId],
      );

    const [columns, ] = useState<IColumn[]>([
      {
        key: 'column1',
        name: 'Contacts',
        fieldName: 'contacts',
        minWidth: 75,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item:ITableRow)=> {
          return <span>
            {item.contacts.map((c,idx) => {
            return <Stack key={idx}>
                <Text>{c.DisplayName}</Text>
                {c.Email?.length > 0 ? <Text variant='smallPlus'>{c.Email} (via {GetIssuerString(c.Issuer)})</Text> : null}
                {c.Roles.indexOf(UserRoles.Admin) !== -1 || c.Roles.indexOf(UserRoles.Reviewer) !== -1 ? <Text variant='small'>{c.Roles.filter(r=>r !== "Viewer").join(", ")}</Text> : null}
                {item.contacts.length > 1 ? <br /> : null}
            </Stack>
            })
          }
          </span>       
        }
      },
    {
      key: 'column2',
      name: 'Students',
      fieldName: 'students',
      minWidth: 75,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item:ITableRow)=> {
        return <span>
          {item.students.map((s,idx) => {
          return <Stack key={idx}>
              <Text>{s.StudentName}</Text>
              <Text variant='small'>{s.Programs.join(", ")}</Text>
              {item.students.length > 1 ? <div style={{marginTop:4}}></div> : null}
          </Stack>
          })
        }
        </span>       
      }
    },
      
        {
            key: 'column3',
            name: 'Status',
            fieldName: 'status',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isCollapsible: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'number',
            isPadded: true,
            onRender: (item: ITableRow) => {
              return <span>{_GetStatusString(item.status)}</span>;
            }
      },   
      {
        key: 'column4a',
        name: 'Invited',
        fieldName: 'invited',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'date',   
        isPadded: true,
        onRender: (item: ITableRow) => {
          return <span>{item.invited === undefined ? '' : item.invited.toDateString()}</span>;
        }
      },
        {
          key: 'column4',
          name: 'Joined',
          fieldName: 'joined',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'date',   
          isPadded: true,
          onRender: (item: ITableRow) => {
            return <span>{item.joined.toDateString()}</span>;
          }
        },
        {
          key: 'column5',
          name: 'Last Visit',
          fieldName: 'lastVisit',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'date',   
          isPadded: true,
          onRender: (item: ITableRow) => {
            return <span>{item.lastVisit === undefined ? '' : item.lastVisit.toLocaleString()}</span>;
          }
        }
      ]);

      const getMembershipStatus = (invitationStatus: InvitationStatus) : MembershipStatus =>  {

        switch(invitationStatus) {
          case InvitationStatus.Pending:
            return MembershipStatus.Invited;
          case InvitationStatus.Expired:
            return MembershipStatus.InvitationExpired
        }

        return MembershipStatus.Active;
      }

      useEffect(()=> {
        if(memberships === undefined) {
          return;
        }

        var tableRows = buildTableRows(programs!, memberships, undefined);                
        setItems(tableRows);
        setAllItems(tableRows);
        // eslint-disable-next-line react-hooks/exhaustive-deps     
      },[memberships]);

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

      const fetchData = async () => {
        if (inProgress === "none" && account) {
          var membersResult = await getMembers(instance, account, offset, 40);
          var programs = await getAllPrograms(instance, account);

          setPrograms(programs);
              
          setMemberships(memberships === undefined ?
            membersResult.Memberships! :
            [...memberships, ...membersResult.Memberships]);
              
          setOffset(membersResult.Offset);
          setTotalMembers(membersResult.TotalMembers);

          setIsLoaded(true);
        }
      }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const _GetStatusString = (status: MembershipStatus) : string => {
      switch(status) {
        case MembershipStatus.Active:
          return "Active";
        case MembershipStatus.Canceled:
          return "Canceled";
        case MembershipStatus.Trial:
          return "Trial";
        case MembershipStatus.Invited:
          return "Invited";
        case MembershipStatus.InvitationExpired:
          return "Invitation Expired";
      }
    }

  const [hideDeleteUserDialog, { toggle: toggleDeleteUserDialog }] = useBoolean(true);
  const [hideCancelMembershipDialog, { toggle: toggleCancelMembershipDialog }] = useBoolean(true);
  const [hideResendInvitationDialog, { toggle: toggleResendInvitationDialog }] = useBoolean(true);
  
    const deleteUserDialogContentProps = {
        type: DialogType.normal,
        title: 'Confirm Deletion',
        closeButtonAriaLabel: 'Close',
        subText: `Are you sure you want to delete the invitation to ${selectedMembership?.Users![0].DisplayName}?`,
  };
  
  const cancelMembershipContentProps = {
    type: DialogType.normal,
    title: 'Confirm Cancellation',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to cancel the membership for ${selectedMembership?.Users![0].DisplayName}?`,
  };

  const resendInvitationContentProps = {
    type: DialogType.normal,
    title: 'Confirm Resend',
    closeButtonAriaLabel: 'Close',
    subText: `Are you sure you want to resend the invitation to ${selectedMembership?.Users![0].DisplayName}?`,
  };

  const onActiveItemChanged = (item?: ITableRow, index?: number) => {
    if (memberships === undefined) {
      return;
    }
        
    for (let m of memberships) {
      let currentMembership: Membership = m;

      currentMembership.Users?.forEach((u) => {
        var prefix = '';
            
        if (u.Id?.length > 0) {
          prefix = u.Id;
        }
        else {
          prefix = u.DisplayName
        }

        if (item?.key === `${prefix}-${currentMembership.Id}`) {

          //strip other users from the membership before saving to allow for editing later
          var proxyMembership = new Membership({
            Created: currentMembership.Created,
            Modified: currentMembership.Modified,
            Invited: currentMembership.Invited,
            Id: currentMembership.Id,
            Status: currentMembership.Status,
            Students: currentMembership.Students,
            Users: [u],
            TrainingFacilityTitle: currentMembership.TrainingFacilityTitle,
            TrainingFacilityId: currentMembership.TrainingFacilityId
          });
          
          setSelectedMembership(proxyMembership);
        }
      });
    }
  }

    const onItemDeleted = (deletedMembership: Membership) => {
      
      var idx = memberships?.indexOf(deletedMembership);
      
      if(idx !== undefined) {       
        memberships?.splice(idx, 1);

        var tableRows = buildTableRows(programs!, memberships!, undefined);                
        setItems(tableRows);
      }
    }

    const onDeleteUser = async () => {
        setIsTableLocked(true);
        
        var success = await postDeleteUser(instance, account!, selectedMembership!.Users![0].DisplayName);
        
        if(!success) {
            alert("Sorry, something went wrong. Please try again.");
        }
        else {
          onItemDeleted(selectedMembership!);
        }

        setIsTableLocked(false);
        
        toggleDeleteUserDialog();

        return true;
  }
  
  const onCancelMembership = async () => {
    setIsTableLocked(true);
    await changeStatus(MembershipStatus.Canceled);
    setIsTableLocked(false);
  }

    
    const onUserEditorStateChange = (currentState: CurrentState, userInvitation: UserInvitation) => {
      setPendingInvitation(userInvitation);
      
      //only allowed to submit when they are in viewer role and have 1 or more student, otherwise it's ok to have no students
      var isViewerOnly = userInvitation.Roles.length === 1 && userInvitation.Roles[0] === UserRoles.Viewer;
      
      if(isViewerOnly && userInvitation.Students.length === 0) {
        setCanSendInvitation(false);
        return;
      }

      setCanSendInvitation(currentState === CurrentState.CanSubmit);
    }

    const updateUser = async () => {
      setSendingInvitation(true);

      var result = await postUpdateUser(instance, account!, selectedMembership!.Users![0].Id, pendingInvitation!);

      if(result?.ok) {
          //update the local copy in the list
          selectedMembership!.Users![0].Roles = pendingInvitation!.Roles;
          selectedMembership!.Students = pendingInvitation!.Students;

          setMemberships([...memberships!]);          

          dismissEditPanel();
      }
      else {          
        alert('Sorry, something went wrong. Please try again.')
      }

      setSendingInvitation(false);
    }
    
    const doInvitationUpdate = async () => {
      setSendingInvitation(true);
      
      //if the email address has not changed, just need to update the membership
      (pendingInvitation as any).Email = pendingInvitation?.Emails[0];
      
      var result = await postUpdatedInvitation(instance, account!, selectedMembership!.Users![0].DisplayName, pendingInvitation!);

      if(result?.ok) {
          dismissInvitePanel();
      }
      else {          
        alert('Sorry, something went wrong. Please try again.')
      }

      setSendingInvitation(false);
    }

    const sendInvitation = async () => {
      setSendingInvitation(true);

      var result = await postUserInvitation(instance, account!, pendingInvitation!);

      setSendingInvitation(false);
      
      if(result?.ok) {
        dismissInvitePanel();
      }
      else {        
        alert('Sorry, something went wrong. Please try again.')
      }
    }

    const [invitationLink, setInvitationLink] = useState<UserInvitationData>();
    const [isFetchingLink, setIsFetchingLink] = useState<boolean>(false);
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const getInvitationLink = async () => {
      setCopyLinkText("Copy link");
      setIsFetchingLink(true);
      showModal();
      var data = await getUserInvitationLink(instance, account!, selectedMembership!.Users![0].DisplayName);
      setInvitationLink(data);
      setIsFetchingLink(false);
    }

    const resendInvitation = async () => {      
      var result = await resendUserInvitation(instance, account!, selectedMembership!.Id);

      if(result) {
        alert('Invitation successfully resent');
      }
      else {
        alert('Sorry, something went wrong.');
      }
    }

    const changeStatus = async (newStatus: MembershipStatus) => {
      if(selectedMembership === undefined) {
        return;
      }

      var result = await changeMembershipStatus(instance, account!, newStatus, [selectedMembership.Id]);

      if(result) {
        var tableRow = items?.find(i=>i.key === `${selectedMembership.Users![0].Id}-${selectedMembership.Id}`);
        tableRow!.status = newStatus;
        setItems([...items!]);
      }
    }

    const farItems: ICommandBarItemProps[] = [{      
      key: 'download',
      text: 'Download', 
      disabled: false,//selection.getSelectedCount() !== 1,
      cacheKey: 'download',
      iconProps: { iconName: 'CloudDownload' },            
      onClick: ()=> showDownloadModal()      
  }]

    const userCommandBarItems:  ICommandBarItemProps[] = [{
      key: 'new',
      text: 'New', 
      disabled: false,//selection.getSelectedCount() !== 1,
      cacheKey: 'new',
      iconProps: { iconName: 'AddFriend' },            
      onClick: ()=> openInvitePanel(),     
    },
    //   {
    //   key: 'bulk',
    //   text: 'Bulk Import', 
    //   disabled: false,
    //   cacheKey: 'bulk',
    //   iconProps: { iconName: 'BulkUpload' },            
    //   onClick: ()=> openBulkInvitePanel(),
    // },
    {
      key: 'edit',
      text: 'Edit', 
      cacheKey: 'edit',
      disabled: selection.getSelectedCount() !== 1,
      iconProps: { iconName: 'Edit' },            
      onClick: ()=> openEditPanel()      
    },
    {
      key: 'changestatus',
      text: 'Change Status', 
      disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Users === undefined,
      cacheKey: 'changestatus',
      iconProps:{iconName:'ChangeEntitlements'},
      subMenuProps: {
        items: [
          {
            key: 'active',
            text: 'Active', 
            disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Status === MembershipStatus.Active,
            cacheKey: 'active',
            iconProps: { iconName: 'Contact' },            
            onClick: ()=> {changeStatus(MembershipStatus.Active)}
          },
          {
            key: 'disabled',
            text: 'Canceled', 
            disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined ||  selectedMembership.Status === MembershipStatus.Canceled,
            cacheKey: 'disabled',
            iconProps: { iconName: 'BlockContact' },            
            onClick: ()=> {changeStatus(MembershipStatus.Canceled)}
          }
        ]
      }
    },
    {
      key: 'delete',
      text: 'Delete', 
      cacheKey: 'delete',
      disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Users === undefined || selectedMembership.Users[0].InvitationStatus !== InvitationStatus.Expired,
      iconProps: { iconName: 'Delete' },            
      onClick: ()=> toggleDeleteUserDialog(),
      
    },        
    {
      key: 'manageinvitation',
      text: 'Manage Invitation', 
      disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Users === undefined || selectedMembership.Users[0].InvitationStatus === InvitationStatus.Accepted,
      cacheKey: 'manageinvitation',
      iconProps:{iconName:'WorkforceManagement'},
      subMenuProps: {
        items: [
          {
            key: 'resendinvitation',
            text: 'Resend Invitation', 
            disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Users === undefined || selectedMembership.Users[0].InvitationStatus !== InvitationStatus.Expired,
            cacheKey: 'resendinvitation',
            iconProps: { iconName: 'Mail' },            
            onClick: ()=> {resendInvitation()}
          },
          {
            key: 'copyinvitationlink',
            text: 'Copy Link', 
            disabled: selection.getSelectedCount() !== 1 || selectedMembership === undefined || selectedMembership.Users === undefined || selectedMembership.Users[0].InvitationStatus !== InvitationStatus.Pending,
            cacheKey: 'copyinvitationlink',
            iconProps: { iconName: 'Copy' },            
            onClick: ()=> {getInvitationLink()}
          }
        ]
      }
    }];

    const doSearch = async (n: string): Promise<IAutoCompleteSuggestion[]>  => {
      var result = await findMember(instance, account!, n!);

      var toReturn: IAutoCompleteSuggestion[] = [];
      
      for (let m of result) {
        toReturn.push({
          id: m.Id,
          title: m.Users.map(u => u.DisplayName === u.Email ? u.DisplayName : `${u.DisplayName}`).join(', '),
          subtitle: m.Users.map(u=>u.Email).join(', '),
          extraData: m.Students.map(s => `${s.FirstName} ${s.LastName}`).join(', ')
        });
      }
      
      return toReturn;
      // var map = result.map((m) => {
      //     value: 'a',
      //     id: m.Id
      // } as IAutoCompleteSuggestion);

      // if(n === undefined || n.length === 0) {
      //   //rebuild the default list
      //   var defaultList = buildTableRows(programs!, memberships!);
      //   setItems(defaultList);
      //   return;
      // }

      // var filtered = allItems?.filter(i => i.contacts.some(u => u.DisplayName.toLowerCase().indexOf(n.toLowerCase()) !== -1) || i.students.some(s=>s.StudentName.toLowerCase().indexOf(n.toLowerCase()) !== -1));
      // setItems([...filtered!]);
    }

    const buildTableRows = (programs: Program[], memberships: Membership[], filter?: string | undefined) => {
      var tableRows = new Array<ITableRow>();
          
      for(let membership of memberships) {
        if(membership.Users !== undefined) {
          for(let u of membership.Users) {
              let title = `${u.DisplayName} (${membership.Students.map(s=> s.FirstName).join(', ')})`;
    
              var studentProgramsCombo : IStudentProgramsCombo[] = [];
    
              for(let student of membership.Students) {
                
                studentProgramsCombo.push({
                  StudentName: student.FirstName,
                  Programs: student.ProgramIds.map((pId)=> programs.find(p=> pId === p.Id)!.Name)
                });
              }
    
              tableRows.push({
                key: `${u.Id?.length > 0 ? u.Id : u.DisplayName}-${membership.Id}`,
                joined: new Date(membership.Created),
                lastVisit: u.LastVisit === undefined ? undefined : new Date(u.LastVisit),
                invited: membership.Invited === undefined ? undefined : new Date(membership.Invited),
                status: membership.Status === MembershipStatus.Invited && u.InvitationStatus !== undefined ? getMembershipStatus(u.InvitationStatus) : membership.Status,
                students: studentProgramsCombo,
                contacts: [u],
                getTitle: ()=> title
              });
            }
          }
      }

      return tableRows;
  }

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  
  const loadMore = async () => {
    setIsLoadingMore(true);

    var membersResult = await getMembers(instance, account!, offset, count);
    setMemberships([...memberships!, ...membersResult.Memberships]);
    setOffset(membersResult.Offset);
    setTotalMembers(membersResult.TotalMembers);
    
    setIsLoadingMore(false);
  }

  const renderSuggestion = (suggestion: IAutoCompleteSuggestion): JSX.Element => {

    const membership = memberships?.find(m => m.Id === suggestion.id);

    return (
      <div>
        <Stack tokens={{ childrenGap: 5 }}>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Stack.Item align='center'>
              <Text variant='medium'>{suggestion.title}</Text>
            </Stack.Item>
            <Stack.Item align='center'>
              <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => { setSelectedMembership(membership); openEditPanel(); }}></ActionButton>
              {membership?.Status === MembershipStatus.Invited &&
                <>
                <ActionButton iconProps={{ iconName: 'Mail' }} onClick={() => { setSelectedMembership(membership); toggleResendInvitationDialog(); }} />
                <ActionButton iconProps={{ iconName: 'Copy' }} onClick={() => { setSelectedMembership(membership); getInvitationLink(); }} />
                </>}
              {membership?.Status === MembershipStatus.Active && <ActionButton iconProps={{ iconName: 'BlockContact' }} onClick={() => { setSelectedMembership(membership); changeStatus(MembershipStatus.Canceled); }}></ActionButton>}
              {membership?.Status === MembershipStatus.Canceled && <ActionButton iconProps={{ iconName: 'Contact' }} onClick={() => { setSelectedMembership(membership); toggleCancelMembershipDialog(); }}></ActionButton>}
            </Stack.Item>
          </Stack>
          <Text variant='smallPlus'>{suggestion.subtitle}</Text>
          <Text variant='small'>{suggestion.extraData}</Text>
        </Stack>
      </div>
    )
  }

  const [copyLinkText, setCopyLinkText] = useState<string>('Copy link');  

    return (
        <>
        {isLoaded ?
          items !== undefined ?
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack.Item align='end'>
                <AutoCompleteSearchBox
                  placeholder="Find users"
                  getSuggestions={doSearch}
                  onRenderSuggestion={renderSuggestion}
                />
              </Stack.Item>
              <CommandBar
                items={userCommandBarItems}
                farItems={farItems}
                ariaLabel="Use left and right arrow keys to navigate between commands" />
              <Text variant='mediumPlus'>Recent Activity</Text>
              <List                
                columns={columns}
                isLocked={isTableLocked}
                allowPaging={offset < totalMembers}
                onPageInData={loadMore}
                isPaging={isLoadingMore}
                selectionMode={SelectionMode.single}
                checkboxVisibility={CheckboxVisibility.hidden}
                items={items}
                onActiveItemChanged={onActiveItemChanged}
                onSelectionChanged={(s) => setSelection(s)} />              
            </Stack>
            : null
          :
          <Loader Text='Just a moment...' />
        }
             <Panel
                headerText="Edit"
                type={PanelType.smallFixedFar}
                isOpen={isEditOpen}
                isLightDismiss={true}
                onDismiss={dismissEditPanel}
                closeButtonAriaLabel="Close">
                    <UserEditor 
                      canEditEmail={selectedMembership !== undefined && (selectedMembership.Status !== MembershipStatus.Active || selectedMembership.Users![0].InvitationStatus !== InvitationStatus.Accepted)} 
                      membership={selectedMembership} 
                      onStateChange={onUserEditorStateChange} 
                      disabled={false} />
                    <br />
                  {canSendInvitation ? <PrimaryButton disabled={!canSendInvitation || sendingInvitation} onClick={()=>selectedMembership?.Status !== MembershipStatus.Active ? doInvitationUpdate() : updateUser()}>{selectedMembership?.Status !== MembershipStatus.Active ? "Update Invitation" : "Update User"}</PrimaryButton> : null}
                  {sendingInvitation ? <Loader Text="Just a moment..." /> : null}
              </Panel>

              <Panel
                headerText="Invite"
                type={PanelType.smallFixedFar}
                isOpen={isInviteOpen}
                isLightDismiss={true}
                onDismiss={dismissInvitePanel}
                closeButtonAriaLabel="Close">
                  <UserEditor onStateChange={onUserEditorStateChange} disabled={sendingInvitation} />
                  <br />
                  {canSendInvitation ? <PrimaryButton disabled={!canSendInvitation || sendingInvitation} onClick={()=>sendInvitation()}>Send Invitation</PrimaryButton> : null}
                  {sendingInvitation ? <Loader Text="Just a moment..." /> : null}
        </Panel>
        {/* <Panel
                headerText="Bulk Import"
                type={PanelType.largeFixed}
                isOpen={isBulkInviteOpen}
                isLightDismiss={true}
                onDismiss={dismissBulkInvitePanel}
                closeButtonAriaLabel="Close">
                  <BulkImporter importCompleted={()=>{ dismissBulkInvitePanel(); document.location.reload();}} />
              </Panel>  */}

        <Dialog
            hidden={hideDeleteUserDialog}
            onDismiss={toggleDeleteUserDialog}
            dialogContentProps={deleteUserDialogContentProps}
            modalProps={modalProps}>
            <DialogFooter>
                <PrimaryButton onClick={onDeleteUser} text="Delete" />
                <DefaultButton onClick={toggleDeleteUserDialog} text="Don't delete" />
            </DialogFooter>
        </Dialog>
        
        <Dialog
              hidden={hideCancelMembershipDialog}
              onDismiss={toggleCancelMembershipDialog}
              dialogContentProps={cancelMembershipContentProps}
              modalProps={modalProps}>
              <DialogFooter>
                  <PrimaryButton onClick={onCancelMembership} text="Cancel" />
                  <DefaultButton onClick={toggleCancelMembershipDialog} text="Don't cancel" />
              </DialogFooter>
        </Dialog>
        
        <Dialog
              hidden={hideResendInvitationDialog}
              onDismiss={toggleResendInvitationDialog}
              dialogContentProps={resendInvitationContentProps}
              modalProps={modalProps}>
              <DialogFooter>
                  <PrimaryButton onClick={resendInvitation} text="Send" />
                  <DefaultButton onClick={toggleResendInvitationDialog} text="Don't send" />
              </DialogFooter>
          </Dialog>

                <Modal
                  isOpen={isModalOpen}                  
                  onDismiss={hideModal}>
                  <Stack tokens={{childrenGap:10}} style={{padding:20}}>
                    <Text variant='xLarge'>Copy Invitation Link</Text>
                    {isFetchingLink ? <Loader Text="Getting link..."/> : null }
                    {invitationLink !== undefined ?
                    <>
                      <Text variant='mediumPlus'>This link is intended only for {selectedMembership?.Users![0].DisplayName} and will expire in {Math.round(DateTime.fromJSDate(invitationLink.Expiry).diff(DateTime.utc(), 'hours').hours)} hrs</Text>
                      <TextField readOnly value={invitationLink.Link}></TextField>
                      <PrimaryButton 
                        onClick={() => { navigator.clipboard.writeText(invitationLink.Link); setCopyLinkText('Link copied');}} 
                        disabled={copyLinkText === 'Link copied'}
                        text={copyLinkText} />
                    </>
                    : null}
                  </Stack>
                </Modal>

                <Modal
                  isOpen={isDownloadModalOpen}
                  onDismiss={hideDownloadModal}            
                  containerClassName={contentStyles.container}>
                <FileDownloader 
                        loadingMessage='Preparing export, just a moment...'
                        startDownload={()=> downloadTenantUsers(instance, account!)} />
              </Modal>
        </>
        
    )
}

export default UserManagement;