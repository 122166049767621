import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { useParams } from "react-router-dom";
import { getTournamentStatus } from "../../ApiService";
import { IStatusRow, PreferredWidth, TournamentStatus } from "../../model/TournamentStatus";
import { Stack, Text } from '@fluentui/react';
import TimeDisplay from "../../components/TimeDisplay";
import { useMediaQuery } from 'react-responsive';
import { ErrorIcon } from "@fluentui/react-icons-mdl2";
import QRCode from "react-qr-code";

//this page requires a magic code to render (user is unauthenticated, token access only)

interface IPage {
    page: number;
    rows: IStatusRow[];
}

const Hub = () => {
    let { appToken } = useParams<{appToken: string }>() as {appToken: string };
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [status, setStatus] = useState<TournamentStatus>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pages, setPages] = useState<IPage[]>();
    const [errorCode, setErrorCode] = useState<number>();
    const [gridTemplateColumns, setGridTemplateColumns] = useState<string>();
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });    

    const getMaxViewportRows = (): number => {
        const headerHeight = 50;
        const footerHeight = 180;
        const viewportHeight = (window.visualViewport?.height || window.innerHeight) - headerHeight - footerHeight;                

        const rowHeight = 90;
        const numberOfRows = Math.floor(viewportHeight / rowHeight);

        return numberOfRows;
    }

    useEffect(() => {
        
        const fetchAsync = async () => {
            try {
                var result = await getTournamentStatus(appToken);             
            
                if(typeof result == 'number') {
                    setErrorCode(result);
                    return;
                }
    
                document.title = `${result.Title} - Status`;

                let maxViewportRows = getMaxViewportRows();

                //calculate number of pages
                let numPages = Math.ceil(result.Rows.length / maxViewportRows);
                let pages: IPage[] = [];

                for (let i = 0; i < numPages; i++) {
                    let pageRows = result.Rows.slice(i * maxViewportRows, (i + 1) * maxViewportRows);
                    pages.push({ page: i + 1, rows: pageRows });
                }
                
                setPages(pages);

                var gtc = '';                

                for(let c of result.ColumnHeaders.filter((v,i)=> i !== 1)) {
                    gtc += c.PreferredWidth === PreferredWidth.Narrow ? "1fr " : "2fr ";
                }
    
                setGridTemplateColumns(gtc);
                setStatus(result);
                setCurrentPage(1);
                setErrorCode(undefined);

                setIsLoading(false);    
            }
            catch {
                setErrorCode(404);
            }
        }

        if(appToken !== undefined) {
            fetchAsync();

            const fetchInterval = setInterval(() => fetchAsync(), 60000);
            return () => clearInterval(fetchInterval);
        }
    }, [appToken]);
    

    useEffect(() => {
        if (pages === undefined) return;
        if (pages.length === 0 || pages.length === 1) return;

        const pageInterval = setInterval(() => {
            if (pages !== undefined) {
                setCurrentPage((currentPage + 1) % (pages.length + 1) || 1);
            }
        }, 15000);
        return () => clearInterval(pageInterval);
    }, [currentPage, pages]);

    const RenderPhone = () => <div style={{
        padding: 20
    }}>
        <Stack>
            <Text variant='smallPlus'>{status?.Title}</Text>
            {errorCode !== undefined ?
                <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}>
                    <ErrorIcon style={{ marginLeft: 10 }} />
                    <Text style={{color:'red', opacity:0.8}} variant="mediumPlus">Connection interrupted</Text>
                </Stack> : null}
        </Stack>
        
        <br/><br/>
        {status?.Rows.map((r,idx) => 
            <Stack key={idx}>
                {r.Values.map((v,idx2) => <Text key={idx2} style={{fontWeight: idx2 === 0 ? 'bold' : 'normal'}} nowrap block>{v.Value}</Text>)}
                <br />
            </Stack>)}
    </div>

    const RenderFooter = () =>
        <div style={{ position: 'fixed', bottom: '40px', width: '95vw', marginLeft:20 }}>    
        <Stack horizontal horizontalAlign="space-between" verticalAlign="baseline">
                {status && status?.Link &&
                    <Stack horizontalAlign="center">
                        <div style={{ background: 'white', padding: '16px', maxWidth: 100 }}>
                            <div style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: 128,
                                width: "100%",
                        }}>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={status?.Link}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                        </div>
                        <Text style={{ color: 'white', marginLeft:'auto', marginRight:'auto', textAlign:'center'}} variant='large'>View on your phone</Text>
                    </Stack>
            }
            <div>
                {pages && pages.length > 1 && <Text variant='large' style={{ color: 'white', textAlign: 'center', display: 'block' }}>Page {currentPage} of {pages?.length}</Text>}
                {errorCode !== undefined ? <>
                    <br />
                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <ErrorIcon style={{ marginLeft: 10 }} />
                        <Text style={{ color: 'white' }}>Connection interrupted</Text>
                    </Stack>
                </> : null}
            </div>
            <div style={{ fontSize: 200, color: 'white' }}>
                <TimeDisplay showHours showMinutes />
                </div> 
            </Stack>        
        </div>

    const RenderHeader = () =>
        <div style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign:'center'
        }}>
            <Text style={{ color: "white" }} variant='xLargePlus'>{status?.Title}</Text>
        </div>
    
    const RenderBigscreen = () =>
        <div style={{
            padding:20, 
            display:'flex',
            alignItems:'center',
            flexDirection: 'column',
            overflow:'hidden',
            gap:40}}>                                  
        <div style={{
            display: 'grid',
            width: '95vw',
            gridAutoRows: 90,
            gridTemplateColumns: gridTemplateColumns,
            justifyContent: 'space-evenly'
        }}>                                                           
                {pages?.find(p=>p.page === currentPage)?.rows.map((sr, rowIndex) => sr.Values.filter((v, i) => i !== 1).map((r, idx3) =>                                
                <div
                    key={idx3}
                    style={{
                        borderTopStyle: 'solid',
                        borderTop: 1,
                        borderBottom: 1,
                        borderBottomStyle: 'solid',
                        borderLeft: 0,
                        paddingLeft: idx3 === 0 ? 20 : 'initial',
                        borderRight: 0,
                        borderColor: 'RGBA(255,255,255,1)',
                        backgroundColor: rowIndex % 2 === 0 ? 'RGBA(73,73,169,0.2)' : 'initial',
                        display: 'flex',
                    }}>
                    <Text
                        nowrap
                        block
                        style={{
                            paddingRight: 10,
                            paddingLeft: idx3 === 2 ? 30 : 0,
                            fontSize: 30,
                            color: 'white',
                            marginTop: 'auto',
                            marginBottom:'auto'
                        }}  variant='large'>{r.Value}</Text>
                </div>
            ))}
        </div>            
</div> 

    return (
        <div
            style={{
                backgroundColor: isMobile ? 'initial' : '#1f2e47',
                color: isMobile ? 'initial' : 'white',
                height: isMobile ? 'initial' : '97vh',
                scrollbarWidth: isMobile ? 'initial' : "none",
                overflowY: isMobile ? 'initial' : "hidden"
            }}>
            {isLoading ?
                <Loader Text="Just a moment" />
                :
                isMobile ?
                    <RenderPhone /> :
                    <div style={{height:'100vh'}}>
                        <RenderHeader />
                        <RenderBigscreen />
                        <RenderFooter />
                    </div>
            }
        </div>
    );
}

export default Hub;