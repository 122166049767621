export interface ITenantEnvironment {
    Id: string;
    Title: string;
    Features: string[];
    StorageQuota: number;
    StorageQuotaUsed: number;
    PaymentGatewayPublicToken: string;
}

export class TenantEnvironment implements ITenantEnvironment {
    Id: string;
    Title: string;
    Features: string[];
    StorageQuota: number;
    StorageQuotaUsed: number;
    PaymentGatewayPublicToken: string;

    constructor(
        params: ITenantEnvironment = {} as ITenantEnvironment) {
            
        let {
            Id = "",
            Title = "",   
            Features = [],
            StorageQuota = 0,
            StorageQuotaUsed = 0,
            PaymentGatewayPublicToken = ''
        } = params;
            
        this.Id = Id;
        this.Title = Title;    
        this.Features = Features;    
        this.StorageQuota = StorageQuota;
        this.StorageQuotaUsed = StorageQuotaUsed;
        this.PaymentGatewayPublicToken = PaymentGatewayPublicToken;
    }
}