import { ILandingPageTournament } from "./TenantLandingPageViewModel";

export interface ITournamentLandingPageViewModel {
    CanJoin: boolean;
    Tournament: ILandingPageTournament;    
    Events: ITournamentLandingPageEvent[];
}

export interface ITournamentLandingPageEvent {
    ImageUri: string;
    Title: string;
}

export class TournamentLandingPageViewModel implements ITournamentLandingPageViewModel {    
    CanJoin: boolean;
    Tournament: ILandingPageTournament;
    Events: ITournamentLandingPageEvent[];
    
    constructor(params: ITournamentLandingPageViewModel = {} as ITournamentLandingPageViewModel) {
            
        let {
            Events = [],
            CanJoin = false,
            Tournament = {} as any,
        } = params;
            
        this.Events = Events;
        this.CanJoin = CanJoin;
        this.Tournament = Tournament;
    }
}