import { RubricType } from "./Rubric";

export enum RubricScoreImpact {
    Increment = 0,
    Decrement = 1
}

export interface IRubricPoints {
    Id: string;
    RubricIds: string[];
    /** * @deprecated The field should not be used */ 
    Type?: RubricType;
    Value: number;
    Min: number;
    Max: number;
    ScoreImpact: RubricScoreImpact;
}

export class RubricPoints implements IRubricPoints {
    Id: string;
    RubricIds: string[];
    /** * @deprecated The field should not be used */ 
    Type?: RubricType;
    Value: number;
    Min: number;
    Max: number;
    ScoreImpact: RubricScoreImpact;
    
    constructor(
        params: IRubricPoints = {} as IRubricPoints) {
            
        let {
            Id = "",
            RubricIds = [],
            Type = undefined,            
            Value = 0,
            Min = 0,
            Max = 0,
            ScoreImpact = RubricScoreImpact.Increment
        } = params;
            
        this.Id = Id;
        this.RubricIds = RubricIds;
        this.Type = Type;
        this.Value = Value;
        this.Min = Min;
        this.Max = Max;
        this.ScoreImpact = ScoreImpact;
    }
}