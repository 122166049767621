import React, { FC, useEffect, useState } from 'react';
import { ActionButton, FontWeights, getTheme, Label, mergeStyleSets, Modal, PrimaryButton, Text } from '@fluentui/react';
import { IRegistrationOption, Tournament } from '../../model/Tournament';
import TournamentEventEditor, { CurrentState } from '../../pages/Admin/components/TournamentEventEditor';
import { CurriculumItem } from '../../model/CurriculumItem';
import { Category } from '../../model/Category';
import {useBoolean} from '@fluentui/react-hooks';
import { TournamentEvent } from '../../model/TournamentEvent';
import { GetPricingLabel } from '../../Display';
import { CleanId } from '../../Validation';
import { EditIcon } from '@fluentui/react-icons-mdl2';
import { getCurriculum } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../Loader';
import { CatalogItem } from '../../model/CatalogItem';

interface IData {
    tournament: Tournament;
    categories: Category[];
    onValidated: (
        isValid: boolean,
        tournament: Tournament,
        curriculumAdds?: CurriculumItem[],
        curriculumEdits?: CurriculumItem[],
        catalogAdds?: CatalogItem[],
        catalogEdits?:CatalogItem[]) => void;
}

export interface IChangeState {
    curriculum: CurriculumItem;
    tournamentEvent: TournamentEvent;
    pricing: IRegistrationOption;
}

enum PendingChangeType {
    Add,
    Edit,
    Remove
}

const EventsWizard : FC<IData> = ({tournament, categories, onValidated}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    //break out by pending additions, edits, removals
    const [pendingChangeType, setPendingChangeType] = useState<PendingChangeType>();

    const [pendingAdds, setPendingAdds] = useState<IChangeState[]>([]);
    const [pendingEdits, setPendingEdits] = useState<IChangeState[]>([]);
    const [pendingRemovals, setPendingRemovals] = useState<IChangeState[]>([]);

    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    
    const { instance, accounts} = useMsal();    
    const account = useAccount(accounts[0] || {});

    const onStateChange = (
        currentState: CurrentState,
        changes: IChangeState) => {
        
        if (currentState === CurrentState.CanSubmit) {
            switch (pendingChangeType) {
                case PendingChangeType.Add:
                    setPendingAdds(pendingAdds => [...pendingAdds, changes]);
                    break;
                case PendingChangeType.Edit:
                    setPendingEdits(pendingEdits => [...pendingEdits.filter(e=>e.tournamentEvent.EventId === changes.tournamentEvent.EventId), changes]);
                    break;
                case PendingChangeType.Remove:
                    setPendingRemovals(pendingRemovals => [...pendingRemovals, changes]);
                    break;
            }
        }

        setCanSubmit(currentState === CurrentState.CanSubmit);
    }

    useEffect(()=> {        
        if (pendingAdds.length === 0 &&
            pendingEdits.length === 0) {
            onValidated(false, internalTournament, pendingAdds.map(a=>a.curriculum), pendingEdits.map(e=>e.curriculum));
            return;
        }
        
        onValidated(true, internalTournament, pendingAdds.map(a=>a.curriculum), pendingEdits.map(e=>e.curriculum));
    // eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament, pendingAdds, pendingEdits]);

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        minWidth:400,
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const [editEvent, setEditEvent] = useState<TournamentEvent>();
    const [editCurriculum, setEditCurriculum] = useState<CurriculumItem>();

    useEffect(() => {
        if (editEvent === undefined) {
            return;
        }
        const fetchAsync = async () => {
            //download the current curriculum item
            let c = await getCurriculum(instance, account!, editEvent.EventId);
            setEditCurriculum(c);
            setPendingChangeType(PendingChangeType.Edit);
        }

        fetchAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editEvent])
    
    useEffect(() => {
        if (editCurriculum === undefined) {
            return;
        }

        showModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editCurriculum])

    const onSubmitEvent = () => {
        if (pendingAdds.length === 0 &&
            pendingEdits.length === 0 &&
            pendingRemovals.length === 0) {
            return;   
        }

        //adds
        let pricing : IRegistrationOption[] = internalTournament.Pricing;
        let events: TournamentEvent[] = internalTournament.Events;
        
        for (let add of pendingAdds) {
            let id = `${internalTournament.Id}-${CleanId(add.curriculum!.Name)}`;
            add.curriculum.Id = id;
            
            if (add.pricing !== undefined) {
                add.pricing.CurriculumId = id;
                pricing.push(add.pricing);
            }

            add.tournamentEvent.EventId = id;
            events.push(add.tournamentEvent);
        }
        
        //edits
        for (let edit of pendingEdits) {
            //remove the old one and add the pending item in it's place
            events = internalTournament.Events.filter(e => e.EventId !== edit.tournamentEvent.EventId);
            pricing = internalTournament.Pricing.filter(p => p.CurriculumId !== edit.tournamentEvent.EventId);

            pricing.push(edit.pricing);
            events.push(edit.tournamentEvent);            
        }

        //removes
        for (let remove of pendingRemovals) {
            //don't remove pricing strategies, they are shared across events and may be used elsewhere later, even if not currently assigned to an event
            events = internalTournament.Events.filter(e => e.EventId !== remove.tournamentEvent.EventId);
            pricing = internalTournament.Pricing.filter(p => p.CurriculumId !== remove.tournamentEvent.EventId);
        }
        
        setInternalTournament(internalTournament =>
        ({
            ...internalTournament,
            Events: events,
            Pricing: pricing
        }));

        dismissModal();//this also resets the edit event/curriculum to undefined
    } 

    const dismissModal = () => {
        setEditCurriculum(undefined);
        setEditEvent(undefined);
        hideModal();
    }

    const beginEditEvent = (e: TournamentEvent) => {
        setPendingChangeType(PendingChangeType.Edit);
        setEditEvent(e);
    }

    const beginAddEvent = () => {
        setPendingChangeType(PendingChangeType.Add);
        showModal();
    }

    return (
        <div style={{display:'flex', flexDirection:'column', gap:10}}>
            <Label>Events</Label>
            {editEvent === undefined || isModalOpen ?
                <>
                    {pendingAdds.length === 0 && 
                        pendingEdits.length === 0 &&
                    internalTournament.Events.length === 0 ?
                        <Text>No events.</Text> :
                        (pendingAdds.map((e, idx) =>
                            <div key={idx} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                <EditIcon style={{ cursor: 'pointer' }} onClick={() => beginEditEvent(e.tournamentEvent)} />
                                <Text><strong>{e.tournamentEvent.Title}</strong><br />{GetPricingLabel(tournament, e.tournamentEvent)}</Text>
                            </div>
                        ),
                        pendingEdits.map((e, idx2) =>
                            <div key={idx2} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                <EditIcon style={{ cursor: 'pointer' }} onClick={() => beginEditEvent(e.tournamentEvent)} />
                                <Text>{e.tournamentEvent.Title}<br />{GetPricingLabel(tournament, e.tournamentEvent)}</Text>
                            </div>
                            ),
                        pendingRemovals.map((e, idx3) =>
                            <div key={idx3} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                    <Text style={{textDecoration: 'line-through'}}>{e.tournamentEvent.Title}<br />{GetPricingLabel(tournament, e.tournamentEvent)}</Text>
                                </div>
                            ),
                        internalTournament.Events.filter(p=> pendingEdits.find(e=>e.tournamentEvent.EventId === p.EventId) !== null).map((e, idx3) =>
                            <div key={idx3} style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                                <EditIcon style={{ cursor: 'pointer' }} onClick={() => beginEditEvent(e)} />
                                <Text>{e.Title}<br />{GetPricingLabel(tournament, e)}</Text>
                            </div>
                        ))
                    }
                </>
                :
                <Loader Text='Just a moment...' />
            }
            <ActionButton iconProps={{iconName:'Add'}} onClick={beginAddEvent}>Add event</ActionButton>

            <Modal                
                isOpen={isModalOpen}
                isBlocking                   
                containerClassName={contentStyles.container}   
                onDismiss={dismissModal}>
                    <div className={contentStyles.header}>
                    <span>{editCurriculum === undefined ? "Add event" : "Edit event"}</span>
                </div>
                <div className={contentStyles.body}>                
                    <TournamentEventEditor 
                        tournament={internalTournament} 
                        editCurriculumItem={editCurriculum}
                        editTournamentEvent={editEvent}
                        categories={categories} 
                        disabled={false} 
                        onStateChange={onStateChange} />
                    <br />
                    {canSubmit && <PrimaryButton onClick={onSubmitEvent}>Submit</PrimaryButton>}
                </div>  
                
            </Modal>            
            
        </div>
    )
}

export default EventsWizard;