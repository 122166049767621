import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Dropdown, IDropdownOption, Label, PrimaryButton, Stack, Text, Dialog, DialogType, DialogFooter, SpinButton } from '@fluentui/react';
import { getTournament, postRequestToAddEventRegistration, postRequestToChangeEventRegistration } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useMediaQuery } from 'react-responsive';
import { Tournament } from '../../../model/Tournament';
import { useBoolean } from '@fluentui/react-hooks';

interface IData {
    studentId: string;
    tournamentId: string;
    eventIdToRemove?: string;
    eventName?: string;
    customerEmail: string;
    alreadyRegisteredIds: string[];

    onCompleted: () => void;
}

const RegistrationEditor : FC<IData> = ({eventName, eventIdToRemove, customerEmail, alreadyRegisteredIds, tournamentId, studentId, onCompleted}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    const [tournament, setTournament] = useState<Tournament>();

    useEffect(()=> {
        const fetchAsync = async () => {
            var tournament = await getTournament(instance, account!, tournamentId);
            
            if(typeof tournament === 'number') {
                setErrorCode(tournament);
                return;
            }

            setTournament(tournament);
        }

        fetchAsync();        
    },[tournamentId, account, instance]);

    const [eventOptions, setEventOptions] = useState<IDropdownOption[]>([]);
    
    useEffect(()=>{
        if(tournament === undefined) {
            return;
        }

        var options = tournament.Events.filter(f => alreadyRegisteredIds.indexOf(f.EventId) === -1 ? true : false).map((e) => {
            return {
                key: e.EventId,
                text: e.Title,
                id: e.EventId
            }
        });

        setEventOptions(options);
    },[tournament, alreadyRegisteredIds]);    
    
    const [newEvents, setNewEvents] = useState<string[]>([]);
    const [changeFee, setChangeFee] = useState<number>(0);

    const sendInvoice = async () => {        
        if (newEvents.length === 0 ||
            changeFee === undefined) {
            toggleHideDialog();
            return;
        }

        setSendingInvoice(true);

        var result = false;

        if (eventIdToRemove !== undefined) {
            //it's a change
            result = await postRequestToChangeEventRegistration(instance, account!, {
                FromEventId: [eventIdToRemove!],
                Fee: changeFee,
                StudentId: studentId,
                ToEventId: newEvents,
                TournamentId: tournament!.Id
            });
        }
        else {
            //an add
            result = await postRequestToAddEventRegistration(instance, account!, {
                ToEventId: newEvents,
                FromEventId: [],
                Fee: changeFee,
                StudentId: studentId,
                TournamentId: tournament!.Id            
            })
        }

        setSendingInvoice(false);

        if (result) {
            onCompleted();
            return;
        }

        alert('Something went wrong. Please try again later.');
    }

    const [sendingInvoice, setSendingInvoice] = useState<boolean>(false);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Invalid Selection',
        closeButtonAriaLabel: 'Close',
        subText: 'Please enter a change fee and select a new event before proceeding.',
    };

    return (
          <AuthenticatedTemplate>
            {tournament === undefined ? <Loader Text='Just a moment...'/> : 
                <div style={{minWidth:isMobile ? 100 : 400, maxWidth:400}}>
                    <div style={{padding:10}}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            {eventIdToRemove !== undefined && eventName !== undefined &&
                                <>
                                    <Label>Current Event</Label>
                                    <Text>{eventName}</Text>
                                </>}
                            <Label>New Event</Label>
                            <Dropdown 
                                required                                
                                disabled={sendingInvoice}
                                multiSelect={eventIdToRemove !== undefined ? false : true}
                                placeholder='Select the new event'
                                options={eventOptions}
                                onChange={(ev, option) =>
                                    eventIdToRemove === undefined ?
                                        option?.selected ?
                                            setNewEvents([...newEvents, option!.id!]) :
                                            setNewEvents([...newEvents.filter(e => e !== option?.id)]) :
                                            setNewEvents([option!.id!])} />
                            <span>
                                <Label>Fee</Label>
                                {eventIdToRemove === undefined ?
                                    <Text variant='small'>The total amount the customer must pay to add these events</Text> :
                                    <Text variant='small'>The total amount the customer must pay to change events</Text>}
                            </span>
                            <SpinButton
                                placeholder='Enter the change fee'
                                defaultValue={changeFee?.toString()}
                                min={0}
                                max={9999.99}
                                upArrowButtonStyles={{ root: { display: 'none' } }}
                                downArrowButtonStyles={{ root: {display:'none'} }}
                                step={0.01}
                                precision={2}
                                disabled={sendingInvoice}
                                onChange={(ev, newValue) => setChangeFee(parseFloat(newValue!))} />
                            
                            {changeFee === 0 ? <Text style={{ maxWidth: 400, color: 'red' }}>Changes are effective immediately once sent.</Text> : <Text style={{ maxWidth: 400 }}>Changes are only complete once the customer has accepted and paid the invoice.</Text>}
                                                        
                            <br />
                            <PrimaryButton disabled={sendingInvoice} onClick={sendInvoice}>Send Invoice</PrimaryButton>
                            <Text variant='small' style={{textAlign:'center'}}>Invoice will be emailed to: {customerEmail}</Text>
                            {sendingInvoice && <Loader Text="Just a moment..." />}
                        </Stack>
                    </div>
                </div>
            }
            <Dialog
                hidden={hideDialog}
                styles={dialogStyles}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
              >
                <DialogFooter>
                  <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
              </Dialog>
          </AuthenticatedTemplate>
    )
}

export default RegistrationEditor;