import {FC, useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import { Stack, Text } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';

export interface IData {
    targetDate: Date;
    showDays?: boolean;
    showHours?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    timezone?: string;
    completed: ()=>void;
}

const CountdownTimer : FC<IData> = ({targetDate, showDays, showHours, showMinutes, showSeconds, completed, timezone}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [days, setDays] = useState<number>();
    const [hours, setHours] = useState<number>();
    const [minutes, setMinutes] = useState<number>();
    const [seconds, setSeconds] = useState<number>();
    const [done, setDone] = useState<boolean>(false);

    useEffect(()=> {
        updateDelta();
    // eslint-disable-next-line react-hooks/exhaustive-deps   
    }, []);

    const updateDelta = () => {
        var targetDateInUtc : DateTime;

        if (timezone !== undefined) {
            targetDateInUtc = DateTime.fromJSDate(targetDate, { zone: timezone });
        }
        else {
            targetDateInUtc = DateTime.fromJSDate(targetDate, {zone:'utc'});
        }
        
        if(DateTime.utc() >= targetDateInUtc) {
            setDays(0);
            setHours(0);
            setMinutes(0);
            setSeconds(0);

            setDone(true);
            completed();
            return;
        }

        var diff = targetDateInUtc.diff(DateTime.utc(), ['day', 'hour', 'minute', 'second','millisecond']);
        
        setDays(diff.days);
        setHours(diff.hours);
        setMinutes(diff.minutes);
        setSeconds(diff.seconds);
    }

    useEffect(() => {
        if(done) {
            return;
        }

        const id = setInterval(updateDelta, 1000);
        return () => clearInterval(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [targetDate]);

    return (
        <>
            {seconds !== undefined && !done ? 
            <Stack horizontal tokens={{childrenGap: isMobile ? 5 : 20}}>
                {showDays ? 
                <Stack horizontalAlign='center'>
                    <Text variant={isMobile ? 'large' : 'superLarge'}>{days}</Text>
                    <Text variant={isMobile ? 'smallPlus' : 'mediumPlus'}>days</Text>
                </Stack> : null }
                
                {showHours ?                 
                <>
                    {showDays ? <Text variant={isMobile ? 'large' : 'superLarge'} style={{fontWeight:'lighter', opacity:0.3}}>|</Text> : null}
                    <Stack horizontalAlign='center'>
                        <Text variant={isMobile ? 'large' : 'superLarge'}>{hours}</Text>
                        <Text variant={isMobile ? 'smallPlus' : 'mediumPlus'}>hours</Text>
                    </Stack>
                </> : null }
                
                {showMinutes ? 
                <>
                { showHours || showDays ? <Text variant={isMobile ? 'large' : 'superLarge'} style={{fontWeight:'lighter', opacity:0.3}}>|</Text> : null}
                <Stack horizontalAlign='center'>
                    <Text variant={isMobile ? 'large' : 'superLarge'}>{minutes}</Text>
                    <Text variant={isMobile ? 'smallPlus' : 'mediumPlus'}>minutes</Text>
                </Stack></> : null }
                
                {showSeconds ? 
                <>
                {showMinutes || showHours || showDays ? <Text variant={isMobile ? 'large' : 'superLarge'} style={{fontWeight:'lighter', opacity:0.3}}>|</Text> : null}
                <Stack horizontalAlign='center'>
                    <Text variant={isMobile ? 'large' : 'superLarge'}>{seconds}</Text>
                    <Text variant={isMobile ? 'smallPlus' : 'mediumPlus'}>seconds</Text>
                </Stack></> : null }
            </Stack>
            : null}
        </>
    )
}

export default CountdownTimer;