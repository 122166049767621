import { Dropdown, IDropdownOption, Label, PrimaryButton } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { GetGenderString } from '../../../Display';
import { DivisionRuleFieldNames, IDivisionRule } from '../../../model/DivisionRuleGroup';
import { Gender } from '../../../model/Student';

interface IData {
    onRuleCreated: (rule: IDivisionRule) => void;
}
const DivisionRuleCreator : FC<IData> = ({onRuleCreated}) => {
    const [divisionRule, setDivisionRule] = useState<IDivisionRule>({} as IDivisionRule);
    const [isValid, setIsValid] = useState<boolean>(false);

    const fieldOptions : IDropdownOption[] = [
        {
            key: DivisionRuleFieldNames.Gender,
            id: DivisionRuleFieldNames.Gender,
            text: DivisionRuleFieldNames.Gender
        },
        {
            key: DivisionRuleFieldNames.YearOfBirth,
            id: DivisionRuleFieldNames.YearOfBirth,
            text: DivisionRuleFieldNames.YearOfBirth
        },
        {
            key: DivisionRuleFieldNames.LevelOrder,
            id: DivisionRuleFieldNames.LevelOrder,
            text: DivisionRuleFieldNames.LevelOrder
        },
        {
            key: DivisionRuleFieldNames.Weight,
            id: DivisionRuleFieldNames.Weight,
            text: DivisionRuleFieldNames.Weight
        }
    ]

    const genderOptions: IDropdownOption[] = [
        {
            key: Gender.Female,
            id: GetGenderString(Gender.Female),
            text: GetGenderString(Gender.Female)
        },
        {
            key: Gender.Male,
            id: GetGenderString(Gender.Male),
            text: GetGenderString(Gender.Male)
        }
    ]

    useEffect(()=> {
        if(divisionRule === undefined) {
            setIsValid(false);
            return;
        }
        
        //validate the division rule has all values non empty
        if(divisionRule.Field?.length === 0) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    },[divisionRule]);

    const createRule = () => {
        //create the rule and assign the correct Id so that the tournament can reference it

        onRuleCreated(divisionRule);
    }

    return (
        <div style={{display:'flex', flexDirection:'row', gap:10}}>            
                <Dropdown 
                    style={{width:300}}
                    options={fieldOptions} 
                    onChange={(e,n) => setDivisionRule(divisionRule => ({...divisionRule, Field:n!.id!}))}/>
                    
                {divisionRule.Field === DivisionRuleFieldNames.Gender ? 
                    <Dropdown 
                        styles={{dropdownOptionText:{fontSize:16}, title:{fontSize:16}}} 
                        options={genderOptions} 
                        onChange={(e,v) => {}}  /> : null
                }
            
                {divisionRule.Field === DivisionRuleFieldNames.YearOfBirth ?
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <Label>Min</Label>

                        <Label>Max</Label>
                    </div>  : null}

            <PrimaryButton disabled={!isValid}  onClick={createRule}>Create</PrimaryButton>
        </div>
    )
}

export default DivisionRuleCreator;