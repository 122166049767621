import { CurriculumItem } from "./CurriculumItem";
import { ReviewPolicy } from "./ReviewPolicy";
import { Student } from "./Student";
import { StudentCurriculumSubmission } from "./StudentCurriculumSubmission";

export interface IBracketReviewViewModel {    
    Students: Student[];
    Submissions: StudentCurriculumSubmission[];
    Curriculum: CurriculumItem;
    DivisionName: string;
    ReviewPolicy?: ReviewPolicy;
}

export class BracketReviewViewModel implements IBracketReviewViewModel {
    Students: Student[];
    Submissions: StudentCurriculumSubmission[];
    Curriculum: CurriculumItem;
    DivisionName: string;
    ReviewPolicy?: ReviewPolicy;

    constructor(
        params: IBracketReviewViewModel = {} as IBracketReviewViewModel) {
            
        let {
            Students = [],
            Submissions = [],
            Curriculum = new CurriculumItem(),
            DivisionName = '',
            ReviewPolicy = undefined
        } = params;
            
        this.Students = Students;
        this.Submissions = Submissions;
        this.Curriculum = Curriculum;
        this.DivisionName = DivisionName;
        this.ReviewPolicy = ReviewPolicy;
    }
}