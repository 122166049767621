import { ChoiceGroup, Dropdown, IChoiceGroupOption, IDropdownOption, PrimaryButton, TextField, Toggle } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { IUserInput, InputType } from '../../../model/CatalogItem';
import { CleanId } from '../../../Validation';

interface IData {
    editInput?: IUserInput;
    onInputCreated: (userInput: IUserInput) => void;
}
const UserInputCreator : FC<IData> = ({onInputCreated}) => {
    const [userInput, setUserInput] = useState<IUserInput>({} as IUserInput);
    const [isValid, setIsValid] = useState<boolean>(false);

    const inputTypeOptions : IChoiceGroupOption[] = [
        {
            key: InputType.Number.toString(),
            text: 'Number'
        },
        {
            key: InputType.String.toString(),
            text: "Text"
        }
    ]

    useEffect(() => {
        if (userInput === undefined) {
            setIsValid(false);
            return;
        }
        
        if (userInput.BindTo === undefined || userInput.BindTo.length === 0) {
            setIsValid(false);
            return;
        }

        if(userInput.Label === undefined || userInput.Label.length === 0) {
            setIsValid(false);
            return;
        }

        if (userInput.Type === undefined) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    },[userInput]);

    const customBindingOptionKey = 'custom';

    const bindingOptions: IDropdownOption[] = [
        { key: 'height', text: "Height" },
        { key: 'weight', text: "Weight" },
        { key: customBindingOptionKey, text: "Custom Field" }
    ];

    const [showCustomBindingField, setShowCustomBindingField] = useState<boolean>(false);

    const onUpdateBinding = (e: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
        if (option === undefined) {
            return;            
        }

        if (option.key === customBindingOptionKey) {
            setUserInput(userInput => ({ ...userInput, BindTo: '', Label: '' }));
            setShowCustomBindingField(true);
            return;
        }

        setShowCustomBindingField(false);
        setUserInput(userInput => ({ ...userInput, BindTo: option.key.toString(), Label: option.text }));
    }

    const updateLabelAndBinding = (label: string) => {
        if (showCustomBindingField) {
            setUserInput(userInput => ({ ...userInput, BindTo: `x:${CleanId(label)}`, Label: label }));
        }
        else {
            setUserInput(userInput => ({ ...userInput, Label: label }));
        }
    }
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>    
            <ChoiceGroup
                options={inputTypeOptions}
                onChange={(e,n) => setUserInput(userInput => ({...userInput, Type: parseInt(n!.key!)}))} />                

            <Dropdown
                label='Binding'
                style={{ minWidth: 120 }}
                options={bindingOptions}
                defaultSelectedKey={showCustomBindingField ? customBindingOptionKey : userInput.BindTo}
                onChange={onUpdateBinding} />
            
            <TextField label="Display Label" 
                value={userInput.Label} 
                placeholder="The label shown to the user."
                onChange={(e, newValue) => updateLabelAndBinding(newValue!)} />
            
            

            {/* {showCustomBindingField &&
                <TextField
                    label="Binding"
                    defaultValue={userInput.BindTo.startsWith("x:") ? userInput.BindTo.substring(2) : userInput.BindTo}
                    onChange={(e, n) => setUserInput(userInput => ({ ...userInput, BindTo: `x:${n!}` }))} />
            } */}

            <Toggle
                label='Required Field'
                defaultChecked={userInput.IsRequired}
                onChange={(e, checked) => setUserInput(userInput => ({ ...userInput, IsRequired: checked! }))} />

            <PrimaryButton disabled={!isValid}  onClick={() => onInputCreated(userInput)}>Create</PrimaryButton>
        </div>
    )
}

export default UserInputCreator;