import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Dropdown, IDropdownOption, Label, Slider, Stack, TextField } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { getCategories } from '../ApiService';
import { Rubric, RubricType } from '../model/Rubric';

interface IData {
    onModelValidationStatusChanged: (rubric: Rubric, isValid: boolean)  => void;
}

const RubricEditor : FC<IData> = ({onModelValidationStatusChanged}) => { 
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [rubric, setRubric] = useState<Rubric>(new Rubric());
    const [categoryOptions, setCategoryOptions] = useState<IDropdownOption[]>([]);

    const rubricTypeOptions : IDropdownOption[] = [
        { key: RubricType.TimeScoped, text: 'Time Scoped',  },
        { key: RubricType.Global, text: 'Overall' },
        { key: RubricType.Numeric, text: 'Numeric' }   
      ];

    useEffect(()=> {
        if(!isAuthenticated) {
            return;
        }
       
        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var categories = await getCategories(instance, account);
                
                var options : IDropdownOption[] = [];

                categories.forEach((c,idx) => {
                    options.push({
                        key: c.Title,
                        text: c.Title
                    });
                });
        
                setCategoryOptions(options);
            }
        }

        setRubric({...rubric,Weight:0.01});
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {
          
        if(rubric.Title.length === 0) {
            onModelValidationStatusChanged(rubric, false);    
            return;
        }

        if(rubric.Category.length === 0) {
            onModelValidationStatusChanged(rubric, false);
            return;
        }

        if(rubric.Weight <= 0) {
            onModelValidationStatusChanged(rubric, false);
            return;
        }
        
        onModelValidationStatusChanged(rubric, true);
      }

      useEffect(()=> {
        checkModelValidationState();                
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [rubric]);

    const sliderAriaValueText = (value: number) => `${value} percent`;
    const sliderValueFormat = (value: number) => `${value} %`;
     
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>Title</Label>            
                <TextField 
                    inputMode="text"                                               
                    defaultValue={rubric.Title}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setRubric(rubric => ({...rubric, Title: newValue!}))} 
                    onGetErrorMessage={validateFieldNotEmpty}/>                                   
                
                <Label>Category</Label>
                <Dropdown 
                    options={categoryOptions} 
                    onChange={(c: React.FormEvent, option?: IDropdownOption) => setRubric(rubric => ({...rubric, Category: option?.text!}))} />
                
                <Label>Type</Label>
                <Dropdown 
                    defaultSelectedKey={rubric.Type}
                    options={rubricTypeOptions} 
                    onChange={(c: React.FormEvent, option?: IDropdownOption) => setRubric(rubric => ({...rubric, Type: option?.key! as RubricType}))} />
                <br />
                <Slider                         
                    label='Weight'
                    min={1}
                    max={100}
                    defaultValue={rubric.Weight}
                    style={{maxWidth:'300px'}}
                    ariaValueText={sliderAriaValueText}
                    valueFormat={sliderValueFormat}
                    onChanged={(e,v)=>setRubric(rubric => ({...rubric, Weight: v/100}))}
                    showValue />
                <br /><br />
            </Stack>

        </>
    )
}

export default RubricEditor;