import React, { FC, useEffect, useState } from 'react';
import { CommandBar, IColumn, Panel, PanelType, Selection, SelectionMode, Stack, CheckboxVisibility, PrimaryButton} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { createLevel, createProgram, editLevel, getAllLevels, getAllPrograms } from '../ApiService';
import List, { IDocument } from './List';
import Loader from './Loader';
import { Program } from '../model/Program';
import { Level } from '../model/Level';
import LevelEditor from './LevelEditor';
import ProgramEditor from './ProgramEditor';

interface ILevelRow extends IDocument{
    label: string;
    description: string;
    order: number;
    progIds: string;
}

interface IProgramRow extends IDocument{
  title: string;
}

enum ActiveSection  {
  None = 0,
  Programs = 1,
  Levels = 2
}
  
const ProgramManagement : FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isEditOpen, { setTrue: openEditPanel, setFalse: dismissEditPanel }] = useBoolean(false);
    const [isEditingLevel, setIsEditingLevel] = useState<boolean>(false);
    const [programItems, setProgramItems] = useState<IProgramRow[]>([]);
    const [levelItems, setLevelItems] = useState<ILevelRow[]>([]);

    const [programs, setPrograms] = useState<Program[]>();
    const [levels, setLevels] = useState<Level[]>();
    const [selectedLevel, setSelectedLevel] = useState<Level>();

    const [canSaveProgram, setCanSaveProgram] = useState<boolean>(false);
    const [canSaveLevel, setCanSaveLevel] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [pendingProgram, setPendingProgram] = useState<Program>();
    const [pendingLevel, setPendingLevel] = useState<Level>();

    const [selection, setSelection] = useState<Selection>(new Selection());
    const [activeSection, setActiveSection] = useState<ActiveSection>(ActiveSection.None);

    const [programColumns, ] = useState<IColumn[]>([
      {
        key: 'column',
        name: 'Title',
        fieldName: 'title',
        minWidth: 75,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
    ]);

    const [levelColumns, ] = useState<IColumn[]>([
      {
        key: 'column0',
        name: 'Label',
        fieldName: 'label',
        minWidth: 50,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column1',
        name: 'Description',
        fieldName: 'description',
        minWidth: 130,
        maxWidth: 250,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
      },
    {
      key: 'column2',
      name: 'Order',
      fieldName: 'order',
      minWidth: 75,
      maxWidth: 75,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'number',
      isPadded: true,
    },
    {
        key: 'column3',
        name: 'Programs',
        fieldName: 'progIds',
        minWidth: 70,
        maxWidth: 250,      
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
    }        
    ]);
     
      const getProgramsString = (programIds: string[], programs: Program[]) : string => {
        var toReturn : string[] = [];

        for(let programId of programIds) {
            let pId = programId;
            var found = programs.find(p=>p.Id === pId);

            if(found !== undefined) {
                toReturn.push(found.Name);
            }
        }
        
        return toReturn.join(',');
      }

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
                var programs = await getAllPrograms(instance, account);
                var levels = await getAllLevels(instance, account);

                setPrograms(programs);
                setLevels(levels);

                var programRows = new Array<IProgramRow>();
                
                for(let p of programs) {
                    programRows.push(buildTableRow(p));
                }

                setProgramItems(programRows);

                var levelRows = new Array<ILevelRow>();
                
                for(let l of levels) {                      
                    levelRows.push(buildTableRowLevel(l, programs));                    
                }

                setLevelItems(levelRows);

                setIsLoaded(true);
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const _GetHeaderForSection = () => {

        switch(activeSection) {
          case ActiveSection.Levels: {
            return "New Level";
          }
          case ActiveSection.Programs: {
            return "New Program";
          }
        }
  
        return "";
      }

    const onLevelValidationChanged = (level: Level, isValid: boolean) => {
      setPendingLevel(level);
      setCanSaveLevel(isValid);
    }

    const onProgramValidationChanged = (program: Program, isValid: boolean) => {
      setPendingProgram(program);
      setCanSaveProgram(isValid);
    }

    const saveProgram = async () => {
      setIsSaving(true);

      var ok = await createProgram(instance, account!, pendingProgram!);
      setIsSaving(false);

      if(ok) {
        dismissEditPanel();      
        
        programItems.push(buildTableRow(pendingProgram!));
        setProgramItems(programItems);
      }
      else{
        setCanSaveProgram(true);
        alert('Sorry, something went wrong. Please try again.')
      }
    }

    const buildTableRow = (item: Program) : IProgramRow => {
      let title = item.Name;

      return {
          key: item.Id,
          title: title,
          getTitle: () => title
      }; 
    }

    const buildTableRowLevel = (pendingLevel: Level, programs: Program[]) : ILevelRow => {
        let title = pendingLevel.Label;
        let programString = getProgramsString(pendingLevel.ProgramIds, programs);

      return {
        key: pendingLevel.Id,
        label: title,
        description: pendingLevel.Description,
        order: pendingLevel.Order,
        progIds: programString,
        getTitle: () => title
      };
    }

    const saveLevel = async () => {
      if(pendingLevel === undefined) {
        return;
      }

      setIsSaving(true);

      var result = isEditingLevel ?
       await editLevel(instance, account!, pendingLevel) :
       await createLevel(instance, account!, pendingLevel);

      setIsSaving(false);

      if(result?.ok) {
        dismissEditPanel();        
        
        if(isEditingLevel) {
          var foundItem = levelItems.find(l=>l.key === selectedLevel?.Id);

          var idx = levelItems.indexOf(foundItem!);
          levelItems?.splice(idx, 1);          
        }

        levelItems?.push(buildTableRowLevel(pendingLevel, programs!));
        
        setIsEditingLevel(false);
        setSelectedLevel(undefined);
        setLevelItems(levelItems);
      }
      else{
        setCanSaveLevel(true);
        alert('Sorry, something went wrong. Please try again.')
      }
    }

    const onActiveLevelChanged = (item?: any, index?: number) => {        
      var foundItem = levels?.find(i=>i.Id === item.key);
      
      setSelectedLevel(foundItem);
    }

    return (
        <>
            {isLoaded ?
                levels !== undefined ? 
                    <Stack>
                        <h3>Programs</h3>
                        Programs are used to link students to curriculum. A student can be a member of one or more programs.
                        {programs !== undefined ? 
                        <>
                        <br /><br />
                          <div style={{background:'white'}}>
                            <CommandBar   
                                items={[{
                                  key: 'new',
                                  text: 'New', 
                                  disabled: false,//selection.getSelectedCount() !== 1,
                                  cacheKey: 'new',
                                  iconProps: { iconName: 'AddTo' },            
                                  onClick: ()=> { setActiveSection(ActiveSection.Programs); openEditPanel();}    
                                },
                                {
                                    key: 'edit',
                                    text: 'Edit', 
                                    disabled: selection.getSelectedCount() !== 1,
                                    cacheKey: 'edit',
                                    iconProps: { iconName: 'Edit' },            
                                    onClick: ()=> { setActiveSection(ActiveSection.Programs); openEditPanel();}  
                                }]}
                                ariaLabel="Use left and right arrow keys to navigate between commands" />
                              <List 
                                columns={programColumns} 
                                selectionMode={SelectionMode.single}
                                onSelectionChanged={(s)=>setSelection(s)}
                                checkboxVisibility={CheckboxVisibility.onHover}
                                enableSort 
                                items={programItems} />
                          </div><br /></> : null                        
                        }
                       
                        <h3>Levels</h3>
                        Levels are used to organise curriculum by difficulty or chronology. Each student has a level that is expected to increase over time as they progress through the curriculum.
                        <br /><br />
                        <CommandBar   
                                items={[{
                                  key: 'new',
                                  text: 'New', 
                                  disabled: false,//selection.getSelectedCount() !== 1,
                                  cacheKey: 'new',
                                  iconProps: { iconName: 'AddTo' },            
                                  onClick: ()=> { setActiveSection(ActiveSection.Levels); setIsEditingLevel(false); openEditPanel();}
                                },
                                {
                                    key: 'edit',
                                    text: 'Edit', 
                                    disabled: selection.getSelectedCount() !== 1,
                                    cacheKey: 'edit',
                                    iconProps: { iconName: 'Edit' },            
                                    onClick: ()=> { setActiveSection(ActiveSection.Levels); setIsEditingLevel(true); openEditPanel();}
                                }]}
                                ariaLabel="Use left and right arrow keys to navigate between commands" />
                      <List 
                          columns={levelColumns} 
                          selectionMode={SelectionMode.single}
                          checkboxVisibility={CheckboxVisibility.onHover}
                          onSelectionChanged={(s)=>setSelection(s)}
                          onActiveItemChanged={onActiveLevelChanged}
                          enableSort 
                          items={levelItems} />
                       
                    </Stack>
                    : null 
                :
                <Loader Text='Just a moment...' />
            }
             <Panel
                headerText={_GetHeaderForSection()}
                type={PanelType.smallFixedFar}
                isOpen={isEditOpen}
                isLightDismiss={true}
                onDismiss={dismissEditPanel}
                closeButtonAriaLabel="Close">
                    <br />
                    {activeSection === ActiveSection.Levels ? 
                    <LevelEditor onModelValidationStatusChanged={onLevelValidationChanged} programs={programs!} editLevel={isEditingLevel ? selectedLevel : undefined} /> :
                    <ProgramEditor onModelValidationStatusChanged={onProgramValidationChanged} />  
                  }
                  {activeSection === ActiveSection.Levels ? 
                    <PrimaryButton disabled={!canSaveLevel || isSaving} onClick={()=> saveLevel()}>Save Level</PrimaryButton>
                    : 
                    <PrimaryButton disabled={!canSaveProgram || isSaving} onClick={()=> saveProgram()}>Save Program</PrimaryButton>
                  }
                  {isSaving ? <Loader Text="Just a moment..." /> : null}
              </Panel>
        </>
    )
}

export default ProgramManagement;