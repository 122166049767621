import { Dropdown, IDropdownOption, Label, Stack, TextField } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { Resource } from '../model/Resource';
import { StoragePurpose } from '../Storage';
import FileUploadButton from './FileUploadButton';

interface IData { 
    editResource?: Resource | undefined;   
    onModelValidationStatusChanged: (resource: Resource, isValid: boolean)  => void;
}

const ResourceEditor : FC<IData> = ({editResource, onModelValidationStatusChanged}) => { 

    const [resource, setResource] = useState<Resource>(editResource === undefined ? new Resource() : editResource);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const mediaTypeOptions : IDropdownOption[] = [
        { key: "youtube", text: 'Youtube',  },
        { key: "video/mp4", text: 'Upload video',  },
        { key: "link", text: 'Link',  }
      ];

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {
        // if(resource.Description.length === 0) {
        //     onModelValidationStatusChanged(resource, false);    
        //     return;
        // }

        if(resource.Name.length === 0) {
            onModelValidationStatusChanged(resource, false);
            return;
        }

        if(resource.Uri.length === 0) {
            onModelValidationStatusChanged(resource, false);
            return;
        }

        //if we're uploading, don't allow the user to move from this view
        if(isUploading) {
            onModelValidationStatusChanged(resource, false);
            return;        
        }
        
        onModelValidationStatusChanged(resource, true);
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [resource]);

     
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>Name</Label>            
                <TextField 
                    required
                    inputMode="text"                                               
                    defaultValue={resource.Name}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setResource(resource => ({...resource, Name: newValue!}))} 
                    onGetErrorMessage={validateFieldNotEmpty}/>
                    
                <Label>Description</Label>
                <TextField 
                    inputMode="text"    
                    rows={3}
                    multiline  
                    defaultValue={resource.Description}
                    onChange={(c: React.FormEvent, newValue?: string) => setResource(resource => ({...resource, Description: newValue!}))} />
                
                <Label>Media Type</Label>
                <Dropdown 
                    required
                    defaultSelectedKey={editResource?.MediaType}
                    options={mediaTypeOptions} 
                    onChange={(e,option) => setResource(resource => ({...resource, MediaType: option?.key! as string}))} />

                {resource.MediaType === "video/mp4" ? 
                    <FileUploadButton 
                        Purpose={StoragePurpose.Curriculum} 
                        FileUploaded={(uploadedFile)=>setResource(resource => ({...resource, Uri: uploadedFile.relativeFilePath}))} 
                        IsUploading={(uploading) => setIsUploading(uploading)} 
                        Label="Upload from your device"
                        style={{textDecoration:"underline" }} /> : 
                    <Stack>
                        <Label>Link</Label>
                        <TextField 
                            required
                            inputMode="text"      
                            defaultValue={resource.Uri}                                         
                            validateOnLoad={false} 
                            validateOnFocusOut 
                            onChange={(c: React.FormEvent, newValue?: string) => setResource(resource => ({...resource, Uri: newValue!}))} 
                            onGetErrorMessage={validateFieldNotEmpty}/>
                    </Stack>
                }
            </Stack>

        </>
    )
}

export default ResourceEditor;