import { IUserInput } from "./CatalogItem";
import { IStudentRegistration } from "./TournamentRegistration";

export enum EventRegistrationStatus {
    Open = 0,
    Closed = 1
}

export enum BracketingPolicy {
    Ordered = 0,
    Repechage = 1
}

export enum PurchasePrerequisiteType {
    Any = 0,
    
    All = 1
}

export interface IPurchasePrerequisite {
    CatalogIds: string[];
    EventIds: string[];
    Type: PurchasePrerequisiteType;
}

export class TournamentEvent {
    Order: number;
    Status: EventRegistrationStatus;
    Role: string;
    EventId: string;
    Description: string;
    Subcategory: string;
    Title: string;
    ImageUri: string;
    Students: IStudentRegistration[];
    Inputs: IUserInput[];
    AllRegisteredStudentIds: string[];
    BracketingPolicy: BracketingPolicy;
    AdditionalTerms: string[];
    Prerequisites: IPurchasePrerequisite[];
    PurchaseGroupId: string;
    
    constructor(
        order: number,
        status: EventRegistrationStatus,
        role: string,
        eventId: string,
        title: string,
        description: string,
        subcategory: string,
        imageUri: string,
        students: IStudentRegistration[],
        inputs: IUserInput[],
        allRegisteredStudentIds: string[],
        bracketingPolicy: BracketingPolicy,
        additionalTerms: string[],
        prerequisites: IPurchasePrerequisite[],
        purchaseGroupId: string) {
            this.Order = order;
            this.Status = status;
            this.Role = role;
            this.EventId = eventId;
            this.Title = title;
            this.ImageUri = imageUri;
            this.Description = description;
            this.Subcategory = subcategory;
            this.Students = students;
            this.Inputs = inputs;
            this.AllRegisteredStudentIds = allRegisteredStudentIds;
            this.BracketingPolicy = bracketingPolicy;
            this.AdditionalTerms = additionalTerms;
            this.Prerequisites = prerequisites;
            this.PurchaseGroupId = purchaseGroupId;
        }
}