import React, { FC, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Label, PrimaryButton, Stack, Dialog, DialogType, DialogFooter, TextField } from '@fluentui/react';
import { createTrainingFacility } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useBoolean } from '@fluentui/react-hooks';
import { ITrainingFacility } from '../../../model/TrainingFacility';

interface IData {
    tournamentId: string;

    onCompleted: (coupon: ITrainingFacility) => void;
}

const CreateFacilityDialog : FC<IData> = ({tournamentId, onCompleted}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [tf, setTf] = useState<ITrainingFacility>({Id: "", Location:'', Title:'', ShortTitle:''});

    const createFacility = async () => {        
        if (tf.Title.length === 0 ||
            tf.ShortTitle.length === 0) {
            alert('Missing required fields, cannot create this facility.');
            return;
        }

        setIsCreating(true);

        var result = await createTrainingFacility(instance, account!, tournamentId, tf);

        if (result) {
            onCompleted(tf);
            return;
        }

        alert('Something went wrong. Please try again later.');
    }

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Missing fields',
        closeButtonAriaLabel: 'Close',
        subText: 'Please enter both a title and a short title before proceeding.',
    };

    return (
          <AuthenticatedTemplate>
                <div>
                <div style={{ padding: 10 }}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Label>Title</Label>
                            <TextField
                                disabled={isCreating}
                                inputMode='text'
                                placeholder='Enter the title'
                                value={tf.Title}
                                onChange={(ev, newValue) => setTf({...tf, Title: newValue!})} />
                            <Label>Short Title</Label>
                            <TextField
                                disabled={isCreating}
                                inputMode='text'
                                placeholder='Enter the short title, usually an abbreviation of the title'
                                value={tf.ShortTitle}
                                onChange={(ev, newValue) => setTf({...tf, ShortTitle: newValue!})} />
                            <PrimaryButton disabled={isCreating} onClick={createFacility}>Create Facility</PrimaryButton>
                            {isCreating && <Loader Text="Just a moment..." />}
                        </Stack>
                    </div>
                </div>
            <Dialog
                hidden={hideDialog}
                styles={dialogStyles}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}>
                <DialogFooter>
                  <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
              </Dialog>
          </AuthenticatedTemplate>
    )
}

export default CreateFacilityDialog;