import React, { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import VideoFeedbackHost from '../components/VideoFeedbackHost';
import { useParams, useNavigate } from 'react-router-dom';
import { Student } from '../model/Student';
import { getCurriculum, getStudent, getTenantEnvironment } from '../ApiService';
import Loader from '../components/Loader';
import { Features } from '../Features';
import { CurriculumType } from '../model/CurriculumItem';

const ReviewEditor : FC= () => {

    let { studentId } = useParams<{studentId: string }>() as {studentId: string };
    let { curriculumId } = useParams<{curriculumId: string }>() as {curriculumId: string };
    let { submissionId } = useParams<{submissionId: string }>() as {submissionId: string };

    const appInsights = useAppInsightsContext();
    const navigate = useNavigate();
    appInsights?.trackPageView({ name:"ReviewEditor" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();    
    const account = useAccount(accounts[0] || {});

    const [student, setStudent] = useState<Student>();
    const [hideNeedsWork, setHideNeedsWork] = useState<boolean>(false);
    
    const onEntrySubmitted = () => {
        navigate(-1);
    }

    useEffect(()=>{
        
        const fetchData = async () => {             
            if(account) {               
                
                var tenantEnvironment = await getTenantEnvironment(instance, account);

                if(tenantEnvironment.Features.indexOf(Features.Tournament) !== -1) {
                    var curriculum = await getCurriculum(instance, account, curriculumId);

                    if(curriculum.Type === CurriculumType.TournamentEvent) {
                        setHideNeedsWork(true);
                    }
                }
                
                var student = await getStudent(instance, account, studentId);
                setStudent(student);
            }
        }
       
        if (inProgress === "none" && account) {
            fetchData();    
        }
        

    }, [isAuthenticated, inProgress, account, studentId, curriculumId, instance]); 

    return (
        student === undefined ? <Loader Text="Just a moment..."/> : 
        <>
            <br />
            <VideoFeedbackHost 
                HideNeedsWork={hideNeedsWork}
                CurriculumId={curriculumId} 
                CurriculumSubmissionId={submissionId}
                Student={student}
                EntrySubmitted={onEntrySubmitted}            
            />
        </>
    )
}

export default ReviewEditor;