import { ScoreTypes } from "./ReviewCollection";

export interface IReviewPolicy {
    RequiredReviews: number;
    RequiredReviewers: string[];
    ScoreType: ScoreTypes;
}

export class ReviewPolicy implements IReviewPolicy {
    RequiredReviews: number;
    RequiredReviewers: string[];
    ScoreType: ScoreTypes;

    constructor(
        params: IReviewPolicy = {} as IReviewPolicy) {
            
        let {
            RequiredReviews = 1,
            RequiredReviewers = [],
            ScoreType = ScoreTypes.Sum
        } = params;
            
        this.RequiredReviews = RequiredReviews;
        this.RequiredReviewers = RequiredReviewers;
        this.ScoreType = ScoreType;
    }
}