import { Label, Stack, TextField } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { Program } from '../model/Program';

interface IData {
    onModelValidationStatusChanged: (program: Program, isValid: boolean)  => void;
}

const ProgramEditor : FC<IData> = ({onModelValidationStatusChanged}) => { 
    const [program, setProgram] = useState<Program>(new Program());

    const validateFieldNotEmpty = (value: string) : string => {
        if(value.length > 0){
          return '';
        }
  
        return 'Field cannot be empty';
      }

      const checkModelValidationState = () => {
          
        if(program?.Name.length === 0) {
            onModelValidationStatusChanged(program, false);    
            return;
        }
        
        onModelValidationStatusChanged(program!, true);
      }

      useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
      }, [program]);
     
    return (
        <>
            <Stack tokens={{childrenGap:10}}>
                <Label>Name</Label>            
                <TextField 
                    inputMode="text"                                               
                    defaultValue={program.Name}
                    validateOnLoad={false} 
                    validateOnFocusOut 
                    onChange={(c: React.FormEvent, newValue?: string) => setProgram({...program, Name: newValue!})}
                    onGetErrorMessage={validateFieldNotEmpty}/>      
                    <br />                             
            </Stack>

        </>
    )
}

export default ProgramEditor;