export interface ILevel {
    Id: string;
    Order: number;
    Grade: number;
    Label: string;
    Description: string;
    ProgramIds: string[];
}

export class Level {
    Id: string;
    Order: number;
    Grade: number;
    Label: string;
    Description: string;
    ProgramIds: string[];

    constructor(
        params: ILevel = {} as ILevel) {
            
        let {
            Id = "",
            Order = -1,
            Grade = -1,
            Label = "",
            Description ="",
            ProgramIds = []
        } = params;
            
        this.Id = Id;
        this.Order = Order;
        this.Grade = Grade;
        this.Label = Label;
        this.Description = Description;
        this.ProgramIds = ProgramIds;
    }
}