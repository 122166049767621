import React, { FC, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Label, PrimaryButton, Stack, Text, Dialog, DialogType, DialogFooter, SpinButton, TextField } from '@fluentui/react';
import { postNewInvoice } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useBoolean } from '@fluentui/react-hooks';
import StudentPicker from '../../../components/StudentPicker';
import { IInvoice } from '../../../model/Invoice';

interface IData {
    tournamentId: string;

    onCompleted: (invoice: IInvoice) => void;
}

const CreateInvoiceDialog : FC<IData> = ({tournamentId, onCompleted}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [description, setDescription] = useState<string>();
    const [total, setTotal] = useState<number>(0);
    const [membershipId, setMembershipId] = useState<string>();

    const sendInvoice = async () => {        
        if (description === undefined ||
            total === undefined ||
            membershipId === undefined) {
            toggleHideDialog();
            return;
        }

        setInvoiceSent(false);
        setSendingInvoice(true);

        var result = await postNewInvoice(instance, account!, tournamentId!, {
            Description: description,
            Amount: total,
            MembershipId: membershipId
        });

        setSendingInvoice(false);

        if (result) {
            setInvoiceSent(true);
            onCompleted(result);
            return;
        }

        alert('Something went wrong. Please try again later.');
    }

    const [sendingInvoice, setSendingInvoice] = useState<boolean>(false);
    const [invoiceSent, setInvoiceSent] = useState<boolean>(false);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Missing fields',
        closeButtonAriaLabel: 'Close',
        subText: 'Please enter both a description and an amount before proceeding.',
    };

    return (
          <AuthenticatedTemplate>
                <div>
                <div style={{ padding: 10 }}>
                    {invoiceSent ?
                        <Text variant='mediumPlus'>Invoice sent successfully.</Text> : 
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Label>Student</Label>
                            <StudentPicker
                                disabled={sendingInvoice}
                                tournamentId={tournamentId}
                                onChange={(items) => items === undefined ? setMembershipId(undefined) : setMembershipId(items[0].membershipId)} />
                            <Label>Description</Label>
                            <TextField
                                disabled={sendingInvoice}
                                multiline
                                inputMode='text'
                                placeholder='Enter a description'
                                onChange={(ev, newValue) => setDescription(newValue!)}
                            />
                            <Label>Amount</Label>
                            <SpinButton
                                placeholder='Enter the total amount'
                                defaultValue={total?.toString()}
                                min={5}
                                max={9999.99}
                                upArrowButtonStyles={{ root: { display: 'none' } }}
                                downArrowButtonStyles={{ root: {display:'none'} }}
                                step={0.01}
                                precision={2}
                                disabled={sendingInvoice}
                                onChange={(ev, newValue) => setTotal(parseFloat(newValue!))} />
                                
                            <Text>Invoice will be sent via email.</Text>
                            <br />
                            <PrimaryButton disabled={sendingInvoice} onClick={sendInvoice}>Send Invoice</PrimaryButton>
                            {sendingInvoice && <Loader Text="Just a moment..." />}
                        </Stack>
                    }
                    </div>
                </div>
            <Dialog
                hidden={hideDialog}
                styles={dialogStyles}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
              >
                <DialogFooter>
                  <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
              </Dialog>
          </AuthenticatedTemplate>
    )
}

export default CreateInvoiceDialog;