import React, { FC } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useParams } from 'react-router-dom';
import { AuthenticatedTemplate, useMsalAuthentication } from '@azure/msal-react';
import { Pivot, PivotItem } from '@fluentui/react';
import RegistationManagement from './components/RegistrationManagement';
import TransactionManagement from './components/TransactionManagement';
import { useIdentity } from '../../Identity';
import { TournamentStakeholderRoles } from '../../model/TournamentReviewDashboardViewModel';
import AthleteCall from './components/AthleteCall';
import { InteractionType } from '@azure/msal-browser';
import AthleteEditor from './components/AthleteEditor';
import Summary from './components/Summary';
import InvoiceManagement from './components/InvoiceManagement';
import TournamentSettings from './components/TournamentSettings';

const TournamentAdmin : FC = () => {
    let { tournamentId } = useParams<{tournamentId?: string }>() as {tournamentId: string };

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"TournamentAdmin" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    
    const identity = useIdentity();
    const goToSettings = document?.URL.endsWith('/settings');

    return (        
        <AuthenticatedTemplate>            
            <Pivot overflowBehavior='menu' defaultSelectedKey={goToSettings ? "settings" : "overview"}>
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Director]) ? 
                    <PivotItem key={0} itemKey="overview" headerText="Overview">
                        <Summary tournamentId={tournamentId} />
                    </PivotItem> 
                    : null}                
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Admin, TournamentStakeholderRoles.Director]) ? 
                    <PivotItem key={1} itemKey="registrations" headerText="Registrations">
                        <RegistationManagement tournamentId={tournamentId} />
                    </PivotItem> 
                : null}
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Director]) ?
                    <PivotItem key={2} itemKey="transactions" headerText="Transactions">
                        <TransactionManagement tournamentId={tournamentId} />
                    </PivotItem> 
                    : null}
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Director]) ?
                    <PivotItem key={3} itemKey="invoices" headerText="Invoices">
                        <InvoiceManagement tournamentId={tournamentId} />
                    </PivotItem> 
                : null}
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Admin, TournamentStakeholderRoles.Director, TournamentStakeholderRoles.Coach]) ?
                    <PivotItem key={5} itemKey="athletes" headerText="Athletes">
                        <AthleteEditor tournamentId={tournamentId} />
                    </PivotItem> 
                : null}
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Admin, TournamentStakeholderRoles.Director, TournamentStakeholderRoles.Staging]) ?
                    <PivotItem key={6} itemKey="athletecall" headerText="Athlete Call">
                        <AthleteCall tournamentId={tournamentId} />
                    </PivotItem> 
                : null}
                {identity.isInTournamentRole(tournamentId, [TournamentStakeholderRoles.Director]) ? 
                    <PivotItem key={7} itemKey="settings" headerText="Settings">
                        <TournamentSettings tournamentId={tournamentId} />
                    </PivotItem> 
                : null}
            </Pivot>
        </AuthenticatedTemplate>
    );  
}

export default TournamentAdmin;