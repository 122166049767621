import React, { FC, useEffect, useState } from 'react';
import { Label, TextField, Text } from '@fluentui/react';
import { Tournament, TournamentType } from '../../model/Tournament';
import { CleanId } from '../../Validation';

interface IData {
    tournament: Tournament;
    fixedId: boolean;
    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

const BasicWizard : FC<IData> = ({tournament, onValidated, fixedId}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {
        if(internalTournament.Title.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        // if(internalTournament.Description.length === 0) {
        //     onValidated(false, internalTournament);
        //     return;
        // }

        if(internalTournament.StatementDescriptor.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        // if(internalTournament.RegistrationDescription?.length === 0) {
        //     onValidated(false, internalTournament);
        //     return;
        // }

        if(internalTournament.Type === TournamentType.InPerson && 
            (internalTournament.Location === undefined || internalTournament.Location.length === 0)) {
            onValidated(false, internalTournament);
            return;
        }

        onValidated(true, internalTournament);
// eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament]);    
    
    const updateTitle = (title: string) => {
        
        var id = internalTournament.Id;

        if (!fixedId) {
            id = CleanId(title);  
        } 

        setInternalTournament(internalTournament => ({ ...internalTournament, Title: title, Id: id }));
    }
  
    return (
      <div style={{display:'flex', flexDirection:'column', gap:5}}>
        <Text variant='large'>Basic details</Text>
            <br />

            <Label required>Title</Label>
        <TextField 
            defaultValue={tournament.Title}
            placeholder='Tournament title' 
            onChange={(e, t) => updateTitle(t!)} />
        
        <Label required>Address</Label>
        <TextField 
            defaultValue={tournament.Location}
            placeholder='Enter the physical address the tournament will be hosted at'
            onChange={(e,v) => setInternalTournament(internalTournament => ({...internalTournament, Location: v! }))}
            />    
        <Label required>Support Email</Label>
        <TextField 
            multiline 
            defaultValue={tournament.SupportEmail}
            placeholder='Email address your customers should contact for help.' 
                onChange={(e, t) => setInternalTournament(internalTournament => ({ ...internalTournament, SupportEmail: t! }))} />  
            
            <Label required>Statement Descriptor</Label>
            <Text variant='small'>Seen by customers on their credit card statement.</Text>
        <TextField 
            defaultValue={tournament.StatementDescriptor}
            maxLength={16}
            placeholder='Shown on customer credit card statements.' 
            onChange={(e, t)=> setInternalTournament(internalTournament => ({...internalTournament, StatementDescriptor:t!}))} />   
                    

        {/* <TextField style={{display:'none'}} disabled label="Tournament Id" readOnly value={internalTournament.Id} /> */}
      
      </div>
    );
}

export default BasicWizard;