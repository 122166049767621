import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvitationStatus } from '../ApiService';
import { InvitationTokenState } from '../model/UserInvitation';
import { useMediaQuery } from 'react-responsive';

const ValidateInvitation : FC = () => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Validate Invitation" });
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });
    const navigate = useNavigate();
    let { redemptionToken } = useParams<{redemptionToken: string }>() as {redemptionToken: string };

    const [state, setState] = useState<InvitationTokenState>()

    const doValidation = async () => {
        var result = await getInvitationStatus(redemptionToken);
        setState(result);
    } 

    useEffect(()=> {
        doValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[]);

    return (
        <>
            {state === InvitationTokenState.NotFound ? <div style={{backgroundColor:'white', padding:isMobile ? 20 : 80, marginLeft:'auto', marginRight:'auto', marginTop:isMobile ? 20 : 50}}>
                <Text variant={isMobile ? 'large' : 'xLargePlus'}>Sorry, something went wrong :(</Text>
                    <br /><br /> 
                <Text variant='medium'>Please contact your administrator.</Text>
                </div> : null}
            
            {state === InvitationTokenState.Invalid ? <div style={{backgroundColor:'white', padding:isMobile ? 20 : 80, marginLeft:'auto', marginRight:'auto', marginTop:isMobile ? 20 : 50}}>
                <Text variant={isMobile ? 'large' : 'xLargePlus'}>This invitation has expired.</Text>
                    <br /><br /> 
                <Text variant='medium'>Please request a new invitation from your administrator.</Text>
                </div> : null}

            {state === InvitationTokenState.Resent ? <div style={{backgroundColor:'white', padding:isMobile ? 20 : 80, marginLeft:'auto', marginRight:'auto', marginTop:isMobile ? 20 : 50}}>
                <Text variant={isMobile ? 'large' : 'xLargePlus'}>This invitation has expired.</Text>
                    <br /><br /> 
                <Text variant='medium'>We have sent a new invitation to you via email. Please click the link in your email to continue with registration and note that you may have to check your spam folder.</Text>
                </div> : null}

            {state === InvitationTokenState.Valid ? 
            <div style={{backgroundColor:'white', padding:isMobile ? 20 : 80, marginLeft:'auto', marginRight:'auto', marginTop:isMobile ? 20 : 50}}>
                <Stack horizontalAlign='center' tokens={{childrenGap:10}}>
                        <Text variant={isMobile ? 'large' : 'xLargePlus'}>Hi there! We'll get you up and running in just a moment.</Text>
                        <Text variant='medium'>On the next screen you will be asked to select your preferred external account to login with.</Text>
                        <Text variant='medium'>Next time you login, you will need to use the same external account.</Text>
                        <video
                            className='video-player'
                            style={{maxWidth:isMobile ? 'auto' : 600}}
                            muted
                            playsInline
                            autoPlay
                            loop
                            src='https://s.ninjapanel.com/www/login-preview.mp4?sp=r&st=2023-10-14T08:20:30Z&se=2025-01-31T15:20:30Z&spr=https&sv=2022-11-02&sr=b&sig=Es7uZlIYwWCiJ%2FfVEvWVJYCemBcnOxGYvexwno66Q7o%3D'></video>
                    <Text variant='medium'>When you are ready, click Next to proceed.</Text>
                    <br />
                    <PrimaryButton styles={{root:{maxWidth:150}}} onClick={()=>navigate(`/redeem/step2/${redemptionToken}`)}>Next</PrimaryButton>
                </Stack>
            </div> : null}
        </>
    )
}

export default ValidateInvitation;
