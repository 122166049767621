export interface IVideoMarker {
    Timestamp: number;
    Title: string;
    X: number;
    Y: number;
    Height: number;
    Width: number;
}

export class VideoMarker implements IVideoMarker {
    Timestamp: number;
    Title: string;
    X: number;
    Y: number;
    Height: number;
    Width: number;

    constructor(
        params: IVideoMarker = {} as IVideoMarker) {
            
        let {            
            Timestamp = -1,
            Title = "",
            X = -1,
            Y = -1,
            Height = -1,
            Width = -1
        } = params;
            
        this.Timestamp = Timestamp;
        this.Title = Title;
        this.X = X;
        this.Y = Y;
        this.Height = Height;
        this.Width = Width;
    }
}