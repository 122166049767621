import React, { CSSProperties, FC, useEffect, useState} from 'react';
import {DefaultButton, Stack, Text, Persona, PersonaPresence, PersonaSize, Modal, Image, FontWeights, getTheme, mergeStyleSets, registerIcons, ActionButton } from '@fluentui/react';
import { getCurriculum, getMembership, getReactions, getReactionSettings, writeReaction } from '../ApiService';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { StudentCurriculumSubmission } from '../model/StudentCurriculumSubmission';
import { Resource } from '../model/Resource';
import VideoPlayer from './VideoPlayer';
import { Student } from '../model/Student';
import Loader from './Loader';
import { CurriculumItem } from '../model/CurriculumItem';
import { Reaction, IReactionValue } from '../model/Reaction';
import { parse } from 'twemoji-parser';
import StudentSelector from './StudentSelector';
import { useBoolean } from '@fluentui/react-hooks';
import { Membership } from '../model/Membership';
import { useMediaQuery } from 'react-responsive';

export interface IData {
    CurriculumId: string;
    TournamentId: string;
    CurriculumSubmission: StudentCurriculumSubmission;
    Student: Student;
    Minimal?: boolean;
    ShowSkipControls?: boolean;

    CanGoNext?: boolean;
    CanGoBack?: boolean;

    GoNext?: () => void;
    GoBack?: () => void;

    style?:CSSProperties;
}

const VideoReactionHost : FC<IData> = ({
    CurriculumId, 
    CurriculumSubmission, 
    Student,
    Minimal,
    ShowSkipControls,
    CanGoBack,
    CanGoNext,
    GoBack,
    GoNext,
    style}) => {

    const [videoRef,setVideoRef] = useState<HTMLVideoElement>();
    const [pendingReaction, setPendingReaction] = useState<Reaction>({...new Reaction(), StudentCurriculumSubmissionId:CurriculumSubmission.Id});
    const [availableReactions, setAvailableReactions] = useState<string[]>([]);
    const [showReaction, setShowReaction] = useState<boolean>(false);
    const [lastReaction, setLastReaction] = useState<string>();

    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const [curriculumItem, setCurriculumItem] = useState<CurriculumItem>();    

    const [students, setStudents] = useState<Student[]>();
    const [canReact, setCanReact] = useState<boolean>(false);
    const [canAddReaction, setCanAddReaction] = useState<boolean>(false);
    const [otherReactions, setOtherReactions] = useState<Reaction[]>();
    const [membership, setMembership] = useState<Membership>();
    const [reactionSettings, setReactionSettings] = useState<string[]>();   
    
    const [shouldPlay, setShouldPlay] = useState<boolean>(false);
    const [videoEndedPlaying, setVideoEndedPlaying] = useState<boolean>(false);
    const [otherReactionsReduced, setOtherReactionsReduced] = useState<Array<IReactionValue>>();    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    useEffect(()=> {
        const fetchStatesAsync = async () => {
        
            if(account === null || membership === undefined) {
                return;
            }
    
            var curriculumItem = await getCurriculum(instance, account, CurriculumId);
            setCurriculumItem(curriculumItem);
            
            var canReact = pendingReaction.AuthorId !== CurriculumSubmission.StudentId;
            setCanReact(canReact);
            
            if(canReact) {
                if(membership.Students.length === 1) {
                    setPendingReaction({...pendingReaction, AuthorId: membership.Students[0].Id, Author: membership.Students[0].FirstName});
                }
                else {
                    setStudents(membership.Students);

                    //if a student has already been selected to react, just keep going with them attributed
                    if(pendingReaction.AuthorId.length === 0) {                
                        showModal();
                    }
                    else {
                        setPendingReaction({...new Reaction(), 
                            StudentCurriculumSubmissionId:CurriculumSubmission.Id, 
                            AuthorId: pendingReaction.AuthorId, 
                            Author: pendingReaction.Author});
                    }
                }
    
                setAvailableReactions(reactionSettings!);                            
            }
            else {
                 setAvailableReactions([]);
            }

            var reactions = await getReactions(instance, account!, CurriculumSubmission.Id);
                setOtherReactions(reactions);
                setOtherReactionsReduced(reactions.reduce((acc, r) => acc.concat(r.Values), new Array<IReactionValue>()));
        }

        fetchStatesAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[CurriculumSubmission, CurriculumId, membership]);

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (account) {
                try {              
                    
                    var reactionSettings = await getReactionSettings(instance, account);

                    let icons: {
                        [key: string]: string | JSX.Element;
                    } = {};

                    for(let r of reactionSettings) {
                        let emoji = parse(r)[0];
                        
                        icons[r] = React.createElement("img", { src: emoji.url, width:30, height: 30}, null);
                    }

                    registerIcons({
                        icons: icons
                    });
                    
                    setReactionSettings(reactionSettings);

                    var membership = await getMembership(instance, account!);                                        
                    setMembership(membership.Result!);                    
                }            
                catch(error) {
                    console.log('acquire token error: ' + error);
                }
            }
        }
       
        fetchData();            
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [account, isAuthenticated]);

    const addReaction = (reactionType: string) => {
        var timestamp = videoRef?.currentTime!;

        //shift back by 300ms 
        if(timestamp > 0.3) {
            timestamp = timestamp - 0.3;
        }

        pendingReaction.Values.push({Timestamp:timestamp, Value: reactionType});
        setPendingReaction({...pendingReaction});

        setLastReaction(reactionType);
        setShowReaction(true);

        const timer = setTimeout(() => {
            setShowReaction(false);
          }, 600);

          return () => clearTimeout(timer);
    }

    const flushReactions = async () => {
        if(pendingReaction.Values.length === 0) {            
            return;
        }

        //it's expected that these will be destructive writes
        await writeReaction(instance, account!, pendingReaction);
    }

    useEffect(()=> {
        
        const timer = setTimeout(() => {
            flushReactions();
          }, 1000);

          return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pendingReaction]);

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 500        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });
        
    const onStudentSelected = (student: Student) => {
        setPendingReaction({...pendingReaction, AuthorId: student.Id, Author: student.FirstName});
        hideModal();
    }

    const handleVideoEnd = async () => {
        await flushReactions();
        
        setVideoEndedPlaying(shouldPlay);

        if(GoNext !== undefined) {            
            GoNext();               
        }
    }

    const onGoNext = () => {
        GoNext?.();
    }

    const isPlayingChanged = (isPlaying: boolean) => {
        var canReact = pendingReaction.AuthorId !== CurriculumSubmission.StudentId;
        setCanReact(canReact);

        setCanAddReaction(isPlaying);
        setShouldPlay(isPlaying);
    }

    const handleCanPlay = () => {
        if(videoEndedPlaying || shouldPlay) {
            setVideoEndedPlaying(false);
            videoRef?.play();
        }
    }

    return (
        <>
            {curriculumItem === undefined ? <Loader Text='Just a moment...' /> :
                <Stack>
                    {Minimal ? 
                        <Stack horizontalAlign='center' tokens={{childrenGap:5}}>
                            <Text variant={isMobile ? 'medium' : 'xLargePlus'}>{curriculumItem?.Name}</Text>
                            <Persona
                                imageUrl={Student.ImageUri}
                                imageInitials={`${Student.FirstName[0]}${Student.LastName[0]}`}
                                size={PersonaSize.size24}
                                text={`${Student.FirstName} ${Student.LastName[0]}.`}
                                secondaryText={Student.Level?.Label}
                                hidePersonaDetails={false}
                                showSecondaryText
                                presence={PersonaPresence.none}
                                imageAlt={`${Student.FirstName[0]} ${Student.LastName[0]}., ${Student.Level?.Label}`}
                            />  
                            <br />                          
                        </Stack> : 
                    <div style={{backgroundColor:'white', marginTop:20, padding:20}}>
                        <Stack tokens={{childrenGap:10}}>
                            <Text variant='xLargePlus'>{curriculumItem?.Name}</Text>
                            <Persona
                                imageUrl={Student.ImageUri}
                                imageInitials={`${Student.FirstName[0]}${Student.LastName[0]}`}
                                size={PersonaSize.size32}
                                text={`${Student.FirstName} ${Student.LastName[0]}.`}
                                secondaryText={Student.Level?.Label}
                                hidePersonaDetails={false}
                                showSecondaryText
                                presence={PersonaPresence.none}
                                imageAlt={`${Student.FirstName[0]} ${Student.LastName[0]}., ${Student.Level?.Label}`}
                            />
                        </Stack>
                    </div>
                    }
                    <div style={{backgroundColor:'white', marginTop: Minimal ? 0 : 20, padding: Minimal ? 0 : 20}}>
                        <Stack tokens={{childrenGap:10}} horizontalAlign='stretch'>
                            <VideoPlayer 
                                style={style}
                                ShowSkipControls={ShowSkipControls} 
                                GoNext={onGoNext}                           
                                Reactions={otherReactionsReduced}
                                VideoLoaded={(videoRef:HTMLVideoElement) => setVideoRef(videoRef)}
                                CanPlay={handleCanPlay}
                                IsPlayingChanged={isPlayingChanged}
                                PosterImage={CurriculumSubmission.PosterImageUri}
                                Resource={new Resource({Uri: CurriculumSubmission.ContentUri, Description: '', MediaType: CurriculumSubmission.ContentType, Name: ''})}
                                VideoEnded={()=>handleVideoEnd()} />   
                                {canReact ?                              
                                <Stack tokens={{childrenGap:20}} horizontal horizontalAlign='center' wrap>
                                    {availableReactions.map((r,idx) => 
                                        <span key={idx}>
                                            <DefaultButton                                                  
                                                disabled={!canAddReaction}
                                                styles={{icon:{height:'unset'}, root:{height:50}}}
                                                onClick={() => addReaction(r)} 
                                                iconProps={{iconName:r}}
                                             />
                                            <span>
                                                {showReaction ? 
                                                    lastReaction === r ?
                                                    <div className='reaction-host'>
                                                        <ActionButton iconProps={{iconName: r}} />
                                                    </div> : null
                                                : null}
                                            </span>
                                        </span>
                                    )}
                                </Stack> : 
                                null}
                        </Stack>                       
                    </div>
                    {otherReactions !== undefined ? 
                        <div style={{backgroundColor:'white', marginTop:20, padding:20}}>
                            <Text variant='large'>Reactions</Text>
                            <br /><br />
                            {otherReactions.length === 0 ? <Text>No one has reacted yet</Text> : null}
                            <Stack tokens={{childrenGap:10}}>
                                {otherReactions.map((r,idx) => <Stack key={idx} horizontal tokens={{childrenGap:5}}>
                                    <Stack.Item align='center'><Text variant='medium'>{r.Author}:</Text></Stack.Item>
                                    {r.Values.map((v,vIdx) => <Stack.Item key={vIdx} align='center'><Image style={{width:10}} src={parse(v.Value)[0].url} /></Stack.Item>)}
                                </Stack>)}
                            </Stack>
                        </div> : 
                    null}
                </Stack>   
            }  

        <Modal
            isOpen={isModalOpen}
            isBlocking            
            onDismiss={hideModal} 
            containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>Who is watching?</span>
                </div>
                <div className={contentStyles.body}>                
                    <StudentSelector 
                        Students={students!} 
                        SubjectName={Student.FirstName}
                        OnStudentSelected={onStudentSelected} />
                </div>            
        </Modal>      
        </>
    );
}

export default VideoReactionHost;