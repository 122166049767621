import React, { FC, useEffect, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { ActionButton, Pivot, PivotItem, Stack, Text } from '@fluentui/react';
import { getTransactionsById } from '../../../ApiService';
import { TournamentRegistration } from '../../../model/TournamentRegistration';
import { useAccount, useMsal } from "@azure/msal-react";
import { Transaction, TransactionStatus, TransactionType } from '../../../model/Transaction';
import Loader from '../../../components/Loader';
import { CurriculumItem } from '../../../model/CurriculumItem';
import { Student } from '../../../model/Student';
import { CatalogItem, IUserInput } from '../../../model/CatalogItem';
import { useMediaQuery } from 'react-responsive';
import { Tournament } from '../../../model/Tournament';

interface IData {
    catalogItem?: CatalogItem;
    curriculum?: CurriculumItem;
    student: Student;
    tournament: Tournament;
    registration: TournamentRegistration;
    inputs: IUserInput[];
}

const RegistrationDetails : FC<IData> = ({inputs, registration, curriculum, catalogItem, student, tournament}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const isMobile = useMediaQuery({ query: '(max-width: 550px)' });

    const [transactions, setTransactions] = useState<Transaction[]>();
    const [showLineItems, setShowLineItems] = useState<boolean>(false);

    const [errorCode, setErrorCode] = useState<number>();

    useEffect(()=> {
      if(errorCode === undefined) {
        return;
      }

      throw new Error("The server returned status code: " + errorCode);
    },[errorCode]);

    useEffect(()=> {
        const fetchAsync = async () => {
            var transactions = await getTransactionsById(instance, account!, registration.TournamentId, registration.TransactionIds);
            
            if(typeof transactions === 'number') {
                setErrorCode(transactions);
                return;
            }
            
            setTransactions(transactions);
        }

        fetchAsync();        
    },[inputs, registration, account, instance]);
    
    return (
          <AuthenticatedTemplate>
            <div style={{minWidth:isMobile ? 100 : 400}}>
                <Pivot>
                    <PivotItem headerText='Details'>
                        <div style={{padding:10}}>
                            <Stack tokens={{childrenGap:10}}>
                                {curriculum !== undefined ? 
                                <>
                                <Stack>
                                    <Text variant='medium'>Event</Text>
                                    <Text variant='small'>{curriculum.Name}</Text>
                                </Stack>
                                {inputs?.map((i,idx) => 
                                    <Stack key={idx}><Text variant='medium'>{i.Label}</Text> {i.BindTo.startsWith('x:') ? <Text variant='small'>{student.CustomValues.find(c=>c.Key === i.BindTo.substring(2))?.Value}</Text> : (student as any)[i.BindTo]}</Stack>)}
                                </> : null}

                                {catalogItem !== undefined ? 
                                <>
                                <Stack>
                                    <Text variant='medium'>Item</Text>
                                    <Text variant='small'>{catalogItem.Name}</Text>
                                </Stack>
                                <Text>Qty: {registration.Catalog.find(c=>c.CatalogItemId === catalogItem.Id)?.Qty}</Text>
                                {registration.Catalog.find(c=>c.CatalogItemId === catalogItem.Id)?.Config?.map((config,idx) => 
                                    <Stack key={idx} horizontal>{config.Key}: {config.Value}</Stack>)}
                                </> : null}
                            </Stack>
                        </div>
                    </PivotItem>
                    <PivotItem headerText='Transaction(s)'>
                        <div style={{padding:20}}>
                        {transactions === undefined ? <Loader Text='Fetching transactions...' /> : 
                            <>
                                {transactions.map((t,idx) => 
                                    t.Status === TransactionStatus.Success ? 
                                        <Stack key={idx} tokens={{childrenGap:5}}>      
                                            <Text style={{ maxWidth:300, color: t.Type === TransactionType.Charge ? 'initial' : 'red'}} block nowrap>{t.GetDescription()}</Text>                                  
                                            <Text style={{ color: t.Type === TransactionType.Charge ? 'initial' : 'red' }}>{t.Type === TransactionType.Charge ? "Total:" : "Refunded:"} {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.GetTotal())}</Text>                                                                 
                                            {t.GetTotal() > 0 ? <Text style={{ color: t.Type === TransactionType.Charge ? 'initial' : 'red'}}>Card: {t.CardBrand} {t.LastFour}</Text> : null}
                                            <Text style={{ color: t.Type === TransactionType.Charge ? 'initial' : 'red'}}>Date: {t.Date.toLocaleString()}</Text>                            
                                            
                                            {showLineItems ? t.LineItems.map((li,idx) => 
                                            <Stack key={idx} horizontal horizontalAlign='end' tokens={{childrenGap:15}}>
                                                <Text style={{color: t.Type === TransactionType.Charge ? 'initial' : 'red'}}>{li.Description}</Text>
                                                <Text style={{color: t.Type === TransactionType.Charge ? 'initial' : 'red'}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(li.Price)}</Text>
                                            </Stack>) : null}
                                            {showLineItems ?                                 
                                            <>
                                                {/* <div style={{borderBottom:'solid', borderColor:'#f3f1f1'}}  /> */}                                                
                                                    {t.ConvenienceFee > 0 && <Text style={{ color: t.Type === TransactionType.Charge ? 'initial' : 'red', textAlign: 'end' }}>{tournament.ConvenienceFeeDescriptor}: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.ConvenienceFee)}</Text>}
                                                    <Text style={{color: t.Type === TransactionType.Charge ? 'initial' : 'red', textAlign:'end'}}>Tax: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.LineItems.reduce((accumulator, item) => {return accumulator + (item.Tax !== undefined ? item.Tax : 0);},0))}</Text>
                                                    <Text style={{ color: t.Type === TransactionType.Charge ? 'initial' : 'red', textAlign: 'end' }}>{t.Type === TransactionType.Charge ? "Total:" : "Refunded:"} {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.GetTotal())}</Text> 
                                            </> : null}
                                            <><br /><div style={{ borderBottom:'solid', borderColor:'#f3f1f1'}}/></>
                                        </Stack>
                                    : 
                                    // t.Status === TransactionStatus.Pending ? 
                                    //     <Stack key={idx} tokens={{childrenGap:5}}>                                        
                                    //         <Text style={{color:'red'}}>Pending transaction</Text>
                                    //         <Text>Date: {t.Date.toLocaleString()}</Text>                            
                                    //         <Text>Total: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.GetTotal())}</Text>                                                                                                                    
                                    //     </Stack>
                                    // : 
                                    t.Status === TransactionStatus.Failure ? 
                                        <Stack key={idx} tokens={{childrenGap:5}}>                                        
                                            <Text style={{color:'red'}}>Transaction failed</Text>
                                            <Text>Total: {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(t.GetTotal())}</Text>
                                            <Text>Date: {t.Date.toLocaleString()}</Text>                            
                                            <Text>Card: {t.CardBrand} {t.LastFour}</Text>
                                            <Text>Error code: {t.ErrorCode}</Text>
                                            <Text>Decline code: {t.DeclineCode}</Text>
                                            <Text>Error description: {t.ErrorDescription}</Text>
                                            <Text>Error type: {t.ErrorType}</Text>                                                                              
                                        </Stack>
                                        :
                                        null                                
                                )}
                                <Stack.Item align='end'>
                                    <ActionButton style={{color:'#1e6ec6'}} onClick={()=>setShowLineItems(!showLineItems)}>{showLineItems ? "View less details" : "View more details"}</ActionButton>
                                </Stack.Item>   
                            </>
                        }
                        </div>
                    </PivotItem>
                </Pivot>
            </div>
          </AuthenticatedTemplate>
    )
}

export default RegistrationDetails;