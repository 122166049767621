import { Lesson } from "./Lesson";

export interface IScheduleViewModel {    
    CurrentTime: Date;
    Lessons: Lesson[];
}

export class ScheduleViewModel implements IScheduleViewModel {
    CurrentTime: Date;
    Lessons: Lesson[];

    constructor(
        params: IScheduleViewModel = {} as IScheduleViewModel) {
            
        let {
            CurrentTime = new Date(),
            Lessons = []
        } = params;
            
        this.CurrentTime = CurrentTime;
        this.Lessons = Lessons;
    }
}