import { DefaultButton, Dialog, DialogFooter, DialogType, Label, MaskedTextField, PrimaryButton, Stack, Text, TextField, Checkbox, ActionButton } from '@fluentui/react';
import React, {FC, useEffect, useState} from 'react';
import { UserProfile } from '../../../model/UserProfile';
import { confirmMobilePhone, getViralJoinEnv, provisionMobilePhone } from '../../../ApiService';
import CreatableSelect from 'react-select/creatable';
import { SingleValue } from 'react-select';
import { TrainingFacility } from '../../../model/TrainingFacility';
import { ViralJoinEnv } from '../../../model/ViralJoinEnv';
import { Membership } from '../../../model/Membership';
import { useBoolean } from '@fluentui/react-hooks';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../../../components/Loader';
import { NormalisePhoneNumber } from '../../../Validation';
import { GetPhoneDisplayString } from '../../../Display';
import { SkypeCheckIcon } from '@fluentui/react-icons-mdl2';

interface IData {
    userProfile: UserProfile;
    membership: Membership;
    onModelValidationStatusChanged: (userProfile: UserProfile, membership: Membership, isValid: boolean)  => void;
}

const UserProfileEditor: FC<IData> = ({ userProfile, membership, onModelValidationStatusChanged }) => {
    const [originalMobile, ] = useState<string>(userProfile.Mobile);
    const [internalUserProfile, setInternalUserProfile] = useState<UserProfile>(userProfile);
    const [internalMembership, setInternalMembership] = useState<Membership>(membership);
    const [needsPhoneVerification, setNeedsPhoneVerification] = useState<boolean>(false);
    const [env, setEnv] = useState<ViralJoinEnv>();
    const [facilityOptions, setFacilityOptions] = useState<Option[]>();
    const [defaultFacility, setDefaultFacility] = useState<Option>();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    interface Option {
        readonly label: string;
        readonly value: string;
    }
      
    const validateFieldNotEmpty = (value: string): string => {
        if (value.length > 0) {
            return '';
        }
  
        return 'Field cannot be empty';
    }

    const checkModelValidationState = () => {
        if (internalUserProfile.DisplayName.length === 0) {
            onModelValidationStatusChanged(internalUserProfile, internalMembership, false);
            return;
        }

        if (internalUserProfile.Email.length === 0) {
            onModelValidationStatusChanged(internalUserProfile, internalMembership, false);
            return;
        }

        if (internalMembership.TrainingFacilityTitle.length === 0) {
            onModelValidationStatusChanged(internalUserProfile, internalMembership, false);
            return;
        }
        
        //historically mobile was not requested, so we won't gate on it being required here
        
        onModelValidationStatusChanged(internalUserProfile, internalMembership, !needsPhoneVerification);
    }

    useEffect(() => {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [internalUserProfile, internalMembership, needsPhoneVerification]);

    useEffect(() => {
        const fetchAsync = async () => {
            var env = await getViralJoinEnv();

            if (env === undefined) {
                throw new Error('Unable to load environment');
            }

            var options: Option[] = [];

            for (let tf of env.TrainingFacilities) {
                options.push({ label: tf.Title, value: tf.Id });
            }

            setFacilityOptions(options);
            setDefaultFacility(options?.find(f => f.label === internalMembership.TrainingFacilityTitle)!)
            // if(!env.CanAddTrainingFacility && env.TrainingFacilities.length === 1) {
                    
            // }
            setEnv(env);
        }
          
        fetchAsync();
                    
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);
    
    const getTrainingFacility = (facilityTitle: string): TrainingFacility => {
        var foundFacility = env?.TrainingFacilities.find(f => f.Title === facilityTitle);
        
        if (foundFacility !== undefined) {
            return foundFacility;
        }

        return new TrainingFacility({ Id: '', Title: facilityTitle, ShortTitle: '', Location: '' });
    }

    const onChangeTrainingFacility = (newValue: SingleValue<Option>) => {
        if (newValue === null) {
            setInternalMembership(internalMembership => ({ ...internalMembership, TrainingFacilityId: '', TrainingFacilityTitle: '' }))
            return;
        }

        //if it's not in the collection, we need to create this facility
        if (env?.TrainingFacilities.find(f => f.Title === newValue!.label) === undefined) {
            //TODO: create new facility

        }

        let newTrainingFacility = getTrainingFacility(newValue!.label);

        setInternalMembership(internalMembership => ({
            ...internalMembership,
            TrainingFacilityId: newTrainingFacility.Id,
            TrainingFacilityTitle: newTrainingFacility.Title
        }));
    }    
    
    const onMobileChanged = (c: React.FormEvent, newValue?: string) => {
        let normalisedNumber = NormalisePhoneNumber(newValue!);
        
        if (originalMobile !== normalisedNumber) {
            setNeedsPhoneVerification(true);
        }
        
        setInternalUserProfile(internalUserProfile => ({ ...internalUserProfile, Mobile: normalisedNumber! }))
    }

    const requestNewCode = () => {
        toggleHideDialog();
        verifyPhone();
    }
    
    const verifyPhone = async () => {
        toggleHideDialog();

        setIsConfirming(true);

        //send request for SMS to be sent to given phone number
        var result = await provisionMobilePhone(instance, account!, internalUserProfile.Mobile);

        if (!result) {
            alert("Sorry, something went wrong");
        }

        setIsConfirming(false);
    }

    const [isConfirming, setIsConfirming] = useState<boolean>(false);
    const [confirmationCode, setConfirmationCode] = useState<string>();
    const [confirmationCodeError, setConfirmationCodeError] = useState<string>();

    const trimMask = (maskedValue: string | undefined) : string | undefined => {
        return maskedValue?.replaceAll('_', '').replaceAll(' ', '');
    }

    useEffect(() => {
        setIsValidConfirmationCode(confirmationCode?.length === 6);
    },[confirmationCode])

    const confirmPhone = async () => {
        setIsConfirming(true);
        setConfirmationCodeError(undefined);
                
        var result = await confirmMobilePhone(instance, account!, confirmationCode!);

        if (result) {
            setIsNumberVerified(true);
            setNeedsPhoneVerification(false);
            toggleHideDialog();
        }
        else {
            setConfirmationCodeError('Number could not be confirmed, please try again.');
        }
        
        setIsConfirming(false);
    }

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogContentProps = {
      type: DialogType.normal,
      title: `Verify ${GetPhoneDisplayString(internalUserProfile.Mobile)}`,
      closeButtonAriaLabel: 'Close',
      subText: `Please enter the code we sent. It might take a moment to arrive.`,
    };

    const [isNumberVerified, setIsNumberVerified] = useState<boolean>(false);
    const [hasMobileConsent, setHasMobileConsent] = useState<boolean>(false);
    const [isValidConfirmationCode, setIsValidConfirmationCode] = useState<boolean>(false);

    const getMaskedEquivalent = (value: string | undefined): string | undefined => {
        if (value === undefined || value === null) {
            return undefined;
        }

        return value.replaceAll(' ', '').replaceAll('+1', '').replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll('_', '');
    }
    return (
        <>
            <Stack tokens={{ childrenGap: 10 }}>
                <Label>Display Name</Label>
                <TextField
                    styles={{ field: { fontSize: 16 } }}
                    inputMode="text"
                    defaultValue={internalUserProfile.DisplayName}
                    validateOnLoad={false}
                    validateOnFocusOut
                    onChange={(c: React.FormEvent, newValue?: string) => setInternalUserProfile(internalUserProfile => ({ ...internalUserProfile, DisplayName: newValue! }))}
                    onGetErrorMessage={validateFieldNotEmpty} />
                
                <Label>Email</Label>
                <TextField
                    readOnly
                    disabled
                    styles={{ field: { fontSize: 16 } }}
                    inputMode="text"
                    defaultValue={internalUserProfile.Email} />
                
                <Label>Phone</Label>
                <MaskedTextField
                    disabled={isNumberVerified}
                    styles={{ field: { fontSize: 16 } }}
                    inputMode="numeric"
                    mask='+1 (999) 999-9999'
                    value={getMaskedEquivalent(internalUserProfile.Mobile)}
                    onChange={onMobileChanged}
                />
                {originalMobile?.length > 0 && originalMobile === internalUserProfile.Mobile ? null :
                    <>
                        <Checkbox
                            disabled={isNumberVerified || isConfirming}
                            styles={{ label: { fontSize: 10 } }}
                            onChange={(e, checked) => setHasMobileConsent(checked!)}
                            label='I consent to NinjaPanel, Inc. sending messages to this number to keep me informed of event updates leading up to and during any tournaments I choose to register and participate in. Message and data rates may apply.' />
                        <DefaultButton
                            disabled={isNumberVerified ||
                                !hasMobileConsent ||
                                internalUserProfile.Mobile === null ||
                                internalUserProfile.Mobile === undefined ||
                                internalUserProfile.Mobile.length === 0}
                            onClick={verifyPhone}>Verify number</DefaultButton>
                    </>
                }
                
                {isNumberVerified && <Text style={{color:'green', textAlign:'center'}} variant='smallPlus'><SkypeCheckIcon /> Phone number verified.</Text>}
                    
                <Label>Training Facility</Label>
                {defaultFacility === undefined ? null : <CreatableSelect
                    isClearable
                    defaultValue={defaultFacility}
                    options={facilityOptions}
                    onChange={(newValue) => onChangeTrainingFacility(newValue)}
                    placeholder="Where do you train? (type to add new location)"
                />}
            </Stack>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}>
                <MaskedTextField
                    disabled={isConfirming}
                    styles={{fieldGroup:{height:'auto'}}}
                    style={{fontSize:40, textAlign:'center'}}
                    mask='9 9 9 9 9 9'
                    onChange={(e,newValue) => setConfirmationCode(trimMask(newValue))} />
                
                <div style={{ textAlign:'center' }}>
                    {!isConfirming && <ActionButton disabled={isConfirming} style={{ color: 'rgb(0, 120, 212)' }} onClick={requestNewCode}>Request another code.</ActionButton>}
                </div>
                
                {isConfirming ?
                    <Loader Text='Just a moment...' /> :
                        confirmationCodeError !== undefined ?
                        <Text style={{ color: 'red' }} variant='smallPlus'>{confirmationCodeError}</Text>
                        : null}
                <br />
                <DialogFooter>                    
                  <PrimaryButton disabled={confirmationCode === undefined || !isValidConfirmationCode} onClick={() => confirmPhone()} text="Confirm" />
                </DialogFooter>
              </Dialog>
        </>
    )
}

export default UserProfileEditor;