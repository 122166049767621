import React, { FC, useRef, useState } from 'react';
import { ActionButton } from '@fluentui/react';
import { StoragePurpose, useSubmissionStorage } from '../Storage';
import Loader from "../components/Loader";

export interface IUploadedFile {
    absoluteFilePath: string;
    relativeFilePath: string;
    type: string;
}

export interface IFileUploadButton {
    Purpose: StoragePurpose;
    CurriculumId?: string;
    StudentId?: string;
    TournamentId?: string;
    FileUploaded: (uploadedFile: IUploadedFile) => void;
    style?: {};
    Label?: string;
    disabled?: boolean;
    MaxSizeInMb?: number;
    FileTooLargeWarning?: string;
    SupportedFormats?: string;
    IsUploading: (uploading: boolean) => void;
}

const FileUploadButton : FC<IFileUploadButton> = ({
    disabled, 
    Purpose,
    StudentId, 
    CurriculumId, 
    TournamentId,
    FileUploaded, 
    style, 
    Label = "upload from your device",
    IsUploading, 
    MaxSizeInMb = 300, 
    SupportedFormats = "video/mp4, video/webm, video/quicktime",
    FileTooLargeWarning}) => { 
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const fileUploadRef = useRef<HTMLInputElement>(null);
    const maxFileSize = 1024 * 1024 * MaxSizeInMb;
    const submissionStorage = useSubmissionStorage(Purpose, StudentId, CurriculumId, TournamentId);

    const onFileSelected = async (e:React.FormEvent<HTMLInputElement>) => { 
        var selectedFile = e.currentTarget.files?.item(0);

        if(selectedFile?.size! > maxFileSize) {
            var warning = FileTooLargeWarning === undefined ? 
            `File is too large. Please limit files to less than ${MaxSizeInMb}mb.`
            : FileTooLargeWarning;

            alert(warning);
            return;
        }        

        IsUploading(true);
        setIsUploading(true);

        //upload to blob storage
        var relativePathFileName = submissionStorage?.submissionEnv.Filename!;
        var absPathFileName = `${submissionStorage?.submissionEnv.Endpoint}${submissionStorage?.submissionEnv.ContainerName}/${relativePathFileName}${submissionStorage?.submissionEnv.SasUri}`;
        var success = await submissionStorage?.uploadFile(selectedFile!);

        IsUploading(false);
        setIsUploading(false);

        if(success) {
            FileUploaded({ absoluteFilePath: absPathFileName, relativeFilePath: relativePathFileName, type: selectedFile!.type });
        }
    }
    return (
        <>
            {submissionStorage === undefined ? <Loader Text="Preparing, just a moment..." /> : null}
            <ActionButton disabled={disabled || submissionStorage === undefined} style={style} onClick={() => {fileUploadRef.current?.click()}}>{Label} (max {MaxSizeInMb}mb)</ActionButton>
            <input disabled={isUploading || disabled} type="file" accept={SupportedFormats} ref={fileUploadRef} style={{display:"none"}} onChange={onFileSelected}/>
                {isUploading ? <Loader Text="Uploading, just a moment..."/> : null}
        </>
    )
}

export default FileUploadButton;