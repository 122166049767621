import {FC, useEffect, useState} from 'react';
import { completePayment } from '../../ApiService';
import { useAccount, useMsal } from '@azure/msal-react';
import Loader from '../../components/Loader';
import { Transaction, TransactionStatus } from '../../model/Transaction';
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const TournamentRegistrationResult : FC = () => {
    const stripe = useStripe();
    const navigate = useNavigate();
    const { instance, accounts} = useMsal();
    const account = useAccount(accounts[0] || {});   
    
    const [transaction, setTransaction] = useState<Transaction>();
    const [additionalContent, setAdditionalContent] = useState<string>();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(()=> {
        if (!stripe) {
            return;
        }

        const paymentIntent = new URLSearchParams(window.location.search).get(
            "payment_intent"
        );

        const fetchAsync = async (paymentIntent: string) => {        
            var result = await completePayment(instance, account!, paymentIntent);

            setTransaction(result.Transaction);
            setAdditionalContent(result.ReceiptAdditionalContent);
            setIsLoading(false);
        }
      
        if (!paymentIntent || !account) {
          return;
        }        
                
        fetchAsync(paymentIntent);
    },[stripe, instance, account]);
    
  return (
    <>
            {isLoading ? <Loader Text='Just a moment...'/> : null}
            {transaction !== undefined ? 
            <>
            <Stack>
                {transaction.Status === TransactionStatus.Success ?
                    <>
                        <br />
                        <Stack tokens={{ childrenGap: 10 }} style={{ backgroundColor: 'white', padding: 10 }}>
                            <Text variant='xLarge'>Transaction Successful</Text>
                            <Text variant='mediumPlus'>Your transaction for ${transaction.GetTotal().toFixed(2)} is complete.</Text>
                        </Stack>
                        <br />

                        <Stack tokens={{ childrenGap: 5 }} style={{ backgroundColor: 'white', padding: 10 }}>
                            <Text variant='large'>Items Purchased</Text>
                            <ul>
                                {transaction.LineItems.map((l, idx) => {
                                    return <li key={idx}><Text variant='medium'>{l.Description}</Text></li>
                                })}
                            </ul>
                        </Stack>
                        
                              {additionalContent !== null && additionalContent !== undefined ?
                                  <>
                                      <br />
                                      <Stack tokens={{ childrenGap: 5 }} style={{ backgroundColor: 'white', padding: 10 }}>
                                    <div dangerouslySetInnerHTML={{ __html: additionalContent! }} />
                                      </Stack>
                                  </> : null}
                        <br /><br />
                        <Stack.Item align='center'>
                            <PrimaryButton onClick={() => navigate('/')}>Go home</PrimaryButton>
                        </Stack.Item>
                    </>
                    : null}
                {transaction.Status === TransactionStatus.Failure ?    
                    <Stack tokens={{childrenGap:10}}>
                        <h2>Transaction Failed</h2>
                        Sorry, your transaction was not successful.
                        <Text variant='mediumPlus'>{transaction.ErrorDescription}</Text>
                        <br /><br />
                        <Stack.Item align='center'>
                            <PrimaryButton onClick={()=>navigate('/')}>Go home</PrimaryButton>
                        </Stack.Item>
                    </Stack>
                : null}
            </Stack>            
            </>
            : null}
        </>
  );
}

export default TournamentRegistrationResult;