import { Student } from "./Student";
import { StudentCurriculumSubmission } from "./StudentCurriculumSubmission";
import { TournamentBracket } from "./TournamentBracket";

export enum TournamentStakeholderRoles {
    Admin = "admin",
    Director = "director",
    Judge = "judge",
    Staging = "staging",
    Coach = "coach"
}
export interface ITournamentReviewDashboardViewModel {
    Events: ITournamentEvent[];
    Roles: ITournamentStakeholderRole[];
}

export interface ITournamentEventRegistration {
    StudentId: string;
    SubmissionId: string;
}

export interface ITournamentDivisionViewModel {
    Id: string;
    Name: string;
    EventId: string;
    EventRegistrations: ITournamentEventRegistration[];
    Brackets: TournamentBracket[];
}

export interface ITournamentEvent {
    ImageUri: string;
    EventName: string;
    Divisions: ITournamentDivisionViewModel[];
    Students: Student[];
    Submissions: StudentCurriculumSubmission[];
}

export interface ITournamentStakeholderRole {
    Value: string;
    StudentIds: string[];
}

export interface ITournamentStakeholderRoleWithId {
    Title: string;
    TournamentId: string;
    Value: string;
    StudentIds: string[];
}

export class TournamentReviewDashboardViewModel implements ITournamentReviewDashboardViewModel {    
    Events: ITournamentEvent[];
    Roles: ITournamentStakeholderRole[];

    constructor(
        params: ITournamentReviewDashboardViewModel = {} as ITournamentReviewDashboardViewModel) {
            
        let {
            Events = [],
            Roles = []
        } = params;
            
        this.Events = Events;
        this.Roles = Roles;
    }
}