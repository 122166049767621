export interface IProgram {    
    Name: string;
    Id: string;
}

export class Program implements IProgram {
    Name: string;
    Id: string;

    constructor(
        params: IProgram = {} as IProgram) {
            
        let {
            Name = "",
            Id = ""
        } = params;
            
        this.Name = Name;
        this.Id = Id;
    }
}