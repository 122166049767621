let newDate = new Date();

export interface IStudentPromotion {
    Id: string;
    EventId: string;
    StudentId: string;
    Date: Date;
    RankIn: number;
    RankOut: number;
}

export class StudentPromotion {
    Id: string;
    EventId: string;
    StudentId: string;
    Date: Date;
    RankIn: number;
    RankOut: number;

    constructor(
        params: IStudentPromotion = {} as IStudentPromotion) {
            
        let {
            Id = "",
            EventId = "",
            StudentId = "",
            Date = newDate,
            RankIn = -1,
            RankOut = -1
        } = params;
            
        this.Id = Id;
        this.EventId = EventId;
        this.StudentId = StudentId;
        this.Date = Date;
        this.RankIn = RankIn;
        this.RankOut = RankOut;
    }
}