import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { getTenantEnvironment } from "./ApiService";

export class Features {
    static EventDashboard: string = "eventdashboard";
    static Tournament: string = "tournament";
    static Schedule: string = "schedule";
    static Payments: string = "payments";
    static ViralJoin: string = "viraljoin"
}


export const useFeatures  = () : string[] | undefined => {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isFetching, setIsFetching] = useState(false);
    const [features, setFeatures] = useState<string[]>();
    
    useEffect(()=> {
        if(!isAuthenticated || isFetching) {
            return;
        }

        const fetchData = async () => {                            
            try {
                if(account) {
                    var env = await getTenantEnvironment(instance, account);
                    setFeatures(env.Features);
                }
            }                            
            catch(error) {
                console.log('unable to get feature env: ' + error);
            }
        }

        if (inProgress === "none" && account) {
            setIsFetching(true);
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inProgress, account, instance]);

    return features;
}