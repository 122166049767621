import React, { FC, useEffect, useState } from 'react';
import { Label, Text } from '@fluentui/react';
import DateEditor from '../DateEditor';
import { Tournament } from '../../model/Tournament';
import { DateTime } from 'luxon';
import Select from 'react-select';
import { ITimezoneViewModel } from '../../model/TimezoneViewModel';
import { GetTournamentLocalDate } from '../../Display';

interface IData {
    tournament: Tournament;
    timezones: ITimezoneViewModel[];
    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

enum DateKind {
    TournamentStart = 0,
    TournamentEnd = 1,
    DisplayAfter = 2,
    RegistrationEnd = 3
}

const DateWizard : FC<IData> = ({tournament, onValidated, timezones}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(() => {
        setValidationError(undefined);

        if(DateTime.fromJSDate(internalTournament.EndDate) < DateTime.fromJSDate(internalTournament.StartDate)) {
            setValidationError('The tournament cannot end before it starts');
            onValidated(false, internalTournament);
            return;
        }

        //display date and registration end date must not be the same dates if the display date is not undefined and the registration end date is not undefined
        if (internalTournament.DisplayAfter !== undefined && internalTournament.RegistrationEnds !== undefined &&
            DateTime.fromJSDate(internalTournament.DisplayAfter).equals(DateTime.fromJSDate(internalTournament.RegistrationEnds))) {
            setValidationError('Registration must close after registration has opened');
            onValidated(false, internalTournament);
            return;   
        }

        //registration must end after it starts
        if (internalTournament.RegistrationEnds !== undefined &&
            internalTournament.DisplayAfter !== undefined &&
            DateTime.fromJSDate(internalTournament.DisplayAfter!) >= DateTime.fromJSDate(internalTournament.RegistrationEnds!)) {
            setValidationError('Registration must close after registration has opened');
            onValidated(false, internalTournament);
            return;
        }

        //registration must end before the tournament ends if it's not undefined
        if (internalTournament.RegistrationEnds !== undefined &&
            DateTime.fromJSDate(internalTournament.RegistrationEnds) > DateTime.fromJSDate(internalTournament.EndDate)) {
            setValidationError('Registration must close before the tournament ends');
            onValidated(false, internalTournament);
            return;
        }

        //display after must occur before the tournament ends
        if (internalTournament.DisplayAfter !== undefined && DateTime.fromJSDate(internalTournament.DisplayAfter) > DateTime.fromJSDate(internalTournament.EndDate)) {
            setValidationError('Registration must open before the tournament ends');
            onValidated(false, internalTournament);
            return;
        }

        if (internalTournament.Timezone.length === 0) {
            setValidationError('Invalid timezone specified.');
            onValidated(false, internalTournament);
            return;
        }
        
        onValidated(true, internalTournament);
        // eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament]);

    interface Option {
        readonly label: string;
        readonly value: string;
    }    

    const [validationError, setValidationError] = useState<string>();

    const getDefaultValue = () => {
        var tz = timezones.find(t => t.Id === tournament.Timezone);
        
        if (tz === undefined) {
            return undefined;
        }
            
        return { label: tz.DisplayName, value: tz.Id }    
    }

    const setDate = (date: Date, kind: DateKind) => {
        //convert from tournament timezone time to UTC time for storage

        switch(kind) {
            case DateKind.TournamentStart:
                setInternalTournament(internalTournament => ({...internalTournament, StartDate: date}));
                break;
            case DateKind.TournamentEnd:
                setInternalTournament(internalTournament => ({...internalTournament, EndDate: date}));
                break;
            case DateKind.DisplayAfter:
                setInternalTournament(internalTournament => ({...internalTournament, DisplayAfter: date}));
                break;
            case DateKind.RegistrationEnd:
                setInternalTournament(internalTournament => ({...internalTournament, RegistrationEnds: date}));
                break;
        }
    }

    const getDateOrDefault = (date: Date | undefined) => {
        if (date === undefined) {
            return undefined;
        }

        if (internalTournament.Timezone.length === 0) {
            return date;   
        }

        return GetTournamentLocalDate(date, internalTournament.Timezone);
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <br />
            <Text variant='large'>Regionional Details</Text>
            <Label required>Tournament Timezone</Label>
            <Select                 
                isClearable
                options={timezones.map(t => ({ label: t.DisplayName, value: t.Id } as Option))}
                defaultValue={getDefaultValue()}
                onChange={(newValue) => newValue === null ? setInternalTournament(internalTournament => ({...internalTournament, Timezone: '' })) : setInternalTournament(internalTournament => ({...internalTournament, Timezone: newValue!.value}))}                
                placeholder="Select the tournament timezone" />
            <hr style={{stroke:'black', strokeWidth:1, width:"90%"}}/>
            <Label required>Open Registration</Label>
            <Text variant='small'>Date and time that the earliest registration can occur.</Text>
            <DateEditor 
                date={getDateOrDefault(internalTournament?.DisplayAfter)} 
                showTime
                onDateChange={(date) => setDate(date, DateKind.DisplayAfter)}  />

            <Label required>Close Registration</Label>
            <Text variant='small'>Date and time that the latest registration can occur.</Text>
            <DateEditor 
                date={getDateOrDefault(internalTournament?.RegistrationEnds)} 
                showTime
                onDateChange={(date) => setDate(date, DateKind.RegistrationEnd)} />  
            <hr style={{stroke:'black', strokeWidth:1, width:"90%"}}/>
            <Label required>Start Tournament</Label>      
            <Text variant='small'>Date and time that the tournament is expected to start.</Text>
            <DateEditor 
                date={getDateOrDefault(internalTournament?.StartDate)} 
                showTime
                onDateChange={(date) => setDate(date, DateKind.TournamentStart)}  />
            
            <Label required>End Tournament</Label>
            <Text variant='small'>Date and time that the tournament is expected to end.</Text>
            <DateEditor 
                date={getDateOrDefault(internalTournament?.EndDate)} 
                showTime
                onDateChange={(date) =>setDate(date, DateKind.TournamentEnd)}  />
                                  
            <br />
            
            {validationError && <Text variant="smallPlus" style={{ color: 'red' }}>{validationError}</Text>}
        </div>
    )
}

export default DateWizard;