import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { FC } from 'react';
import { Navigate, } from 'react-router-dom';
  
interface IData {
    children: any;
}

const ProtectedRoute : FC<IData> = ({ children }) => {
    
    return (
        <>
            <AuthenticatedTemplate>
                {children}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Navigate to={`/login?to=${document.location.pathname}`} replace />;
            </UnauthenticatedTemplate>        
        </>
    )
};

export default ProtectedRoute;