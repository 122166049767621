import React, { FC, useEffect, useState } from 'react';
import { Dropdown, IDropdownOption, Label, TextField, Text } from '@fluentui/react';
import { Tournament, TournamentLayout } from '../../model/Tournament';
import { GetTournamentLayoutString } from '../../Display';
import EditableImage from '../../pages/Admin/components/EditableImage';
import { StoragePurpose } from '../../Storage';

interface IData {
    tournament: Tournament;
    onValidated: (isValid: boolean, tournament: Tournament) => void;
}

const DisplayWizard : FC<IData> = ({tournament, onValidated}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {
       
        onValidated(true, internalTournament);
// eslint-disable-next-line react-hooks/exhaustive-deps                 
    },[internalTournament]);

    const tournamentLayoutOptions : IDropdownOption[] = [
        {
            key: TournamentLayout.Compact,
            id: TournamentLayout.Compact.toString(),
            text: GetTournamentLayoutString(TournamentLayout.Compact)
        },
        {
            key: TournamentLayout.Default,
            id: TournamentLayout.Default.toString(),
            text: GetTournamentLayoutString(TournamentLayout.Default)
        }
    ]  
  
    return (
      <div style={{display:'flex', flexDirection:'column', gap:5}}>
        <Text variant='large'>Visual details</Text>
            <br />
            
            <Label>Logo</Label>
            <Text variant='small'>The logo to be displayed on the website, credentials and brackets.</Text>
            <EditableImage
                width={100}
                purpose={StoragePurpose.Tournament}
                uri={tournament.ImageUri}
                onUriChange={(uri) => setInternalTournament(internalTournament => ({ ...internalTournament, ImageUri: uri }))} />                                                       

        <Label required>Registration Page Layout</Label>
        <Text variant='small'>Compact layout is recommended if you have more than 6 events.</Text>
        <Dropdown 
            key='layout-options'
            options={tournamentLayoutOptions}
            style={{width:300}} 
            selectedKey={internalTournament.Layout}
            onChange={(e,o)=>setInternalTournament(() => ({...internalTournament, Layout: parseInt(o?.id!)}))}/>        

        <Label>Landing Page Content</Label>
        <TextField 
            multiline 
            defaultValue={tournament.Description}
            placeholder='General description of your tournament, displayed on the landing page' 
            onChange={(e, t)=> setInternalTournament(internalTournament => ({...internalTournament, Description:t!}))} />

        <Label>Registration Content</Label>
        <TextField 
            multiline 
            defaultValue={tournament.RegistrationDescription}
            placeholder='Description that is displayed at the top of the registration page' 
            onChange={(e, t) => setInternalTournament(internalTournament => ({ ...internalTournament, RegistrationDescription: t! }))} />
      </div>
    );
}

export default DisplayWizard;