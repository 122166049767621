import { ICatalogItemWithQuantity } from "./CatalogItem";
import { Student } from "./Student";
import { TournamentEvent } from "./TournamentEvent";

export enum TournamentLayout {
    Default = 0,
    Compact = 1
}

export enum TournamentRole {
    Participant = 0,
    Spectator = 1
}

export enum TournamentType {
    Online = 0,        
    InPerson = 1,    
    Hybrid = 2
}
export interface IRegistrationOption {
    CurriculumId: string;
    PricingStrategyId: string;
    Price: number;
    Role: TournamentRole;
    AdditionalFee: number;
}

export interface IPricingStrategy {
    Id: string;
    InitialPrice: number;
    SubsequentPrice: number;
    Expiry: Date;
    Title: string;
}

export enum PurchaseGroupItemFilter {
    HideUnregisterableItems = 0,
    ShowUnregisterableItems = 1
}

export interface IPurchaseGroup {
    Id: string;
    Title: string;
    Subtitle: string;
    MaxItemsPerParticipant: number | undefined;
    MinItemsPerParticipant: number | undefined;
    ItemFilter: PurchaseGroupItemFilter;
}

export interface ITournamentStakeholderRole {
    Value: string;
    StudentIds: string[];
}

export interface ITournamentStakeholder {
    MembershipId: string;
    Roles: ITournamentStakeholderRole[];
}

export interface ITournamentCredentialTemplate {
    Id: string;
    DisplayName: string;
}

export interface ITournament {
    Id: string;
    Title: string;
    ImageUri: string;
    CredentialTemplates: ITournamentCredentialTemplate[];
    Description: string;
    EventLandingPage: boolean;
    BracketGenerationDate?: Date;
    RegistrationDescription: string;
    DisplayAfter?: Date;
    Created: Date;
    StartDate: Date;
    EndDate: Date;
    Location?: string;
    Type: TournamentType;
    RegistrationEnds?: Date;
    Pricing: IRegistrationOption[];
    PricingStrategies: IPricingStrategy[];
    RawPricingStrategies: IPricingStrategy[];
    PurchaseGroups: IPurchaseGroup[];
    Catalog: ICatalogItemWithQuantity[];
    Events: TournamentEvent[];
    Students: Student[];
    Terms: string;    
    JudgingTerms: string;
    Stakeholders: ITournamentStakeholder[];
    Layout: TournamentLayout;
    StatementDescriptor: string;
    BracketDownloadUri: string;
    HasEnded?: boolean;
    StatusHubId: string;
    Timezone: string;
    StagingLocations: string[];
    BracketLocations: string[];
    AthleteCallMinutes: number[];
    CustomerPaysConvenienceFee: boolean;
    ConvenienceFeeDescriptor?: string;
    SupportEmail?: string;
    ReceiptImageUri: string;
    DivisionRulesModified?: Date;
}

export class Tournament implements ITournament {
    Id: string;
    Title: string;
    ImageUri: string;
    Description: string;
    EventLandingPage: boolean;
    BracketGenerationDate?: Date;
    CredentialTemplates: ITournamentCredentialTemplate[];
    RegistrationDescription: string;
    DisplayAfter?: Date;
    Created: Date;
    StartDate: Date;
    EndDate: Date;
    Location?: string;
    Type: TournamentType;
    RegistrationEnds?: Date;
    Pricing: IRegistrationOption[];
    PricingStrategies: IPricingStrategy[];
    RawPricingStrategies: IPricingStrategy[];
    PurchaseGroups: IPurchaseGroup[];
    Catalog: ICatalogItemWithQuantity[];
    Events: TournamentEvent[];
    Students: Student[];
    Terms: string;   
    JudgingTerms: string; 
    Stakeholders: ITournamentStakeholder[];
    Layout: TournamentLayout;
    StatementDescriptor: string;
    BracketDownloadUri: string;
    HasEnded?: boolean;
    StatusHubId: string;
    Timezone: string;
    StagingLocations: string[];
    BracketLocations: string[];
    AthleteCallMinutes: number[];
    CustomerPaysConvenienceFee: boolean;
    ConvenienceFeeDescriptor?: string;
    SupportEmail?: string;
    ReceiptImageUri: string;
    DivisionRulesModified?: Date;

    constructor(
        params: ITournament = {} as ITournament) {
            
        let {
            Id = "",
            Title = "",
            ImageUri = "",
            Catalog = [],
            CredentialTemplates = [],
            Description = "",
            EventLandingPage = false,
            BracketGenerationDate = undefined,
            RegistrationDescription = "",
            DisplayAfter = undefined,
            Created = new Date(),
            EndDate = new Date(),
            Events = [],
            Students = [],
            Location = '',
            Type = TournamentType.InPerson,
            Pricing = [],
            PricingStrategies = [],
            PurchaseGroups = [],
            RawPricingStrategies = [],
            RegistrationEnds = undefined,
            StartDate = new Date(),
            Terms = "",
            JudgingTerms = "",
            Stakeholders = [],
            Layout = TournamentLayout.Default,
            StatementDescriptor = "",
            BracketDownloadUri = "",
            HasEnded = undefined,
            StatusHubId = "",
            Timezone = "",
            StagingLocations = [],
            BracketLocations = [],
            AthleteCallMinutes = [],
            CustomerPaysConvenienceFee = false,
            ConvenienceFeeDescriptor = undefined,
            SupportEmail = undefined,
            ReceiptImageUri = '',
            DivisionRulesModified = undefined
        } = params;
            
        this.Id = Id;
        this.Title = Title;
        this.ImageUri = ImageUri;
        this.CredentialTemplates = CredentialTemplates;
        this.Description = Description;
        this.BracketGenerationDate = BracketGenerationDate;
        this.EventLandingPage = EventLandingPage;
        this.RegistrationDescription = RegistrationDescription;
        this.DisplayAfter = DisplayAfter;
        this.Catalog = Catalog;
        this.Created = Created;
        this.Location = Location;
        this.Type = Type;
        this.StartDate = StartDate;
        this.EndDate = EndDate;
        this.Pricing = Pricing;
        this.PricingStrategies = PricingStrategies;
        this.RawPricingStrategies = RawPricingStrategies;
        this.PurchaseGroups = PurchaseGroups;
        this.Events = Events;
        this.Students = Students;
        this.RegistrationEnds = RegistrationEnds;
        this.Terms = Terms;
        this.JudgingTerms = JudgingTerms;
        this.Stakeholders = Stakeholders;
        this.Layout = Layout;
        this.StatementDescriptor = StatementDescriptor;
        this.BracketDownloadUri = BracketDownloadUri;
        this.HasEnded = HasEnded;
        this.StatusHubId = StatusHubId;
        this.Timezone = Timezone;
        this.StagingLocations = StagingLocations;
        this.BracketLocations = BracketLocations;
        this.AthleteCallMinutes = AthleteCallMinutes;
        this.CustomerPaysConvenienceFee = CustomerPaysConvenienceFee;
        this.ConvenienceFeeDescriptor = ConvenienceFeeDescriptor;
        this.SupportEmail = SupportEmail;
        this.ReceiptImageUri = ReceiptImageUri;
        this.DivisionRulesModified = DivisionRulesModified;
    }
}