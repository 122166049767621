import React, { FC, useEffect, useState } from 'react';
import { ActionButton, CommandButton, FontWeights, getTheme, IButtonStyles, IconButton, IContextualMenuProps, IIconProps, Label, mergeStyleSets, Modal, Pivot, PivotItem, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { IPricingStrategy, Tournament } from '../../model/Tournament';
import {useBoolean} from '@fluentui/react-hooks';
import PricingStrategyCreator from '../../pages/Admin/components/PricingStrategyCreator';
import { GetPricingLabelForStrategy, GetTournamentLocalTime } from '../../Display';
import DateEditor from '../DateEditor';
import { DateTime } from 'luxon';
import { EditIcon, SaveIcon } from '@fluentui/react-icons-mdl2';
import { CleanId } from '../../Validation';

interface IData {
    tournament: Tournament;

    onValidated: (isValid: boolean, tournament: Tournament) => void;
    onDismiss: () => void;
}

const PricingWizard : FC<IData> = ({tournament, onValidated, onDismiss}) => {
    const [internalTournament, setInternalTournament] = useState<Tournament>(tournament);

    useEffect(()=> {
        if(internalTournament.PricingStrategies.length === 0) {
            onValidated(false, internalTournament);
            return;
        }

        onValidated(true, internalTournament);
// eslint-disable-next-line react-hooks/exhaustive-deps
    },[internalTournament]);

    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    const [/*isEditModalOpen*/, { setTrue: showEditModal }] = useBoolean(false);

    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 0px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        minWidth:400,
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const [pendingStrategyTitle, setPendingStrategyTitle] = useState<string>();
    const [pendingEarlyStrategy, setPendingEarlyStrategy] = useState<IPricingStrategy>();
    const [pendingRegularStrategy, setPendingRegularStrategy] = useState<IPricingStrategy>();
    const [pendingLateStrategy, setPendingLateStrategy] = useState<IPricingStrategy>();

    const [isPendingEarlyStrategyValid, setIsPendingEarlyStrategyValid] = useState<boolean>(false);
    const [isPendingRegularStrategyValid, setIsPendingRegularStrategyValid] = useState<boolean>(false);
    const [isPendingLateStrategyValid, setIsPendingLateStrategyValid] = useState<boolean>(false);

    const addPricingStrategy = () => {
        hideModal();

        //set the ids for each pending strategy
        pendingEarlyStrategy!.Id = CleanId(pendingStrategyTitle!);
        pendingRegularStrategy!.Id = CleanId(pendingStrategyTitle!);
        pendingLateStrategy!.Id = CleanId(pendingStrategyTitle!);

        //and the titles
        pendingEarlyStrategy!.Title = pendingStrategyTitle!;
        pendingRegularStrategy!.Title = pendingStrategyTitle!;
        pendingLateStrategy!.Title = pendingStrategyTitle!;

        setInternalTournament(internalTournament => ({
            ...internalTournament,
            PricingStrategies: [...internalTournament.PricingStrategies, pendingEarlyStrategy!, pendingRegularStrategy!, pendingLateStrategy!]
        }));
    }

    const updatePricingStrategy = () => {
        // hideEditModal();

        //and the titles
        pendingEarlyStrategy!.Title = pendingStrategyTitle!;
        pendingRegularStrategy!.Title = pendingStrategyTitle!;
        pendingLateStrategy!.Title = pendingStrategyTitle!;

        setInternalTournament(internalTournament => ({
            ...internalTournament,
            PricingStrategies: [...internalTournament.PricingStrategies, pendingEarlyStrategy!, pendingRegularStrategy!, pendingLateStrategy!]
        }));
    }

    const [isTipModalOpen, { setTrue: showTipModal, setFalse: hideTipModal }] = useBoolean(false);
    const [tip, setTip] = useState<string>();
    const [tipTitle, setTipTitle] = useState<string>();

    const showTip = (tip: string, title: string) => {
        setTip(tip);
        setTipTitle(title);
        showTipModal();
    }

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    const iconButtonStyles: Partial<IButtonStyles> = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };

    const getEarliestExpiry = () => {
        if (tournament.PricingStrategies.length === 0) {
            let maxDays = DateTime.fromJSDate(tournament.RegistrationEnds!).diff(DateTime.fromJSDate(tournament.DisplayAfter!), 'days').days;

            return DateTime.fromJSDate(tournament.DisplayAfter!).plus({ days: Math.min(maxDays, 14) }).toJSDate();
        }
        let earliestExpiry = tournament.PricingStrategies[0].Expiry!;
        for (let i = 1; i < tournament.PricingStrategies.length; i++) {
            if (tournament.PricingStrategies[i].Expiry! < earliestExpiry) {
                earliestExpiry = tournament.PricingStrategies[i].Expiry!;
            }
        }
        return earliestExpiry;
    };

    const getLatestExpiry = () => {
        if (tournament.PricingStrategies.length === 0) {
            return tournament.RegistrationEnds!;
        }
        let latestExpiry = tournament.PricingStrategies[0].Expiry!;
        for (let i = 1; i < tournament.PricingStrategies.length; i++) {
            if (tournament.PricingStrategies[i].Expiry! > latestExpiry) {
                latestExpiry = tournament.PricingStrategies[i].Expiry!;
            }
        }
        return latestExpiry;
    };

    const getRegularExpiry = () => {
        if (tournament.PricingStrategies.length === 0) {
            let maxDays = DateTime.fromJSDate(tournament.RegistrationEnds!).diff(DateTime.fromJSDate(tournament.DisplayAfter!), 'days').days;
            return DateTime.fromJSDate(tournament.DisplayAfter!).plus({ days: Math.min(maxDays, 28) }).toJSDate();
        }
        let earliestExpiry = getEarliestExpiry();
        let latestExpiry = getLatestExpiry();

        //return the first expiry found that is later than the earliest and earlier than the latest expiry
        for (let i = 0; i < tournament.PricingStrategies.length; i++) {
            if (tournament.PricingStrategies[i].Expiry! > earliestExpiry && tournament.PricingStrategies[i].Expiry! < latestExpiry) {
                return tournament.PricingStrategies[i].Expiry!;
            }
        }

        //return a date that's the midpoint between the early and late dates
        return DateTime.fromJSDate(earliestExpiry).plus({ days: Math.floor(DateTime.fromJSDate(latestExpiry).diff(DateTime.fromJSDate(earliestExpiry), 'days').days / 2) }).toJSDate();
    }

    const [earlyExpiry, setEarlyExpiry] = useState<Date>(getEarliestExpiry());
    const [regularExpiry, setRegularExpiry] = useState<Date>(getRegularExpiry());
    const [lateExpiry, setLateExpiry] = useState<Date>(getLatestExpiry());

    const [isEditingEarlyExpiry, setIsEditingEarlyExpiry] = useState<boolean>();
    const [isEditingRegularExpiry, setIsEditingRegularExpiry] = useState<boolean>();
    const [isEditingLateExpiry, setIsEditingLateExpiry] = useState<boolean>();

    const onEarlyExpiryChanged = (date: Date) => {
        //update any existing pricing strategies that use this expiry
        let strategies = internalTournament.PricingStrategies.filter(f => f.Expiry === earlyExpiry);

        for (let i = 0; i < strategies.length; i++) {
            strategies[i].Expiry = date;
        }

        setEarlyExpiry(date);
    }

    const onRegularExpiryChanged = (date: Date) => {
        //update any existing pricing strategies that use this expiry
        let strategies = internalTournament.PricingStrategies.filter(f => f.Expiry === regularExpiry);

        for (let i = 0; i < strategies.length; i++) {
            strategies[i].Expiry = date;
        }

        setRegularExpiry(date);
    }

    const onLateExpiryChanged = (date: Date) => {
        //update any existing pricing strategies that use this expiry
        let strategies = internalTournament.PricingStrategies.filter(f => f.Expiry === lateExpiry);

        for (let i = 0; i < strategies.length; i++) {
            strategies[i].Expiry = date;
        }

        setLateExpiry(date);
    }

    const getEditPricingProps = () : IContextualMenuProps => {
        const uniqueStrategies: { key: string, text: string, onClick: () => void }[] = internalTournament.PricingStrategies.reduce((acc: { key: string, text: string, onClick: () => void }[], p) => {
            if (!acc.some(strategy => strategy.text === p.Title)) {
                acc.push({
                    key: acc.length.toString(),
                    text: p.Title,
                    onClick: () => {
                        editPricing(p);
                    }
                });
            }
            return acc;
        }, []);
console.log(internalTournament.PricingStrategies);
        return {
            items: uniqueStrategies
        };
    }

    const editPricing = (strategy: IPricingStrategy) => {
        //store the current price for each item in the strategy against the pending strategy items
        setPendingStrategyTitle(strategy.Title);

        var earlyStrategy = internalTournament.PricingStrategies.find(f => f.Expiry === earlyExpiry && f.Id === strategy.Id);
        var regularStrategy = internalTournament.PricingStrategies.find(f => f.Expiry === regularExpiry && f.Id === strategy.Id);
        var lateStrategy = internalTournament.PricingStrategies.find(f => f.Expiry === lateExpiry && f.Id === strategy.Id);

        setPendingEarlyStrategy(earlyStrategy);
        setPendingRegularStrategy(regularStrategy);
        setPendingLateStrategy(lateStrategy);
    }

    const [isEditing, ] = useState<boolean>(false);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <br />
            <Stack.Item align='end'>
                <ActionButton iconProps={{ iconName: 'Add' }} onClick={showModal}>Add pricing</ActionButton>

                <CommandButton
                    disabled={true || internalTournament.PricingStrategies.length === 0}
                    menuProps={getEditPricingProps()}
                    iconProps={{ iconName: 'Edit' }}
                    onClick={showEditModal}>Edit pricing</CommandButton>


            </Stack.Item>
            <Stack horizontal tokens={{childrenGap:10}}>
                <Stack.Item>
                    <Text variant='large'>Early Pricing</Text>
                </Stack.Item>
                <Stack.Item align='center'>
                    <IconButton
                        styles={{root:{width:15,height:15}}}
                        style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:7}}
                        iconProps={{iconName:'StatusCircleQuestionMark'}}
                        onClick={() => showTip('Typically used to encourage early registration in return for a lower price.', 'Early Pricing')} />
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item align='center'>
                    {isEditingEarlyExpiry ? <DateEditor
                        minDate={tournament.DisplayAfter}
                        maxDate={tournament.RegistrationEnds}
                        date={earlyExpiry}
                        onDateChange={onEarlyExpiryChanged}
                        showTime />
                        :
                        <Text variant='small'>{GetTournamentLocalTime(earlyExpiry, tournament.Timezone)}</Text>
                    }
                </Stack.Item>
                <Stack.Item align='center'>
                    {isEditingEarlyExpiry ? <SaveIcon onClick={() => setIsEditingEarlyExpiry(false)} /> : <EditIcon onClick={() => setIsEditingEarlyExpiry(true)} />}
                </Stack.Item>


            </Stack>
            {internalTournament.PricingStrategies.filter(f => f.Expiry === earlyExpiry).map((p, idx) => <Text key={idx}>{p.Title}: {GetPricingLabelForStrategy(p, 0)}</Text>)}
            <br />
            <Stack horizontal tokens={{childrenGap:10}}>
                <Stack.Item>
                <Text variant='large'>Regular Pricing</Text>
                </Stack.Item>
                <Stack.Item align='center'>
                    <IconButton
                        styles={{root:{width:15,height:15}}}
                        style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:7}}
                        iconProps={{iconName:'StatusCircleQuestionMark'}}
                        onClick={() => showTip('Your desired price. Most people will register somewhere between early and the end of regular pricing.', 'Regular Pricing')} />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item align='center'>
                    {isEditingRegularExpiry ? <DateEditor
                        minDate={tournament.DisplayAfter}
                        maxDate={tournament.RegistrationEnds}
                        date={regularExpiry}
                        onDateChange={onRegularExpiryChanged}
                        showTime />
                        :
                        <Text variant='small'>{GetTournamentLocalTime(regularExpiry, tournament.Timezone)}</Text>
                    }
                </Stack.Item>
                <Stack.Item align='center'>
                    {isEditingRegularExpiry ? <SaveIcon onClick={() => setIsEditingRegularExpiry(false)} /> : <EditIcon onClick={() => setIsEditingRegularExpiry(true)} />}
                </Stack.Item>
            </Stack>
            {internalTournament.PricingStrategies.filter(f=>f.Expiry === regularExpiry).map((p, idx) => <Text key={idx}>{p.Title}: {GetPricingLabelForStrategy(p, 0)}</Text>)}

            <br />
            <Stack horizontal tokens={{childrenGap:10}}>
                <Stack.Item>
                <Text variant='large'>Late Pricing</Text>
                </Stack.Item>
                <Stack.Item align='center'>
                    <IconButton
                        styles={{root:{width:15,height:15}}}
                        style={{borderRadius:'50%', border:'solid', borderWidth:1, marginTop:7}}
                        iconProps={{iconName:'StatusCircleQuestionMark'}}
                        onClick={() => showTip('Typically a higher price to reinforce the benefits of early registration.', 'Late Pricing')} />
                </Stack.Item>
            </Stack>

            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Stack.Item align='center'>
                    {isEditingLateExpiry ? <DateEditor
                        minDate={tournament.DisplayAfter}
                        maxDate={tournament.RegistrationEnds}
                        date={tournament.RegistrationEnds}
                        onDateChange={onLateExpiryChanged}
                        showTime />
                        :
                        <Text variant='small'>{GetTournamentLocalTime(lateExpiry, tournament.Timezone)}</Text>
                    }
                </Stack.Item>
                <Stack.Item align='center'>
                    {isEditingLateExpiry ? <SaveIcon onClick={() => setIsEditingLateExpiry(false)} /> : <EditIcon onClick={() => setIsEditingLateExpiry(true)} />}
                </Stack.Item>
            </Stack>
            {internalTournament.PricingStrategies.filter(f=>f.Expiry === lateExpiry).map((p, idx) => <Text key={idx}>{p.Title}: {GetPricingLabelForStrategy(p, 0)}</Text>)}

            <Modal
                isOpen={isModalOpen}
                isBlocking
                containerClassName={contentStyles.container}
                onDismiss={hideModal}>
                    <div className={contentStyles.header}>
                    <span>Manage pricing</span>
                </div>
                <div className={contentStyles.body}>
                    <br />
                    <Label required>Title</Label>
                    <TextField
                        defaultValue={pendingStrategyTitle}
                        placeholder='Title that describes the pricing, for example "Ages 12-18" or "Special Events'
                        onChange={(e, v) => setPendingStrategyTitle(v!)} />
<br />
                    <Pivot>
                        <PivotItem headerText='Early Pricing'>
                            <PricingStrategyCreator
                                editPricingStrategy={pendingEarlyStrategy}
                                expiry={earlyExpiry!}
                                onValidated={(isValid, pricingStrategy) => { setIsPendingEarlyStrategyValid(isValid); setPendingEarlyStrategy(pricingStrategy); }} />
                        </PivotItem>
                        <PivotItem headerText='Regular Pricing'>
                            <PricingStrategyCreator
                                expiry={regularExpiry!}
                                editPricingStrategy={pendingRegularStrategy}
                                onValidated={(isValid, pricingStrategy) => { setIsPendingRegularStrategyValid(isValid); setPendingRegularStrategy(pricingStrategy); }} />
                        </PivotItem>
                        <PivotItem headerText='Late Pricing'>
                            <PricingStrategyCreator
                                expiry={lateExpiry!}
                                editPricingStrategy={pendingLateStrategy}
                                onValidated={(isValid, pricingStrategy) => { setIsPendingLateStrategyValid(isValid); setPendingLateStrategy(pricingStrategy); }} />
                        </PivotItem>
                    </Pivot>
                    <br />
                    <PrimaryButton disabled={!(isPendingEarlyStrategyValid && isPendingRegularStrategyValid && isPendingLateStrategyValid)} onClick={isEditing ? updatePricingStrategy : addPricingStrategy}>Submit</PrimaryButton>
                </div>

            </Modal>
            <Modal
                isOpen={isTipModalOpen}
                onDismiss={hideTipModal}
                containerClassName={contentStyles.container}>
                <div className={contentStyles.header}>
                    <span>{tipTitle}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close modal"
                        onClick={hideTipModal} />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={{childrenGap:10}}>
                        <Text variant="medium">{tip}</Text>
                    </Stack>
                </div>
            </Modal>
        </div>
    )
}

export default PricingWizard;