import { FC, useEffect, useState } from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {useParams} from 'react-router-dom';
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';
import { Student } from '../model/Student';
import Loader from "../components/Loader";
import CurriculumRow from '../components/CurriculumRow';
import { StudentCurriculumSubmission, SubmissionStatus } from '../model/StudentCurriculumSubmission';
import { Review, ReviewStatus } from '../model/Review';
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence, Stack, List, Text, ActionButton } from '@fluentui/react';
import { CurriculumItem, CurriculumResponse } from '../model/CurriculumItem';
import { getPriorCurriculumForStudent, getStudentDashboard } from '../ApiService';

const StudentDashboard : FC = () => {
    let { studentId } = useParams<{studentId: string }>() as {studentId: string };

    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"StudentDashboard" });

    useMsalAuthentication(InteractionType.Redirect, { scopes:['openid profile','offline_access'], state: document.location.href});
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [curriculum, setCurriculum] = useState<CurriculumItem[]>();
    const [priorCurriculum, setPriorCurriculum] = useState<CurriculumItem[]>();
    const [student, setStudent] = useState<Student>();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPriorCurriculum, setIsLoadingPriorCurriculum] = useState(false);
    

    const getStatus = (submissionsAtThisRank: StudentCurriculumSubmission[], reviews: Review[], curriculumId: string) : [SubmissionStatus, boolean] => {
      
      if (submissionsAtThisRank.length === 0 || submissionsAtThisRank.find(s=>s.CurriculumId === curriculumId) === undefined) {
        return [SubmissionStatus.Draft, false];
      }

      var hasPass = false;
      var hasBeenReviewed = false;

      for(let submission of submissionsAtThisRank) {
        
        if(submission.CurriculumId === curriculumId) {
          hasBeenReviewed = submission.Status === SubmissionStatus.Reviewed;
          
          try{            
            for(let foundReview of reviews) {
              if(foundReview.StudentCurriculumSubmissionId === submission.Id && foundReview!.Status === ReviewStatus.Pass) {
                hasPass = true;
              }
            }
          }
          catch{}
        }
      }

      if(!hasBeenReviewed){
        return [SubmissionStatus.PendingReview, false];
      }

      return [SubmissionStatus.Reviewed, hasPass];
    } 

    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (inProgress === "none" && account) {
              var model = await getStudentDashboard(instance, account, studentId);
              var student = model.Student;
              
              var curriculum = model.CurriculumItems;
              
              for(let i of curriculum) {              
                var existingSubmissions = model.CurriculumSubmissions;

                if(existingSubmissions !== undefined && existingSubmissions.length > 0) {
                  
                  //look for a submission that matches the student's current rank
                  var submittedAtThisLevel = existingSubmissions.filter((s:StudentCurriculumSubmission) => 
                    s.StudentLevelIdWhenSubmitted === student.Level!.Id);
                    
                  var status = getStatus(submittedAtThisLevel, model.Reviews, i.Id);

                  i.Status = status[0];
                  i.HasPass = status[1];
                }
              }
              
              setStudent(student);
              setCurriculum(curriculum);
              setIsLoading(false);                
            }
        }
       
        fetchData();    
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [isAuthenticated, inProgress, account]);

    const getIcon = (category: string) : string => {
      switch(category)
      {
        case "a":
          return ""
      }

      return "";
    }
    
    const onRenderCurriculumRow = (item: CurriculumItem | undefined, index: number | undefined) => {
      return (
       <CurriculumRow 
          isPass={item!.HasPass !== undefined ? item!.HasPass : false} 
          state={item?.Response === CurriculumResponse.None ? 
              undefined : item!.Status !== undefined ? 
                item!.Status! : SubmissionStatus.Draft} 
          icon={getIcon(item!.Category)} 
          item={item!} 
          key={index} 
          studentId={studentId} />
      );
    }; 

    const studentPersona: IPersonaSharedProps = {
      imageUrl: student?.ImageUri,
      imageInitials: `${student?.FirstName[0].toUpperCase()}${student?.LastName[0].toUpperCase()}`,
      text: `${student?.FirstName} ${student?.LastName}`,
      secondaryText: `${student?.Level!.Label}`,
      // tertiaryText: `Last Promotion: ${student?.PromotionHistory !== undefined && student.PromotionHistory.length > 0 ? new Date(student?.PromotionHistory[student.PromotionHistory.length-1].Date).toDateString() : "n/a"}`,
      size: PersonaSize.size120
    };

    const loadPriorCurriculum = async () => {
      setIsLoadingPriorCurriculum(true);
      var priorCurriculum = await getPriorCurriculumForStudent(instance, account!, student!.Id);
      
      setPriorCurriculum([...priorCurriculum]);
      setIsLoadingPriorCurriculum(false);
    }


   return (  
        <>
        {isLoading ? 
            <Loader Text="Fetching student, just a moment..."/>
            :
            <>
                <Stack horizontal style={{marginTop:10}}>
                  <Persona
                    {...studentPersona}
                    presence={PersonaPresence.none}
                    hidePersonaDetails={false}
                    imageAlt={`${student?.FirstName} ${student?.LastName}`}
                    size={PersonaSize.size100}                    
                  />
                </Stack>              
          
                <>
                
                  {curriculum === undefined ?
                      null : 
                      curriculum.length > 0 ?
                      <>
                        <h4>{student?.Level!.Label} curriculum</h4>
                        <hr style={{maxWidth:400, marginRight:"auto",marginLeft:0,marginTop:10}}/>
                        <List items={curriculum} getKey={c=>c.Id} onRenderCell={onRenderCurriculumRow} />
                      </> : <Text variant='small'>No curriculum found.</Text>
                  }
              </> 

                <>
                  {priorCurriculum !== undefined && priorCurriculum.length > 0 ? 
                  <>
                    <h4>Prior curriculum</h4>
                    <hr style={{maxWidth:400, marginRight:"auto",marginLeft:0,marginTop:10}}/>   
                    <List items={priorCurriculum} getKey={c=>c.Id} onRenderCell={onRenderCurriculumRow} />
                  </> : priorCurriculum?.length === 0 && curriculum !== undefined && curriculum?.length > 0 ? <Text variant='small'>No prior curriculum found.</Text> : null}
                  {isLoadingPriorCurriculum ? <Loader Text="Fetching prior curriculum, just a moment..."/> : null }
                  {curriculum !== undefined && curriculum.length > 0 ? 
                    priorCurriculum === undefined && !isLoadingPriorCurriculum ? <ActionButton iconProps={{iconName:'History'}} onClick={loadPriorCurriculum}>Review prior curriculum</ActionButton> : null
                  : null}
                </>
            </>
            }
        </>
   );
}

export default StudentDashboard;