import React, { FC, useEffect, useState } from 'react';
import {ActivityFeedItem, ActivityFeedItemCategory} from '../model/ActivityFeedItem';
import {ActivityItem, IPersonaSharedProps, mergeStyleSets} from '@fluentui/react';
import { Link } from 'react-router-dom';

interface IActivityItem {
    activityDescription: any[];
    activityPersonas: IPersonaSharedProps[];
    timeStamp: string;
}

export interface IActivityFeedProps {
    values: ActivityFeedItem[]
}

const ActivityFeed : FC<IActivityFeedProps> = ({values}) => {
     
    const [activities, setActivities] = useState<IActivityItem[]>()
    const classNames = mergeStyleSets({
        exampleRoot: {
            marginTop: '20px',
          },
        nameText: {
          fontWeight: 'bold',
        },
      });

      
    useEffect(()=>{

        var toSet = new Array<IActivityItem>();
    
        for(let item of values) {
            var toAdd = {
                activityDescription: [
                    <>
                    {item.Category === ActivityFeedItemCategory.Global ? 
                        <>{item.Author} posted an announcement<br /></> : 
                        null
                    } 
                    <>{item.Text}</>
                    {item.Link !== null && item.Link.length > 0 ?
                    <><br /><Link to={item.Link} title="View">View</Link></> : null}
                    </>                                        
                ],
                activityPersonas: [
                    { imageUrl: item.ImageUri }
                ],
                timeStamp: new Date(item.Created).toDateString(),
            };
            
            toSet.push(toAdd);
        }
        setActivities(toSet);
    },[values]);
    
    return (
        <>
            {activities?.map((item: IActivityItem, idx: number) => (
                <div key={idx}>
                    <ActivityItem {...item} key={idx} className={classNames.exampleRoot} />
                </div>
            ))}
        </>
    );    
}

export default ActivityFeed;