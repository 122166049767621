import { Stack, TooltipHost, Persona, PersonaSize, PersonaPresence, DefaultButton } from '@fluentui/react';
import React, { FC, useEffect, useState } from 'react';
import { Student } from '../model/Student';
import { TournamentEntryViewModel } from '../model/TournamentEntryViewModel';
import VideoReactionHost from './VideoReactionHost';

export interface IData {
    students: Student[] | undefined;
    entries: TournamentEntryViewModel[] | undefined;
    tournamentId: string;
    randomiseOrder?: boolean;
}

const Carousel : FC<IData> = ({students, entries, tournamentId, randomiseOrder = true}) => {
    const [currentEntry, setCurrentEntry] = useState<TournamentEntryViewModel>();
    const [arrangedEntries, setArrangedEntries] = useState<TournamentEntryViewModel[]>();
    const [idx, setIdx] = useState<number>();

    useEffect(()=> {
        if(entries !== undefined && entries.length > 0) {
            //shuffle them 
            var e = randomiseOrder ? 
                entries.map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value) :
                entries;
            
            setArrangedEntries(e);
            setIdx(0);
        }
        // eslint-disable-next-line 
    }, []);

    useEffect(()=> {        
        if(arrangedEntries === undefined || idx === undefined) {
            return;
        }

        setCurrentEntry(arrangedEntries[idx]);
    }, [idx, arrangedEntries]);

    const onGoNext = () => {
        if(arrangedEntries === undefined || idx === undefined) {
            return;
        }

        if(idx === arrangedEntries.length - 1) {
            //move to view of people in event/end screen telling ppl to come back if the tournament isn't over

        }

        var newIdx = idx + 1;
        
        setIdx(newIdx);    
    }

    const onGoBack = () => {
        
    }

    return (
        <>
        {arrangedEntries !== undefined && 
            currentEntry !== undefined && 
            idx !== undefined ? 
                <VideoReactionHost  
                    style={{maxHeight:'500px'}}          
                    Minimal
                    ShowSkipControls
                    CanGoNext={idx < arrangedEntries.length -1}
                    CanGoBack={idx >=1}
                    GoNext={onGoNext}
                    GoBack={onGoBack}
                    CurriculumId={currentEntry.StudentCurriculumSubmission.CurriculumId}
                    CurriculumSubmission={currentEntry.StudentCurriculumSubmission}
                    Student={currentEntry.Student}
                    TournamentId={tournamentId}                
                    />
            :
            <Stack tokens={{childrenGap:10}}>
                <Stack horizontal tokens={{childrenGap:10}} wrap>
                    {students?.map((student, idx) => {
                        return (                                             
                                <Stack.Item  className='athlete-host' style={{['--animation-delay' as any]:`${Math.random()*5}s`}} key={idx}>
                                    <TooltipHost
                                        tooltipProps={{onRenderContent:()=> (<>{student.FirstName} {student.LastName[0]}<br /> {student.Level?.Description}</>)}}>
                                        <Persona                                                                       
                                            imageUrl={student.ImageUri}
                                            imageInitials={`${student.FirstName[0]}${student.LastName[0]}`}
                                            size={PersonaSize.size48}
                                            hidePersonaDetails
                                            presence={PersonaPresence.none}
                                            imageAlt={`${student.FirstName} ${student.LastName[0]}, ${student.LevelId}`}
                                        />
                                    </TooltipHost>
                                </Stack.Item>
                        )
                    })}
                </Stack>
                {arrangedEntries !== undefined && arrangedEntries.length > 0 ? <DefaultButton onClick={() => setIdx(0)}>Watch again</DefaultButton> : null}
            </Stack>
        }
        </>        
    );    
}

export default Carousel;