import { Review } from './Review';

export enum ScoreTypes {
    Percentage = 0,
    Sum = 1
}

export interface IResultRow {
    Value: number;
    Label: string;
    IsTotal: boolean;
}

export interface IResult {
    Type: ScoreTypes;
    Rows: IResultRow[];
    Total: number;
}

export interface IReviewCollection {
    Reviews: Review[];  
    Result: IResult;
    ResultText: string;
    HasFeedback: boolean;
}

export class ReviewCollection implements IReviewCollection {
    Reviews: Review[];
    Result: IResult;
    ResultText: string;
    HasFeedback: boolean;

    constructor(
        params: IReviewCollection = {} as IReviewCollection) {
            
        let {
            Reviews = [],
            Result = {Rows:[], Total: -1, Type: ScoreTypes.Percentage},
            ResultText = '',
            HasFeedback = false
        } = params;
            
        this.Reviews = Reviews;
        this.Result = Result;
        this.ResultText = ResultText;
        this.HasFeedback = HasFeedback;
    }
}