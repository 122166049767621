import React, { FC, useEffect, useState } from 'react';
import {  FontWeights, getTheme, mergeStyleSets, PrimaryButton, Stack, Text } from '@fluentui/react';
import Loader from './Loader';
import { IImportRulesPreview, importDivisionRulesWithPreviewId, previewImportDivisionRules } from '../ApiService';
import { useMsal, useAccount } from '@azure/msal-react';
import { Tournament } from '../model/Tournament';

interface IData {
    file: File;
    tournament: Tournament;
}

enum ImportState {
    PendingPreview = 0,
    PreviewComplete = 1,
    PendingImport = 2,
    ImportComplete = 3
}

const DivisionRuleImporter : FC<IData> = ({file, tournament}) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [importState, setImportState] = useState<ImportState>(ImportState.PendingPreview);
    
    const theme = getTheme();
    const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        maxWidth: 900        
    },
    header: [
        theme.fonts.xLarge,
        {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
        },
    },
    });

    const [error, setError] = useState<string>();
    const [result, setResult] = useState<IImportRulesPreview>();

    useEffect(()=> {
        const fetchAsync = async () => {
            var result = await previewImportDivisionRules(instance, account!, tournament.Id, file);
               
            if(typeof result === 'string') {
                setError('There was an error importing the file: ' + result);
                return;
            }

            if (result === undefined) {
                setError('An unknown error occurred while importing the file.');
                return;
            }

            setImportState(ImportState.PreviewComplete);
            setResult(result);
        }

        if (instance && account && tournament && file) {
            fetchAsync();    
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [instance, account, tournament, file]);
    
    const getRulesRemoved = (result: IImportRulesPreview): number => {
        if (result === undefined) return 0;
        
        //sum the total of all rules removed in the result object
        return result.Modifications.reduce((acc, rule) => acc + rule.RulesRemoved, 0) || 0;
    }

    const getRulesAdded = (result: IImportRulesPreview): number => {
        if (result === undefined) return 0;
        
        //sum the total of all rules added in the result object
        return result.Modifications.reduce((acc, rule) => acc + rule.RulesAdded, 0) || 0;
    }

    const completeImport = async () => {
        if (result === undefined) return;

        setImportState(ImportState.PendingImport);
        
        var importResult = await importDivisionRulesWithPreviewId(instance, account!, tournament.Id, result.Id);

        if (!importResult) {
            setImportState(ImportState.PendingPreview);
            setError('There was an error importing the file');
            return;
        }

        setImportState(ImportState.ImportComplete);
    }
    
    return (
        <>
            <div className={contentStyles.header}>
                <span>{importState === ImportState.PendingPreview ?
                    "Just a moment" :
                    importState === ImportState.PreviewComplete ?
                        "Ready to import" : 
                            "Importing"}</span>                
            </div>
            <div className={contentStyles.body}>   
                {result === undefined ? 
                    <Loader Text='Preparing import, just a moment...' /> :
                    <Stack tokens={{ childrenGap: 15 }}>
                        {importState === ImportState.PreviewComplete && <Text variant='medium'>Ready to import {getRulesAdded(result)} rules and remove {getRulesRemoved(result)} rules.</Text>}
                        {importState === ImportState.PreviewComplete && result.Id !== undefined && <PrimaryButton onClick={completeImport}>Complete Import</PrimaryButton>}
                        {importState === ImportState.ImportComplete && <Text variant='medium' style={{marginTop:20}}>Import successfully completed.</Text>}                        
                    </Stack>
                }
                {importState === ImportState.PendingImport && <Loader Text='Importing, just a moment...' />}
                {error === undefined ? null : <p style={{color:'red'}}>{error}</p>}
            </div>
        </>
    )
}

export default DivisionRuleImporter;