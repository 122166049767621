export enum AudienceComparison {
        Exact = 0,
        EqualToOrGreaterThan = 1
}
export interface IAudience {    
    LevelId: string;
    ProgramId: string;
    Comparison: AudienceComparison;
}

export class Audience implements IAudience {
    LevelId: string;
    ProgramId: string;
    Comparison: AudienceComparison;

    constructor(
        params: IAudience = {} as IAudience) {
            
        let {
            LevelId = "",
            ProgramId = "",
            Comparison = AudienceComparison.Exact
        } = params;
            
        this.LevelId = LevelId;
        this.ProgramId = ProgramId;
        this.Comparison = Comparison;
    }
}