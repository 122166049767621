import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Label, PrimaryButton, Stack, TextField, Text, SpinButton, Toggle } from '@fluentui/react';
import EditableImage from './EditableImage';
import { StoragePurpose } from '../../../Storage';
import { ICatalogItemWithQuantity } from '../../../model/CatalogItem';
import { CleanId } from '../../../Validation';

interface ICatalogProps {
    editCatalogItem?: ICatalogItemWithQuantity;
    onStateChange: (currentState: CatalogState, catalogItem: ICatalogItemWithQuantity) => void;
}

export enum CatalogState {
    CreateCatalogItem = 1,
    CanSubmit = 2
}

const CatalogEditor : FC<ICatalogProps> = ({editCatalogItem, onStateChange}) => {

    const [catalogItem, setCatalogItem] = useState<ICatalogItemWithQuantity>(editCatalogItem === undefined ? { Description: '', Id: '', ImageUri: '', Inputs: [], IsSuggested: false, IsTaxed: false, Name: '', Price: 0, AvailableQuantity: 10, CatalogItemId: '', AdditionalTerms:[] } : editCatalogItem);
    const [currentState, setCurrentState] = useState<CatalogState>(CatalogState.CreateCatalogItem);
    const [canGoNext, setCanGoNext] = useState<boolean>(false);
    const [nextButtonText, setNextButtonText] = useState<string>("Next");
    
    useEffect(()=> {
        onStateChange(currentState, catalogItem!);

        // eslint-disable-next-line react-hooks/exhaustive-deps    
    },[currentState]);

    
    useEffect(()=> {
        checkModelValidationState();
        // eslint-disable-next-line react-hooks/exhaustive-deps       
    }, [catalogItem]);
    
    
    const checkModelValidationState = ()  => {
        switch(currentState)
        {
            case CatalogState.CreateCatalogItem:
                {
                    if(catalogItem.Name.length === 0) {
                        setCanGoNext(false);
                        return;    
                    }
        
                    if (catalogItem.Description.length === 0) {
                        setCanGoNext(false);
                        return;
                    }                                    

                    break;
                }                
        }

        setCanGoNext(true);
    }   

    const goNextState = () => {
        switch (currentState) {
            case CatalogState.CreateCatalogItem:
                setCurrentState(CatalogState.CanSubmit);
                setNextButtonText("Next");
                setCanGoNext(true);
                return;
        }
    }

//   const goPreviousState = () => {
//     switch(currentState) {
//         case CatalogState.CanSubmit:
//             setCurrentState(CatalogState.CreateCatalogItem);
//             return;
//     }
//   }
    
    const onNameChanged = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
        if (newValue === undefined) {
            return;
        }
        
        if (editCatalogItem === undefined) {
            setCatalogItem(catalogItem => ({ ...catalogItem, Name: newValue!, Id: CleanId(newValue!) }))    
        }     
        else {
            setCatalogItem(catalogItem => ({ ...catalogItem, Name: newValue! }))
        }
        
    }
    return (
        <div style={{width:'100%'}}>
        <Stack tokens={{childrenGap:10}}>
            {currentState === CatalogState.CreateCatalogItem ?      
                    <Stack>
                        <br />
                        <Label required>Image</Label>
                        <EditableImage
                            purpose={StoragePurpose.Tournament}
                            uri={catalogItem.ImageUri}
                            onUriChange={(uri) => setCatalogItem(catalogItem => ({ ...catalogItem, ImageUri: uri }))} />
                        
                    <TextField label="Name" 
                        required 
                        placeholder="The name of the item"
                        defaultValue={catalogItem.Name}
                        onChange={onNameChanged} />

                        <SpinButton
                            placeholder='Price for this catalog item'
                            label='Pricing'
                            onChange={(e,v)=>setCatalogItem(catalogItem => ({...catalogItem, Price: parseInt(v!)}))}
                            defaultValue={catalogItem.Price.toString()}
                        />   
                        
                    <TextField label="Description"
                        required 
                        placeholder="Description of the event that will be displayed on the registration page."
                        defaultValue={catalogItem.Description} 
                        multiline rows={10} 
                            onChange={(e, newValue) => setCatalogItem(catalogItem => ({ ...catalogItem, Description: newValue! }))} />    
                        
                        {/* Supporting only a single additional terms item via the UI */}
                    <TextField label="Additional Terms"                          
                        defaultValue={catalogItem.AdditionalTerms[0]}
                        placeholder="Any additional terms the purchaser must agree to."
                            onChange={(e, newValue) => setCatalogItem({ ...catalogItem, AdditionalTerms: [newValue!]})} />
                        

                        <Toggle
                            label='Can Purchase'
                            defaultChecked={catalogItem.AvailableQuantity > 0}
                            onChange={(e, checked) => setCatalogItem(catalogItem => ({ ...catalogItem, AvailableQuantity: checked! ? 10 : 0 }))} />                    
                </Stack>
                : null}

            {currentState === CatalogState.CanSubmit ? 
                    <>
                        <br />
                        <Text><strong>Name:</strong> {catalogItem.Name}</Text>
                        <Text><strong>Price:</strong> {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(catalogItem.Price)}</Text>
                        <Text><strong>Can Purchase:</strong> {catalogItem.AvailableQuantity > 0 ? 'Yes' : 'No'}</Text>
                        <Text style={{ maxWidth: '90%' }}><strong>Description:</strong> {catalogItem.Description}</Text>
                        
                    </> : null
                }

                <Stack horizontal horizontalAlign='end' tokens={{childrenGap:10}}>                    
                    {currentState !== CatalogState.CanSubmit ? <PrimaryButton disabled={!canGoNext} onClick={()=>goNextState()}>{nextButtonText}</PrimaryButton> : null}
                </Stack>
            </Stack>
        </div>
    );    
}

export default CatalogEditor;