import React, { FC, useEffect, useState} from 'react';
import { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { TextField, DefaultButton, IContextualMenuItem, PrimaryButton, Stack, Text, Slider, IStackStyles, Persona, PersonaPresence, PersonaSize, SpinButton } from '@fluentui/react';
import { getRubricPoints, getCurriculum, getRubric, getStudentCurriculumSubmission, postReview } from '../ApiService';
import { Rubric, RubricType } from '../model/Rubric';
import { IReviewAuthor, Review, ReviewStatus } from '../model/Review';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ReviewFeedback } from '../model/ReviewFeedback';
import { StudentCurriculumSubmission } from '../model/StudentCurriculumSubmission';
import { Resource } from '../model/Resource';
import VideoPlayer from './VideoPlayer';
import { VideoMarker } from '../model/VideoMarker';
import { RubricPoints } from '../model/RubricPoints';
import { Student } from '../model/Student';
import Loader from './Loader';
import { CurriculumItem, CurriculumType } from '../model/CurriculumItem';
import { TournamentScoring } from '../TournamentHooks';

export interface IFeedbackModal {
    CurriculumId: string;
    CurriculumSubmissionId: string;
    Student: Student;
    TournamentId?: string;
    AuthoredBy?: IReviewAuthor;
    EntrySubmitted: (success:boolean)=>void;
    HideNeedsWork?: boolean;
}

const VideoFeedbackHost : FC<IFeedbackModal> = ({CurriculumId, CurriculumSubmissionId, Student, EntrySubmitted, TournamentId, AuthoredBy, HideNeedsWork}) => {

    enum FeedbackState {
        ViewVideo = 0,
        AnnotateVideo = 1,
        VideoFinished = 2,
        ProvideComments = 3
    }

    const [videoRef,setVideoRef] = useState<HTMLVideoElement>();
    const [feedbackState, setFeedbackState] = useState<FeedbackState>(FeedbackState.ViewVideo);
    const [review, setReview] = useState(new Review());
    const [canSubmit, setCanSubmit] = useState<boolean>();
    const [deductionMarkers, setDeductionMarkers] = useState<VideoMarker[]>([])
    const [rubric, setRubric] = useState<Rubric[]>(); 
    const [rubricPoints, setRubricPoints] = useState<RubricPoints[]>();
    const [deduction, setDeduction] = useState<Rubric>();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuthenticated = useIsAuthenticated();
    const [score, setScore] = useState<number>(100);
    const [submission, setSubmission] = useState<StudentCurriculumSubmission>();
    const [curriculumItem, setCurriculumItem] = useState<CurriculumItem>();
    const [tournamentScoring, ] = useState<TournamentScoring>(new TournamentScoring());

    const handleDeduction = (deduction: Rubric) => {        
        setFeedbackState(FeedbackState.AnnotateVideo);
        videoRef?.pause();
        setDeduction(deduction);                
    }


    const handleImmediateDeduction = (deduction: Rubric) => {
        //store the feedback
        var feedback = new ReviewFeedback({
            X: -1,
            Y: -1,
            Height: -1,
            Width: -1,
            Timestamp: videoRef?.currentTime!,            
            RubricId: deduction?.Id,
            RubricType: deduction.Type,
            RubricTitle: deduction?.Title,
            Weight: deduction.Weight                 
        });
        review.Feedback.push(feedback);

        setReview({...review});
        
        setFeedbackState(FeedbackState.ViewVideo);
        setDeductionMarkers([...deductionMarkers, { 
            Title: feedback.RubricTitle!, 
            Timestamp: feedback.Timestamp,
            X: -1,
            Y: -1,
            Height: -1,
            Width: -1,
        }]);
        
        updateScore();
    }

    const onPlaybackStatusChanged = (x: number, y: number, width: number, height: number, isPlaying: boolean) => {
        if(feedbackState === FeedbackState.AnnotateVideo && deduction !== undefined) {

            //store the feedback
            var feedback = new ReviewFeedback({
                X: Math.round(x),
                Y: Math.round(y),
                Height: Math.round(height),
                Width: Math.round(width),
                Timestamp: videoRef?.currentTime!,            
                RubricId: deduction?.Id,
                RubricType: deduction.Type,
                RubricTitle: deduction?.Title,
                Weight: deduction.Weight                 
            });
            review.Feedback.push(feedback);
    
            setReview({...review});
            
            setFeedbackState(FeedbackState.ViewVideo);
            setDeductionMarkers([...deductionMarkers, { 
                Title: feedback.RubricTitle!, 
                Timestamp: feedback.Timestamp,
                X: Math.round(x),
                Y: Math.round(y),
                Height: Math.round(height),
                Width: Math.round(width),
            }]);

            updateScore();
        }
    }

    const getDeductionMenu = () : IContextualMenuProps => {
        var toReturn : IContextualMenuProps = {items:[]};

        if(rubric !== undefined) {
            for(let item of rubric) {
                let i = item;

                if(i.Type === RubricType.TimeScoped) {
                    toReturn.items.push({
                        key: item.Id,
                        text: item.Title,
                        id: item.Title,
                        onClick: (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem) => 
                            curriculumItem?.Type === CurriculumType.TournamentEvent ? 
                                handleImmediateDeduction(i) : 
                                handleDeduction(i)
                    } as IContextualMenuItem);
                }
            }
        }

        return toReturn;
    }

    useEffect(()=> {
        if(review.Text.length === 0) {
            setCanSubmit(false);                
            return;
        } 

        setCanSubmit(true);
    },[review]);

    const updateScore = async () => {
        var score = await tournamentScoring.calculateScore(review.Feedback, rubricPoints!);        
        setScore(score);
    }

    useEffect(()=> {
        rubric?.filter(r=>r.Type === RubricType.Global || r.Type === RubricType.Numeric).forEach(r=> {
            if(review.Feedback.find(f=>f.RubricId === r.Id) === undefined) {                
                review.Feedback.push(new ReviewFeedback({
                    RubricId: r.Id,
                    RubricType: r.Type,
                    Weight: r.Weight,
                    RubricTitle: r.Title,
                    X: -1,
                    Y: -1,
                    Height: -1,
                    Width: -1,
                    Timestamp: -1
                }));
            }
        });

        updateScore();
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    },[rubricPoints]);


    useEffect(() => {
        if(!isAuthenticated) {
            return;
        }

        const fetchData = async () => {
            if (account) {
                try {
                    var submission = await getStudentCurriculumSubmission(instance, account, CurriculumSubmissionId);    
                    var curriculumItem = await getCurriculum(instance, account, CurriculumId);
                    var rubric = await getRubric(instance, account, curriculumItem.Category);
                    var rubricPoints = await getRubricPoints(instance, account, rubric);

                    setCurriculumItem(curriculumItem);
                    setSubmission(submission);
                    setRubric(rubric);     
                    setRubricPoints(rubricPoints);
                }            
                catch(error) {
                    console.log('acquire token error: ' + error);
                }
            }
        }
       
        fetchData();            
      // eslint-disable-next-line react-hooks/exhaustive-deps                 
    }, [account, isAuthenticated]);

    const submitAccepted = async () => {
        review.StudentCurriculumSubmissionId = CurriculumSubmissionId;
        review.Status = ReviewStatus.Pass;
        
        if(TournamentId !== undefined) {
            review.AuthoredBy = AuthoredBy;
        }
        
        var result = await postReview(instance, account!, review, TournamentId);

        if(result?.ok) {
            EntrySubmitted(true);
        }
        else {
            EntrySubmitted(false);
        }
    };

    const submitNeedsWork = async () => {
        review.StudentCurriculumSubmissionId = CurriculumSubmissionId;
        review.Status = ReviewStatus.Fail;
        
        var result = await postReview(instance, account!, review);

        if(result?.ok) {
            EntrySubmitted(true);
        }
        else {
            EntrySubmitted(false);
        }
    };

    const sliderAriaValueText = (value: number) => `${value} percent`;
    const sliderValueFormat = (value: number) => `${value} points`;

    const stackStyles: Partial<IStackStyles> = { root: { maxWidth: 300 } };

    const rubricSliderChanged = (foundRubric: Rubric, value: number) => {
        review.Feedback.find(f=>f.RubricId === foundRubric?.Id)!.Weight = value/100;
        
        setReview({...review});
        updateScore();
    }

    const numericInputChanged = (foundRubric: Rubric, value: string | undefined) => {
        if(value === undefined) {
            review.Feedback.find(f=>f.RubricId === foundRubric?.Id)!.Weight = 0;
            return;
        }

        review.Feedback.find(f=>f.RubricId === foundRubric?.Id)!.Weight = parseInt(value)/100;
        
        setReview({...review});
        updateScore();
    }


    return (
        <>
        {feedbackState === FeedbackState.ViewVideo || 
            feedbackState === FeedbackState.AnnotateVideo || 
            feedbackState === FeedbackState.VideoFinished ? 
        <div>
        {submission !== undefined ?
            <>
            <Stack>
                <div style={{backgroundColor:'white', paddingLeft:20, paddingRight:20, paddingBottom:20}}>
                    <Stack tokens={{childrenGap:10}}>
                        <Text variant='xLargePlus'>{curriculumItem?.Name}</Text>
                        <Persona
                            imageUrl={Student.ImageUri}
                            imageInitials={`${Student.FirstName[0]}${Student.LastName[0]}`}
                            size={PersonaSize.size32}
                            text={`${Student.FirstName} ${Student.LastName[0]}.`}
                            secondaryText={Student.Level?.Label}
                            hidePersonaDetails={false}
                            showSecondaryText
                            presence={PersonaPresence.none}
                            imageAlt={`${Student.FirstName[0]} ${Student.LastName[0]}., ${Student.Level?.Label}`}
                        />
                        <Text variant='mediumPlus'>Current Score: <strong>{score}</strong></Text>
                    </Stack>
                    <Stack tokens={{childrenGap:10}} horizontalAlign='stretch'>
                        <VideoPlayer 
                            Markers={deductionMarkers}
                            PlaybackStatusChanged={onPlaybackStatusChanged}
                            VideoLoaded={(videoRef:HTMLVideoElement) => setVideoRef(videoRef)}
                            Resource={new Resource({Uri: submission?.ContentUri!, Description: '', MediaType: submission?.ContentType!, Name: ''})}
                            VideoEnded={()=>setFeedbackState(FeedbackState.VideoFinished)} /> 
                            {feedbackState === FeedbackState.AnnotateVideo ? <Text variant="smallPlus" style={{color:"red"}}>Tap the video where the deduction occurred</Text> : null}
                        {rubric === undefined || rubric.find(r=>r.Type === RubricType.TimeScoped) === undefined ? null 
                        : 
                            rubric.filter(r=>r.Type === RubricType.TimeScoped).length > 2 ?
                            <DefaultButton 
                                disabled={rubric.length === 0 || feedbackState === FeedbackState.AnnotateVideo} 
                                menuProps={getDeductionMenu()} 
                                onMenuClick={()=>videoRef?.pause()}>Deduction</DefaultButton>
                                :
                            <Stack tokens={{childrenGap:20}} horizontal horizontalAlign='center'>{rubric.filter(r=>r.Type === RubricType.TimeScoped).map((r,idx) => <DefaultButton key={idx} style={{padding:20}} onClick={() => curriculumItem?.Type === CurriculumType.TournamentEvent ? handleImmediateDeduction(r) : handleDeduction(r)}>{r.Title}</DefaultButton>)}</Stack>
                        }
                    </Stack>
            </div>
            </Stack>
                {feedbackState === FeedbackState.VideoFinished ? <><br /><PrimaryButton onClick={()=>setFeedbackState(FeedbackState.ProvideComments)}>Next</PrimaryButton></> : null}
            </>
            : 
            <Loader Text="Almost there..." />
        }
            
        </div> : <>
        <div style={{backgroundColor:'white', marginTop:20, padding:20}}>
            <Stack tokens={{childrenGap:10}}>
                <Text variant='xLargePlus'>{curriculumItem?.Name}</Text>
                <Persona
                    imageUrl={Student.ImageUri}
                    imageInitials={`${Student.FirstName[0]}${Student.LastName[0]}`}
                    size={PersonaSize.size32}
                    text={`${Student.FirstName} ${Student.LastName[0]}.`}
                    secondaryText={Student.Level?.Label}
                    hidePersonaDetails={false}
                    showSecondaryText
                    presence={PersonaPresence.none}
                    imageAlt={`${Student.FirstName[0]} ${Student.LastName[0]}., ${Student.Level?.Label}`}
                />
                <Text variant='mediumPlus'>Current Score: <strong>{score}</strong></Text>
            </Stack>
        </div>
        <div style={{backgroundColor:'white', marginTop:20, paddingTop:5, paddingLeft:20, paddingRight:20, paddingBottom:1}}>
                <p>{review.Feedback.sort((a:ReviewFeedback,b:ReviewFeedback) => a.Timestamp > b.Timestamp ? 1 : -1)
                        .filter(f=>f.Timestamp !== -1)
                        .map((value:ReviewFeedback, idx:number) => 
                            <span key={idx.toString()}>{value.Timestamp.toFixed(2)} {value.RubricTitle}<br /></span>)}
                </p>

                {rubric?.find(r=>r.Type === RubricType.Global) !== undefined ? 
                <>
                <Stack tokens={{childrenGap:10}} styles={stackStyles}>
                    {rubric.filter(r=>r.Type === RubricType.Global).map((r,idx) => {
                        return <Slider
                            key={idx}                         
                            label={`${r.Title}`}
                            max={r.Weight*100}
                            defaultValue={review.Feedback.find(f=>f.RubricId === r.Id) === undefined ? r.Weight*100 : review.Feedback.find(f=>f.RubricId === r.Id)!.Weight*100}
                            style={{maxWidth:'300px'}}
                            ariaValueText={sliderAriaValueText}
                            valueFormat={sliderValueFormat}
                            onChanged={(e,v)=>rubricSliderChanged(r,v)}
                            showValue
                        />
                    })}
                </Stack>
                </> : null}
                <br /><br />
                {rubric?.find(r=>r.Type === RubricType.Numeric) !== undefined ? 
                <>
                <Stack tokens={{childrenGap:10}} styles={stackStyles}>
                    {rubric.filter(r=>r.Type === RubricType.Numeric).map((r,idx) => {
                        return <SpinButton
                                    key={idx}    
                                    inputMode='numeric'
                                    min={0}
                                    max={9999}
                                    label={`${r.Title}`}
                                    onChange={(e,v)=>numericInputChanged(r,v)}
                                    defaultValue={review.Feedback.find(f=>f.RubricId === r.Id) === undefined ? (r.Weight*100).toString() : (review.Feedback.find(f=>f.RubricId === r.Id)!.Weight*100).toString()}
                                    style={{maxWidth:'300px'}}
                        />
                    })}
                </Stack>
                <br />
                </> : null}
                <TextField 
                    label="Feedback"
                    key={CurriculumId}
                    placeholder="Enter feedback for the student here" 
                    multiline={true} 
                    required 
                    onChange={(e:any, newValue?:string)=> {review.Text = newValue!; setReview({...review});}} />
                <br />                
            </div>
            <br />
            <Stack tokens={{childrenGap:10}}>                
                <Stack horizontal tokens={{childrenGap:20}}>
                    <PrimaryButton style={{paddingTop:20, paddingBottom:20,paddingLeft:40, paddingRight:40}} disabled={!canSubmit} onClick={()=>submitAccepted()}>Accept</PrimaryButton> 
                    {HideNeedsWork? null : <Stack.Item align='center'>
                        <DefaultButton disabled={!canSubmit} onClick={()=>submitNeedsWork()}>Needs work</DefaultButton>                     
                    </Stack.Item>}
                </Stack>
                <Stack.Item align='end'>
                    <DefaultButton onClick={()=>setFeedbackState(FeedbackState.VideoFinished)}>Go Back</DefaultButton>
                </Stack.Item>
            </Stack>                
            </>}
            {/* <Text variant="smallPlus"><em>{student?.FirstName} {student?.LastName}</em></Text>
            <Stack tokens={stackTokens}>
                <Text variant="small">{curriculumItem?.Description}</Text>
            </Stack> */}
        </>
    );
}

export default VideoFeedbackHost;