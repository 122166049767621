import { Image, ImageFit } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { EditIcon, ImagePixelIcon } from '@fluentui/react-icons-mdl2';
import FileUploadButton, { IUploadedFile } from '../../../components/FileUploadButton';
import { StoragePurpose } from '../../../Storage';

interface IData {
    uri?: string;
    purpose: StoragePurpose;
    width?: number;
    onUriChange?: (uri: string) => void;
}
const EditableImage : FC<IData> = ({uri, onUriChange, purpose, width=165}) => {
    
    const [uriValue, setUriValue] = useState<string>(uri === undefined ? "" : uri);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const onSave = (uploadedFile: IUploadedFile) => {
        setUriValue(uploadedFile.absoluteFilePath);
        setIsEditing(false);
        onUriChange?.(uploadedFile.absoluteFilePath.substring(0, uploadedFile.absoluteFilePath.indexOf('?')));
    }

    return (
        isEditing ?
            <div>
                <FileUploadButton 
                    Purpose={purpose}
                    FileUploaded={(uploadedFile) => { onSave(uploadedFile) } }
                    IsUploading={(uploading) => { }}
                    Label='Upload an image'
                    MaxSizeInMb={2}
                    SupportedFormats='*.jpg|*.png' />
            </div>
            :
            <div style={{position:'relative', width:width, height:width}}>
                {uriValue === undefined || uriValue === null || uriValue.length === 0 ? 
                    <div style={{width:width, backgroundColor:'grey'}}><ImagePixelIcon style={{width:width, height:width, backgroundColor:'yellow'}} /></div> : 
                    <Image src={uriValue} width={width} imageFit={ImageFit.contain} />}                    
                <EditIcon
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        bottom: 5,
                        right: 5,
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        borderRadius: '50%',
                        padding: '4px',
                        transition: 'background-color 0.3s',
                    }}
                    onClick={() => setIsEditing(true)} />
            </div>
    )
}

export default EditableImage;