import { Stack, Text } from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { FC } from 'react';
import { HeartBrokenIcon } from '@fluentui/react-icons-mdl2';

interface IData {
    error: any;
    isSmall?: boolean;
}

const ErrorPage : FC<IData> = ({error, isSmall = false}) => {
    const appInsights = useAppInsightsContext();
    appInsights?.trackPageView({ name:"Error Page" });    

    var errorMessage = '';

    if(typeof error.error === 'string') {
        errorMessage = error.error;
    }
    else if(error.error as Error !== undefined) {
        errorMessage = (error.error as Error).message;
    }
    else {
        errorMessage = JSON.stringify(error.error);
    }

    const mailtoLink = `mailto:help@ninjapanel.com?subject=${encodeURIComponent('[Error] ' + errorMessage)}&body=${encodeURIComponent('Location: ' + document.location.host)}`;
    
    return (
            <div style={{backgroundColor:'white', padding:isSmall ? 0 : 80, marginLeft:'auto', marginRight:'auto', marginTop:50}}>                
                <Stack tokens={{childrenGap:10}} horizontalAlign='center'>
                <Text style={{fontSize: isSmall ? 90 : 200}} variant={isSmall ? 'large' : 'xxLargePlus'}><HeartBrokenIcon /></Text>
                    <br /><br /> 
                    <Text style={{fontWeight:'normal'}} variant={isSmall ? 'mediumPlus' : 'xLarge'}>Sorry friend, something went wrong and we can't load the page right now.</Text>
                    <br /><br />                     
                    <Text style={{fontWeight:'bolder'}} variant={isSmall ? 'small' : 'smallPlus'}>{errorMessage}</Text>
                    <Text style={{maxWidth: isSmall ? 'initial' : 500}} variant='small'><a href={mailtoLink}>Get help via email</a></Text>
                    <br /><br />
                    <hr style={{width:'100%', strokeWidth:1, color:'black'}} />
                </Stack>
            </div>
    )
}

export default ErrorPage;