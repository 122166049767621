export class SubmissionEnv {
    Filename: string;
    SasUri: string;
    ContainerName: string;
    Endpoint: string;
    
    constructor(
        filename: string,
        sasUri: string,
        containerName: string,
        endpoint: string) {
            this.Filename = filename;
            this.SasUri = sasUri;
            this.ContainerName = containerName;
            this.Endpoint = endpoint;
        }
}