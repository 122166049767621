import React, { FC, useEffect, useState } from 'react';
import { Selection, IColumn, SelectionMode, Stack, CheckboxVisibility, SearchBox, Text, ICommandBarItemProps, CommandBar, Panel, Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType, Modal, TextField } from '@fluentui/react';
import { useMsal, useAccount } from "@azure/msal-react";
import { getInvoicesForTournament, sendInvoiceReminder } from '../../../ApiService';
import Loader from '../../../components/Loader';
import List, { IDocument } from '../../../components/List';
import { UserProfile } from '../../../model/UserProfile';
import { useBoolean } from '@fluentui/react-hooks';
import { IInvoice, InvoiceStatus } from '../../../model/Invoice';
import { IInvoicesViewModel } from '../../../model/InvoicesViewModel';
import CreateInvoiceDialog from './CreateInvoiceDialog';

interface ITableRow extends IDocument {
    key: string;
    id: string;
    invoice: IInvoice;
    number: number;
    description: string;
    amount: number;
    user: string;
    sent: Date;    
    paid: Date | undefined;
    status: InvoiceStatus;
}


interface IData {
  tournamentId?: string;
}

const InvoiceManagement: FC<IData> = ({ tournamentId }) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [items, setItems] = useState<ITableRow[]>();
    const [allItems, setAllItems] = useState<ITableRow[]>();
    const [allInvoices, setAllInvoices] = useState<IInvoicesViewModel>();
    const [isTableLocked,] = useState<boolean>(false);
    const [selection, setSelection] = useState<Selection>(new Selection());

    const getRowColour = (status: InvoiceStatus) => {
        switch (status) {
            case InvoiceStatus.PendingNotice:
            case InvoiceStatus.Paid: {
                return 'initial';
            }
            case InvoiceStatus.Unpaid: {
                return 'red';
            }              
            case InvoiceStatus.Viewed: {
                return 'orange';
            }
        }
    }
    
    const [columns,] = useState<IColumn[]>([
        {
            key: 'column2',
            name: 'Number',
            fieldName: 'number',
            minWidth: 50,
            maxWidth: 60,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: true,            
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{item.number}</span>
            }
        },
        {
            key: 'column3',
            name: 'Description',
            fieldName: 'description',
            minWidth: 40,
            maxWidth: 400,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'string',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{item.description}</span>
            }
        },
        {
            key: 'column4a',
            name: 'Amount',
            fieldName: 'amount',
            minWidth: 60,
            maxWidth: 85,
            isRowHeader: true,
            isResizable: true,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'number',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.amount)}</span>
            }
        },
        {
            key: 'column5',
            name: 'Sent to',
            fieldName: 'user',
            minWidth: 50,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: true,
            isCollapsible: true,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            data: 'number',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{item.user}</span>
            }
        },
        {
            key: 'column6',
            name: 'Date Sent',
            fieldName: 'sent',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'date',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{item.sent.toLocaleString()}</span>;
            }
        },
        {
            key: 'column6a',
            name: 'Date Paid',
            fieldName: 'paid',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'date',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{item.paid === undefined ? '' : item.paid.toLocaleString()}</span>;
            }
        },
        {
            key: 'column9',
            name: 'Status',
            fieldName: 'status',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'date',
            isPadded: true,
            onRender: (item: ITableRow) => {
                return <span style={{ color: getRowColour(item.status) }}>{getStatusString(item.invoice)}</span>
            }
        }
    ]);
    
    const [errorCode, setErrorCode] = useState<number>();

    useEffect(() => {
        if (errorCode === undefined) {
            return;
        }

        throw new Error("The server returned status code: " + errorCode);
    }, [errorCode]);

    useEffect(() => {
        const fetchAsync = async () => {
            var allInvoices: IInvoicesViewModel | number;

            allInvoices = await getInvoicesForTournament(instance, account!, tournamentId!);

            if (typeof allInvoices === 'number') {
                setErrorCode(allInvoices);
                return;
            }
          
            setAllInvoices(allInvoices);
            setIsLoaded(true);
        }

        if (account) {
            fetchAsync();
        }
    }, [instance, account, tournamentId]);

    useEffect(() => {
        if (allInvoices === undefined) {
            return;
        }

        var tableRows = buildTableRows(allInvoices.Invoices, allInvoices.Users);
        setItems(tableRows);
        setAllItems(tableRows);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps     
    }, [allInvoices]);


    const doSearch = (n: string | undefined) => {
      
        if (n === undefined || n.length === 0) {
            //rebuild the default list
            var defaultList = buildTableRows(allInvoices!.Invoices, allInvoices!.Users);
            setItems(defaultList);
            return;
        }
  
        var filtered = allItems?.filter(i =>
            i.id.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
            i.description.toLowerCase().indexOf(n.toLowerCase()) !== -1 ||
            i.user.toLowerCase().indexOf(n.toLowerCase()) !== -1);

        setItems([...filtered!]);
    }

    const getStatusString = (invoice: IInvoice) => {
        switch (invoice.Status) {
            case InvoiceStatus.Paid: {
                return `Paid`;
            }
            case InvoiceStatus.Unpaid: {
                return "Unpaid";
            }
            case InvoiceStatus.PendingNotice: {
                return "Not sent";
            }
            case InvoiceStatus.Viewed: {
                return "Viewed";
            }
        }
    }

      const buildTableRows = (invoices: IInvoice[], users: UserProfile[]) => {
        var tableRows = new Array<ITableRow>();
            
        for(let invoice of invoices) {
            let title = `${invoice.Description}`;
            
            let t = invoice;
            
            tableRows.push({
                key: t.Id,
                invoice: t,
                amount: t.Total,
                sent: t.Created,
                paid: t.Paid,
                description: t.Description, 
                number: t.Number,
                id: t.Id,
                status: t.Status,
                user: users.find(u => u.Id === t.UserId)!.DisplayName,
                getTitle: ()=> title
            });
        }
  
        return tableRows;
      }

    const [selectedRow, setSelectedRow] = useState<ITableRow>();

    const onActiveItemChanged = (item?: ITableRow, index?: number) => {
        if (selection === undefined) { //do nothing to keep linter happy
            setSelectedRow(undefined);
        }
        else {
            setSelectedRow(item!);         
        }
    }

    const copyLink = () => {
        setCopyLinkText("Copy link");
        showCopyLinkModal();
    }

    const [isNewModalOpen, { setTrue: showNewModal, setFalse: hideNewModal }] = useBoolean(false);    

    const nearCommandBarItems: ICommandBarItemProps[] = [{
        key: 'new',
        text: 'New Invoice',
        iconProps: { iconName: 'Send' },
        disabled: false,
        onClick: () => showNewModal()
    },
    {
        key: 'copylink',
        text: 'Copy Link',
        iconProps: { iconName: 'Copy' },
        disabled: selectedRow === undefined || selectedRow.status === InvoiceStatus.Paid,
        onClick: () => copyLink()
    },
    {
        key: 'reminder',
        text: 'Send Reminder',
        iconProps: { iconName: 'MailReminder' },
        disabled: selectedRow === undefined || selectedRow.status === InvoiceStatus.Paid,
        onClick: () => toggleHideDialog()
    }];
    
    const updateInvoices = (invoice: IInvoice | undefined) => {
        if (invoice === undefined) {
            return;
        }

        var tr = buildTableRows([invoice], allInvoices!.Users);
        var updatedRows = [...items!, tr[0]];

        setItems(updatedRows);
        setAllItems(updatedRows);
    }
    
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogContentProps = {
      type: DialogType.normal,
      title: 'Confirm Reminder',
      closeButtonAriaLabel: 'Close',
      subText: 'Are you sure you want to send a reminder?',
    };
    
    const sendReminder = async () => {
        if (selectedRow === undefined || tournamentId === undefined) {
            return;
        }
        
        toggleHideDialog();
        await sendInvoiceReminder(instance, account!, tournamentId!, selectedRow.invoice.Id);
    }
        
    const [copyLinkText, setCopyLinkText] = useState<string>('Copy link');
    const [isCopyLinkModalOpen, { setTrue: showCopyLinkModal, setFalse: hideCopyLinkModal }] = useBoolean(false);

    return (
        <>
            {isLoaded ?
                items !== undefined && allInvoices !== undefined && allInvoices.Invoices.length > 0 ? 
                    <Stack tokens={{childrenGap:10}}>
                      <br />
                      <Stack.Item align='end'>
                        <SearchBox placeholder="Find invoice" onChange={(e,n) => doSearch(n)} />
                      </Stack.Item>
                      <Stack.Item>                       
                      </Stack.Item>
                      {tournamentId !== undefined ? 
                        <CommandBar
                          items={nearCommandBarItems} />
                        : null}
                        <List 
                            columns={columns}                             
                          isLocked={isTableLocked}
                          selectionMode={SelectionMode.single}   
                          enableSort                       
                          checkboxVisibility={CheckboxVisibility.hidden}
                          items={items}
                          onActiveItemChanged={onActiveItemChanged} 
                          onSelectionChanged={(s)=>setSelection(s)}/>
                    </Stack>
                    : <div style={{paddingTop:20, paddingLeft:10}}><Text>No invoices yet.</Text></div> 
                :
                <Loader Text='Just a moment...' />
            }
            <Panel
                 headerText='New Invoice'
                 isOpen={isNewModalOpen}
                 onDismiss={hideNewModal}
                 closeButtonAriaLabel="Close">
                <CreateInvoiceDialog
                    tournamentId={tournamentId!}
                    onCompleted={updateInvoices} />
            </Panel>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}
              >
                <DialogFooter>
                  <PrimaryButton onClick={() => sendReminder()} text="Send" />
                  <DefaultButton onClick={toggleHideDialog} text="Don't send" />
                </DialogFooter>
            </Dialog>
            <Modal
                  isOpen={isCopyLinkModalOpen}                  
                  onDismiss={hideCopyLinkModal}>
                  <Stack tokens={{childrenGap:10}} style={{padding:20}}>
                    <Text variant='xLarge'>Copy Invoice Link</Text>
                    <>
                        <br />
                      <Text variant='mediumPlus'>This link will only work for the membership that was invoiced.</Text>
                      <TextField readOnly value={`${document.location.protocol}://${document.location.host}/invoice/view/${selectedRow?.id}`}></TextField>
                      <PrimaryButton 
                        onClick={() => { navigator.clipboard.writeText(`${document.location.protocol}://${document.location.host}/invoice/view/${selectedRow?.id}`); setCopyLinkText('Link copied');}} 
                        disabled={copyLinkText === 'Link copied'}
                        text={copyLinkText} />
                    </>
                  </Stack>
                </Modal>
        </>
    );
}

export default InvoiceManagement;
