import React, { FC, useState } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import { Label, PrimaryButton, Stack, Dialog, DialogType, DialogFooter, SpinButton, TextField } from '@fluentui/react';
import { CouponType, createCoupon, ICoupon } from '../../../ApiService';
import { useAccount, useMsal } from "@azure/msal-react";
import Loader from '../../../components/Loader';
import { useBoolean } from '@fluentui/react-hooks';
import { CleanId } from '../../../Validation';
import DateEditor from '../../../components/DateEditor';

interface IData {
    tournamentId: string;

    onCompleted: (coupon: ICoupon) => void;
}

const CreateCouponDialog : FC<IData> = ({tournamentId, onCompleted}) => {  
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    
    const [id, setId] = useState<string>();
    const [amount, setAmount] = useState<number>(0);
    const [maxRedemptions, setMaxRedemptions] = useState<number | undefined>(undefined);
    const [maxRedemptionsPerMembership, setMaxRedemptionsPerMembership] = useState<number | undefined>(undefined);
    const [expiry, setExpiry] = useState<Date | undefined>();

    const requestCoupon = async () => {        
        if (id === undefined ||
            amount === 0) {
            toggleHideDialog();
            return;
        }

        setIsCreating(true);

        var result = await createCoupon(instance, account!,  {
            ActualRedemptions: 0,
            Id: id,
            TournamentId: tournamentId,
            Type: CouponType.DiscountAmount,
            Value: amount,
            MaxRedemptions: maxRedemptions,
            MaxRedemptionsPerMembership: maxRedemptionsPerMembership,
            Expiry: expiry
        });

        if (result) {
            onCompleted(result);
            return;
        }

        alert('Something went wrong. Please try again later.');
    }

    const [isCreating, setIsCreating] = useState<boolean>(false);

    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const dialogStyles = { main: { maxWidth: 450 } };
    
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Missing fields',
        closeButtonAriaLabel: 'Close',
        subText: 'Please enter both a description and an amount before proceeding.',
    };

    return (
          <AuthenticatedTemplate>
                <div>
                <div style={{ padding: 10 }}>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Label>Coupon Code</Label>
                            <TextField
                                disabled={isCreating}
                                inputMode='text'
                                placeholder='Enter a coupon code'
                                value={id === undefined ? "" : CleanId(id)}
                                onChange={(ev, newValue) => setId(CleanId(newValue!))}
                        />
                            <Label>Value</Label>
                            <SpinButton
                                placeholder='Enter the amount the coupon should discount from the total'
                                defaultValue={amount?.toString()}
                                min={5}
                                max={9999.99}
                                upArrowButtonStyles={{ root: { display: 'none' } }}
                                downArrowButtonStyles={{ root: {display:'none'} }}
                                step={0.01}
                                precision={2}
                                disabled={isCreating}
                                onChange={(ev, newValue) => setAmount(parseFloat(newValue!))} />
                            <Label>Max Redemptions</Label>
                            <SpinButton
                                placeholder='Enter the maximum number of times this coupon can be redeemed.'
                                defaultValue={maxRedemptions?.toString()}
                                min={1}
                                max={9999}
                                step={1}
                                precision={0}
                                disabled={isCreating}
                                onChange={(ev, newValue) => setMaxRedemptions(parseInt(newValue!))} />
                            <Label>Max Redemptions (per membership)</Label>
                            <SpinButton
                                placeholder='Enter the maximum number of times a single member can use this coupon.'
                                defaultValue={maxRedemptionsPerMembership?.toString()}
                                min={1}
                                max={10}
                                step={1}
                                precision={0}
                                disabled={isCreating}
                                onChange={(ev, newValue) => setMaxRedemptionsPerMembership(parseInt(newValue!))} />
                        <Label>Expiry</Label>
                            <DateEditor 
                                date={expiry}
                                onDateChange={(date) => setExpiry(date)} />
                            <br />
                            <PrimaryButton disabled={isCreating} onClick={requestCoupon}>Create Coupon</PrimaryButton>
                            {isCreating && <Loader Text="Just a moment..." />}
                        </Stack>
                    </div>
                </div>
            <Dialog
                hidden={hideDialog}
                styles={dialogStyles}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={{isBlocking:true}}>
                <DialogFooter>
                  <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
              </Dialog>
          </AuthenticatedTemplate>
    )
}

export default CreateCouponDialog;