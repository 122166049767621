import { Checkbox, DefaultButton, getTheme, IScrollablePaneStyles, mergeStyleSets, ScrollablePane, ScrollbarVisibility, Stack, Text } from '@fluentui/react';
import React, { FC, useState } from 'react';

export interface IData {
    terms: string;
    commitSelection: () => void
}

enum JudgingSelectionState {
    AcceptTerms = 0
}

const ConfirmJudgingSelection : FC<IData> = ({terms, commitSelection}) => {    
    const [status, ] = useState<JudgingSelectionState>(JudgingSelectionState.AcceptTerms);
    
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean>(false);
    
    const theme = getTheme();
    
    const scrollablePaneClassNames = mergeStyleSets({        
        pane: {
          border: '1px solid ' + theme.palette.neutralLight,
        },
        sticky: {
          color: theme.palette.neutralDark,
          padding: '5px 10px 5px 10px',
          fontSize: '13px',
          borderTop: '1px solid ' + theme.palette.black,
          borderBottom: '1px solid ' + theme.palette.black,
        }
      });

    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: scrollablePaneClassNames.pane };

    return (
        <>
            <Stack tokens={{childrenGap:10}} verticalAlign='stretch' verticalFill>
                <br />                
                {status === JudgingSelectionState.AcceptTerms ? 
                <Stack tokens={{childrenGap:10}}>
                    <Stack.Item align='start'>
                        <Text variant='large'>Accept Terms</Text>
                        <br />                    
                    </Stack.Item>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} style={{minHeight:'450px', position:'relative'}} styles={scrollablePaneStyles}>
                        <div style={{padding: '10px'}}>
                            <p dangerouslySetInnerHTML={{__html: terms}} />
                        </div>
                    </ScrollablePane>

                    <Checkbox onChange={(e,checked) => setHasAcceptedTerms(checked!) } label='Accept terms'/>
                </Stack> : null}                

                <Stack.Item align='end'>
                    {status === JudgingSelectionState.AcceptTerms ?
                        <DefaultButton disabled={!hasAcceptedTerms} onClick={()=> commitSelection()}>Submit</DefaultButton> :
                        null
                    }
                </Stack.Item>
            </Stack>
        </>
    );
}

export default ConfirmJudgingSelection;